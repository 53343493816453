var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _vm._m(0),
        !_vm.isForget
          ? [
              _c("login-form", {
                attrs: { isForget: _vm.isForget },
                on: { forgetPwd: _vm.forgetPwd }
              })
            ]
          : [
              _c("login-verify", {
                attrs: {
                  isForget: _vm.isForget,
                  isUpdate: _vm.isUpdate,
                  loading: _vm.loading
                },
                on: {
                  authClick: function($event) {
                    return _vm.authClick(arguments)
                  },
                  backLogin: _vm.backLogin
                }
              }),
              _c("login-update", {
                attrs: {
                  isUpdate: _vm.isUpdate,
                  loadingReset: _vm.loadingReset
                },
                on: { updateBtn: _vm.updateBtn }
              })
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-container" }, [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }