// getInfo
import adCache from '@u/stroageEncapsulation';
import { login, logout } from '@/api/user';
import { getToken, setToken, removeToken } from '@u/auth';
import { resetRouter } from '@/router';
// 默认的数据结构
const getDefaultState = () => {
  // getToken()
  return {
    token: getToken(),
    account: {
      auid: '',
      name: '',
      avatarUrl: '',
      registerTime: '',
      countryCode: '',
      gender: '',
      phone: '',
    },
    p: [],
    role: {
      name: '',
      roleId: '',
    },
    study: {
      studyId: '',
      name: '',
      sponsor: '',
    },
    custom_data: [],
  };
};

const state = getDefaultState();
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ACCOUNT: (state, account) => {
    state.account = { ...account };
    adCache.setlocalFun('account', account);
    adCache.setlocalFun('username', account.username || '');
  },
  SET_STUDY: (state, study) => {
    state.study = { ...study };
    adCache.setlocalFun('study', study);
  },
  // 权限列表
  SET_P: (state, p) => {
    state.p = p;
    adCache.setlocalFun('p', p);
  },
  SET_ROLE: (state, role) => {
    state.role = { ...role };
    adCache.setlocalFun('role', role);
  },
  SET_CUSTOM_DATA: (state, custom_data) => {
    state.custom_data = custom_data;
    adCache.setlocalFun('custom_data', custom_data);
  },
};

const actions = {
  login({ commit }, userInfo) {
    const { username, password, captcha } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, captcha: captcha })
        .then((response) => {
          commit('SET_ACCOUNT', response);
          commit('SET_TOKEN', response.auid);
          commit('SET_P', response.p);
          setToken(response.auid);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  wx_login({ commit }, response) {
    return new Promise((resolve, reject) => {
      commit('SET_ACCOUNT', response);
      commit('SET_TOKEN', response.auid);
      commit('SET_P', response.p);
      setToken(response.auid);
      resolve();
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  },
  // 设置权限列表
  p({ commit }, p) {
    commit('SET_P', p);
  },
  study({ commit }, study) {
    commit('SET_STUDY', study);
  },
  role({ commit }, role) {
    commit('SET_ROLE', role);
  },
  customData({ commit }, custom_data) {
    commit('SET_CUSTOM_DATA', custom_data);
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      logout(state.token).then(() => {
        commit('RESET_STATE');
        removeToken(); // must remove  token  first
        resetRouter();
        dispatch('permission/generateRoutes', '', { root: true });
        localStorage.clear();
        resolve();
      });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
