var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "item-box",
    { attrs: { classSuffix: "layout" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "列" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 24 },
            model: {
              value: _vm.data.setting.col,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "col", $$v)
              },
              expression: "data.setting.col"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0, max: 24 },
            model: {
              value: _vm.data.setting.gutter,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "gutter", $$v)
              },
              expression: "data.setting.gutter"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }