import InputConfig from './input';
import TextareaConfig from './textarea';
import PhoneConfig from './phone';
import PasswordConfig from './password';

import RadioConfig from './radio';
import CheckboxConfig from './checkbox';
import SelectConfig from './select';
import TimePickerConfig from './time-picker';
import TimePickerMultipleConfig from './time-picker-multiple';
import DatePickerConfig from './date-picker';
import DatePickerMultipleConfig from './date-picker-multiple';

import RateConfig from './rate';
import SwitchConfig from './switch';
import InputNumberConfig from './input-number';

import LinkConfig from './link';
import DividerConfig from './divider';
import LayoutConfig from './layout';

export default {
  InputConfig,
  TextareaConfig,
  PhoneConfig,
  PasswordConfig,

  RadioConfig,
  CheckboxConfig,
  SelectConfig,
  TimePickerConfig,
  TimePickerMultipleConfig,
  DatePickerConfig,
  DatePickerMultipleConfig,

  RateConfig,
  SwitchConfig,
  InputNumberConfig,

  LinkConfig,
  DividerConfig,
  LayoutConfig,
};
