<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="ms-cdash">
    <div class="header">
      <div class="conetent centre">
        <div class="title">
          <img src="@/assets/img/logo.png" />
        </div>
        <el-button type="text" style="font-size: 16px" class="back" @click="$router.push('/')"> 返回 </el-button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="ms-cdash-container app-container">
      <div class="ms-cdash-operate">
        <search-box
          :keyword.sync="keyword"
          @handleSearchClick="handleSearchClick(cdashTemplateList)"
          @handleClearInput="handleClearInput(cdashTemplateList)"
        />
        <div class="operation-box">
          <el-button type="success" size="medium" icon="el-icon-plus" @click="handleAddClick"> 添加CDASH </el-button>
          <el-button type="success" size="medium" icon="el-icon-plus" @click="handleDataExport"> 导出数据 </el-button>
          <el-button type="success" size="medium" icon="el-icon-plus" @click="handleDataImport"> 批量导入 </el-button>
          <!-- <el-button type="success" size="medium" icon="el-icon-plus" @click="handleAddClick"> 批量删除 </el-button> -->
        </div>
      </div>
      <div class="ms-cdash-table">
        <el-table
          v-loading="loading"
          style="width: 100%"
          :data="tableData"
          :header-cell-style="{ background: '#F5F6F8', color: '#191D30' }"
          @filter-change="handleFilterChange"
          @row-dblclick="handleDetail"
        >
          <el-table-column
            label="观察类别"
            prop="observationClass"
            width="100"
            column-key="observationClass"
            :filters="observationClass"
            :filter-multiple="false"
            show-overflow-tooltip
          />
          <el-table-column
            label="域"
            prop="domain"
            width="80"
            column-key="domain"
            :filters="domain"
            :filter-multiple="false"
            show-overflow-tooltip
          />
          <el-table-column
            label="数据收集情形"
            prop="dataCollectionScenario"
            width="120"
            column-key="dataCollectionScenario"
            :filters="dataCollectionScenario"
            :filter-multiple="false"
            show-overflow-tooltip
          />
          <el-table-column
            label="实施选项"
            prop="implementationOptions"
            width="100"
            column-key="implementationOptions"
            :filters="implementationOptions"
            :filter-multiple="false"
            show-overflow-tooltip
          />
          <el-table-column show-overflow-tooltip label="变量" prop="cdashigVariable" width="150" />
          <el-table-column show-overflow-tooltip label="标签" prop="cdashigVariableLabel" width="200" />
          <el-table-column show-overflow-tooltip label="定义" prop="draftCdashigDefinition" width="200" />
          <el-table-column show-overflow-tooltip label="示例" prop="caseReportFormCompletionInstructions" width="200" />
          <el-table-column show-overflow-tooltip label="数据类型" prop="dataType" width="100" />
          <el-table-column show-overflow-tooltip label="核心类型" prop="cdashigCore" width="100" />
          <el-table-column show-overflow-tooltip label="提示" prop="prompt" width="200" />
          <el-table-column show-overflow-tooltip label="实施说明" prop="implementationNotes" min-width="100" />
          <el-table-column label="操作" fixed="right" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)"> 查看 </el-button>
              <el-button type="text" :disabled="scope.row.status === 'complete'" @click="handleEditClick(scope.row)">
                编辑
              </el-button>
              <el-button type="text" :disabled="scope.row.status === 'complete'" @click="handleDelClick(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        :request="cdashTemplateList"
        :page.sync="pagination.page"
        :pageSize.sync="pagination.pageSize"
        :total="total"
      />
      <cdash-dialog
        :dialog.sync="dialog"
        :title="title"
        :dialogType="dialogType === 'edit'"
        :formData.sync="formData"
        @handleSubmitClick="handleSubmitClick"
      />
    </div>
    <detail-drawer :visible.sync="drawer" :cdashItem="cdashItem"></detail-drawer>
    <data-import :visible.sync="dataImportVisible" :cdashItem="cdashItem"></data-import>
  </div>
</template>

<script>
import page from '@/static/mixin/page';
import search from '@c/SearchBox/search';
import CdashDialog from './components/CdashDialog.vue';
import DetailDrawer from './components/DetailDrawer.vue';
import DataImport from './components/DataImport.vue';

export default {
  name: 'MsCdash',
  components: { CdashDialog, DetailDrawer, DataImport },
  filters: {},
  mixins: [page, search],
  props: {},
  data() {
    return {
      map: {
        observationClass: [],
        domain: [],
        dataCollectionScenario: [],
        implementationOptions: [],
      },
      filterTable: {
        observationClass: null,
        domain: null,
        dataCollectionScenario: null,
        implementationOptions: null,
      },
      tableData: [],
      // 弹窗
      dialog: false,
      dialogType: null,
      title: null,
      formData: null,
      // loading
      loading: false,
      drawer: false,
      dataImportVisible: false,
      cdashItem: {},
    };
  },
  computed: {
    observationClass() {
      const observationClass = this.map.observationClass;
      const map = observationClass.map((m) => {
        return { text: m, value: m };
      });
      return map;
    },
    domain() {
      const domain = this.map.domain;
      const map = domain.map((m) => {
        return { text: m, value: m };
      });
      return map;
    },
    dataCollectionScenario() {
      const dataCollectionScenario = this.map.dataCollectionScenario;
      const map = dataCollectionScenario.map((m) => {
        return { text: m, value: m };
      });
      return map;
    },
    implementationOptions() {
      const implementationOptions = this.map.implementationOptions;
      const map = implementationOptions.map((m) => {
        return { text: m, value: m };
      });
      return map;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.cdashTemplateList();
  },
  methods: {
    cdashTemplateList() {
      this.loading = true;
      this.$post(this.urls.cdash_template.list, {
        keyword: this.keyword,
        ...this.filterTable,
        ...this.pagination,
      })
        .then(({ data, map, total }) => {
          this.tableData = data;
          this.map = map;
          this.total = total;
          this.loading = false;
        })
        .catch(() => {});
    },
    handleAddClick() {
      this.dialog = true;
      this.title = '创建CDASH';
      this.dialogType = 'create';
    },
    handleDataExport() {
      this.$downloadFile('/api/v1/' + this.urls.cdash_template.data_export, {}, 'cdash数据导出.xlsx');
    },
    handleDataImport() {
      this.dataImportVisible = true;
    },
    handleEditClick(row) {
      this.dialog = true;
      this.title = 'CDASH编辑';
      this.dialogType = 'edit';
      this.formData = row;
    },
    handleDelClick(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.cdashTemplateDel(row.id);
        })
        .catch(() => {});
    },
    handleSubmitClick(params) {
      this.$post(this.urls.cdash_template.save, params).then(() => {
        this.$message.success(this.dialogType === 'edit' ? '更新成功' : '创建成功');
        this.cdashTemplateList();
      });
    },
    cdashTemplateDel(id) {
      this.$post(this.urls.cdash_template.delete, { id }).then(() => {
        this.cdashTemplateList();
        this.$message.success('删除成功');
      });
    },
    handleFilterChange(data) {
      const key = Object.keys(data)[0];
      this.filterTable[key] = data[key].length === 0 ? null : data[key][0];
      this.cdashTemplateList();
    },
    handleDetail(row) {
      this.cdashItem = row;
      this.drawer = true;
    },
  },
};
</script>
<style lang="scss">
.el-table-filter {
  max-height: 300px;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.ms-cdash {
  background: #f6f7f9;
  height: 100%;
  &-container {
    margin-top: 20px;
    background-color: #fff;
  }
  &-operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-table {
    margin: 20px 0;
  }
}
.header {
  display: flex;
  align-items: center;
  background: #fff;
  .centre {
    width: 980px;
    margin: 0 auto;
  }
  .conetent {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
    .title {
      color: #303843;
      img {
        height: 40px;
      }
    }
    .back {
      color: #409eff;
    }
  }
}
</style>
