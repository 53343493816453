<template>
  <!-- {{ data[`${data.itemId}_${data.itemName}`] }} -->
  <div>
    <el-input :placeholder="`请输入${data.itemLabel}`" />
  </div>
</template>

<script>
export default {
  name: "AdInput",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
