// 参数过滤
export default function parametFilter(obj) {
  const newObj = {};
  for (const key in obj) {
    if (
      (typeof obj[key] === 'string' || typeof obj[key] === 'number') &&
      obj[key] !== ''
    ) {
      newObj[key] = obj[key];
    } else if (
      JSON.stringify(obj[key]) !== '{}' &&
      obj[key] instanceof Object &&
      !Array.isArray(obj[key])
    ) {
      newObj[key] = obj[key];
    } else if (Array.isArray(obj[key]) && obj[key].length !== 0) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}
