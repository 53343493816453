const state = {
  stepsStatus: 1,
};

const mutations = {
  SET_STEPSTATUS: (state, stepsStatus) => {
    state.stepsStatus = stepsStatus;
  },
};

const actions = {
  status({ commit }, status) {
    commit('SET_STEPSTATUS', status);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
