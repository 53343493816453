var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          model: {
            value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
            callback: function($$v) {
              _vm.$set(
                _vm.model[_vm.itemItemsCode],
                "" + _vm.data.itemCode,
                $$v
              )
            },
            expression: "model[itemItemsCode][`${data.itemCode}`]"
          }
        },
        _vm._l(_vm.data.configuration.allowableValues, function(item) {
          return _c("el-option", {
            key: item.label,
            attrs: { label: item.label, value: item.label }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }