var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemItemsCode
        ? _c("el-input", {
            attrs: {
              placeholder: "请输入" + _vm.data.itemLabel,
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              rows: 2
            },
            model: {
              value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
              callback: function($$v) {
                _vm.$set(
                  _vm.model[_vm.itemItemsCode],
                  "" + _vm.data.itemCode,
                  $$v
                )
              },
              expression: "model[itemItemsCode][`${data.itemCode}`]"
            }
          })
        : _c("el-input", {
            attrs: {
              placeholder: "请输入" + _vm.data.itemLabel,
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              rows: 2
            },
            model: {
              value: _vm.model["" + _vm.data.itemCode],
              callback: function($$v) {
                _vm.$set(_vm.model, "" + _vm.data.itemCode, $$v)
              },
              expression: "model[`${data.itemCode}`]"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }