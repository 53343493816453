<template>
  <div class="log">
    <el-table
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="ip"
        label="IP"
        width="180"
      />
      <el-table-column
        prop="operationEvent"
        label="操作"
        width="180"
      >
        <template v-slot="scope">
          <span>{{
            filterOperation(scope.row.operationEvent)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="afterOperation"
        label="操作前"
        width="180"
      />
      <el-table-column
        prop="beforeOperation"
        label="操作后"
        width="180"
      />
      <el-table-column
        prop="operationTime"
        label="操作时间"
      >
        <template v-slot="scope">
          <span>{{
            scope.row.operationTime | parseTime("{y}-{m}-{d}  {h}:{i}:{s}")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页
      currentPage: 0,
      total: 0,
      tableData: [],
      operation: [
        { text: '微信扫码', value: 'loginByWeChat' },
        { text: '小程序登录', value: 'loginByApplet' },
        { text: '手机登录', value: 'loginByPhone' },
        { text: '邮箱登录', value: 'loginByEmail' },
        { text: '帐号登录', value: 'loginByPwd' },
        { text: '绑定手机', value: 'bindPhone' },
        { text: '绑定邮箱', value: 'bindEmail' },
        { text: '更换手机', value: 'updatePhone' },
        { text: '更换邮箱', value: 'updateEmail' },
        { text: '解绑微信', value: 'unbindWeChat' },
        { text: '绑定微信', value: 'bindWeChat' },
        { text: '更新个人信息', value: 'updateInfo' },
        { text: '重置密码', value: 'resetPwd' },
        { text: '更新密码', value: 'updatePwd' },

      ],
    };
  },
  created() {
    this.getLogList();
  },
  methods: {
    handleCurrentChange(val) {
      this.getLogList(val);
    },
    filterOperation(type) {
      let text = type;
      this.operation.map((item) => {
        if (item.value === type) {
          text = item.text;
        }
      });
      return text;
    },
    getLogList(page = 1) {
      this.$post(this.urls.operation_log.account, {
        page: page,
        pageSize: 10,
      })
        .then((result) => {
          this.total = result.total;
          this.tableLoading = false;
          this.tableData = result.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.log{
    .block {
    margin-top: 20px;
  }
}

</style>
