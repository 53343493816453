import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';
const login = () => import('@/views/login/index');
const activate = () => import('@/views/account/activate');
const changpwd = () => import('@/views/account/changpwd');
const binding = () => import('@/views/account/binding');
const accountList = () => import('@/views/account/list');
// 研究方案
const experiment = () => import('@/views/experiment');
const experimentAdd = () => import('@/views/experiment/add');
// 模板
const template_sys = () => import('@/views/template/sys');
const tags = () => import('@/views/tags/add');
const user = () => import('@/views/account/user');
const caseReport = () => import('@/views/caseReport');
const caseReportList = () => import('@/views/caseReport/list');
const caseReportEdit = () => import('@/views/caseReport/edit');
const patientList = () => import('@/views/caseReport/patientList');
const cdash = () => import('@/views/cdash/');
export const constantRoutes = [
  {
    path: '/login',
    title: '登录',
    name: 'login',
    component: login,
    hidden: true,
  },
  {
    path: '/account/activate',
    title: '激活',
    component: activate,
    hidden: true,
  },
  {
    path: '/account/changpwd',
    title: '修改登录密码',
    name: 'changpwd',
    component: changpwd,
    hidden: true,
  },
  {
    path: '/account/binding',
    title: '绑定',
    name: 'binding',
    component: binding,
    hidden: true,
  },
  {
    path: '/account/user',
    name: 'user',
    component: user,
    title: '账户',
  },
  {
    path: '/caseReport',
    name: 'caseReport',
    component: caseReport,
    title: '病历',
  },
  {
    path: '/caseReport/list',
    name: 'caseReport_list',
    component: caseReportList,
    title: '病历列表',
  },
  {
    path: '/caseReport/edit',
    name: 'caseReport_edit',
    component: caseReportEdit,
    title: '病历编辑',
  },
  {
    path: '/caseReport/patientList',
    name: 'patientList',
    component: patientList,
    title: '推荐病历',
  },
  {
    path: '/cdash',
    name: 'Cdash',
    component: cdash,
    title: 'CDASH',
    hidden: true,
  },
  {
    path: '/experiment',
    name: 'experiment',
    component: experiment,
    hidden: true,
  },
  // TODO: 路由可能存在问题
  {
    path: '/experiment/add',
    name: 'experimentAdd',
    component: experimentAdd,
    title: '试验列表添加',
  },
  {
    path: '/account/user',
    name: 'accountUser',
    component: user,
    title: '个人中心',
  },
  {
    path: '/account/list',
    name: 'accountList',
    component: accountList,
    title: '用户管理',
  },
  {
    path: '/',
    component: Layout,
    redirect: '/experiment',
    children: [
      {
        path: '/account/update/pwd',
        meta: { title: '修改密码', icon: 'dashboard' },
        name: 'updatePwd',
        component: () => import('@/views/account/update/pwd'),
        hidden: true,
      },
    ],
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true,
  },
];
export const asyncRoutes = [
  // {
  //   path: '/template',
  //   redirect: '/research/sys',
  //   component: Layout,
  //   meta: { title: '模板', icon: 'el-icon-s-check' },
  //   children: [
  //     {
  //       path: 'sys',
  //       name: 'sysTemplate',
  //       component: template_sys,
  //       meta: { title: '研究配置' },
  //     },
  //   ],
  // },
  {
    path: '/template',
    redirect: '/template/sys',
    component: Layout,
    meta: { title: '模板', icon: 'el-icon-s-check' },
    children: [
      {
        path: 'sys',
        name: 'templateSys',
        component: template_sys,
        meta: { title: 'CRF模板管理' },
      },
    ],
  },
  {
    path: '/tags',
    redirect: '/tags/add',
    component: Layout,
    meta: { title: '添加表格', icon: 'el-icon-s-check' },
    children: [
      {
        path: 'add',
        name: 'tags',
        component: tags,
        meta: { title: '标签管理' },
      },
    ],
  },
  { path: '*', redirect: '/404', hidden: true },
];
const createRouter = () =>
  new Router({
    mode: 'history',
    base: '/',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });
const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
