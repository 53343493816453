<template>
  <div class="operator_item">
    <div v-if="['hv', 'nv'].indexOf(ruleItem.rule.operator) === -1 && ruleItem.rule.operator" class="">
      <template v-if="ruleItem.rule.operator !== 'in'">
        <el-input
          v-if="ruleItem.rule.formType === 'input'"
          v-model="ruleItem.rule.comparedValue"
          placeholder="请输入条件"
        />
        <el-input
          v-if="'timePicker' == ruleItem.rule.formType"
          v-model="ruleItem.rule.comparedValue"
          placeholder="请输入条件"
          @input="handelValueInput"
        />
        <el-time-picker
          v-if="'timePicker' === ruleItem.rule.formType"
          v-model="ruleItem.rule.comparedValue"
          placeholder="请选择入条件"
        />

        <el-date-picker
          v-if="ruleItem.rule.formType === 'datePicker' "
          v-model="ruleItem.rule.comparedValue"
          :placeholder="`请选择入条件`"
          :default-value="day"
          value-format="timestamp"
        />
        <div v-if="ruleItem.rule.formType === 'select'" class="select_list">
          <div v-if="['cancerSubTypeId', 'city'].includes(ruleItem.rule.code)">
            <div v-if="ruleItem.rule.code === 'cancerSubTypeId'">
              <el-select v-model="ruleItem.rule.supplement" style="margin-right: 24px" placeholder="请选择">
                <el-option
                  v-for="item in allowableValues"
                  :key="item.cancerTypeGroupId"
                  :label="item.cnName"
                  :value="item.cancerTypeGroupId"
                />
              </el-select>
              <el-select v-model="ruleItem.rule.comparedValue" placeholder="请选择">
                <el-option
                  v-for="item in getCancerSubTypes()"
                  :key="item.cnName"
                  :label="item.cnName"
                  :value="item.cnName"
                />
              </el-select>
            </div>
            <div v-if="ruleItem.rule.code === 'city'">
              <el-select v-model="ruleItem.rule.supplement" style="margin-right: 24px" placeholder="请选择">
                <el-option v-for="item in allowableValues" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
              <el-select v-model="ruleItem.rule.comparedValue" placeholder="请选择">
                <el-option v-for="item in getCityList()" :key="item.name" :label="item.name" :value="item.name" />
              </el-select>
            </div>
          </div>
          <div v-else>
            <el-select
              v-if="ruleItem.rule.code === 'cancerTypeGroupId'"
              v-model="ruleItem.rule.comparedValue"
              placeholder="请选择"
            >
              <el-option v-for="item in allowableValues" :key="item.cnName" :label="item.cnName" :value="item.cnName" />
            </el-select>
            <el-select
              v-else-if="ruleItem.rule.code === 'province'"
              v-model="ruleItem.rule.comparedValue"
              placeholder="请选择"
            >
              <el-option v-for="item in allowableValues" :key="item.name" :label="item.name" :value="item.name" />
            </el-select>
            <el-select v-else v-model="ruleItem.rule.comparedValue" placeholder="请选择">
              <el-option v-for="item in allowableValues" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="ruleItem.rule.formType === 'numInput'" class="select_list">
          <el-input
            v-model="ruleItem.rule.startRange"
            style="width:187px"
            placeholder="请输入条件"
            @input="handelValueInput"
          />
          -
          <el-input
            v-model="ruleItem.rule.endRange"
            style="width:187px"
            placeholder="请输入条件"
            @input="handelValueInput"
          />
        </div>
        <div v-if="ruleItem.rule.formType === 'datePicker'" class="select_list">
          <el-date-picker
            v-model="ruleItem.rule.startRange"
            :placeholder="`请选择入条件`"
            :default-value="day"
            value-format="timestamp"
          />
          -
          <el-date-picker
            v-model="ruleItem.rule.endRange"
            :placeholder="`请选择入条件`"
            :default-value="day"
            value-format="timestamp"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ruleItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      allowableValues: [],
      day: new Date().getTime(),
    };
  },
  inject: ['Parent'],

  created() {
    if (this.ruleItem.rule.formType === 'select') {
      this.getSelections();
    }
  },
  methods: {
    handelValueInput(e) {
      this.ruleItem.rule.comparedValue = this.numFilter(e);
    },
    numFilter(value) {
      let num = '';
      const regStr = `^\\d*(\\.?\\d{0,${4}})`;
      const reg = new RegExp(regStr, 'g');
      num = value.match(reg)[0];
      return num;
    },
    getSelections() {
      let arr = [];
      let ruleItem = this.ruleItem;
      if (['cancerTypeGroupId', 'cancerSubTypeId'].includes(ruleItem.rule.code)) {
        arr = this.Parent.cancerType;
      } else if (['province', 'city'].includes(ruleItem.rule.code)) {
        arr = this.Parent.cityJson;
      } else {
        this.Parent.reportFormItems.map((item) => {
          if (item.itemCode === ruleItem.code) {
            item.repotItems.map((items) => {
              if (items.itemCode === ruleItem.rule.code) {
                arr = items.configuration.allowableValues;
              }
            });
          }
        });
      }

      this.allowableValues = arr;
    },
    getCityList() {
      let arr = [];
      this.allowableValues.map((item) => {
        if (item.code === this.ruleItem.rule.supplement) {
          arr = item.children;
        }
      });
      return arr;
    },
    getCancerSubTypes() {
      let arr = [];
      this.allowableValues.map((item) => {
        if (item.cancerTypeGroupId === this.ruleItem.rule.supplement) {
          arr = item.cancerSubTypes;
        }
      });
      return arr;
    },
  },
};
</script>

<style></style>
