<template>
  <el-time-picker
    v-if="model"
    v-model="model[data.fieldId]"
    is-range
    :range-separator="data.setting.rangeSeparator"
    :start-placeholder="data.setting.startPlaceholder"
    :end-placeholder="data.setting.endPlaceholder"
    :picker-options="{
      selectableRange: data.setting.selectableRange,
      format: data.setting.format
    }"
    :prefix-icon="data.setting.prefixIcon"
    :placeholder="data.placeholder"
    @focus="focus"
  />
  <el-time-picker
    v-else
    v-model="data.value"
    is-range
    :range-separator="data.setting.rangeSeparator"
    :start-placeholder="data.setting.startPlaceholder"
    :end-placeholder="data.setting.endPlaceholder"
    :picker-options="{
      selectableRange: data.setting.selectableRange,
      format: data.setting.format
    }"
    :prefix-icon="data.setting.prefixIcon"
    :placeholder="data.placeholder"
    @focus="focus"
  />
</template>

<script>
export default {
  name: 'TpTimePickerMultiple',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    focus() {
      if (Array.isArray(this.data.value) && this.data.value.length === 0) {
        this.data.value = [new Date(), new Date()];
      }
    },
  },
};
</script>
