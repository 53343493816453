<template>
  <div v-loading="loading" class="recommend_patient" element-loading-text="加载中...">
    <div class="recommend_patient_header">
      <div
        v-for="(item, index) in patientPeopleNumber"
        :key="index"
        class="patient_people_number"
        @click="onMatchRate(item)"
      >
        <div class="people_number">
          {{ peopleNumber[index] }}
        </div>
        <div class="people_text">
          <span :class="matchRate.indexOf(item.matchRate) >= 0 ? `${item.color}` : `${item.color} is-disabled`" />{{
            item.text
          }}
        </div>
      </div>
    </div>
    <div class="recommend_patient_list">
      <el-row v-if="recommendPatientArr.length > 0" :gutter="20">
        <el-col v-for="(item, index) in recommendPatientArr" :key="index" :span="8">
          <div v-if="getRandomNumber(parseInt(item._studyMatchRate * 100))" class="recommend_patient_item">
            <div class="patient_number">
              患者编号：{{ item.randomNumber }}
              <div class="disease">
                <el-tag type="info" size="small">
                  {{ item.information.cancerTypeGroupId }}
                </el-tag>
              </div>
              <div class="disease">
                <el-tag type="info" size="small">
                  {{ item.information.cancerSubTypeId }}
                </el-tag>
              </div>
            </div>
            <div class="patient_item_top">
              <div class="patient_detail">
                <div class="patient_detail_item">
                  <span class="detail_item_label">姓名</span>：{{ item.information.name }}
                </div>
                <div class="patient_detail_item">
                  <span class="detail_item_label">年龄</span>：{{ item.information.age }}
                </div>
                <div class="patient_detail_item">
                  <span class="detail_item_label">联系方式</span>：{{ item.information.phone }}
                </div>
                <div class="patient_detail_item">
                  <span class="detail_item_label">地区</span>：{{ item.information.province }}
                </div>
                <div class="patient_detail_item">
                  <span class="detail_item_label">负责人</span>：{{ item.dataEntryClerkName }}
                </div>
              </div>
              <div
                class="matched-degree"
                :style="`border: 1px solid ${getColor(parseInt(item._studyMatchRate * 100))}`"
              >
                <div :class="`matched-degree_progress ${getColorName(parseInt(item._studyMatchRate * 100))} `">
                  <el-progress
                    type="circle"
                    :width="100"
                    :stroke-width="6"
                    :percentage="parseInt(item._studyMatchRate * 100)"
                    :color="colors"
                  />
                </div>
                <div class="matched-degree_text">
                  项目匹配度
                </div>
              </div>
            </div>
            <div class="patient_item_bottom">
              <div class="button" @click="acceptPatient()">
                同意推荐
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-else class="emptys">
        <img src="@/assets/wsj.png" alt="暂无数据" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        { color: '#FD5451', percentage: 25 },
        { color: '#FFBA36', percentage: 60 },
        { color: '#51C979', percentage: 100 },
      ],
      patientPeopleNumber: [
        {
          text: '匹配率≥60',
          color: 'people_text_a',
          matchRate: 'A',
        },
        {
          text: '匹配率60~25',
          color: 'people_text_b',
          matchRate: 'B',
        },
        {
          text: '匹配率＜25',
          color: 'people_text_c',
          matchRate: 'C',
        },
      ],

      activeName: 'patient',
      recommendPatientArr: [],
      loading: false,
      peopleNumber: [0, 0, 0],
      matchRate: ['A', 'B', 'C'],
    };
  },
  created() {
    this.getRecommendPatient();
  },
  methods: {
    getRandomNumber(num) {
      if (num >= 60) {
        return this.matchRate.includes('A');
      } else if (num < 60 && num >= 25) {
        return this.matchRate.includes('B');
      } else {
        return this.matchRate.includes('C');
      }
    },
    getColor(num) {
      if (num >= 60) {
        return '#DCF4E4';
      } else if (num < 60 && num >= 25) {
        return '#FFF1D6';
      } else {
        return '#FFDDDC;';
      }
    },
    getColorName(num) {
      if (num >= 60) {
        return 'color_51C979';
      } else if (num < 60 && num >= 25) {
        return 'color_FFBA36';
      } else {
        return 'color_FD5451;';
      }
    },
    acceptPatient() {
      this.$confirm(`后续医助会联系患者。`, '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showClose: false,
      })
        .then(() => {})
        .catch(() => {});
    },
    getRecommendPatient() {
      this.loading = true;
      this.$post(this.urls.sick_mr.query_match_list, {
        studyId: this.$route.query.studyId,
      })
        .then((res) => {
          this.loading = false;
          this.recommendPatientArr = res;
          res.map((item) => {
            let { _studyMatchRate } = item;

            let num = parseInt(_studyMatchRate * 100);
            if (num >= 60) {
              this.peopleNumber[0] = this.peopleNumber[0] + 1;
            } else if (num < 60 && num >= 25) {
              this.peopleNumber[1] = this.peopleNumber[1] + 1;
            } else {
              this.peopleNumber[2] = this.peopleNumber[2] + 1;
            }
          });
        })
        .catch(() => {});
    },
    onMatchRate(item) {
      let index = this.matchRate.indexOf(item.matchRate);
      if (index >= 0) {
        this.matchRate.splice(index, 1);
      } else {
        this.matchRate.push(item.matchRate);
      }
    },
  },
};
</script>
<style lang="scss">
.recommend_patient {
  .matched-degree {
    .matched-degree_progress {
      .el-progress__text {
        font-size: 16px !important;
        font-weight: bold;
      }
    }
    .color_51C979 {
      .el-progress__text {
        color: #51c979;
      }
    }
    .color_FFBA36 {
      .el-progress__text {
        color: #ffba36;
      }
    }
    .color_FD5451 {
      .el-progress__text {
        color: #fd5451;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.recommend_patient {
  min-height: 500px;
  ::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性

    background-color: #f8f8f8;
  }

  ::-webkit-scrollbar {
    //滚动条的宽度

    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    //滚动条的设置

    background-color: #dddddd;

    background-clip: padding-box;

    min-height: 28px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .recommend_patient_list {
    margin-top: 20px;
    height: calc(100vh - 260px);
    overflow: hidden;
    overflow-y: auto;
    padding-right: 8px;
  }
  .emptys {
    margin: 20px auto;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  .recommend_patient_header {
    display: flex;
    justify-content: flex-end;
    padding-right: 36px;
    .patient_people_number {
      text-align: center;
      padding: 0 12px;
      cursor: pointer;
      .people_number {
        font-size: 24px;

        color: #323b46;
      }
      .people_text {
        margin-top: 4px;
        font-size: 14px;

        font-weight: 400;
        line-height: 20px;
        color: #989da4;
        display: flex;
        align-items: center;
        > span {
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-right: 4px;
        }
        .people_text_a {
          background: #51c979;
        }
        .people_text_b {
          background: #ffba36;
        }
        .people_text_c {
          background: #fd5451;
        }
        .is-disabled {
          opacity: 0.4;
        }
      }
    }
  }
  .recommend_patient_item {
    height: 260px;
    margin-top: 24px;
    border: 1px solid #c3cad2;
    border-radius: 4px;
    position: relative;
    .patient_number {
      font-size: 16px;

      font-weight: bold;
      color: #323b46;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 24px;
      margin-top: 24px;
      .disease {
        padding-left: 10px;
        max-width: 120px;
      }
    }
    .patient_item_top {
      padding: 0 24px;
      display: flex;

      .patient_detail {
        flex: 1;
        padding-right: 24px;

        .patient_detail_item {
          font-size: 14px;

          font-weight: 400;
          line-height: 20px;
          color: #323b46;
          margin: 4px 0;
          > span {
            display: inline-block;
            width: 70px;
            text-align-last: justify;
          }
        }
      }
      .matched-degree {
        border: 1px solid #fff5f5;
        border-radius: 50%;
        padding: 10px;
        height: 120px;
        width: 120px;
        position: relative;
        .matched-degree_text {
          position: absolute;
          bottom: -26px;
          text-align: center;
          width: 100%;
          right: 0px;
          font-size: 14px;

          color: #323b46;
        }
      }
    }
    .patient_item_bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40px;
      background: #f5f8fd;
      border-top: 1px solid #c3cad2;
      border-radius: 0px 0px 4px 4px;
      display: flex;
      .button {
        margin: 0 auto;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #1e87ff;
        cursor: pointer;
      }
    }
  }
}
</style>
