var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ad-mf-select-icon",
      attrs: { title: "图标选择", visible: _vm.dialogVisible, width: "600px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    _vm._l(_vm.iconList, function(icon) {
      return _c(
        "div",
        {
          key: icon,
          class: [
            "ad-mf-select-icon--item",
            { "ad-mf-select-icon--item__active": icon === _vm.i }
          ],
          on: {
            click: function($event) {
              return _vm.selectItem(icon)
            }
          }
        },
        [_c("i", { class: icon })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }