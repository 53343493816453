<template>
  <div class="ad-date-picker">
    <div v-if="itemItemsCode">
      <el-date-picker
        v-if="show"
        v-model="model[itemItemsCode][`${data.itemCode}`]"
        :picker-options="pickerTime"
        :placeholder="`选择${data.itemLabel}`"
        :default-value="data.configuration.defaultValue || day"
        value-format="timestamp"
      />
    </div>
    <div v-else>
      <el-date-picker
        v-if="show"
        v-model="model[`${data.itemCode}`]"
        :picker-options="pickerTime"
        :placeholder="`选择${data.itemLabel}`"
        :default-value="data.configuration.defaultValue || day"
        value-format="timestamp"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MsInput',

  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
    itemItemsCode: {
      type: String,
      default: '',
    },
  },
  data() {
    let that = this;
    return {
      pickerTime: {
        disabledDate(time) {
          return time.getTime() < that.data.configuration.minDate || time.getTime() > that.data.configuration.maxDate;
        },
      },
      day: new Date().getTime(),
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.ad-date-picker {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
