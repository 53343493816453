<template>
  <div class="account-update-pwd-container">
    <el-form
      ref="accountForm"
      :model="accountForm"
      :rules="rules"
      class="pwd-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          修改密码
        </h3>
      </div>
      <el-form-item prop="oldPassword">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="pass"
          ref="oldPassword"
          v-model="accountForm.oldPassword"
          :type="oldPassword"
          placeholder="旧密码"
          name="oldPassword"
          tabindex="2"
          auto-complete="on"
        />
        <span
          class="show-pwd"
          @click="handleShowPwd('oldPassword')"
        >
          <svg-icon :icon-class="oldPassword === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="pass"
          ref="pass"
          v-model="accountForm.password"
          :type="pass"
          placeholder="新密码"
          name="password"
          tabindex="2"
          auto-complete="on"
        />
        <span
          class="show-pwd"
          @click="handleShowPwd('pass')"
        >
          <svg-icon :icon-class="pass === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>
      <el-form-item prop="pass2">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="pass2"
          ref="pass2"
          v-model="accountForm.pass2"
          :type="pass2"
          placeholder="确认密码"
          name="pass2"
          tabindex="2"
          auto-complete="on"
        />
        <span
          class="show-pwd"
          @click="handleShowPwd('pass2')"
        >
          <svg-icon :icon-class="pass2 === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>

      <el-button
        :loading="loading"
        type="success"
        style="width:100%;margin-bottom:30px;"
        @click.native.prevent="handleLogin"
      >
        确认
      </el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'UpdatePwd',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码长度至少六位数'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.accountForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      accountForm: {
        oldPassword: '',
        password: '',
        pass2: '',
      },
      rules: {
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        pass2: [{ required: true, trigger: 'blur', validator: validatePass2 }],
        oldPassword: [{ required: true, trigger: 'blur', message: '请输入旧密码' }],
      },
      pass: 'password',
      pass2: 'password',
      oldPassword: 'password',
      loading: false,
    };
  },
  methods: {
    handleShowPwd(type) {
      let ref = '';
      if (type === 'pass') {
        if (this.pass === 'password') {
          this.pass = '';
        } else {
          this.pass = 'password';
        }
        ref = this.$refs.pass.focus();
      } else if (type === 'oldPassword') {
        if (this.oldPassword === 'password') {
          this.oldPassword = '';
        } else {
          this.oldPassword = 'password';
        }
        ref = this.$refs.oldPassword.focus();
      } else {
        if (this.pass2 === 'password') {
          this.pass2 = '';
        } else {
          this.pass2 = 'password';
        }
        ref = this.$refs.pass2.focus();
      }
      this.$nextTick(() => {
        ref;
      });
    },
    handleLogin() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            auid: this.$store.getters.account.auid,
            oldPassword: this.accountForm.oldPassword,
            password: this.accountForm.password,
          };
          this.$post(this.urls.account.update_password, params)
            .then(() => {
              this.$alert('密码重置成功', '提示', {
                confirmButtonText: '确定',
                showClose: false,
                callback: () => {
                  this.$router.go(-1);
                },
              });
            })
            .catch((err) => {
              switch (err.code) {
                case this.codes.account.CODE_EXPIRED.code:
                  this.$message.error('操作超时');
                  this.$router.push({ name: 'login' });
                  break;
                case this.codes.login.USERNAME_PASSWORD_ERROR.code:
                  this.$message.error(this.codes.login.USERNAME_PASSWORD_ERROR.text);
                  break;
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/el-input/index.scss';
$bg: transparent;
$light_gray: black;
$cursor: black;

/* reset element-ui css */
.account-update-pwd-container {
  @include checkC3Cursor($cursor);
  .el-input {
    display: inline-block;
    width: 80%;
    @include inputFix($light_gray, $cursor, $bg);
  }
  .el-form-item {
    color: #454545;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .el-form-item__content {
      height: 52px;
      line-height: 52px;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.account-update-pwd-container {
  .pwd-form {
    position: relative;
    width: 520px;
    margin: 0 auto;
    padding: 160px 35px 0;
    overflow: hidden;
  }

  .svg-container {
    margin-left: 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
