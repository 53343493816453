const debounce = function(fn, delay) {
  let timer = null;
  return function() {
    if (timer !== null) {
      clearTimeout(timer);
    } // 清除定时器
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, delay);
  };
};
export default debounce;
