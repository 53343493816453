var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "experiment-add-container" },
    [
      _c("adCard", {
        staticClass: "experiment-add-wrap",
        attrs: { title: "name" },
        scopedSlots: _vm._u([
          {
            key: "container",
            fn: function() {
              return [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "基础信息", name: "info" } },
                      [_c("Ad-info", { attrs: { studyId: _vm.studyId } })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "入组标准", name: "succeed" } },
                      [_c("Ad-standard", { attrs: { studyId: _vm.studyId } })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "排除标准", name: "defeated" } },
                      [_vm._v(" 排除标准 ")]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "系统配置", name: "configuration" } },
                      [_vm._v(" 系统配置 ")]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "随机方案", name: "scheme" } },
                      [_vm._v(" 随机方案 ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }