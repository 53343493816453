var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "ad-mf-layout"
    },
    [
      _vm.crfStatus === "pending"
        ? _c(
            "div",
            { staticClass: "ad-mf-layout--left" },
            [_c("Items", { attrs: { componentsList: _vm.componentsList } })],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "ad-mf-layout--center" }, [
        _c(
          "div",
          { staticClass: "ad-mf-layout--center--content" },
          [
            _c("Content", {
              attrs: { status: _vm.status },
              on: {
                change: _vm.contentChange,
                "select-change": _vm.contentSelectChange
              }
            })
          ],
          1
        ),
        _vm.status === "pending"
          ? _c(
              "div",
              { staticClass: "ad-mf-layout--center--btns" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-bell", type: "primary" },
                    on: { click: _vm.saveClick }
                  },
                  [_vm._v(" 保存 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-lock", type: "primary" },
                    on: { click: _vm.lockClick }
                  },
                  [_vm._v(" 锁定并发布 ")]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.crfStatus === "pending"
        ? [
            _c(
              "div",
              { staticClass: "ad-mf-layout--right" },
              [
                _vm.form.formDataList.length && _vm.settingData
                  ? _c("Setting", { attrs: { data: _vm.settingData } })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }