var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isForget && !_vm.isUpdate
    ? _c("div", { staticClass: "login-verify" }, [
        _c("div", { staticClass: "login-verify-title" }, [
          _c("h1", { staticClass: "title" }, [_vm._v(" 忘记密码 ")]),
          _c(
            "div",
            {
              class: [{ "way-actived": _vm.isPhone }, "way-type"],
              on: {
                click: function($event) {
                  return _vm.changeType("phone")
                }
              }
            },
            [_vm._v(" 手机号找回 ")]
          ),
          _c("div", { staticClass: "way-tip" }, [_vm._v(" | ")]),
          _c(
            "div",
            {
              class: [{ "way-actived": !_vm.isPhone }, "way-type"],
              on: {
                click: function($event) {
                  return _vm.changeType("email")
                }
              }
            },
            [_vm._v(" 邮箱找回 ")]
          )
        ]),
        _c(
          "div",
          { staticClass: "form" },
          [
            !_vm.isUpdate && _vm.isPhone
              ? _c(
                  "el-form",
                  {
                    ref: "verifyForm",
                    staticClass: "login-form",
                    attrs: {
                      model: _vm.verifyForm,
                      rules: _vm.verifyRules,
                      "auto-complete": "on",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "title-form-item",
                        attrs: { prop: "phone" }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "shoujihao" }
                            })
                          ],
                          1
                        ),
                        _c("el-input", {
                          ref: "phone",
                          attrs: {
                            placeholder: "请输入手机号",
                            name: "phone",
                            type: "text",
                            autocomplete: "off",
                            maxlength: "11"
                          },
                          model: {
                            value: _vm.verifyForm.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.verifyForm, "phone", $$v)
                            },
                            expression: "verifyForm.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "verify-box" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "password-form-item",
                            attrs: { prop: "code" }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "svg-container" },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "yanzhengma" }
                                })
                              ],
                              1
                            ),
                            _c("el-input", {
                              ref: "code",
                              attrs: {
                                type: "text",
                                placeholder: "请输入验证码",
                                name: "code",
                                autocomplete: "off"
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleAuth($event)
                                }
                              },
                              model: {
                                value: _vm.verifyForm.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.verifyForm, "code", $$v)
                                },
                                expression: "verifyForm.code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "show-pwd code-btn",
                            attrs: { disabled: _vm.phoneDisabled },
                            on: { click: _vm.handleVerifyCode }
                          },
                          [_vm._v(" " + _vm._s(_vm.phoneBtnTxt) + " ")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "check" }, [
                      _c("span", { on: { click: _vm.backLogin } }, [
                        _vm._v("返回登录")
                      ])
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "el-btn",
                        attrs: { loading: _vm.loading, type: "primary" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleAuth($event)
                          }
                        }
                      },
                      [_vm._v(" 重置密码 ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isUpdate && !_vm.isPhone
              ? _c(
                  "el-form",
                  {
                    ref: "verifyForm",
                    staticClass: "login-form",
                    attrs: {
                      model: _vm.verifyForm,
                      rules: _vm.verifyRules,
                      "auto-complete": "on",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "title-form-item",
                        attrs: { prop: "email" }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "youxiang" }
                            })
                          ],
                          1
                        ),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入邮箱",
                            name: "email",
                            type: "email",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.verifyForm.email,
                            callback: function($$v) {
                              _vm.$set(_vm.verifyForm, "email", $$v)
                            },
                            expression: "verifyForm.email"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "verify-box" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "password-form-item",
                            attrs: { prop: "code" }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "svg-container" },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "yanzhengma" }
                                })
                              ],
                              1
                            ),
                            _c("el-input", {
                              ref: "code",
                              attrs: {
                                type: "text",
                                placeholder: "请输入验证码",
                                name: "code",
                                tabindex: "2",
                                autocomplete: "off"
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleAuth($event)
                                }
                              },
                              model: {
                                value: _vm.verifyForm.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.verifyForm, "code", $$v)
                                },
                                expression: "verifyForm.code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "show-pwd code-btn",
                            attrs: { disabled: _vm.emailDisabled },
                            on: { click: _vm.handleVerifyCode }
                          },
                          [_vm._v(" " + _vm._s(_vm.emailBtnTxt) + " ")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "check" }, [
                      _c("span", { on: { click: _vm.backLogin } }, [
                        _vm._v("返回登录")
                      ])
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "el-btn",
                        attrs: { loading: _vm.loading, type: "primary" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleAuth($event)
                          }
                        }
                      },
                      [_vm._v(" 重置密码 ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }