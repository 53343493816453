var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img_auot" },
    [
      _c(
        "div",
        {
          staticClass: "left",
          attrs: { span: "8" },
          on: {
            mousewheel: function($event) {
              $event.preventDefault()
              return _vm.rollImg($event)
            }
          }
        },
        [
          _c(
            "div",
            { ref: "imgDiv", staticClass: "img", on: { mousedown: _vm.move } },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "auto" },
                attrs: { src: _vm.imgUrl, alt: "" }
              })
            ],
            1
          )
        ]
      ),
      _c("el-image", {
        ref: "previewImg",
        staticStyle: { width: "100%", height: "auto", display: "none" },
        attrs: {
          disabled: "",
          src: _vm.imgUrl,
          alt: "",
          "preview-src-list": _vm.srcList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }