var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "repot_form_edit" },
    [
      _c(
        "div",
        { staticClass: "add_form_item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.saveForm()
                }
              }
            },
            [_vm._v(" 保存 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = true
                }
              }
            },
            [_vm._v(" 新增 ")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { disabled: true } },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            _vm._l(_vm.formItems, function(item, index) {
              return _c(
                "el-collapse-item",
                {
                  key: index,
                  staticClass: "form_item",
                  attrs: { title: item.itemLabel, name: index }
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "form_item_title" }, [
                      _vm._v(" " + _vm._s(item.itemLabel) + " ")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "form_item_list" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "flex-wrap": "wrap",
                            "flex-direction": "row"
                          },
                          attrs: { gutter: 20, type: "flex" }
                        },
                        [
                          _vm._l(item.repotItems, function(items, indexs) {
                            return _c(
                              "el-col",
                              { key: items.itemCode, attrs: { span: 6 } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form_items",
                                    style: _vm.getWidth(items)
                                  },
                                  [
                                    _vm.activeItemCode.indexOf(
                                      items.itemCode
                                    ) == -1
                                      ? _c(
                                          "div",
                                          { staticClass: "form_items_edit" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-edit",
                                              on: {
                                                click: function($event) {
                                                  return _vm.editItems(
                                                    items,
                                                    index,
                                                    indexs
                                                  )
                                                }
                                              }
                                            }),
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteItems(
                                                    index,
                                                    indexs
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-form-item",
                                      {
                                        class: { required: items.required },
                                        attrs: {
                                          prop: item.itemCode
                                            ? item.itemCode +
                                              "." +
                                              items.itemCode
                                            : "" + items.itemCode,
                                          rules: _vm.setRules(items)
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item_label" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(items.itemLabel) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("AdWhichComponents", {
                                          attrs: { data: items }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "form_items add_items",
                                on: {
                                  click: function($event) {
                                    return _vm.addItems(item, index)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                2
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            title: "添加检查项目",
            center: "",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "add_item_form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { "label-width": "80px", model: _vm.formLabelAlign }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "name",
                        label: "名称",
                        rules: {
                          required: true,
                          message: "请输入名称",
                          trigger: "blur"
                        }
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formLabelAlign.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formLabelAlign, "name", $$v)
                          },
                          expression: "formLabelAlign.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "编码",
                        rules: {
                          required: true,
                          message: "编码不能为空",
                          trigger: "blur"
                        },
                        prop: "code"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formLabelAlign.code,
                          callback: function($$v) {
                            _vm.$set(_vm.formLabelAlign, "code", $$v)
                          },
                          expression: "formLabelAlign.code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addItemForm("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "50%",
            title: _vm.formItemLabel,
            center: "",
            visible: _vm.addItemsShow,
            "before-close": _vm.handleCloseAddItems
          },
          on: {
            "update:visible": function($event) {
              _vm.addItemsShow = $event
            }
          }
        },
        [
          _vm.addItemsShow
            ? _c(
                "div",
                { staticClass: "add_item_form" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addRuleForm",
                      attrs: { "label-width": "80px", model: _vm.addItemsForm }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "itemLabel",
                            label: "名称",
                            rules: {
                              required: true,
                              message: "请输入名称",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.addItemsForm.itemLabel,
                              callback: function($$v) {
                                _vm.$set(_vm.addItemsForm, "itemLabel", $$v)
                              },
                              expression: "addItemsForm.itemLabel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "编码",
                            rules: {
                              required: true,
                              message: "编码不能为空",
                              trigger: "blur"
                            },
                            prop: "itemCode"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.hasDataType },
                            model: {
                              value: _vm.addItemsForm.itemCode,
                              callback: function($$v) {
                                _vm.$set(_vm.addItemsForm, "itemCode", $$v)
                              },
                              expression: "addItemsForm.itemCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "formType",
                            label: "组件类型",
                            rules: {
                              required: true,
                              message: "请输入选择组件类型",
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.hasDataType },
                              model: {
                                value: _vm.addItemsForm.formType,
                                callback: function($$v) {
                                  _vm.$set(_vm.addItemsForm, "formType", $$v)
                                },
                                expression: "addItemsForm.formType"
                              }
                            },
                            _vm._l(_vm.serverList, function(item) {
                              return _c("el-option", {
                                key: item.type,
                                attrs: { label: item.name, value: item.type }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否必填" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.addItemsForm.configuration.required,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.addItemsForm.configuration,
                                  "required",
                                  $$v
                                )
                              },
                              expression: "addItemsForm.configuration.required"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.addItemsForm.formType === "select"
                        ? _c("el-form-item", { attrs: { label: "属性设置" } }, [
                            _c(
                              "div",
                              {
                                ref: "allowableValues",
                                staticClass: "configuration_allowableValues"
                              },
                              [
                                _vm._l(
                                  _vm.addItemsForm.configuration
                                    .allowableValues,
                                  function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.value,
                                        staticClass: "allowableValues_item"
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入内容" },
                                          model: {
                                            value: item.label,
                                            callback: function($$v) {
                                              _vm.$set(item, "label", $$v)
                                            },
                                            expression: "item.label"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "allowableValues_item_right"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: { color: "#F56C6C" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteAllowableValuesItem(
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm.addAllowableValuesShow
                                  ? _c(
                                      "div",
                                      { staticClass: "allowableValues_item" },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入内容" },
                                          model: {
                                            value: _vm.addAllowableValues.label,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addAllowableValues,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "addAllowableValues.label"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "allowableValues_item_right"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-check",
                                              staticStyle: { color: "#67C23A" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveData()
                                                }
                                              }
                                            }),
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: { color: "#F56C6C" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveDelete()
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "configuration_allowableValues_add"
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "success",
                                      disabled: _vm.addAllowableValuesShow
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onAddAllowableValues()
                                      }
                                    }
                                  },
                                  [_vm._v(" 新增 ")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleCloseAddItems()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addItemForms("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }