var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-carousel-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "card-carousel--nav__left",
        attrs: { disabled: _vm.atHeadOfList },
        on: {
          click: function($event) {
            return _vm.moveCarousel(-1)
          }
        }
      },
      [_c("i", { staticClass: "el-icon-arrow-left" })]
    ),
    _c("div", { ref: "carouselWrapper", staticClass: "card-carousel" }, [
      _c("div", { staticClass: "card-carousel--overflow-container" }, [
        _c(
          "div",
          {
            staticClass: "card-carousel-cards",
            style: {
              transform: "translateX" + "(" + _vm.currentOffset + "px" + ")"
            }
          },
          _vm._l(_vm.imgList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  _vm.activateImage.fileId == item.fileId
                    ? "card-carousel--card activate_image"
                    : "card-carousel--card"
              },
              [
                _c("el-image", {
                  staticStyle: { width: "90px", height: "90px" },
                  attrs: { fit: "cover", src: item.url },
                  on: {
                    click: function($event) {
                      return _vm.onPreviewImg(item, index)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "card-carousel--nav__right",
        attrs: { disabled: _vm.atEndOfList },
        on: {
          click: function($event) {
            return _vm.moveCarousel(1)
          }
        }
      },
      [_c("i", { staticClass: "el-icon-arrow-right" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }