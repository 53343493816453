let urls = {
    study: {
        distribution_study: '', // 创建研究
        create: '',
        query: '',
        get: '',
        activate: '', // 激活
        lock: '', // 上锁
        get_configuration: '', // 获取该研究的系统配置
        query_joined: '', // 中心查询
        get_site_study_relation: '', // 报表统计=》入组进度计
        check_alive: '',
        delete: '', // 删除研究
    },

    user: {
        login: '',
        getInfo: '',
        logout: '',
    },
    account: {
        send_code: '', // 发送验证码
        validate_code: '', // 验证验证码
        reset_pwd: '', // 重置密码
        create: '', // 同意该申请
        activate: '', // 激活接口
        apply_activate: '', // 申请激活
        reset_password: '', // 重新设置密码,
        apply_reset_password: '', // 忘记密码
        query_joined: '', // 中心管理
        join: '', // 加入用户
        query: '', // 查询所有用户
        update_joined: '', // 更新用户
        search_query: '', // 检索用户
        batch_import: '', // 上传用户表格
        remove: '', // 删除用户
        update_password: '', // 修改密码
        captcha_get: 'captcha/get',
        send_phone_code: '', // 发送验证码
        auth_by_phone: '', // 获取token
        update_phone: '', // 更换手机
        send_email_code: '', // 发送邮箱
        update_email: '', // 更新邮箱
        auth_by_email: '', // 认证
        get_info: '',
        update_info: '',
        update_avatar: '',
        auth_by_password: '',
        update_pwd: '', // 更新密码
    },
    wx: {
        qr_authorize: '',
        web_bind_wx_account: '',
        get_account_by_code: '',
        unbind_account: '',
        bind_qr_authorize: '',
    },

    crf_template: {
        list: '',
        create_crf_template_title: '',
        edit: '',
        delete: '',
        save: '',
        publish: '',
        get_one: '',
    },
    cloud_file: {
        single_upload: '',
    },
    application_tag: {
        create_tag_group: '',
        list_tags: '',
        edit_tag_group: '',
        delete_group: '',
        // tag
        create_tag: '',
        edit_tag: '',
        delete_tag: '',
        // 查询已经打上的标签
        list_template_tags: '',
        print_template: '',
    },
    operation_log: {
        account: '',
    },
    role: {
        get_authority: '', // 权限接口
    },
    // 病历
    mdb_data: {
        getone: '', // 查询病例
        save_data: '', // 提交
        query_data: '', // 查询
        save_image_data: '', // 病例图片
    },
    ocr: {
        python: '',
        upload_image: '',
        network: '',
    },
    sick: {
        get: '',
        has_data: '',
        save_data: '',
    },
    sick_mr: {
        delete: '',
        getone: '',
        query_condition: '',
        query_match_list: '',
        save_condition: '',
    },
    cdash_template: {
        save: '',
        delete: '',
        list: '',
        suggestions: '',
        upload: '',
        download_template: '',
        data_export: '',
    },
    common: {
        data_dictionarty: '',
    },
};
for (const key in urls) {
    for (const keys in urls[key]) {
        if (urls[key][keys] === '') {
            urls[key][keys] = `${key}/${keys}`;
        } else {
            urls[key][keys] = `${key}/${urls[key][keys]}`;
        }
    }
}
export default urls;