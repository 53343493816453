<template>
  <el-radio-group
    v-if="data.setting.isRemote && model"
    v-model="model[data.fieldId]"
    class="ad-radio-group"
  >
    <el-radio
      v-for="r in remoteData"
      :key="r[data.setting.remoteDataProps.value]"
      :label="r[data.setting.remoteDataProps.value]"
      class="ad-radio"
    >
      {{ r[data.setting.remoteDataProps.label] }}
    </el-radio>
  </el-radio-group>
  <el-radio-group
    v-else-if="data.setting.isRemote && !model"
    v-model="data.value"
  >
    <el-radio
      v-for="r in remoteData"
      :key="r[data.setting.remoteDataProps.value]"
      :label="r[data.setting.remoteDataProps.value]"
      class="ad-radio"
    >
      {{ r[data.setting.remoteDataProps.label] }}
    </el-radio>
  </el-radio-group>
  <el-radio-group
    v-else-if="!data.setting.isRemote && model"
    v-model="model[data.fieldId]"
    class="ad-radio-group"
  >
    <el-radio
      v-for="r in data.selections"
      :key="r.value"
      :label="r.value"
      class="ad-radio"
    >
      {{ r.label }}
    </el-radio>
  </el-radio-group>
  <el-radio-group
    v-else
    v-model="data.value"
    class="ad-radio-group"
  >
    <el-radio
      v-for="r in data.selections"
      :key="r.value"
      :label="r.value"
      class="ad-radio"
    >
      {{ r.label }}
    </el-radio>
  </el-radio-group>
</template>

<script>
import RemoteMixin from '../../utils/remote-mixin';
export default {
  name: 'TpRadio',
  mixins: [RemoteMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.ad-radio-group {
  display: flex;
  flex-direction: column;
  .ad-radio {
    margin-top: 10px;
    &:first-child {
      margin-top: 0px;
    }
  }
}
</style>

