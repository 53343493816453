<template>
  <!-- 添加标题的弹窗 -->
  <el-dialog
    center
    :title="title"
    width="560px"
    :visible="dialog"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    class="ad-dialog"
    @close="onCloseClick"
  >
    <el-form
      ref="form"
      class="label-form"
      label-width="auto"
      :model="form"
      :rules="rule"
    >
      <el-form-item
        prop="name"
        :label="label"
      >
        <el-input
          v-model="form.name"
          autocomplete="off"
          @keyup.enter.native="handleSubmitClick"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="onCancelClick">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click.native.prevent="handleSubmitClick"
      >
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  filters: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    parame: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '模板名称',
    },
    message: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'side', // 表格 侧边组建
    },
  },
  data() {
    const checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入需要名称'));
      }
      setTimeout(() => {
        const collapseFilter = this.list.filter((item) => {
          if (this.type === 'side') return item.formName === value;
          else if (this.type === 'labelGroup') return item.tagGroupName === value;
          else if (this.type === 'label') return item.applicationTagName === value;
        });
        if (collapseFilter.length !== 0) {
          callback(new Error('名称重复'));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      form: {
        name: '',
      },
      rule: {
        name: [{ required: true, validator: checkName, trigger: 'blur' }],
      },
    };
  },
  computed: {},
  watch: {
    formName(e) {
      this.$set(this.form, 'name', e);
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 创建文件夹
    handleSubmitClick() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let parame = {
            ...this.parame,
          };
          switch (this.type) {
            case 'side':
              parame.formName = this.form.name;
              break;
            case 'labelGroup':
              parame.tagGroupName = this.form.name;
              break;
            case 'label':
              parame.applicationTagName = this.form.name;
              break;
            default:
              break;
          }
          this.$post(this.url, parame)
            .then(() => {
              this.$refs['form'].resetFields();
              this.$emit('submit');
              this.$message({
                message: this.message,
                type: 'success',
              });
            })
            .catch((e) => {
              if (this.type === 'labelGroup' || this.type === 'label') {
                let text = '';
                switch (e.code) {
                  case this.codes.applicationTag.APPLICATION_TAG_GROUP_NOT_EXIST.code:
                    text = this.codes.applicationTag.APPLICATION_TAG_GROUP_NOT_EXIST.text;
                    break;
                  case this.codes.applicationTag.APPLICATION_TAG_EXIST.code:
                    text = this.codes.applicationTag.APPLICATION_TAG_EXIST.text;
                    break;
                  default:
                    break;
                }
                this.$message({
                  message: text,
                  type: 'error',
                });
              } else {
                this.$message({
                  message: '服务异常',
                  type: 'error',
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    onCloseClick() {
      this.$set(this.form, 'name', '');
      this.$refs['form'].resetFields();
      this.$emit('close');
    },
    onCancelClick() {
      this.$set(this.form, 'name', '');
      this.$emit('onCancelClick');
    },
  },
};
</script>
<style lang='scss' scoped>
// 格式化弹窗样式
.ad-dialog {
  ::v-deep .el-dialog {
    .el-dialog__body {
      display: inline-block;
      .el-form {
        .el-form-item {
          .el-form-item__content {
            width: 320px;
          }
        }
      }
    }
  }
}
</style>
