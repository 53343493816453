<template>
  <div class="ad-box-card">
    <!-- <div class="ad-box-card_title">
      {{ title }}
    </div> -->
    <div class="ad-box-card_container">
      <slot name="container" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  filters: {},
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.ad-box-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  &_title {
    padding: 20px;
    font-size: 20px;
    border-bottom: 1px solid #ebeef5;
  }
  &_container {
    padding: 20px;
  }
}
</style>
