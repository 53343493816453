var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "update_user" }, [
    _c(
      "div",
      { staticClass: "upload_user_header" },
      [
        _c(
          "el-steps",
          { attrs: { space: 200, active: _vm.active, "align-center": "" } },
          [
            _c("el-step", { attrs: { title: "安全认证" } }),
            _vm.type == "phone"
              ? _c("el-step", { attrs: { title: "验证新手机号" } })
              : _vm.type == "email"
              ? _c("el-step", { attrs: { title: "验证新邮箱" } })
              : _vm.type == "password"
              ? _c("el-step", { attrs: { title: "更新新密码" } })
              : _c("el-step", {
                  attrs: {
                    title: _vm.account.webOpenId ? "微信解绑" : "微信绑定"
                  }
                })
          ],
          1
        )
      ],
      1
    ),
    _vm.activeType == "verify"
      ? _c("div", [
          _vm.verifyType == "email"
            ? _c(
                "div",
                [
                  _c("VerifyEmail", {
                    attrs: {
                      account: _vm.account,
                      verifyType: _vm.verifyTypeCode
                    },
                    on: {
                      submitForm: _vm.submitForm,
                      onVerifyFu: _vm.onVerifyFu
                    }
                  })
                ],
                1
              )
            : _vm.verifyType == "password"
            ? _c(
                "div",
                [
                  _c("VerifyPassword", {
                    attrs: {
                      account: _vm.account,
                      verifyType: _vm.verifyTypeCode
                    },
                    on: {
                      submitForm: _vm.submitForm,
                      onVerifyFu: _vm.onVerifyFu
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("Verify", {
                    attrs: {
                      account: _vm.account,
                      verifyType: _vm.verifyTypeCode
                    },
                    on: {
                      submitForm: _vm.submitForm,
                      onVerifyFu: _vm.onVerifyFu
                    }
                  })
                ],
                1
              )
        ])
      : _c("div", [
          _vm.type == "weixin"
            ? _c(
                "div",
                { staticClass: "upload-item" },
                [
                  _vm.account.webOpenId
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUnbind }
                        },
                        [_vm._v(" 解绑微信 ")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdateWX }
                        },
                        [_vm._v(" 绑定微信 ")]
                      )
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.type == "phone"
                    ? _c("Upload", {
                        attrs: { account: _vm.account, token: _vm.token }
                      })
                    : _vm.type == "email"
                    ? _c("UploadEmage", {
                        attrs: { account: _vm.account, token: _vm.token }
                      })
                    : _vm.type == "password"
                    ? _c("UploadPassword", {
                        attrs: { account: _vm.account, token: _vm.token }
                      })
                    : _vm._e()
                ],
                1
              )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }