var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tp-form-box",
    { staticClass: "ad-sf-tp-form", attrs: { formData: _vm.formData } },
    _vm._l(_vm.formData.formDataList, function(li) {
      return _c("which-component", {
        key: li.fieldId,
        attrs: {
          data: li,
          type: "ShowForm",
          prop: li.fieldId,
          model: _vm.formData.model
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }