var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("el-rate", {
        attrs: {
          max: _vm.data.setting.max,
          "allow-half": _vm.data.setting.allowHalf,
          colors: _vm.data.setting.colors,
          "void-color": _vm.data.setting.voidColor,
          "icon-classes": _vm.data.setting.iconClasses,
          "void-icon-class": _vm.data.setting.voidIconClass,
          "show-text": _vm.data.setting.showText,
          "text-color": _vm.data.setting.textColor,
          texts: _vm.data.setting.texts,
          "show-score": _vm.data.setting.showScore,
          "score-template": _vm.data.setting.scoreTemplate
        },
        model: {
          value: _vm.model[_vm.data.fieldId],
          callback: function($$v) {
            _vm.$set(_vm.model, _vm.data.fieldId, $$v)
          },
          expression: "model[data.fieldId]"
        }
      })
    : _c("el-rate", {
        attrs: {
          max: _vm.data.setting.max,
          "allow-half": _vm.data.setting.allowHalf,
          colors: _vm.data.setting.colors,
          "void-color": _vm.data.setting.voidColor,
          "icon-classes": _vm.data.setting.iconClasses,
          "void-icon-class": _vm.data.setting.voidIconClass,
          "show-text": _vm.data.setting.showText,
          "text-color": _vm.data.setting.textColor,
          texts: _vm.data.setting.texts,
          "show-score": _vm.data.setting.showScore,
          "score-template": _vm.data.setting.scoreTemplate
        },
        model: {
          value: _vm.data.value,
          callback: function($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }