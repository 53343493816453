import Vue from 'vue';

import { post, get, exlPost, downloadFile } from '@/https/request';
import webSocket from '@/https/webSocket';
import code from '@/static/code';
import 'normalize.css/normalize.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
import '@c/Dynamicform/components/style/index.scss';
import '@/icons';
import store from './store';
import App from './App';
import router from './router';
import '@/permission';
import urls from '@/static/urls';
import { parseTime } from '@/utils';
import Pagination from '@c/Pagination';
import SearchBox from '@c/SearchBox';

import message from '@u/reset-message/index.js';
import directives from './directives';
import checkPermission from '@/directives/check/permission'; // 权限判断函数
import Components from '@c/Dynamicform/components/index.js';
import '@/assets/iconfont/iconfont.js';
import '@/assets/iconfont/iconfont.css';

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale });
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI);
Vue.use(directives); // 安装命令
Vue.use(Components);

Vue.config.productionTip = false;
Vue.prototype.urls = urls;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$webSocket = webSocket;
Vue.prototype.$exlPost = exlPost;
Vue.prototype.$downloadFile = downloadFile;
Vue.prototype.codes = code;
// 生命权限
Vue.prototype.checkPermission = checkPermission;
// 百度重写message
Vue.prototype.$message = message;
Vue.filter('parseTime', (originVal, type) => {
  let value = '';
  if (originVal !== '') {
    value = parseTime(originVal, type);
  }
  return value;
});

Vue.filter('studyActivated', (value) => {
  if (value) {
    return '已启动';
  } else {
    return '未启动';
  }
});

Vue.component(Pagination.name, Pagination);
Vue.component(SearchBox.name, SearchBox);

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
