<template>
  <div class="card-carousel-wrapper">
    <div class="card-carousel--nav__left" :disabled="atHeadOfList" @click="moveCarousel(-1)">
      <i class="el-icon-arrow-left" />
    </div>
    <div ref="carouselWrapper" class="card-carousel">
      <div class="card-carousel--overflow-container">
        <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')' }">
          <div
            v-for="(item, index) in imgList"
            :key="index"
            :class="activateImage.fileId == item.fileId ? 'card-carousel--card activate_image' : 'card-carousel--card'"
          >
            <el-image
              fit="cover"
              style="width: 90px; height: 90px;"
              :src="item.url"
              @click="onPreviewImg(item, index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-carousel--nav__right" :disabled="atEndOfList" @click="moveCarousel(1)">
      <i class="el-icon-arrow-right" />
    </div>
  </div>
</template>

<script>
const elementResizeDetectorMaker = require('element-resize-detector');

export default {
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
    activateImage: {
      type: Object,
      default: () => {},
    },
    activateIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentOffset: 0,
      windowSize: 0,
      paginationFactor: 110,
      activate: true,
      activateIndexs: 0,
    };
  },
  computed: {
    atEndOfList() {
      return this.currentOffset <= this.paginationFactor * -1 * (this.imgList.length - this.windowSize);
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  created() {
    this.activateIndexs = this.activateIndex;
  },

  mounted() {
    // 监听右侧元素宽度变化，改变该元素可以存在多少项（windowSize）
    let erd = elementResizeDetectorMaker();
    let w = 0;
    erd.listenTo(this.$refs.carouselWrapper, (ele) => {
      let s = this.windowSize;
      this.windowSize = parseInt(ele.clientWidth / this.paginationFactor);
      w = parseInt(ele.clientWidth / this.paginationFactor);
      // 但可视宽度变化，那么显示数量也将发生变化
      if (s === this.windowSize && s > 0) {
        this.activate = true;
      }
      if (this.activate) {
        this.getActivateIndex(w);
      }
    });
    // 移动到默认项
  },

  methods: {
    getActivateIndex(w) {
      let S = w || this.windowSize; // 可显示的数量
      this.currentOffset = 0;
      let activateIndex = this.activateIndexs;
      let L = this.imgList.length; //  图片数量
      let Max = parseInt(L / S) * S; // 最后一组下标 = 图片数量/ 可显示的数量
      let X = 0; // 居中补位数
      let I = (activateIndex + 1) % S; // 选中图片的余数  = 选中图片的下标 % 可显示的数量
      // 当余数小等于可显示的数量1/2时就进行补位 ， 否则进行缩减
      if (S / 2 >= I) {
        X = I;
      } else {
        X = S - I;
      }
      // 判断选中图片是否在第一组里。
      if (S < activateIndex + 1) {
        // 判断选中图片是否在可显示的数量最后组里
        if (Max <= activateIndex + 1) {
          // 当选中图片在最后一组中，将图片列表滚动到最右边。
          if (Max > 1) {
            for (let i = 0; i < Max; i++) {
              this.moveCarousel(1);
            }
          } else if (Max === 1) {
            this.moveCarousel(1);
          }
        } else {
          for (let i = 0; i < activateIndex - X; i++) {
            this.moveCarousel(1);
          }
        }
      }
    },
    moveCarousel(direction) {
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
    onPreviewImg(item, index) {
      this.activateIndexs = index;
      this.getActivateIndex();
      this.$emit('onPreviewImg', { item, index });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  color: #666a73;
  width: 100%;
}

.card-carousel {
  display: flex;
  // justify-content: center;
  flex: 1;
  width: 86%;
}
.card-carousel--overflow-container {
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 24px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 150ms linear;
  background: rgba(00, 00, 00, 0.4);
  line-height: 90px;
  color: #fff;
  font-size: 18px;
  text-align: center;
}
.card-carousel--nav__left {
  margin-right: 10px;
}
.card-carousel--nav__right {
  margin-left: 10px;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel-cards {
  .card-carousel--card {
    margin: 0 7px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    z-index: 3;
    border: 3px solid #fff;
  }
  .card-carousel--card:hover {
    border: 3px solid #409eff;
  }
  .activate_image {
    border: 3px solid #409eff;
  }
}
</style>
