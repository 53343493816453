<template>
  <el-form-item label="正则">
    <div
      v-if="inputing"
      class="ad-mf-setting-regx"
    >
      <el-row>
        <el-col :span="20">
          <el-input
            v-model="addForm.regx"
            placeholder="输入要匹配的正则"
            @blur="blur"
          >
            <template slot="prepend">
              /
            </template>
            <template slot="append">
              /
            </template>
          </el-input><br>
          <el-input
            v-model="addForm.message"
            placeholder="匹配不成功的提示"
            @blur="blur"
          />
        </el-col>
        <el-col
          :span="4"
          class="ad-mf-setting-regx--delete-col"
        >
          <i
            class="el-icon-delete"
            style="cursor:pointer;"
            @click.stop="delRegx(value.regx)"
          />
        </el-col>
      </el-row>
    </div>
    <el-button
      v-else
      type="text"
      icon="el-icon-plus"
      size="mini"
      @click="add"
    >
      添加正则项
    </el-button>
  </el-form-item>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'RegxAdd',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputing: false,
      addForm: {
        regx: '',
        message: '',
      },
      rules: {
        regx: [{ required: true, message: '请输入正则表达式', trigger: 'blur' }],
        message: [{ required: true, message: '请输入提示', trigger: 'blur' }],
      },
    };
  },
  watch: {
    value: {
      handler(v) {
        if (v.regx) {
          this.inputing = true;
          Object.assign(this.addForm, v.regx);
        }
      },
      immediate: true,
    },
  },
  methods: {
    add() {
      this.addForm = {
        regx: '',
        message: '',
      };
      this.inputing = true;
    },
    blur() {
      if (_.trim(this.addForm.regx) === '') {
        this.$delete(this.value, 'regx');
        return;
      }
      const obj = {
        ...this.addForm,
        trigger: 'blur',
      };
      this.$set(this.value, 'regx', obj);
      this.inputing = true;
    },
    delRegx() {
      this.$delete(this.value, 'regx');
      this.inputing = false;
    },
  },
};
</script>
