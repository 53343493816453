var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad-date-picker" },
    [
      _vm.show
        ? _c("el-date-picker", {
            attrs: {
              "picker-options": _vm.pickerTime,
              placeholder: "选择" + _vm.data.itemLabel,
              "default-value": _vm.data.configuration.defaultValue || _vm.day,
              "value-format": "timestamp"
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }