<template>
  <div class="ad-mf-layout--items">
    <!-- 使用列表动态渲染侧边栏 componentsList的大数组 label and list -->
    <!--
      <p class="ad-mf-layout--items--panel--label">
        {{ li.label }}
      </p> -->
    <!-- 这里使用的 draggable clone的克隆属性, list对应的是componentsList参数下的list -->
    <!-- </div> -->
    <div class="ad-mf-layout--items--panel">
      <draggable
        v-model="list"
        class="ad-mf-layout--items--panel--list"
        :group="{ name: 'TpFormGroup', pull: 'clone', put: false }"
        :clone="clone"
        :sort="false"
        animation="300"
      >
        <!-- 生成对应的数据集合list -->
        <div
          v-for="item in list"
          :key="item.name"
          class="ad-mf-layout--items--panel--list--li"
        >
          {{ item.label }}
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
export default {
  name: 'Items',
  components: { draggable },
  props: {
    componentsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.recursiveList(this.componentsList);
  },
  methods: {
    clone(obj) {
      /**
       * 浅拷贝拷贝一个对象，cloneDeep递归深度克隆一个对象
       * 具体查看https://www.lodashjs.com/docs/lodash.cloneDeep#_clonedeepvalue
       */
      const newObj = Object.assign(_.cloneDeep(obj), {
        fieldId: `${obj.name}_${new Date().getTime()}`,
      });
      return newObj;
    },
    recursiveList(array) {
      array.forEach((element) => {
        if (element.list) {
          element.list.forEach((element2) => {
            this.list.push(element2);
            if (element.list) this.recursiveList(element.list);
          });
        }
      });
    },
  },
};
</script>
