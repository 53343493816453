var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-divider",
    {
      attrs: {
        direction: _vm.data.setting.direction,
        "content-position": _vm.data.setting.contentPosition
      }
    },
    [
      _vm.data.setting.icon
        ? _c("i", { class: ["tp-divider--icon", _vm.data.setting.icon] })
        : _vm._e(),
      _vm.data.setting.content
        ? _c("span", {
            domProps: { textContent: _vm._s(_vm.data.setting.content) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }