<template>
  <div class="upload_user">
    <div>
      <el-form ref="ruleForm" :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
        <div class="form-item">
          <el-input v-model="ruleForm.phone" placeholder="新的手机号" style="width: 290px;" maxlength="11" />
        </div>

        <div class="form-item">
          <el-input v-model="ruleForm.code" style="width: 290px;" placeholder="请输入验证码">
            <el-button slot="append" class="code-btn" :disabled="phoneDisabled" @click="handleVerifyCode">
              {{ phoneBtnTxt }}
            </el-button>
          </el-input>
        </div>

        <div class="form_bottom">
          <el-button type="primary" :disabled="ruleForm.code == ''" @click="updatePhone()">
            确定
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    token: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ruleForm: {
        phone: '',
        code: '',
        token: '',
      },

      active: 1,
      phoneDisabled: false,
      activateInterval: false,
      phoneBtnTxt: '获取验证码',
      phoneType: 'verifyNewPhone',
    };
  },
  methods: {
    updatePhone() {
      if (this.ruleForm.code) {
        let { phone, code } = this.ruleForm;
        let token = this.token;
        let phoneObj = {
          phone,
          code,
          token,
        };
        this.$post(this.urls.account.update_phone, phoneObj)
          .then(() => {
            this.$message.success('更新成功');
            this.ruleForm.phone = '';
            this.ruleForm.code = '';
            setTimeout(() => {
              this.$router.go(0);
            }, 1000);
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.updatePhone.VERIFICATION_CODE_EXPIRED.code:
                this.$message.error(this.codes.login.VERIFICATION_CODE_EXPIRED.text);
                break;
              case this.codes.updatePhone.VERIFICATION_CODE_ERROR.code:
                this.$message.error(this.codes.updatePhone.VERIFICATION_CODE_ERROR.text);
                break;
              case this.codes.updatePhone.TOKEN_INVALID.code:
                this.$message.error(this.codes.updatePhone.TOKEN_INVALID.text);
                break;
            }
          });
      } else {
        this.$message.error('验证码有误');
      }
    },
    // 验证手机号、邮箱
    validateRules() {
      if (!this.ruleForm.phone) {
        this.$message.error('手机号码不能为空！');
      } else if (!/^1[3|4|5|6|7|8][0-9]{9}$/.test(this.ruleForm.phone)) {
        this.$message.error('手机号码格式不正确！');
      } else {
        return true;
      }
    },
    // 获取验证码按钮
    handleVerifyCode: debounce(function() {
      if (this.validateRules()) {
        let type = this.phoneType;
        let phoneObj = {
          phone: this.ruleForm.phone,
          type,
        };
        this.$post(this.urls.account.send_phone_code, phoneObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.activateInterval = false; //
            this.sendCodeBtn();
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.login.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_PHONE_NOT_EXIST.text);
                break;
              case this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.text);
                break;
            }
          });
      }
    }, 400),
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.ruleForm.code) {
        this.ruleForm.code = '';
      }
      let time = 59;
      let timer = setInterval(() => {
        if (time === 0 || this.activateInterval) {
          clearInterval(timer);
          this.phoneDisabled = false;
          this.phoneBtnTxt = '获取验证码';
        } else {
          this.phoneBtnTxt = time + '秒后重试';
          this.phoneDisabled = true;
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.phone {
  .upload_user_header {
    .el-step__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
.upload_user {
  .upload_user_header {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .form-item {
    margin-top: 46px;
    padding: 0 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    .code-btn {
      background: #409eff;
      border-radius: 0;
      font-size: 14px;

      color: #ffffff;
    }
  }
  .form_bottom {
    margin-top: 66px;
    display: flex;
    justify-content: center;
  }
}
</style>
