<template>
  <div class="email">
    <div>
      <el-form ref="ruleForm" :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
        <div class="form-item">
          <span> 使用邮箱 {{ geTel(account.email) }} 验证 </span>
          <span class="activate_tooltip" style="color: #409EFF; margin-left: 12px;">
            邮箱不可用？
            <div class="item_tooltip">
              <div
                v-if="verifyType == 'authUpdatePassword'"
                class="item_tooltip_header"
                @click="onVerifyFu('password')"
              >
                <img src="@/assets/img/verifyPassword.png" alt="" />
                <span>验证密码</span>
              </div>
              <div class="item_tooltip_header" @click="onVerifyFu('phone')">
                <img src="@/assets/img/verifyPhone.png" alt="" />
                <span>验证手机号</span>
              </div>
              <div class="item_tooltip_text">以上方式不可用，可联系管理员 <span> 400-8788-638</span></div>
            </div>
          </span>
        </div>

        <div class="form-item">
          <el-input v-model="ruleForm.code" style="width: 290px;" placeholder="请输入验证码">
            <el-button slot="append" class="code-btn" :disabled="emailDisabled" @click="handleVerifyCode">
              {{ emailBtnTxt }}
            </el-button>
          </el-input>
        </div>

        <div class="form_bottom">
          <el-button type="primary" :disabled="ruleForm.code == ''" @click="submitForm('ruleForm')">
            下一步
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    verifyType: {
      type: String,
      default: 'authUpdateEmail',
    },
  },
  data() {
    return {
      ruleForm: {
        email: '',
        code: '',
      },
      emailDisabled: false,
      emailBtnTxt: '获取验证码',
      active: 1,
      activateInterval: false,
      phoneType: 'authUpdateEmail',
    };
  },
  created() {
    this.phoneType = this.verifyType;
  },
  methods: {
    updatePhone() {
      if (this.ruleForm.code) {
        let { email, code, token } = this.ruleForm;
        let phoneObj = {
          email,
          code,
          token,
        };
        this.$post(this.urls.account.update_email, phoneObj)
          .then(() => {
            this.$message.success('更新成功');
            this.ruleForm.email = '';
            this.ruleForm.code = '';
            setTimeout(() => {
              this.$router.go(0);
            }, 1000);
          })
          .catch(() => {
            this.$message.error('参数有误');
          });
      } else {
        this.$message.error('验证码有误');
      }
    },
    // 验证手机号、邮箱
    validateRules() {
      if (!this.ruleForm.email) {
        this.$message.error('邮箱不能为空！');
      } else if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.ruleForm.email)) {
        this.$message.error('邮箱格式不正确！');
      } else {
        return true;
      }
      return false;
    },
    submitForm() {
      if (this.ruleForm.code) {
        let { email, code } = this.ruleForm;
        let phoneObj = {
          email,
          code,
        };
        this.$post(this.urls.account.auth_by_email, phoneObj)
          .then((res) => {
            this.ruleForm.token = res.token;
            this.$message.success('校验成功');
            this.phoneType = 'verifyNewEmail';
            this.activateInterval = true;
            this.$emit('submitForm', res.token);
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.code:
                this.$message.error(this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.text);
                break;
              case this.codes.authByPhone.VERIFICATION_CODE_ERROR.code:
                this.$message.error(this.codes.authByPhone.VERIFICATION_CODE_ERROR.text);
                break;
              case this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.text);
                break;
            }
          });
      } else {
        this.$message.error('验证码有误');
      }
    },
    // 获取验证码按钮
    handleVerifyCode: debounce(function() {
      this.ruleForm.email = this.account.email;
      if (this.validateRules()) {
        let emailObj = {
          email: this.ruleForm.email,
        };
        this.$post(this.urls.account.send_email_code, emailObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.sendCodeBtn();
          })
          .catch(() => {
            this.$message.error('参数有误');
          });
      }
    }, 400),
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.ruleForm.code) {
        this.ruleForm.code = '';
      }
      let time = 59;
      let timer = setInterval(() => {
        if (time === 0 || this.activateInterval) {
          clearInterval(timer);
          this.emailDisabled = false;
          this.emailBtnTxt = '获取验证码';
        } else {
          this.emailBtnTxt = time + '秒后重试';
          this.emailDisabled = true;
          time--;
        }
      }, 1000);
    },
    geTel(tel) {
      let index = tel.indexOf('@');
      return tel.substring(0, 3) + '****' + tel.substr(index);
    },
    onVerifyFu(type) {
      this.$emit('onVerifyFu', type);
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/tooltip.scss';
</style>
<style lang="scss">
.email {
  .email_header {
    .el-step__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
.email {
  .email_header {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .form-item {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    line-height: 40px;
    .code-btn {
      background: #409eff;
      border-radius: 0;
      font-size: 14px;

      color: #ffffff;
    }
  }
  .form_bottom {
    margin-top: 66px;
    display: flex;
    justify-content: center;
  }
}
</style>
