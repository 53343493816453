<template>
  <div class="image-crop">
    <div v-if="show">
      <my-upload
        :modelValue.sync="show"
        :langExt="{ hint: langText }"
        :width="width"
        :height="height"
        img-format="jpg/png"
        :maxSize="size"
        :params="uploadData"
        field="file"
        langType="zh"
        :noRotate="false"
        :url="`/api/v1/${urls.cloud_file.single_upload}`"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
      />
    </div>
  </div>
</template>
<script>
import 'babel-polyfill'; // es6 shim
import myUpload from 'vue-image-crop-upload';
export default {
  components: {
    'my-upload': myUpload,
  },
  props: {
    // 上传图片大小
    maxSize: {
      type: Number,
      default: 5,
    },
    // 限制大小
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    // 提示语
    langText: {
      type: String,
      default: '点击，或拖动图片至此处。',
    },
    // 添加上传类型
    uploadData: {
      type: Object,
      default: () => {
        return {
          type: '',
          groupName: '',
          remark: '',
          studyId: '',
        };
      },
    },
  },
  data() {
    return {
      size: 1024,
      show: false,
    };
  },
  watch() {},
  created() {
    this.size = this.maxSize * this.size;
  },
  methods: {
    // 显示上传图片
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------', imgDataUrl, field);
    },
    // 上传成功回调
    cropUploadSuccess(jsonData) {
      console.log('-------- upload success --------');
      this.show = false;
      this.$emit('onSubmitImage', jsonData.data);
    },
    // 上传失败回调
    cropUploadFail(status, field) {
      console.log('上传失败状态' + status);
      console.log('field: ' + field);
    },
  },
};
</script>

<style></style>
