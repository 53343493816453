<template>
  <div class="info-component-container">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      label-position="left"
    >
      <el-form-item
        label="研究名称"
        prop="name"
      >
        <el-input
          v-model="ruleForm.name"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
        />
      </el-form-item>
      <el-row :gutter="60">
        <el-col :span="12">
          <el-form-item
            label="方案编号"
            prop="protocolNo"
          >
            <el-input v-model="ruleForm.protocolNo" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="试验OID"
            prop="oid"
          >
            <el-input v-model="ruleForm.oid" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="12">
          <el-form-item
            label="申办方"
            prop="sponsor"
          >
            <el-input v-model="ruleForm.sponsor" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="主要研究者"
            prop="principalInvestigator"
          >
            <el-input v-model="ruleForm.principalInvestigator" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="12">
          <el-form-item
            label="研究期间"
            required
          >
            <el-date-picker
              v-model="ruleForm.planned"
              class="date-picker"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="入组期间"
            prop="enrollment"
            required
          >
            <el-date-picker
              v-model="ruleForm.enrollment"
              class="date-picker"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="12">
          <el-form-item
            label="计划入组"
            prop="expectedEnrollment"
          >
            <el-input
              v-model="ruleForm.expectedEnrollment"
              autocomplete="off"
              class="expectedEnrollment"
              type="number"
              :min="1"
              onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="盲态"
            prop="blindStatus"
          >
            <el-radio-group v-model="ruleForm.blindStatus">
              <el-radio
                label="开放"
                value="open"
              />
              <el-radio
                label="单盲"
                value="single"
              />
              <el-radio
                label="双盲"
                value="double"
              />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="12">
          <el-form-item
            label="试验分期"
            prop="stage"
          >
            <el-radio-group v-model="ruleForm.stage">
              <el-radio
                v-for=" (item,index) in nperList"
                :key="index"
                :label="item.value"
              >
                {{ item.text }}期
              </el-radio>
              <el-radio label="其他" />
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="方案版本号"
            prop="protocolVersion"
          >
            <el-input v-model="ruleForm.protocolVersion" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  filters: {},
  studyId: {
    type: String,
    default: '',
  },
  data() {
    return {
      ruleForm: {
        name: '',
        protocolNo: '',
        oid: '',
        sponsor: '',
        principalInvestigator: '',
        planned: '',
        enrollment: '',
        expectedEnrollment: '',
        blindStatus: '',
        stage: '',
        protocolVersion: '',
      },
      nperList: [
        { value: 'I', text: '一' },
        { value: 'II', text: '二' },
        { value: 'Ⅲ', text: '三' },
        { value: 'Ⅳ', text: '四' },
      ],
      rules: {
        name: [{ required: true, message: '请输入研究名称', trigger: 'blur' }],
        protocolNo: [{ required: true, message: '请输入方案编号', trigger: 'change' }],
        oid: [{ required: true, message: '请输入试验OID', trigger: 'change' }],
        sponsor: [{ required: true, message: '请输入申办方', trigger: 'change' }],
        principalInvestigator: [{ required: true, message: '请输入主要研究者', trigger: 'change' }],
        planned: [{ required: true, message: '请选择研究时间', trigger: 'change' }],
        enrollment: [{ required: true, message: '请选择入组时间', trigger: 'change' }],
        expectedEnrollment: [{ required: true, message: '请输入计划入组', trigger: 'blur' }],
        blindStatus: [{ required: true, message: '请选择盲太', trigger: 'blur' }],
        stage: [{ required: true, message: '请选择研究分期', trigger: 'blur' }],
        protocolVersion: [{ required: true, message: '请输入方案版本号', trigger: 'blur' }],
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.info-component-container {
  ::v-deep .el-form {
    .el-form-item {
      .el-form-item__content {
        .expectedEnrollment {
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
}
</style>
