import { render, staticRenderFns } from "./addWhichComponents.vue?vue&type=template&id=6e549f74&scoped=true&"
import script from "./addWhichComponents.vue?vue&type=script&lang=js&"
export * from "./addWhichComponents.vue?vue&type=script&lang=js&"
import style0 from "./addWhichComponents.vue?vue&type=style&index=0&id=6e549f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e549f74",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\development_idea_workspace\\中山一院\\aidi_web_recruiting_sso\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e549f74')) {
      api.createRecord('6e549f74', component.options)
    } else {
      api.reload('6e549f74', component.options)
    }
    module.hot.accept("./addWhichComponents.vue?vue&type=template&id=6e549f74&scoped=true&", function () {
      api.rerender('6e549f74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/caseReportForm/components/addWhichComponents.vue"
export default component.exports