var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        title: _vm.title,
        width: "50%",
        visible: _vm.dialog,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "before-close": _vm.handleCancelClick
      },
      on: {
        "update:visible": function($event) {
          _vm.dialog = $event
        },
        closed: _vm.handleClosed
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "观察类别", prop: "observationClass" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          placeholder: _vm.observationClassPlaceholder,
                          "fetch-suggestions": function(queryString, cb) {
                            _vm.querySearch("observationClass", queryString, cb)
                          }
                        },
                        model: {
                          value: _vm.form.observationClass,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "observationClass", $$v)
                          },
                          expression: "form.observationClass"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "域", prop: "domain" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          placeholder: _vm.domainPlaceholder,
                          "fetch-suggestions": function(queryString, cb) {
                            _vm.querySearch("domain", queryString, cb)
                          }
                        },
                        model: {
                          value: _vm.form.domain,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "domain", $$v)
                          },
                          expression: "form.domain"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "实施选项",
                        prop: "implementationOptions"
                      }
                    },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          placeholder: _vm.implementationOptionsPlaceholder,
                          "fetch-suggestions": function(queryString, cb) {
                            _vm.querySearch(
                              "implementationOptions",
                              queryString,
                              cb
                            )
                          }
                        },
                        model: {
                          value: _vm.form.implementationOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "implementationOptions", $$v)
                          },
                          expression: "form.implementationOptions"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签", prop: "cdashigVariableLabel" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.cdashigVariableLabelPlaceholder
                        },
                        model: {
                          value: _vm.form.cdashigVariableLabel,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cdashigVariableLabel", $$v)
                          },
                          expression: "form.cdashigVariableLabel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "变量", prop: "cdashigVariable" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.cdashigVariablePlaceholder },
                        model: {
                          value: _vm.form.cdashigVariable,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cdashigVariable", $$v)
                          },
                          expression: "form.cdashigVariable"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据类型", prop: "dataType" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.dataTypePlaceholder },
                        model: {
                          value: _vm.form.dataType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dataType", $$v)
                          },
                          expression: "form.dataType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "核心类型", prop: "cdashigCore" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.cdashigCorePlaceholder },
                        model: {
                          value: _vm.form.cdashigCore,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cdashigCore", $$v)
                          },
                          expression: "form.cdashigCore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数据收集情形",
                        prop: "dataCollectionScenario"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.dataCollectionScenarioPlaceholder
                        },
                        model: {
                          value: _vm.form.dataCollectionScenario,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dataCollectionScenario", $$v)
                          },
                          expression: "form.dataCollectionScenario"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "定义", prop: "draftCdashigDefinition" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.draftCdashigDefinitionPlaceholder },
                model: {
                  value: _vm.form.draftCdashigDefinition,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "draftCdashigDefinition", $$v)
                  },
                  expression: "form.draftCdashigDefinition"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "示例",
                prop: "caseReportFormCompletionInstructions"
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.caseReportFormCompletionInstructionsPlaceholder
                },
                model: {
                  value: _vm.form.caseReportFormCompletionInstructions,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "caseReportFormCompletionInstructions",
                      $$v
                    )
                  },
                  expression: "form.caseReportFormCompletionInstructions"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示", prop: "prompt" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.promptPlaceholder },
                model: {
                  value: _vm.form.prompt,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "prompt", $$v)
                  },
                  expression: "form.prompt"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实施说明", prop: "implementationNotes" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.implementationNotesPlaceholder },
                model: {
                  value: _vm.form.implementationNotes,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "implementationNotes", $$v)
                  },
                  expression: "form.implementationNotes"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmitClick }
            },
            [_vm._v(" 确认 ")]
          ),
          _c("el-button", { on: { click: _vm.handleCancelClick } }, [
            _vm._v(" 取消 ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }