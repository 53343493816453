<template>
  <div
    v-show="active"
    class="menu"
  >
    <div
      class="menu-item"
      @click.stop="handleTagClick"
    >
      关联标签
    </div>
    <div
      class="menu-item"
      @click.stop="handleRenameClick"
    >
      编辑
    </div>
    <div
      class="menu-item"
      @click.stop="handleDelClick"
    >
      删除
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  filters: {},
  props: {
    menuActive: Boolean,
  },
  data() {
    return {
      active: false,
      dialogFormVisible: false,
    };
  },
  computed: {},
  watch: {
    menuActive: {
      handler: function (newval) {
        this.active = newval;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleTagClick() {
      this.active = false;
      this.$emit('handleTagClick', this.active);
    },
    handleRenameClick() {
      this.active = false;
      this.$emit('handleRenameClick', this.active);
    },
    handleDelClick() {
      this.active = false;
      this.$emit('handleDelClick', this.active);
    },
  },
};
</script>
<style lang='scss' scoped>
.menu {
  min-width: 100px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  .menu-item {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    &:hover {
      background: #e6f7ff;
    }
  }
}
</style>
