<template>
  <el-dialog
    center
    :title="title"
    width="50%"
    :visible.sync="dialog"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :before-close="handleCancelClick"
    @closed="handleClosed"
  >
    <el-form ref="form" label-position="right" label-width="120px" :model="form" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="观察类别" prop="observationClass">
            <el-autocomplete
              v-model="form.observationClass"
              :placeholder="observationClassPlaceholder"
              :fetch-suggestions="
                (queryString, cb) => {
                  querySearch('observationClass', queryString, cb);
                }
              "
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="域" prop="domain">
            <el-autocomplete
              v-model="form.domain"
              :placeholder="domainPlaceholder"
              :fetch-suggestions="
                (queryString, cb) => {
                  querySearch('domain', queryString, cb);
                }
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="实施选项" prop="implementationOptions">
            <el-autocomplete
              v-model="form.implementationOptions"
              :placeholder="implementationOptionsPlaceholder"
              :fetch-suggestions="
                (queryString, cb) => {
                  querySearch('implementationOptions', queryString, cb);
                }
              "
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="标签" prop="cdashigVariableLabel">
            <el-input v-model="form.cdashigVariableLabel" :placeholder="cdashigVariableLabelPlaceholder" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="变量" prop="cdashigVariable">
            <el-input v-model="form.cdashigVariable" :placeholder="cdashigVariablePlaceholder" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据类型" prop="dataType">
            <el-input v-model="form.dataType" :placeholder="dataTypePlaceholder" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="核心类型" prop="cdashigCore">
            <el-input v-model="form.cdashigCore" :placeholder="cdashigCorePlaceholder" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据收集情形" prop="dataCollectionScenario">
            <el-input v-model="form.dataCollectionScenario" :placeholder="dataCollectionScenarioPlaceholder" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="定义" prop="draftCdashigDefinition">
        <el-input v-model="form.draftCdashigDefinition" :placeholder="draftCdashigDefinitionPlaceholder" />
      </el-form-item>
      <el-form-item label="示例" prop="caseReportFormCompletionInstructions">
        <el-input
          v-model="form.caseReportFormCompletionInstructions"
          :placeholder="caseReportFormCompletionInstructionsPlaceholder"
        />
      </el-form-item>
      <el-form-item label="提示" prop="prompt">
        <el-input v-model="form.prompt" :placeholder="promptPlaceholder" />
      </el-form-item>
      <el-form-item label="实施说明" prop="implementationNotes">
        <el-input v-model="form.implementationNotes" :placeholder="implementationNotesPlaceholder" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmitClick"> 确认 </el-button>
      <el-button @click="handleCancelClick"> 取消 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'CdashDialog',
  components: {},
  filters: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: () => {},
    },
    dialogType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: null,
      observationClassPlaceholder: '请输入观察类别', // 观察类别
      domainPlaceholder: '请输入域', // 域
      cdashigVariablePlaceholder: '请输入变量', // 变量
      cdashigVariableLabelPlaceholder: '请输入标签', // 标签
      draftCdashigDefinitionPlaceholder: '请输入定义', // 定义
      caseReportFormCompletionInstructionsPlaceholder: '请输入示例', // 示例
      dataTypePlaceholder: '请输入数据类型', // 数据类型
      cdashigCorePlaceholder: '请输入核心类型', // 核心类型
      promptPlaceholder: '请输入提示', // 提示
      implementationNotesPlaceholder: '请输入实施说明', // 实施说明
      dataCollectionScenarioPlaceholder: '请输入数据收集情形', // 数据收集情形
      implementationOptionsPlaceholder: '请输入实施选项', // 实施选项
      timeChange: {
        disabledDate: this.disabledDate,
      },
      suggestions: {},
    };
  },
  computed: {
    ...mapGetters(['dataDictionarty_code']),
    rules() {
      return {
        observationClass: [{ required: true, message: this.observationClassPlaceholder, trigger: 'change' }], // 观察类别
        domain: [{ required: true, message: this.domainPlaceholder, trigger: 'change' }], // 域
        cdashigVariable: [{ required: true, message: this.cdashigVariablePlaceholder, trigger: 'blur' }], // 变量
        cdashigVariableLabel: [{ required: true, message: this.cdashigVariableLabelPlaceholder, trigger: 'blur' }], // 标签
        draftCdashigDefinition: [{ required: true, message: this.draftCdashigDefinitionPlaceholder, trigger: 'blur' }], // 定义
        caseReportFormCompletionInstructions: [
          { required: true, message: this.caseReportFormCompletionInstructionsPlaceholder, trigger: 'blur' },
        ], // 示例
        dataType: [{ required: true, message: this.dataTypePlaceholder, trigger: 'blur' }], // 数据类型
        cdashigCore: [{ required: true, message: this.cdashigCorePlaceholder, trigger: 'blur' }], // 核心类型
        prompt: [{ required: true, message: this.promptPlaceholder, trigger: 'blur' }], // 提示
        implementationNotes: [{ required: true, message: this.implementationNotesPlaceholder, trigger: 'blur' }], // 实施说明
        dataCollectionScenario: [{ required: true, message: this.dataCollectionScenarioPlaceholder, trigger: 'blur' }], // 数据收集情形
        implementationOptions: [{ required: true, message: this.implementationOptionsPlaceholder, trigger: 'change' }], // 实施选项
      };
    },
  },
  watch: {
    formData: {
      handler(form) {
        if (form) this.form = _.cloneDeep(form);
        else this.form = this.formInit();
      },
      immediate: true,
    },
  },
  created() {
    this.querySuggestions();
  },
  mounted() {},
  methods: {
    handleSubmitClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('update:dialog', false);
          this.$emit('handleSubmitClick', this.form);
        } else {
          return false;
        }
      });
    },
    handleCancelClick() {
      this.$emit('update:dialog', false);
    },
    handleClosed() {
      this.$emit('update:formData', null);
      this.form = this.formInit();
    },
    querySuggestions() {
      this.$post(this.urls.cdash_template.suggestions, {})
        .then((response) => {
          this.suggestions = response;
        })
        .catch(() => {});
    },
    querySearch(prop, queryString, callback) {
      var propSuggestions = this.suggestions[prop];
      if (propSuggestions == null || propSuggestions.length === 0) {
        callback([]);
        return;
      }
      var results = [];
      propSuggestions.forEach((item) => {
        if (queryString && item.indexOf(queryString) !== 0) {
          return;
        }
        results.push({ value: item });
      });
      callback(results);
    },
    formInit() {
      return {
        observationClass: '', // 观察类别
        domain: '', // 域
        cdashigVariable: '', // 变量
        cdashigVariableLabel: '', // 标签
        draftCdashigDefinition: '', // 定义
        caseReportFormCompletionInstructions: '', // 示例
        dataType: '', // 数据类型
        cdashigCore: '', // 核心类型
        prompt: '', // 提示
        implementationNotes: '', // 实施说明
        dataCollectionScenario: '', // 数据收集情形
        implementationOptions: '', // 实施选项
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/el-dialog/index.scss';
.el-autocomplete {
  width: 100%;
}
</style>
