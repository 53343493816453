var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case_report_img_content" }, [
    _c("h3", [_vm._v(" 患者文件 ")]),
    _c(
      "div",
      { staticClass: "case_report_img_item" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.fullscreenLoading,
                expression: "fullscreenLoading"
              }
            ],
            staticClass: "img_item_list"
          },
          [
            _c("div", { staticClass: "img_item_label" }, [
              _vm._v(" 上传病历单 ")
            ]),
            _vm.previewType === "listImg"
              ? _c("div", { staticClass: "upload_img" }, [
                  _c(
                    "div",
                    { staticClass: "upload_img_list" },
                    [
                      _vm._l(_vm.caseReport.imgList, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: item.fileId,
                            staticClass: "upload_img_list_item"
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: item.url, fit: "cover" },
                              on: {
                                click: function($event) {
                                  return _vm.handlePictureCardPreview(
                                    item,
                                    index
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action:
                              "/api/v1/" + _vm.urls.cloud_file.single_upload,
                            "list-type": "picture-card",
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            data: _vm.uploadData
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    ],
                    2
                  )
                ])
              : _c("div", { staticClass: "ocr_img" }, [
                  _c(
                    "div",
                    { staticClass: "preview_img" },
                    [
                      _c("div", { staticClass: "preview_img_header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "preview_img_title",
                            on: { click: _vm.handleEdit }
                          },
                          [
                            _vm.activateImage.name
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.activateImage.name || ""))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("i", { staticClass: "el-icon-edit" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "close",
                            on: {
                              click: function($event) {
                                return _vm.closePreviewImg()
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      ]),
                      _c("ImgAuot", {
                        ref: "ImgAuot",
                        attrs: { imgUrl: _vm.dialogImageUrl }
                      }),
                      _c(
                        "div",
                        { staticClass: "preview_img_side" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "side_item",
                              on: {
                                click: function($event) {
                                  return _vm.onDialogVisible()
                                }
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-quanping" })]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "side_item",
                              on: {
                                click: function($event) {
                                  return _vm.handleRemove()
                                }
                              }
                            },
                            [_c("i", { staticClass: "iconfont icon-shanchu" })]
                          ),
                          _vm.ocrShow
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "el-btn",
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    round: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.ocrPreviewImg()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-ocr ",
                                    staticStyle: { "margin-right": "4px" }
                                  }),
                                  _vm._v("自动识别 ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "preview_img_list" }, [
                    _vm.previewType === "ocrImg"
                      ? _c(
                          "div",
                          { staticClass: "preview_scroll_bar" },
                          [
                            _c("Carousel", {
                              ref: "Carousel",
                              attrs: {
                                imgList: _vm.caseReport.imgList,
                                activateIndex: _vm.activateIndex,
                                activateImage: _vm.activateImage
                              },
                              on: { onPreviewImg: _vm.onPreviewImg }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ocrContentText != ""
                      ? _c("div", { staticClass: "ocr_content" }, [
                          _c("div", { staticClass: "img_item_label" }, [
                            _vm._v(" 识别结果 ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "ocr_content_text" },
                            _vm._l(_vm.ocrContentText, function(item, index) {
                              return _c("div", { key: index }, [
                                _vm._v(" " + _vm._s(item.text) + " ")
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ])
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible = $event
              }
            }
          },
          [
            _c("el-image", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
            })
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticStyle: { "z-index": "2040" },
            attrs: {
              title: "图片编辑",
              visible: _vm.dialogEditImg,
              "append-to-body": "",
              width: "30%",
              center: ""
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogEditImg = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.activateImage } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "名称",
                      "label-width": _vm.formLabelWidth,
                      placeholder: "请输入名字"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.activateImage.name,
                        callback: function($$v) {
                          _vm.$set(_vm.activateImage, "name", $$v)
                        },
                        expression: "activateImage.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.dialogEditImg = false
                      }
                    }
                  },
                  [_vm._v(" 取 消 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleEditImg()
                      }
                    }
                  },
                  [_vm._v(" 确 定 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }