<template>
  <item-box classSuffix="textarea">
    <el-form-item label="标签">
      <el-input v-model="data.label" />
    </el-form-item>
    <!-- <el-form-item label="默认值">
      <el-input v-model="data.value" />
    </el-form-item> -->
    <el-form-item label="提示">
      <el-input v-model="data.placeholder" />
    </el-form-item>
    <el-form-item label="必填">
      <el-switch
        v-model="required"
        active-text="是"
        inactive-text="否"
      />
    </el-form-item>
    <!-- <el-form-item
      v-if="required"
      label="必填提示"
    >
      <el-input
        v-model="data.rules.required.message"
        placeholder="必填提示文本"
      />
    </el-form-item> -->
    <!-- <regx-add v-model="data.rules" /> -->
  </item-box>
</template>

<script>
import ItemBox from '../common/ItemBox';
// import RegxAdd from '../common/regx-add';
import Mixins from '../common/mixins';
export default {
  name: 'TextareaConfig',
  components: { ItemBox },
  mixins: [Mixins],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data(v) {
      this.required = v.rules.required ? v.rules.required.required : false;
    },
  },
};
</script>
