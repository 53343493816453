<template>
  <div>
    <ad-input v-if="data.formType === 'input' || data.formType === 'numInput'" :data="data" />
    <ad-textarea v-else-if="data.formType === 'textarea'" :data="data" />
    <ad-radio v-else-if="data.formType === 'radio'" :data="data" />
    <ad-checkbox v-else-if="data.formType === 'checkbox'" :data="data" />
    <ad-select v-else-if="data.formType === 'select'" :data="data" />
    <ad-time-picker v-else-if="data.formType === 'timePicker'" :data="data" />
    <ad-date-picker v-else-if="data.formType === 'datePicker'" :data="data" />
  </div>
</template>

<script>
import AllComponents from './components.js';
export default {
  name: 'WhichComponents',
  components: { ...AllComponents },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'MakeForm',
    },
    prop: {
      type: String,
      default: '',
    },

    itemItemsCode: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.which-com-container {
  background-color: #fff;
}
</style>
