var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.active,
          expression: "active"
        }
      ],
      staticClass: "menu"
    },
    [
      _c(
        "div",
        {
          staticClass: "menu-item",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleTagClick($event)
            }
          }
        },
        [_vm._v(" 关联标签 ")]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleRenameClick($event)
            }
          }
        },
        [_vm._v(" 编辑 ")]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleDelClick($event)
            }
          }
        },
        [_vm._v(" 删除 ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }