<template>
  <div class="user">
    <div class="user_header">
      <div class="user_conetent user_centre">
        <div class="title">
          系统管理
        </div>
        <el-button type="text" style="font-size: 16px;" class="back" @click="$router.push('/')">
          返回
        </el-button>
      </div>
    </div>
    <div class="user_contnent user_centre">
      <el-tabs type="border-card">
        <el-tab-pane label="个人中心">
          <div class="user_form">
            <el-form ref="form" label-position="right" :model="account" label-width="120px">
              <el-form-item label="头像：">
                <div class="ad—form_item">
                  <el-avatar shape="square" :size="50" :src="account.avatarUrl" />
                  <el-button type="text" class="update_button" @click="toggleShow()">
                    更新
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="密码：">
                <div class="ad—form_item">
                  <span>已设置</span>
                  <el-button type="text" class="update_button" @click="onUpdate('password')">
                    <span>
                      更换
                    </span>
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="手机号：">
                <div class="ad—form_item">
                  <span v-if="account.phone">
                    {{ geTel(account.phone) }}
                  </span>
                  <span v-else>
                    未绑定
                  </span>
                  <el-button type="text" class="update_button" @click="onUpdate('phone')">
                    <span v-if="account.phone">
                      更换
                    </span>
                    <span v-else>
                      绑定
                    </span>
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="邮箱：">
                <div class="ad—form_item">
                  <span v-if="account.email">
                    {{ geTels(account.email) }}
                  </span>
                  <span v-else>
                    未绑定
                  </span>
                  <el-button type="text" class="update_button" @click="onUpdate('email')">
                    <span v-if="account.email">
                      更换
                    </span>
                    <span v-else>
                      绑定
                    </span>
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="微信：">
                <div class="ad—form_item">
                  <img v-if="account.webOpenId" src="@/assets/img/weixin1.png" alt="" srcset="" />
                  <span v-else>未绑定</span>
                  <el-button v-if="account.webOpenId" type="text" class="update_button" @click="onUpdate('weixin')">
                    解绑
                  </el-button>
                  <el-button v-else type="text" class="update_button" @click="onUpdate('weixin')">
                    绑定
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="昵称：">
                <el-input v-model="account.nickname" placeholder="请输入昵称" />
              </el-form-item>
              <el-form-item label="所属单位：">
                <el-input v-model="account.affiliation" placeholder="请输入所属单位" />
              </el-form-item>
              <el-form-item label="部门/科室：">
                <el-input v-model="account.department" placeholder="请输入部门/科室" />
              </el-form-item>
              <el-form-item label="职位：">
                <el-input v-model="account.jobTitle" placeholder="请输入职位" />
              </el-form-item>
              <el-form-item label="注册时间：">
                <div class="ad—form_item">
                  <span>
                    {{ account.registerTime | parseTime }}
                  </span>
                </div>
              </el-form-item>
              <div class="fubmit_button">
                <el-button type="primary" @click="onSubmit">
                  保存
                </el-button>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志">
          <Log />
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      destroy-on-close
      :close-on-click-modal="false"
      width="620px"
      height="480px"
      :before-close="handleClose"
    >
      <UpdateUser v-if="dialogVisible" :account="account" :type="dialogType" />
    </el-dialog>
    <ImageCrop ref="ImageCrop" :width="100" :height="100" :uploadData="uploadData" @onSubmitImage="onSubmitImage" />
  </div>
</template>

<script>
import ImageCrop from '@/components/ImageCrop';
import UpdateUser from './components/index.vue';
import Log from './components/log.vue';
export default {
  components: {
    ImageCrop,
    UpdateUser,
    Log,
  },
  data() {
    return {
      account: {},
      dialogVisible: false,
      dialogType: '',
      title: '提示',
      showImageCrop: false,
      size: 2.1,
      show: false,
      uploadData: {
        type: '',
        groupName: '',
        remark: '',
        studyId: '',
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$post(this.urls.account.get_info, {
        auid: this.account.auid,
      })
        .then((res) => {
          this.account = res;
        })
        .catch(() => {});
    },
    onSubmit() {
      let { affiliation, department, jobTitle, nickname } = this.account;
      this.$post(this.urls.account.update_info, {
        affiliation,
        department,
        jobTitle,
        nickname,
      })
        .then(() => {
          this.$message.success('更新成功');
          this.getInfo();
        })
        .catch(() => {});
    },
    handleClose() {
      this.dialogVisible = false;
      this.title = '提示';
    },
    onUpdate(type) {
      if (type === 'phone') {
        this.title = '更换手机号';
      } else if (type === 'email') {
        this.title = '更换邮箱账号';
      } else if (type === 'weixin') {
        this.title = '微信账号';
      } else if (type === 'password') {
        this.title = '更换密码';
      }
      this.dialogType = type;
      this.dialogVisible = true;
    },
    geTel(tel) {
      return tel.substring(0, 3) + '****' + tel.substr(tel.length - 4);
    },
    geTels(tel) {
      let index = tel.indexOf('@');
      return tel.substring(0, 3) + '****' + tel.substr(index);
    },

    onSubmitImage(avatar) {
      this.account.avatarUrl = avatar.fileUrl;
      this.$post(this.urls.account.update_avatar, {
        auid: this.account.auid,
        fileId: avatar.fileId,
      })
        .then(() => {
          this.$message.success('上传头像成功');
        })
        .catch(() => {
          this.$message.success('参数有误');
        });
    },
    toggleShow() {
      this.$refs.ImageCrop.toggleShow();
    },
  },
};
</script>
<style lang="scss">
.user {
  .el-form-item__label {
    font-size: 16px;
    color: #969ba1;
  }
}
</style>
<style lang="scss" scoped>
.user {
  background: #f6f7f9;
  height: 100%;
  .user_centre {
    width: 980px;
    margin: 0 auto;
  }
  .user_header {
    background: #fff;
    .user_conetent {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      .title {
        color: #303843;
        line-height: 40px;
      }
      .back {
        color: #409eff;
      }
    }
  }
  .user_contnent {
    margin-top: 24px;
  }
  .user_form {
    margin: 36px auto;
    width: 500px;

    .ad—form_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #303843;
      > img {
        width: 40px;
        height: 40px;
      }
      .update_button {
        font-size: 14px;

        color: #409eff;
      }
    }
    .fubmit_button {
      display: flex;
      justify-content: center;
    }
  }
  .upload-image {
    text-align: center;

    .upload-image_content {
      display: flex;
      justify-content: center;
      .upload-demo {
      }
    }

    .dialog-footer {
      margin-top: 20px;
    }
  }
}
</style>
