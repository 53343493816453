var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.formType === "input" || _vm.data.formType === "numInput"
        ? _c("ad-input", { attrs: { data: _vm.data } })
        : _vm.data.formType === "textarea"
        ? _c("ad-textarea", { attrs: { data: _vm.data } })
        : _vm.data.formType === "radio"
        ? _c("ad-radio", { attrs: { data: _vm.data } })
        : _vm.data.formType === "checkbox"
        ? _c("ad-checkbox", { attrs: { data: _vm.data } })
        : _vm.data.formType === "select"
        ? _c("ad-select", { attrs: { data: _vm.data } })
        : _vm.data.formType === "timePicker"
        ? _c("ad-time-picker", { attrs: { data: _vm.data } })
        : _vm.data.formType === "datePicker"
        ? _c("ad-date-picker", { attrs: { data: _vm.data } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }