<template>
  <div
    v-if="isUpdate"
    class="login-update"
  >
    <div class="login-update-title">
      <h1 class="title">
        重置密码
      </h1>
    </div>
    <div class="form">
      <el-form
        v-if="isUpdate"
        ref="updateForm"
        :model="updateForm"
        :rules="updateRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <el-form-item
          class="title-form-item"
          prop="pwd"
        >
          <el-input
            ref="pwd"
            v-model="updateForm.pwd"
            placeholder="新密码"
            name="pwd"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          class="password-form-item"
          prop="pwd2"
        >
          <el-input
            ref="pwd2"
            v-model="updateForm.pwd2"
            type="password"
            placeholder="确认密码"
            name="pwd2"
            autocomplete="off"
            @keyup.enter.native="handleUpdate"
          />
        </el-form-item>
        <el-button
          :loading="loadingReset"
          type="primary"
          class="el-btn"
          @click.native.prevent="handleUpdate"
        >
          保存修改
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingReset: {
      default: true,
      type: Boolean,
    },
    isUpdate: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    // 密码验证
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码不能小于六位数'));
      } else {
        callback();
      }
    };
    const validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.updateForm.pwd) {
        callback(new Error('两次输入密码不一致！'));
      } else {
        callback();
      }
    };
    return {
      isPhone: true,
      isForget: false,
      token: '',
      updateForm: {
        pwd: '',
        pwd2: '',
      },
      updateRules: {
        pwd: [{ required: true, trigger: 'blur', validator: validatePassword }],
        pwd2: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validatePassword2, trigger: 'blur', required: true },
        ],
      },
      loading: false,
    };
  },
  created() {},
  methods: {
    // 重置密码
    handleUpdate() {
      this.$refs.updateForm.validate((valid) => {
        if (valid) {
          this.$emit('updateBtn', { pwd: this.updateForm.pwd2 });
        } else {
          // this.$message.error('参数验证不合法！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline-block;
  padding-bottom: 6px;
  padding-left: 24px;
  font-size: 18px;
  color: #c7cbda;
  vertical-align: middle;
}
.login-update {
  .login-update-title {
    display: flex;
    margin: 10px auto 0;
    width: 400px;
    height: 30px;
    align-items: center;
    color: #444;
    .title {
      font-size: 22px;
      font-weight: 500;
      margin: 0 10px 0 0;
    }
  }
  .form {
    width: 400px;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
  }
  .el-btn {
    width: 100%;
    padding: 14px 40px;
    font-size: 18px;
    border-radius: 4px;
  }
}
</style>
