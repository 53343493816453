var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("el-time-picker", {
        attrs: {
          "is-range": "",
          "range-separator": _vm.data.setting.rangeSeparator,
          "start-placeholder": _vm.data.setting.startPlaceholder,
          "end-placeholder": _vm.data.setting.endPlaceholder,
          "picker-options": {
            selectableRange: _vm.data.setting.selectableRange,
            format: _vm.data.setting.format
          },
          "prefix-icon": _vm.data.setting.prefixIcon,
          placeholder: _vm.data.placeholder
        },
        on: { focus: _vm.focus },
        model: {
          value: _vm.model[_vm.data.fieldId],
          callback: function($$v) {
            _vm.$set(_vm.model, _vm.data.fieldId, $$v)
          },
          expression: "model[data.fieldId]"
        }
      })
    : _c("el-time-picker", {
        attrs: {
          "is-range": "",
          "range-separator": _vm.data.setting.rangeSeparator,
          "start-placeholder": _vm.data.setting.startPlaceholder,
          "end-placeholder": _vm.data.setting.endPlaceholder,
          "picker-options": {
            selectableRange: _vm.data.setting.selectableRange,
            format: _vm.data.setting.format
          },
          "prefix-icon": _vm.data.setting.prefixIcon,
          placeholder: _vm.data.placeholder
        },
        on: { focus: _vm.focus },
        model: {
          value: _vm.data.value,
          callback: function($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }