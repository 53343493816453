var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad-mf-layout--content" },
    [
      _c(
        "tp-form-box",
        {
          staticClass: "ad-mf-layout--content--form",
          attrs: { formData: _vm.formData, status: _vm.status }
        },
        [
          _c(
            "draggable",
            {
              staticClass: "ad-mf-layout--content--draggable",
              attrs: { group: "TpFormGroup", animation: "300" },
              model: {
                value: _vm.formData.formDataList,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "formDataList", $$v)
                },
                expression: "formData.formDataList"
              }
            },
            _vm._l(_vm.formData.formDataList, function(li) {
              return _c("which-component", {
                key: li.fieldId,
                attrs: { data: li, status: _vm.status },
                nativeOn: {
                  click: function($event) {
                    return _vm.clickComponent(li)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }