<template>
  <div class="label_item">
    <div class="container">
      <!-- @click="handleAddFileClick" -->
      <div class="container-header">
        <div class="title">
          <span>{{ item.tagGroupName }}</span>
        </div>
        <div class="operation">
          <span
            class="edit"
            @click="handleTagGroupEditClick(item)"
          >编辑</span>
          <span
            class="delete"
            @click="handleTagGroupDelClick(item)"
          >删除</span>
        </div>
      </div>

      <div class="label-table">
        <el-button
          size="mini"
          type="success"
          @click="HandleAddClick(item)"
        >
          添加标签
        </el-button>

        <el-table
          ref="multipleTable"
          :data="item.applicationTags"
          tooltip-effect="dark"
        >
          <el-table-column label="标签名称">
            <template slot-scope="scope">
              {{ scope.row.applicationTagName }}
            </template>
          </el-table-column>
          <el-table-column
            width="200px"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.row,item)"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row,item)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <ad-dialog
        :dialog="dialog"
        label="名称"
        :message="message"
        :title="title"
        :url="url"
        :parame="parame"
        :formName="formName"
        :list="list"
        :type="type"
        @close="handleCloseClick"
        @submit="handleSubmitClick"
        @onCancelClick="onCancelClick"
      />
    </div>
  </div>
</template>

<script>
import dialog from './dialog.vue';
export default {
  name: '',
  components: {
    'ad-dialog': dialog,
  },
  filters: {},
  props: {
    caseReportFormTemplateId: {
      type: String,
      default: '',
    },
    multipleSelection: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    enableShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applicationTagIds: [],
      // 弹窗属性
      dialog: false,
      formName: '',
      message: '',
      title: '',
      url: '',
      list: [],
      parame: {},
      type: 'label',
      item: {},
      // 全选
    };
  },
  computed: {},
  watch: {
    value(e) {
      this.item = e;
    },
  },
  created() {
    this.item = this.value;
  },
  mounted() {},
  methods: {
    handleCheckAllChange() {
      this.applicationTagIds = [];
      this.item.applicationTags.forEach((element) => {
        if (this.checkAll) {
          this.applicationTagIds.push(element.applicationTagId);
          this.$set(element, 'checkbox', true);
        } else {
          this.$set(element, 'checkbox', false);
        }
      });
    },
    handleCheckBoxChange() {
      this.applicationTagIds = [];
      this.item.applicationTags.forEach((element) => {
        if (element.checkbox) this.applicationTagIds.push(element.applicationTagId);
      });
      this.Checkstatus();
    },
    // 添加组
    handleLabelGroupClick() {
      this.dialog = true;
      this.message = '添加成功';
      this.title = '添加标签组';
      this.type = 'labelGroup';
      this.url = this.urls.application_tag.create_tag_group;
      this.list = this.tags;
      this.parame = {};
    },
    // 弹窗操作
    handleSubmitClick() {
      this.$emit('handleSubmitClick');
      this.dialog = false;
    },
    handleCloseClick() {
      this.$emit('handleCloseClick');
      this.dialog = false;
    },
    // 标签组的操作
    handleTagGroupDelClick(item) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('handleTagGroupDelClick', item);
        })
        .catch(() => {});
    },
    handleTagGroupEditClick(item) {
      this.dialog = true;
      this.message = '修改成功';
      this.title = '修改标签组';
      this.url = this.urls.application_tag.edit_tag_group;
      this.list = this.tags;
      this.formName = item.tagGroupName;
      this.type = 'labelGroup';
      this.parame = {
        tagGroupId: item.tagGroupId,
      };
    },
    // 添加标签
    HandleAddClick(item) {
      this.dialog = true;
      this.message = '创建成功';
      this.title = '创建标签';
      this.url = this.urls.application_tag.create_tag;
      this.list = item.applicationTags;
      this.formName = '';
      this.type = 'label';
      this.parame = {
        tagGroupId: item.tagGroupId,
      };
    },
    handleEdit(row, item) {
      this.dialog = true;
      this.message = '修改成功';
      this.title = '修改标签名称';
      this.url = this.urls.application_tag.edit_tag;
      this.list = item.applicationTags;
      this.formName = row.applicationTagName;
      this.type = 'label';
      this.parame = {
        applicationTagId: row.applicationTagId,
      };
    },
    handleDelete(row) {
      this.$confirm('是否删除改标签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('handleDelete', row);
        })
        .catch(() => {});
    },
    onCancelClick() {
      this.title = '';
      this.formName = null;
      this.dialog = false;
    },
  },
};
</script>
<style lang='scss' scoped>
@import '../../styles/label-item';
</style>
