var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.name === "Input"
    ? _c("input-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Textarea"
    ? _c("textarea-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Phone"
    ? _c("phone-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Password"
    ? _c("password-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Radio"
    ? _c("radio-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Checkbox"
    ? _c("checkbox-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Select"
    ? _c("select-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "TimePicker"
    ? _c("time-picker-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "TimePickerMultiple"
    ? _c("time-picker-multiple-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "DatePicker"
    ? _c("date-picker-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "DatePickerMultiple"
    ? _c("date-picker-multiple-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Rate"
    ? _c("rate-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Switch"
    ? _c("switch-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "InputNumber"
    ? _c("input-number-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Link"
    ? _c("link-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Divider"
    ? _c("divider-config", { attrs: { data: _vm.data } })
    : _vm.data.name === "Layout"
    ? _c("layout-config", { attrs: { data: _vm.data } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }