const code = {
  // 激活返回值
  account: {
    CODE_EXPIRED: { code: '10004', text: '链接已失效' }, // 邮箱过期
    NEED_RESET_PASSWORD: { code: '20005', text: '抱歉该账号重设密码' }, // 需要设置密码,
    ACTIVATED: { code: '20006', text: '抱歉该用户以激活' }, // 用户已激活
    INACTIVATED: { code: '20007', text: '是否发送激活邮件' },
    ACCOUNT_JOINED: { code: '40002', text: '该用户已经加入' },
    ACCOUNT_PHONE_FORMAT_ERROR: { code: '20020', text: '手机号错误' },
    ACCOUNT_PHONE_REPEAT: { code: '20028', text: '用户手机号码重复' },
    ACCOUNT_EMAil_REPEAT: { code: '20027', text: '用户邮件重复' },
  },
  BIND_WX_ACCOUNT: [
    { kye: 'ACCOUNT_EMAIL_ERROR', code: '20023', text: '邮箱错误' },
    { kye: 'USERNAME_PASSWORD_ERROR', code: '20003', text: '密码错误' },
    { kye: 'ACCOUNT_WECHAT_BINDED', code: '20030', text: '邮箱已使用' },
    { kye: 'ACCOUNT_WECHAT_OPENID_ERROR', code: '20032', text: '微信账户错误' },
    { kye: 'ACCOUNT_WECHAT_OPENID_USED', code: '20031', text: '微信帐号已注册' },
  ],
  login: {
    ACCOUNT_PHONE_NOT_EXIST: { code: '20022', text: '手机号不存在' },
    ACCOUNT_EMAIL_NOT_EXIST: { code: '20021', text: '邮箱不存在' },
    VERIFICATION_CODE_EXPIRED: { code: '20011', text: '验证码已过期' },
    VERIFICATION_CODE_ERROR: { code: '20012', text: '验证码错误' },
    ACCOUNT_EMAIL_ERROR: { code: '20023', text: '邮箱错误' },
    ACCOUNT_PHONE_ERROR: { code: '20024', text: '手机号错误' },
    ACCOUNT_FORGET_PASSWORD_EXPIRE: { code: '20025', text: '帐户忘记密码过期' },
    ACCOUNT_FORGET_PASSWORD_ERROR: { code: '20026', text: '帐户忘记密码错误' },
    LOGIN_FAIL: { code: '20002', text: '登录失败' }, // 邮箱过期
    ACCOUNT_LOCKED: { code: '20001', text: '该账号被锁，请联系管理员' }, // 需要设置密码,
    USERNAME_PASSWORD_ERROR: { code: '20003', text: '密码错误' }, // 用户已激活
    Account_INACTIVATED: {
      code: '20007',
      text: '当前账户未激活，请前往指定邮箱进行激活',
    }, // 用户已激活
    ACCOUNT_NOT_EXIST: { code: '20008', text: '该用户不存在' },
  },

  bindWxAccount: [
    { code: '20004', text: '操作超时' },
    { code: '20030', text: '账户已绑定微信，请先进行解绑' },
    { code: '20031', text: '该微信已绑定其他账户' },
    { code: '20032', text: '操作超时' },
  ],

  loginCodeArr: [
    { kye: 'LOGIN_FAIL', code: '20002', text: '登录失败' },
    { kye: 'ACCOUNT_LOCKED', code: '20001', text: '登录失败次数过多，该账户暂时被锁定，请30分钟后再试' },
    { kye: 'USERNAME_PASSWORD_ERROR', code: '20003', text: '账号或密码错误' },
    {
      kye: 'Account_INACTIVATED',
      code: '20007',
      text: '当前账户未激活，请前往指定邮箱进行激活',
    },
    { kye: 'ACCOUNT_NOT_EXIST', code: '20008', text: '该用户不存在' },
    { kye: 'VERIFICATION_CODE_EXPIRED', code: '20011', text: '验证码过期了' },
    { kye: 'VERIFICATION_CODE_ERROR', code: '20012', text: '验证码错误' },
    { kye: 'LOGIN_FAILURE_TOO_MANY', code: '20027', text: '登录失败次数过多，该账户暂时被锁定，请30分钟后再试；' },
  ],
  token: {
    TOKEN_INVALID: { code: '20004', text: 'token失效' },
  },

  updatePhone: {
    VERIFICATION_CODE_EXPIRED: {
      code: '20011',
      text: '验证码过期',
    },
    VERIFICATION_CODE_ERROR: {
      code: '20012',
      text: '验证码错误',
    },
    TOKEN_INVALID: {
      code: '20004',
      text: '操作超时',
    },
  },
  //  Application tag group not exist
  applicationTag: {
    APPLICATION_TAG_GROUP_NOT_EXIST: {
      code: '80002',
      text: '无效的tagGroup',
    },
    APPLICATION_TAG_EXIST: {
      code: '80000',
      text: '标签名称重复',
    },
  },
  authByPhone: {
    VERIFICATION_CODE_EXPIRED: {
      code: '20011',
      text: '验证码过期',
    },
    VERIFICATION_CODE_ERROR: {
      code: '20012',
      text: '验证码错误',
    },
    ACCOUNT_PHONE_NOT_EXIST: {
      code: '20022',
      text: '手机号不存在',
    },
  },
  // 新增研究（服务器申请）
  addExperiment: [
    {
      code: '10000',
      text: '连接成功',
      type: 'success',
    },
    {
      code: '40008',
      text: '服务不可用',
      type: 'warning',
    },
    {
      code: '40010',
      text: '连接失败',
      type: 'danger',
    },
  ],
};
export default code;
