import router from './router';
import store from './store';
// import { Message } from 'element-ui';
import NProgress from 'nprogress'; // 导航条参考
import 'nprogress/nprogress.css'; // 导航条样式
import { getToken } from '@/utils/auth'; // 从cookie中拿token
import getPageTitle from '@/utils/get-page-title'; // 获取页面头部title

NProgress.configure({ showSpinner: false }); // 导航条

const whiteList = ['/login', '/account/activate', '/account/changpwd', '/account/binding']; // 路由白名单

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/experiment' });
      NProgress.done();
    } else {
      // const p = store.getters.p.length;
      const permission_routes = store.getters.permission_routes;
      if (permission_routes.length === 0) {
        const accessRoutes = await store.dispatch(
          'permission/generateRoutes',
          store.getters.p,
        );
        router.addRoutes(accessRoutes);
        next({ ...to, replace: true });
      } else {
        next();
        // try {
        //   next();
        // } catch (error) {
        //     // remove token and go to login page to re-login
        //     await store.dispatch('user/logout');
        //     Message.error(error || 'Has Error');
        //     next(`/login?redirect=experiment`);
        //     NProgress.done();
        //   }
        // }
      }
      // if (p === 0) {
      // } else {
      //   try {
      //     // get user info
      //     // await store.dispatch('user/getInfo');
      //     next();
      //   } catch (error) {
      //     // remove token and go to login page to re-login
      //     await store.dispatch('user/logout');
      //     Message.error(error || 'Has Error');
      //     next(`/login?redirect=experiment`);
      //     NProgress.done();
      //   }
      // }
    }
  } else {
    // 对于路径的检索判断是否出现在白名单里面,在白名单直接go
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      // 没有在白名单里面直接去登录页面,并拼上重定向的参数
      next(`/login?redirect=experiment`);
      NProgress.done();
    }
  }
});
// 全局后置
router.afterEach(() => {
  NProgress.done();
});
