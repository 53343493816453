<template>
  <div class="repot_form_edit">
    <div class="add_form_item">
      <el-button type="primary" @click="saveForm()">
        保存
      </el-button>
      <el-button type="success" @click="dialogVisible = true">
        新增
      </el-button>
    </div>
    <el-form :disabled="true">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(item, index) in formItems"
          :key="index"
          :title="item.itemLabel"
          :name="index"
          class="form_item"
        >
          <template slot="title">
            <div class="form_item_title">
              {{ item.itemLabel }}
            </div>
          </template>
          <div class="form_item_list">
            <!-- :span="8 * items.span || 8" -->
            <el-row :gutter="20" type="flex" style="flex-wrap: wrap; flex-direction: row">
              <el-col v-for="(items, indexs) in item.repotItems" :key="items.itemCode" :span="6">
                <div class="form_items" :style="getWidth(items)">
                  <div v-if="activeItemCode.indexOf(items.itemCode) == -1" class="form_items_edit">
                    <i class="el-icon-edit" @click="editItems(items, index, indexs)" />
                    <i class="el-icon-delete" @click="deleteItems(index, indexs)" />
                  </div>
                  <el-form-item
                    :prop="item.itemCode ? `${item.itemCode}.${items.itemCode}` : `${items.itemCode}`"
                    :class="{ required: items.required }"
                    :rules="setRules(items)"
                  >
                    <div class="item_label">
                      {{ items.itemLabel }}
                    </div>
                    <AdWhichComponents :data="items" />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="form_items add_items" @click="addItems(item, index)">
                  <i class="el-icon-plus" />
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>

    <el-dialog width="40%" title="添加检查项目" center :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="add_item_form">
        <el-form ref="ruleForm" label-width="80px" :model="formLabelAlign">
          <el-form-item
            prop="name"
            label="名称"
            :rules="{
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            }"
          >
            <el-input v-model="formLabelAlign.name" />
          </el-form-item>
          <el-form-item
            label="编码"
            :rules="{
              required: true,
              message: '编码不能为空',
              trigger: 'blur',
            }"
            prop="code"
          >
            <el-input v-model="formLabelAlign.code" />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addItemForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      width="50%"
      :title="formItemLabel"
      center
      :visible.sync="addItemsShow"
      :before-close="handleCloseAddItems"
    >
      <div v-if="addItemsShow" class="add_item_form">
        <el-form ref="addRuleForm" label-width="80px" :model="addItemsForm">
          <el-form-item
            prop="itemLabel"
            label="名称"
            :rules="{
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            }"
          >
            <el-input v-model="addItemsForm.itemLabel" />
          </el-form-item>
          <el-form-item
            label="编码"
            :rules="{
              required: true,
              message: '编码不能为空',
              trigger: 'blur',
            }"
            prop="itemCode"
          >
            <el-input v-model="addItemsForm.itemCode" :disabled="hasDataType" />
          </el-form-item>
          <el-form-item
            prop="formType"
            label="组件类型"
            :rules="{
              required: true,
              message: '请输入选择组件类型',
              trigger: 'blur',
            }"
          >
            <el-select v-model="addItemsForm.formType" :disabled="hasDataType">
              <el-option v-for="item in serverList" :key="item.type" :label="item.name" :value="item.type" />
            </el-select>
          </el-form-item>

          <el-form-item label="是否必填">
            <el-switch v-model="addItemsForm.configuration.required" />
          </el-form-item>
          <!-- <el-form-item label="规则设置">
            <div class=" configuration_rules">
              {{ addItemsForm.configuration.rules }}
            </div>
          </el-form-item> -->
          <el-form-item v-if="addItemsForm.formType === 'select'" label="属性设置">
            <div ref="allowableValues" class="configuration_allowableValues">
              <div
                v-for="item in addItemsForm.configuration.allowableValues"
                :key="item.value"
                class="allowableValues_item"
              >
                <el-input v-model="item.label" placeholder="请输入内容" />
                <div class="allowableValues_item_right">
                  <!-- <i class="el-icon-edit" style="color:#409eff;" @click="editAllowableValuesItem(item)" /> -->
                  <i class="el-icon-delete" style="color:#F56C6C;" @click="deleteAllowableValuesItem(item)" />
                </div>
              </div>
              <div v-if="addAllowableValuesShow" class="allowableValues_item">
                <el-input v-model="addAllowableValues.label" placeholder="请输入内容" />
                <div class="allowableValues_item_right">
                  <i class="el-icon-check" style="color:#67C23A" @click="saveData()" />
                  <i class="el-icon-delete" style="color:#F56C6C;" @click="saveDelete()" />
                </div>
              </div>
            </div>
            <div class="configuration_allowableValues_add">
              <el-button size="small" type="success" :disabled="addAllowableValuesShow" @click="onAddAllowableValues()">
                新增
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseAddItems()">取 消</el-button>
        <el-button type="primary" @click="addItemForms('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AdWhichComponents from './components/addWhichComponents';
import cityJson from '@/utils/city.json';
export default {
  components: {
    AdWhichComponents,
  },
  props: {
    reportFormItems: {
      type: Array,
      default: () => [],
    },
    configId: { type: String, default: '' },
  },
  data() {
    return {
      defaultCancerType: ['cancerTypeGroupId', 'cancerSubTypeId', 'province', 'city'],
      activeNames: ['1'],
      activeItemCode: ['name', 'phone', 'cancerTypeGroupId', 'cancerSubTypeId', 'province', 'city', 'age', 'Birthday'],
      formItems: [],
      provinces: cityJson,
      addItemType: '',
      cancerSubTypes: [],
      citys: [],
      ruleForm: {},
      dialogVisible: false,
      addItemsShow: false,
      hasDataType: false,
      formItemLabel: '',
      formItemIndex: 0,
      itemIndex: [],
      addAllowableValues: { label: '', value: '' },
      addAllowableValuesShow: false,

      formLabelAlign: {
        name: '',
        code: '',
      },
      addItemsForm: {
        itemLabel: '',
        formType: '',
        itemCode: '',
        itemValue: '',
        spread: true,
        span: 1,
        configuration: {
          required: false,
          allowableValues: [],
        },
      },
      serverList: [
        {
          name: '输入框',
          type: 'input',
        },
        {
          name: '数字输入框',
          type: 'numInput',
        },
        {
          name: '文本',
          type: 'textarea',
        },
        // {
        //   name: '单选',
        //   type: 'radio',
        // },
        // {
        //   name: '多选',
        //   type: 'checkbox',
        // },
        {
          name: '下拉选择',
          type: 'select',
        },

        {
          name: '选择时间',
          type: 'timePicker',
        },
        {
          name: '选择日期',
          type: 'datePicker',
        },
      ],
    };
  },
  created() {
    this.formItems = this.reportFormItems;
  },
  methods: {
    setRules(items) {
      let { required, rules } = items.configuration;
      let rulesLsit = [
        {
          required: required,
          message: `请选择${items.itemLabel}`,
          trigger: 'change',
        },
      ];
      if (required && rules && rules.length > 0) {
        rules.forEach((item) => {
          rulesLsit.push(item);
        });
      }
      return rulesLsit;
    },
    hasData(name) {
      return this.$post(this.urls.sick.has_data, {
        configId: this.configId,
        filedName: name,
      });
    },
    getWidth() {
      return 'width : 100%';
    },
    saveForm() {
      this.$emit('saveForm', this.formItems);
    },
    saveData() {
      let { label, value } = this.addAllowableValues;
      let allowableValues = this.addItemsForm.configuration.allowableValues;
      let s = true;
      if (label === '') {
        s = false;
        this.$message({
          type: 'error',
          message: '内容不能为空！',
        });
        return s;
      }
      allowableValues.map((item) => {
        if (label === item.label) {
          s = false;
          this.$message({
            type: 'error',
            message: '该选项已存在！',
          });
        }
      });
      if (s) {
        this.addItemsForm.configuration.allowableValues.push({ label, value });
        this.addAllowableValues = {
          label: '',
          value: '',
        };
        this.addAllowableValuesShow = false;
      }
    },
    saveDelete() {
      this.addAllowableValues = {
        label: '',
        value: '',
      };
      this.addAllowableValuesShow = false;
    },
    onAddAllowableValues() {
      this.addAllowableValuesShow = true;
      this.addAllowableValues = {
        label: '',
        value: '',
      };
      this.$nextTick(() => {
        let scrollElem = this.$refs.allowableValues;
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      });
    },
    addItemForm() {
      let formItems = this.formItems;
      let { code, name } = this.formLabelAlign;
      let s = true;
      formItems.map((item) => {
        if (code === item.itemCode) {
          s = false;
          this.$message({
            type: 'error',
            message: '该编码已存在！',
          });
        }
      });
      if (s) {
        this.formItems.push({
          itemLabel: name,
          itemCode: code,
          repotItems: [],
        });
        this.formLabelAlign = {
          name: '',
          code: '',
        };
        this.dialogVisible = false;
      }
    },
    addItemForms() {
      if (this.addItemType === 'add') {
        let repotItems = this.formItems[this.itemIndex[0]].repotItems;
        let { itemCode } = this.addItemsForm;
        let s = true;
        repotItems.map((item) => {
          if (itemCode === item.itemCode) {
            s = false;
            this.$message({
              type: 'error',
              message: '该编码已存在！',
            });
          }
        });
        if (s) {
          this.formItems[this.itemIndex[0]].repotItems.push(this.addItemsForm);
          this.addItemsShow = false;
        }
      } else {
        this.formItems[this.itemIndex[0]].repotItems[this.itemIndex[1]] = this.addItemsForm;
        this.addItemsShow = false;
      }
    },
    addItems(item, index) {
      this.formItemLabel = item.itemLabel;
      this.itemIndex = [];
      this.addItemType = 'add';
      this.hasDataType = false;
      this.itemIndex = [index];
      this.addItemsForm = {
        itemLabel: '',
        formType: '',
        itemCode: '',
        itemValue: '',
        spread: true,
        span: 1,
        configuration: {
          required: false,
          allowableValues: [],
        },
      };
      this.addItemsShow = true;
    },
    handleCloseAddItems() {
      this.addItemsShow = false;
      this.addAllowableValuesShow = false;
      this.addAllowableValues = {
        label: '',
        value: '',
      };
      this.addItemsForm = {
        itemLabel: '',
        formType: '',
        itemCode: '',
        itemValue: '',
        spread: true,
        span: 1,
        configuration: {
          required: false,
        },
      };
    },
    handleClose() {
      this.dialogVisible = false;
      this.addItemsShow = false;
    },
    editItems(items, index, indexs) {
      this.itemIndex = [];
      this.addItemType = 'edit';
      let n1 = this.formItems[index].itemCode;
      let n2 = this.formItems[index].repotItems[indexs].itemCode;
      this.hasData(`${n1}.${n2}`).then((res) => {
        this.hasDataType = res.hasData;
        this.itemIndex = [index, indexs];
        this.addItemsForm = JSON.parse(JSON.stringify(items));
        this.addItemsShow = true;
      });
    },
    async deleteItems(index, indexs) {
      let n1 = this.formItems[index].itemCode;
      let n2 = this.formItems[index].repotItems[indexs].itemCode;

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.hasData(`${n1}.${n2}`).then((res) => {
            if (res.hasData) {
              this.$message({
                type: 'error',
                message: '该选项已使用不可删除',
              });
            } else {
              this.formItems[index].repotItems.splice(indexs, 1);
              this.$message({
                type: 'success',
                message: '删除成功',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    editAllowableValuesItem(item) {
      console.log(item);
    },
    deleteAllowableValuesItem(item) {
      console.log(item);
    },
  },
};
</script>
<style lang="scss">
.repot_form_edit {
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: visible;
  }
  .el-select {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.repot_form_edit {
  margin-top: 12px;
  padding-bottom: 60px;
  .form_item {
    // padding: 20px 24px 16px 20px;
    // width: 80%;
    .form_item_title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      // padding-bottom: 12px;
    }
    .form_item_list {
      .form_items {
        padding: 0 12px;
        margin: 12px 0;
        min-height: 114px;
        border: 1px dotted #ccc;
        position: relative;
        min-height: 125px;
        .item_label {
          font-weight: 700;
          margin-right: 80px;
          margin-top: 10px;
          margin: 10px 80px 20px 0;
          line-height: 1.4;
          font-size: 14px;
          color: #606266;
        }
        &_edit {
          position: absolute;
          top: 6px;
          right: 8px;
          font-size: 18px;
          z-index: 10;
          i {
            padding: 4px 6px;
          }
          .el-icon-edit {
            color: #409eff;
            margin-right: 4px;
          }
          .el-icon-delete {
            color: #f56c6c;
          }
        }
      }
      .add_items {
        border: 2px dotted #409eff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #409eff;
        font-size: 40px;
      }
    }
  }
  .add_form_item {
    display: flex;
    margin: 24px 0;
    justify-content: flex-end;
  }
  .add_item_form {
    width: 500px;
    margin: 0 auto;
  }
  .configuration_allowableValues {
    overflow: auto;
    max-height: 260px;
    padding-bottom: 10px;
    padding-right: 8px;
    .allowableValues_item {
      display: flex;
      margin-bottom: 12px;
      align-items: center;

      .allowableValues_item_right {
        padding: 4px;
        width: 88px;
        > i {
          padding: 4px;
          margin: 4px;
        }
      }
    }
  }
}
</style>
