<template>
  <el-radio-group v-model="value">
    <el-radio
      v-for="r in data.configuration.allowableValues || []"
      :key="r.value"
      :label="r.value"
    >
      {{ r.cnValue }}
    </el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: "AdRadio",
  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: ""
    };
  }
};
</script>
