// import constant from '@/utils/constant';

const reportForm = [{
  itemLabel: '基础信息',
  itemCode: 'information',
  repotItems: [{
    itemLabel: '姓名',
    formType: 'input',
    itemCode: 'name',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '电话',
    formType: 'input',
    itemCode: 'phone',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: true,
      rules: [{
        pattern: /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/,
        message: '请输入正确手机号格式',
        trigger: ['blur', 'change'],
      }],
    },
  },

  {
    itemLabel: '癌种类型',
    formType: 'select',
    itemCode: 'cancerTypeGroupId',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: true,
      allowableValues: [],
    },
  },

  {
    itemLabel: '病种',
    formType: 'select',
    itemCode: 'cancerSubTypeId',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: true,
      allowableValues: [],
    },
  },
  {
    itemLabel: '归属省份',
    formType: 'select',
    itemCode: 'province',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '归属城市',
    formType: 'select',
    itemCode: 'city',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 2,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '年龄',
    formType: 'numInput',
    itemCode: 'age',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 2,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '出生日期',
    formType: 'datePicker',
    itemCode: 'birthday',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 2,
    configuration: {
      required: true,
    },
  },
  {
    itemLabel: '微信昵称',
    formType: 'input',
    itemCode: 'wechatName',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '微信号',
    formType: 'input',
    itemCode: 'wechatId',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '来源',
    formType: 'select',
    itemCode: 'source',
    itemValue: '',
    spread: false,
    fixed: true,
    span: 1,
    configuration: {
      required: false,
      allowableValues: [{
        value: '贴吧',
        label: '贴吧',
      },
      {
        value: 'QQ群',
        label: 'QQ群',
      },
      {
        value: '医生介绍',
        label: '医生介绍',
      },
      {
        value: '朋友圈',
        label: '朋友圈',
      },
      {
        value: 'QQ',
        label: 'QQ',
      },
      {
        value: '客服电话',
        label: '客服电话',
      },
      {
        value: '内部业务员介绍',
        label: '内部业务员介绍',
      },
      {
        value: '头条',
        label: '头条',
      },
      {
        value: '知乎',
        label: '知乎',
      },
      {
        value: '病友推荐',
        label: '病友推荐',
      },
      {
        value: '代理推荐',
        label: '代理推荐',
      },
      {
        value: '竞品',
        label: '竞品',
      },
      {
        value: '其他',
        label: '其他',
      },
      ],
    },
  },
  {
    itemLabel: '有无化疗史',
    formType: 'select',
    itemCode: 'chemotherapyHistory',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      allowableValues: [{
        label: '有',
        value: true,
      },
      {
        label: '无',
        value: false,
      },
      ],
    },
  },

  {
    itemLabel: '最近一次化疗时间',
    formType: 'datePicker',
    itemCode: 'chemotherapyTime',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '有无放疗史',
    formType: 'select',
    itemCode: 'radiotherapyHistory',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      allowableValues: [{
        label: '有',
        value: true,
      },
      {
        label: '无',
        value: false,
      },
      ],
    },
  },
  {
    itemLabel: '最近一次放疗时间',
    formType: 'datePicker',
    itemCode: 'radiotherapyTime',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '有无服药史',
    formType: 'select',
    itemCode: 'medicationHistory',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      allowableValues: [{
        label: '有',
        value: true,
      },
      {
        label: '无',
        value: false,
      },
      ],
    },
  },
  {
    itemLabel: '服用过药物',
    formType: 'textarea',
    itemCode: 'medication',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '预计复查日期',
    formType: 'datePicker',
    itemCode: 'expectedReviewTime',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '备注',
    formType: 'textarea',
    itemCode: 'remark',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  ],
},
{
  itemLabel: '出院小结',
  itemCode: 'summary',
  repotItems: [{
    itemLabel: '入院日期',
    formType: 'datePicker',
    itemCode: 'admissionDate',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '出院日期',
    formType: 'datePicker',
    itemCode: 'dischargeDate',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '住院天数',
    formType: 'input',
    itemCode: 'hospitalizationDays',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '入院诊断描述',
    formType: 'textarea',
    itemCode: 'admissionDiagnosis',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '入院情况描述',
    formType: 'textarea',
    itemCode: 'admissionCondition',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '是否手术',
    formType: 'select',
    itemCode: 'surgery',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      allowableValues: [{
        label: '有',
        value: true,
      },
      {
        label: '无',
        value: false,
      },
      ],
    },
  },
  {
    itemLabel: '手术名称',
    formType: 'textarea',
    itemCode: 'surgeryName',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '诊疗经过描述',
    formType: 'textarea',
    itemCode: 'diagnosisTreatment',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '出院情况描述',
    formType: 'textarea',
    itemCode: 'dischargeCondition',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '出院诊断描述',
    formType: 'textarea',
    itemCode: 'dischargeDiagnosis',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '出院医嘱描述',
    formType: 'textarea',
    itemCode: 'dischargeOrder',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '出院指导描述',
    formType: 'textarea',
    itemCode: 'dischargeGuidance',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '复诊建议描述',
    formType: 'textarea',
    itemCode: 'followUpAdvice',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '其他描述',
    formType: 'textarea',
    itemCode: 'remark',
    itemValue: '',
    spread: true,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  ],
},
{
  itemLabel: '病理描述',
  itemCode: 'pathological',
  repotItems: [{
    itemLabel: '标本名称',
    formType: 'input',
    itemCode: 'specimen',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '检查日期',
    formType: 'datePicker',
    itemCode: 'inspectionDate',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 2,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '临床诊断描述',
    formType: 'textarea',
    itemCode: 'clinicalDiagnosis',
    itemValue: '',
    spread: true,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '肉眼所见描述',
    formType: 'textarea',
    itemCode: 'generalView',
    itemValue: '',
    spread: true,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '内镜所见描述',
    formType: 'textarea',
    itemCode: 'endoscopicFindings',
    itemValue: '',
    spread: true,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '病理诊断描述',
    formType: 'textarea',
    itemCode: 'pathologicDiagnosis',
    itemValue: '',
    spread: true,
    span: 2,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  ],
},
{
  itemLabel: 'CT描述',
  itemCode: 'ct',
  repotItems: [{
    itemLabel: '检查部位',
    formType: 'input',
    itemCode: 'part',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '检查方法',
    formType: 'input',
    itemCode: 'method',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '检查日期',
    formType: 'datePicker',
    itemCode: 'inspectionDate',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '临床诊断描述',
    formType: 'textarea',
    itemCode: 'clinicalDiagnosis',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '影像所见描述',
    formType: 'textarea',
    itemCode: 'image',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '影像诊断描述',
    formType: 'textarea',
    itemCode: 'imagingDiagnosis',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  ],
},
{
  itemLabel: 'MR描述',
  itemCode: 'mr',
  repotItems: [{
    itemLabel: '检查部位',
    formType: 'input',
    itemCode: 'items',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '检查设备',
    formType: 'input',
    itemCode: 'equipment',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '检查日期',
    formType: 'datePicker',
    itemCode: 'inspectionDate',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
    },
  },
  {
    itemLabel: '影像学所见描述',
    formType: 'textarea',
    itemCode: 'imagingFindings',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  {
    itemLabel: '影像学诊断描述',
    formType: 'textarea',
    itemCode: 'imagingDiagnosis',
    itemValue: '',
    spread: false,
    fixed: false,
    span: 1,
    configuration: {
      required: false,
      autosize: {
        minRows: 1,
        maxRows: 4,
      },
    },
  },
  ],
},
];

export default reportForm;
