import { post } from '@/https/request';
import urls from '@s/urls';

const state = {
  configuration: null,
  study: null,
};

const mutations = {
  SET_STUDY: (state, study) => {
    state.study = study;
  },
  SET_STUDYCONFIGURATION: (state, configuration) => {
    state.configuration = configuration;
  },
};

const actions = {
  study({ commit }, studyId) {
    return new Promise((resolve, reject) => {
      post(urls.study.get, { studyId })
        .then((result) => {
          commit('SET_STUDY', result);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  studyConfiguration({ commit }, studyId) {
    return new Promise((resolve, reject) => {
      post(urls.study_configuration.query, { studyId })
        .then(({ data }) => {
          const studyConfiguration = {};
          for (let index = 0; index < data.length; index++) {
            const { code, actualValue } = data[index];
            studyConfiguration[code] = actualValue;
          }
          commit('SET_STUDYCONFIGURATION', studyConfiguration);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetStudy({ commit }) {
    commit('SET_STUDY', null);
    commit('SET_STUDYCONFIGURATION', null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
