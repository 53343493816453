<template>
  <div class="upload_password">
    <div class="upload_password_form">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="密码" prop="pass">
          <el-input v-model="ruleForm.pass" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" />
        </el-form-item>
        <div class="upload_password_form-item">
          <el-button type="primary" @click="submitForm('ruleForm')">
            提交
          </el-button>
          <el-button @click="resetForm('ruleForm')">
            重置
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' },
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' },
        ],
      },
      ruleForm: {
        pass: '',
        checkPass: '',
      },
    };
  },
  methods: {

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(this.urls.account.update_pwd
            , {
              password: this.ruleForm.checkPass,
              token: this.token,
            })
            .then(() => {
              this.$message.success('密码重置成功');
              setTimeout(() => {
                this.$router.go(0);
              }, 1000);
            })
            .catch((err) => {
              this.loadingReset = false;
              if (err.code === '20004') {
                this.$message.error('操作超时');
              } else {
                this.$message.error('设置失败，参数错误');
              }
            });
        } else {
          this.$message.error('参数错误');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_password_form{
    width: 400px;
    margin: 0 auto;
    margin-top: 20px;

    .upload_password_form-item{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}

</style>
