<template>
  <div class="sys-container">
    <!-- 第一部分显示列表 -->
    <div class="list">
      <div
        class="header"
        @click="handleAddFileClick"
      >
        <i
          class="el-icon-circle-plus-outline"
          style="font-size:20px"
        />
        <span>添加CRF模板</span>
      </div>
      <ul>
        <li
          v-for="(item, index) in sidebarList"
          :key="item.caseReportFormTemplateId"
          :class="{'is-item__content_active':caseReportFormTemplateId === item.caseReportFormTemplateId}"
          @click="handleItemClick(item,index)"
        >
          {{ item.formName }}
          <i
            class="ad-collapse-item_outline el-icon-more-outline"
            :class="{'is-menu-active':caseReportFormTemplateId===item.caseReportFormTemplateId}"
            @click.stop="handleOutlineClick(item.caseReportFormTemplateId)"
          />
          <ad-popover
            class="ad-popover"
            :menuActive="childActive===item.caseReportFormTemplateId"
            @handleRenameClick="handleRenameClick(item)"
            @handleDelClick="handleDelClick"
            @handleTagClick="handleTagClick"
          />
        </li>
      </ul>
    </div>
    <div
      v-if="makeFormShow"
      class="make-form-ly"
    >
      <MakeFormLayout
        :echoData="echoData"
        :crfStatus="crfStatus"
        @acquireJson="acquireJson"
        @handleLockClick="handleLockClick"
      />
    </div>
    <template v-if="showTag">
      <div class="ad-check-box">
        <div
          v-for="tagGroup in tags"
          :key="tagGroup.tagGroupId"
          class="check-item"
        >
          <div class="title">
            {{ tagGroup.tagGroupName }}：
          </div>
          <div class="group">
            <el-checkbox-group v-model="checkAll">
              <el-checkbox
                v-for="applicationTag in tagGroup.applicationTags"
                :key="applicationTag.applicationTagId"
                :label="applicationTag.applicationTagId"
              >
                {{ applicationTag.applicationTagName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <el-button
          class="check-btn"
          type="primary"
          @click="handleCheckAllClick"
        >
          保存
        </el-button>
      </div>
    </template>
    <ad-dialog
      :dialog="addDialog"
      :formName="formName"
      :message="message"
      :title="title"
      :url="url"
      :parame="parame"
      :list="sidebarList"
      @close="handleAddCloseClick"
      @submit="HandleAddSubmitClick"
      @onCancelClick="onCancelClick"
    />
  </div>
</template>

<script>
import popover from '@c/Sidebar';
import dialog from './components/dialog.vue';
export default {
  name: '',
  components: {
    'ad-popover': popover,
    'ad-dialog': dialog,
  },
  filters: {},
  props: {},
  data() {
    return {
      sidebarList: [],
      caseReportFormTemplateId: '', // 侧边栏选中的item
      echoData: [],
      crfStatus: '',
      childActive: '',
      makeFormShow: false,
      // 添加的弹窗
      addDialog: false,
      formName: '',
      message: '',
      title: '',
      url: '',
      parame: {},
      position: 0,
      showTag: false,
      multipleSelection: [],
      // tags
      tags: [],
      checkAll: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTamplateList();
    this.getListTags();
  },
  mounted() {},
  methods: {
    // 获取列表
    getTamplateList() {
      this.$post(this.urls.crf_template.list, {})
        .then((result) => {
          this.sidebarList = result.data;
        })
        .catch(() => {});
    },
    handleAddFileClick() {
      this.addDialog = true;
      this.formName = '';
      this.message = '创建成功';
      this.title = '新建病例报告表模板';
      this.url = this.urls.crf_template.create_crf_template_title;
      this.parame = { position: this.sidebarList.length };
    },
    // 选中块
    handleItemClick(item, index) {
      this.caseReportFormTemplateId = item.caseReportFormTemplateId;
      this.position = index;
      this.childActive = '';
      this.makeFormShow = true;
      this.showTag = false;
      this.getOneCrf();
    },
    // 三点的样式
    handleOutlineClick(e) {
      this.childActive = e;
      this.caseReportFormTemplateId = e;
    },
    // 编辑样式
    handleRenameClick(item) {
      this.message = '修改成功';
      this.formName = item.formName;
      this.title = '修改表单';
      this.url = this.urls.crf_template.edit;
      this.parame = { caseReportFormTemplateId: this.childActive };
      this.childActive = '';
      this.addDialog = true;
    },
    handleDelClick() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$post(this.urls.crf_template.delete, {
            caseReportFormTemplateId: this.caseReportFormTemplateId,
          })
            .then(() => {
              this.$message({
                message: '删除成功',
                type: 'success',
              });
              this.getTamplateList();
            })
            .catch(() => {
              this.$message.error('删除失败');
            });
        })
        .catch(() => {});
      this.childActive = '';
    },
    // 标签
    handleTagClick() {
      this.childActive = '';
      this.makeFormShow = false;
      if (this.tags.length !== 0) {
        this.showTag = true;
        this.$post(this.urls.application_tag.list_template_tags, {
          caseReportFormTemplateId: this.caseReportFormTemplateId,
        })
          .then((result) => {
            this.checkAll = result;
          })
          .catch(() => {});
      } else {
        this.$message({
          message: '暂无标签',
          type: 'error',
        });
      }
    },
    handleCheckAllClick() {
      this.$post(this.urls.application_tag.print_template, {
        caseReportFormTemplateId: this.caseReportFormTemplateId,
        applicationTagIds: this.checkAll,
      })
        .then(() => {
          this.$message({
            message: '保存成功',
            type: 'success',
          });
        })
        .catch(() => {});
    },
    // 添加文件
    HandleAddSubmitClick() {
      this.addDialog = false;
      this.getTamplateList();
    },
    handleAddCloseClick() {
      this.addDialog = false;
      this.formName = null;
      this.title = '';
      this.url = '';
      this.parame = {};
      this.message = '';
    },
    onCancelClick() {
      this.addDialog = false;
      this.title = '';
      this.formName = '';
    },
    // 保存
    acquireJson(e) {
      this.crfSaveFun(e)
        .then(() => {
          this.$message({
            message: '保存成功',
            type: 'success',
          });
          this.getOneCrf();
        })
        .catch(() => {});
    },
    // 保存并发布
    handleLockClick(e) {
      this.$confirm('锁定以后将不能修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.crfSaveFun(e).then(() => {
          this.$post(this.urls.crf_template.publish, {
            caseReportFormTemplateId: this.caseReportFormTemplateId,
          }).then(() => {
            this.getOneCrf();
          });
        });
      });
    },
    // save
    crfSaveFun(e) {
      return this.$post(this.urls.crf_template.save, {
        crfFormJson: e,
        caseReportFormTemplateId: this.caseReportFormTemplateId,
        position: this.position,
      })
        .then((relust) => {
          return relust;
        })
        .catch(() => {
          this.$message({
            message: '服务异常',
            type: 'error',
          });
        });
    },
    getOneCrf() {
      return this.$post(this.urls.crf_template.get_one, {
        caseReportFormTemplateId: this.caseReportFormTemplateId,
      })
        .then((result) => {
          const crf = result.crf;
          this.crfStatus = result.status;
          // this.crfStatus = 'publish';
          this.echoData = [];
          crf.forEach((element) => {
            let obj = {
              fieldId: element.itemTemplateId,
              label: element.itemLabel,
              makeStyle: {
                active: false,
              },
              name: element.itemName,
              placeholder: element.placeholder,
              rules: {},
              style: {},
              value: '',
              itemId: element.itemTemplateId,
            };
            if (element.itemName === 'Input' || element.itemName === 'Textarea') {
              obj.maxlength = element.length;
            }
            if (
              element.itemName === 'Checkbox' ||
              element.itemName === 'Radio' ||
              element.itemName === 'Select'
            ) {
              obj.setting = {
                isRemote: false,
                remoteUrl: '',
                remoteDataPath: '',
                remoteDataProps: {
                  label: 'label',
                  value: 'value',
                },
              };
              // obj.selections = element.selections;
              let arr = [];
              element.selections.forEach((item) => {
                arr.push({
                  label: item.itemSelectionLabel,
                  value: item.itemSelectionValue,
                });
              });
              obj.selections = arr;
            }
            if (element.itemName === 'Textarea') {
              obj.setting = { rows: 2 };
            }
            if (element.itemName === 'DatePicker') {
              obj.setting = {
                type: 'date',
                format: '',
                prefixIcon: 'el-icon-date',
              };
            }
            if (element.itemName === 'TimePicker') {
              obj.setting = {
                selectableRange: '00:00:00-23:59:59',
                format: 'HH:mm:ss',
                prefixIcon: 'el-icon-time',
              };
            }
            if (element.required) {
              obj.rules = {
                required: {
                  message: '不能为空',
                  required: true,
                  trigger: 'blur',
                },
              };
            }
            obj.deleted = false;
            obj.value = element.defaultValue;
            this.echoData.push(obj);
          });
        })
        .catch(() => {});
    },
    getListTags() {
      this.$post(this.urls.application_tag.list_tags, {})
        .then((result) => {
          this.tags = result;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang='scss' scoped>
@import '../styles/sys.scss';
</style>
