var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        size: "40%",
        title: "",
        visible: _vm.visible,
        direction: "rtl",
        "before-close": _vm.handleClose,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 30px" } },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                title: "详情",
                column: 1,
                direction: "horizontal",
                border: "",
                "label-style": _vm.labelStyle
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "观察类别" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.observationClass) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "域" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.domain) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "数据收集情形" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.dataCollectionScenario) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "实施选项" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.implementationOptions) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "变量" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.cdashigVariable) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "标签" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.cdashigVariableLabel) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "定义" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.draftCdashigDefinition) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "示例" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.cdashItem.caseReportFormCompletionInstructions) +
                    " "
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "数据类型" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.dataType) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "核心类型" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.cdashigCore) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "提示" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.prompt) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "实施说明" } }, [
                _vm._v(" " + _vm._s(_vm.cdashItem.implementationNotes) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }