<template>
  <div class="login-form">
    <div class="form">
      <!-- 登录表单 -->
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
        <el-form-item class="username-form-item" prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入账号"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>
        <el-form-item class="password-form-item" prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            ref="password"
            :key="passwordType"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.enter.native="handleLogin"
          />
          <!-- <span
            class="show-pwd"
            @click="handleShowPwd"
          >
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span> -->
        </el-form-item>
        <div class="verify-box">
          <el-form-item class="password-form-item" prop="captcha">
            <span class="svg-container">
              <svg-icon icon-class="yanzhengma" />
            </span>
            <el-input
              ref="captcha"
              v-model="loginForm.captcha"
              type="text"
              placeholder="请输入验证码"
              name="captcha"
              autocomplete="off"
              @keyup.enter.native="handleLogin"
            />
          </el-form-item>
          <div class="code-img-btn" @click="onGetCaptcha()">
            <img v-if="imgShow" src="/api/v1/account/captcha/get" style="width: 120px; height: 50px" alt="" />
          </div>
        </div>
        <div class="check">
          <span @click="handleForget">忘记密码</span>
        </div>

        <el-button :loading="loading" type="primary" class="el-btn" @click.native.prevent="handleLogin">
          登录
        </el-button>
        <div v-if="false" class="login-oauth">
          <div class="login-icon" @click="toWxLogin">
            <img src="@/assets/img/weixin.png" alt="" srcset="" /> 微信登录
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';

export default {
  props: {
    isForget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 密码验证
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码不能小于六位数'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        captcha: '',
      },
      loginRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'change' }],
        password: [{ required: true, trigger: 'change', validator: validatePassword }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'change' }],
      },
      passwordType: 'password',
      loading: false,
      redirect: undefined,
      imgUrl: '',
      imgShow: true,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    let code = this.$route.query.code || '';
    if (code && parseInt(code) === 10000) {
      this.getWXCodeInfon(this.$route.query.token);
    }
  },
  methods: {
    // handleShowPwd() {
    //   if (this.passwordType === 'password') {
    //     this.passwordType = '';
    //   } else {
    //     this.passwordType = 'password';
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.password.focus();
    //   });
    // },
    // 登录方法

    getWXCode() {
      this.$get(this.urls.wx.qr_authorize)
        .then((res) => {
          console.log(res);

          window.location.href = res;
        })
        .catch((err) => {
          console.log(err);
          window.location.href = err;
        });
    },
    handleLogin: debounce(function() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || '/experiment' });
              // this.$webSocket.initWebSocket('cloud-dev.musuikeji.com/api/v1/data/ws');
              this.loading = false;
            })
            .catch((err) => {
              this.onGetCaptcha();
              this.loginForm.captcha = '';
              this.codes.loginCodeArr.forEach((e) => {
                if (e.key === 'Account_INACTIVATED' && e.code === err.code) {
                  this.$alert(e.text, '提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                      if (action === 'confirm') {
                        this.accountActivate();
                      } else {
                        this.loading = false;
                      }
                    },
                  });
                } else if (e.code === err.code) {
                  this.$message.error(e.text);
                  this.loading = false;
                }
              });
            });
        } else {
          return false;
        }
      });
    }, 400),

    onGetCaptcha: debounce(function() {
      this.imgShow = false;
      setTimeout(() => {
        this.imgShow = true;
      }, 500);
    }, 500),
    handleForget() {
      this.$emit('forgetPwd');
    },
    getWXCodeInfon(token) {
      let param = new FormData();
      param.append('code', token);
      this.$post(this.urls.wx.get_account_by_code, param)
        .then((result) => {
          this.$store
            .dispatch('user/wx_login', result)
            .then(() => {
              this.$router.push({ path: this.redirect || '/experiment' });
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          this.codes.loginCodeArr.forEach((e) => {
            if (e.code === err.code) {
              this.$message.error(e.text);
              this.loading = false;
            }
          });
        });
    },
    // 激活用户方法
    accountActivate() {
      this.$post(this.urls.account.apply_activate, {
        email: this.loginForm.username,
      })
        .then(() => {
          this.$message('请前往你的邮箱激活');
        })
        .catch(() => {
          this.$message.error('发送失败');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toWxLogin() {
      this.getWXCode();
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #889aa4;
$black_gray: black;

.svg-container {
  display: inline-block;
  padding-bottom: 6px;
  padding-left: 24px;
  font-size: 18px;
  color: #c7cbda;
  vertical-align: middle;
  .svg-icon {
    width: 1.2em;
    height: 1.2em;
  }
}
.form {
  width: 400px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
}
.check {
  margin-bottom: 30px;
  text-align: right;
  color: #444;
  font-size: 16px;
  font-weight: 400;
  span {
    cursor: pointer;
    color: #63b7ff;
  }
}
.show-pwd {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 18px;
  color: $dark_gray;
  user-select: none;
}
.el-btn {
  width: 100%;
  padding: 14px 40px;
  font-size: 18px;
  border-radius: 4px;
}
.verify-box {
  display: flex;
  justify-content: space-between;
  .password-form-item {
    width: 60%;
  }
}
.code-img-btn {
  height: 50px;
  width: 120px;
  border-radius: 4px;
  overflow: hidden;
  padding: 0 4px;
  border: 1px solid #b9bed1;
  cursor: pointer;
}

.login-oauth {
  text-align: center;
  margin-top: 26px;

  .login-icon {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
}
</style>
