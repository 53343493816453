var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.$store.getters.account
                      ? _vm.$store.getters.account.avatarUrl
                      : ""
                  }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v(" 回到首页 ")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/experiment" } },
                    [_c("el-dropdown-item", [_vm._v(" 切换研究中心 ")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/account/update/pwd" } },
                    [_c("el-dropdown-item", [_vm._v(" 修改密码 ")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }