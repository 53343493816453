<template>
  <div class="ad-date-picker">
    <el-date-picker
      v-if="show"
      v-model="value"
      :picker-options="pickerTime"
      :placeholder="`选择${data.itemLabel}`"
      :default-value="data.configuration.defaultValue || day"
      value-format="timestamp"
    />
  </div>
</template>

<script>
export default {
  name: "MsInput",

  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    let that = this;
    return {
      pickerTime: {
        disabledDate(time) {
          return (
            time.getTime() < that.data.configuration.minDate ||
            time.getTime() > that.data.configuration.maxDate
          );
        }
      },
      day: new Date().getTime(),
      show: false,
      value: ""
    };
  },
  mounted() {
    this.show = true;
  },

  methods: {}
};
</script>
<style lang="scss" scoped>
.ad-date-picker {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
