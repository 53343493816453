var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-changpwd-container" },
    [
      _c(
        "el-form",
        {
          ref: "accountForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.accountForm,
            rules: _vm.rules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v(" 修改密码 ")])
          ]),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _c("el-input", {
                key: _vm.pass,
                ref: "pass",
                attrs: {
                  type: _vm.pass,
                  placeholder: "新密码",
                  name: "password",
                  tabindex: "2",
                  "auto-complete": "on"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  }
                },
                model: {
                  value: _vm.accountForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.accountForm, "password", $$v)
                  },
                  expression: "accountForm.password"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "show-pwd",
                  on: {
                    click: function($event) {
                      return _vm.handleShowPwd("pass")
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": _vm.pass === "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "pass2" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _c("el-input", {
                key: _vm.pass2,
                ref: "pass2",
                attrs: {
                  type: _vm.pass2,
                  placeholder: "确认密码",
                  name: "pass2",
                  tabindex: "2",
                  "auto-complete": "on"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  }
                },
                model: {
                  value: _vm.accountForm.pass2,
                  callback: function($$v) {
                    _vm.$set(_vm.accountForm, "pass2", $$v)
                  },
                  expression: "accountForm.pass2"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "show-pwd",
                  on: {
                    click: function($event) {
                      return _vm.handleShowPwd("pass2")
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pass2 === "password" ? "eye" : "eye-open"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "success" },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                }
              }
            },
            [_vm._v(" 确认 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }