var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-checkbox-group",
        {
          staticClass: "ad-checkbox-group",
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.remoteData, function(item) {
          return _c(
            "el-checkbox",
            {
              key: item[_vm.data.setting.remoteDataProps.value],
              staticClass: "ad-checkbox",
              attrs: { label: item[_vm.data.setting.remoteDataProps.value] }
            },
            [
              _c("span", {
                staticClass: "ad-label-props",
                domProps: {
                  textContent: _vm._s(
                    item[_vm.data.setting.remoteDataProps.label]
                  )
                }
              })
            ]
          )
        }),
        1
      )
    : _vm.data.setting.isRemote && !_vm.model
    ? _c(
        "el-checkbox-group",
        {
          staticClass: "ad-checkbox-group",
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.remoteData, function(item) {
          return _c(
            "el-checkbox",
            {
              key: item[_vm.data.setting.remoteDataProps.value],
              staticClass: "ad-checkbox",
              attrs: { label: item[_vm.data.setting.remoteDataProps.value] }
            },
            [
              _c("span", {
                staticClass: "ad-label-props",
                domProps: {
                  textContent: _vm._s(
                    item[_vm.data.setting.remoteDataProps.label]
                  )
                }
              })
            ]
          )
        }),
        1
      )
    : !_vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-checkbox-group",
        {
          staticClass: "ad-checkbox-group",
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.data.selections, function(item) {
          return _c(
            "el-checkbox",
            {
              key: item.value,
              staticClass: "ad-checkbox",
              attrs: { label: item.value }
            },
            [
              _c("span", {
                staticClass: "ad-label-props",
                domProps: { textContent: _vm._s(item.label) }
              })
            ]
          )
        }),
        1
      )
    : _c(
        "el-checkbox-group",
        {
          staticClass: "ad-checkbox-group",
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.data.selections, function(item) {
          return _c(
            "el-checkbox",
            {
              key: item.value,
              staticClass: "ad-checkbox",
              attrs: { label: item.value }
            },
            [
              _c("span", {
                staticClass: "ad-label-props",
                domProps: { textContent: _vm._s(item.label) }
              })
            ]
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }