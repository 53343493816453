var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ad-dialog",
      attrs: {
        center: "",
        title: _vm.title,
        width: "560px",
        visible: _vm.dialog,
        "close-on-click-modal": false,
        "destroy-on-close": false
      },
      on: { close: _vm.onCloseClick }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "label-form",
          attrs: { "label-width": "auto", model: _vm.form, rules: _vm.rule }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name", label: _vm.label } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSubmitClick($event)
                  }
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.onCancelClick } }, [
            _vm._v(" 取 消 ")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmitClick($event)
                }
              }
            },
            [_vm._v(" 确 定 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }