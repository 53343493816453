var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.itemCode === "cancerTypeGroupId"
      ? _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { disabled: _vm.cancerDisabled },
                on: { change: _vm.cancerTypeGroupSelChange },
                model: {
                  value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.model[_vm.itemItemsCode],
                      "" + _vm.data.itemCode,
                      $$v
                    )
                  },
                  expression: "model[itemItemsCode][`${data.itemCode}`]"
                }
              },
              _vm._l(_vm.cancerTypeGroups, function(item) {
                return _c("el-option", {
                  key: item.cancerSubTypeId,
                  attrs: { label: item.cnName, value: item.cnName }
                })
              }),
              1
            )
          ],
          1
        )
      : _vm.data.itemCode === "cancerSubTypeId"
      ? _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { disabled: _vm.cancerDisabled },
                model: {
                  value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.model[_vm.itemItemsCode],
                      "" + _vm.data.itemCode,
                      $$v
                    )
                  },
                  expression: "model[itemItemsCode][`${data.itemCode}`]"
                }
              },
              _vm._l(_vm.cancerSubTypes, function(item) {
                return _c("el-option", {
                  key: item.cancerSubTypeId,
                  attrs: { label: item.cnName, value: item.cnName }
                })
              }),
              1
            )
          ],
          1
        )
      : _vm.data.itemCode === "province"
      ? _c(
          "div",
          [
            _c(
              "el-select",
              {
                on: { change: _vm.provinceSelChange },
                model: {
                  value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.model[_vm.itemItemsCode],
                      "" + _vm.data.itemCode,
                      $$v
                    )
                  },
                  expression: "model[itemItemsCode][`${data.itemCode}`]"
                }
              },
              _vm._l(_vm.provinces, function(item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { label: item.name, value: item.name }
                })
              }),
              1
            )
          ],
          1
        )
      : _vm.data.itemCode === "city"
      ? _c(
          "div",
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.model[_vm.itemItemsCode],
                      "" + _vm.data.itemCode,
                      $$v
                    )
                  },
                  expression: "model[itemItemsCode][`${data.itemCode}`]"
                }
              },
              _vm._l(_vm.citys, function(item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { label: item.name, value: item.name }
                })
              }),
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }