<template>
  <div>
    <el-checkbox-group v-model="value">
      <el-checkbox
        v-for="(item, index) in data.configuration.allowableValues"
        :key="index"
        :label="item.value"
      >
        <span v-text="item.cnValue" />
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "AdCheckbox",
  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: ""
    };
  },
  created() {}
};
</script>
