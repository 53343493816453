<template>
  <div
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="数据加载中"
    class="account-container"
  />
</template>

<script>
export default {
  data() {
    return {
      fullscreenLoading: false,
    };
  },
  watch: {},
  created() {
    // 激活
    this.fullscreenLoading = true;
    this.$post(this.urls.account.activate, {
      code: this.$route.query.code,
    })
      .then(() => {
        this.$alert('激活成功', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: () => {
            this.$router.push({ name: 'login' });
          },
        });
      })
      .catch((err) => {
        this.code = err.code;
        let text = '';
        let routerName = '';
        switch (err.code) {
          case this.codes.account.ACTIVATED.code:
            text = this.codes.account.ACTIVATED.text;
            routerName = 'login';
            break;
          case this.codes.account.CODE_EXPIRED.code:
            text = this.codes.account.CODE_EXPIRED.text;
            routerName = 'login';
            break;
          case this.codes.account.NEED_RESET_PASSWORD.code:
            text = this.codes.account.NEED_RESET_PASSWORD.text;
            routerName = `changpwd?code=${err.data.code}`;
            break;
        }
        this.$alert(text, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: () => {
            this.$router.push({ path: routerName });
          },
        });
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  },
  methods: {},
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .account-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.account-container {
  .el-input {
    display: inline-block;
    height: 46px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.account-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
