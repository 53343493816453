import { post } from '@/https/request';
import urls from '@s/urls';

export function login(data) {
  // 登录接口
  const formData = new URLSearchParams();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return post(urls.user.login, formData);
}
export function getInfo(token, data) {
  return post(urls.user.getInfo, { data, token });
}
export function logout() {
  return post(urls.user.logout);
}
