<template>
  <div class="experiment-container">
    <div class="title">
      <div>用户管理</div>
      <div class="title_right">
        <el-button type="text" class="back" @click="$router.push('/')">
          返回
        </el-button>
      </div>
    </div>
    <div class="test-form">
      <el-row type="flex" justify="space-between">
        <el-col :span="14">
          <el-form ref="form" :inline="true" :model="form">
            <el-form-item prop="keyword" label="手机号">
              <el-input v-model="form.keyword" />
            </el-form-item>
            <!-- <el-form-item prop="protocolNo" label="方案编号">
              <el-input v-model="formInline.protocolNo" />
            </el-form-item> -->

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="onSearchClick">
                搜索
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" class="icon">
          <el-row type="flex" justify="end">
            <el-button icon="el-icon-plus" type="success" @click="handleCreateAccount">
              新增用户
            </el-button>
          </el-row>
        </el-col>
      </el-row>
      <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="nickname" label="姓名" />
        <el-table-column prop="phone" label="手机号" />
        <el-table-column prop="email" label="邮箱" />

        <el-table-column prop="studyTotal" label="参与研究数量" />
        <el-table-column prop="registerTime" label="注册时间">
          <template slot-scope="scope">
            {{ scope.row.registerTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag type="success">
              {{ scope.row.status === 'active' ? '正常' : '' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button size="mini" type="danger" @click="handleHome(scope.row)">
              重置密码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <pagination
          :request="queryData"
          :page.sync="pagination.page"
          :pageSize.sync="pagination.pageSize"
          :total="total"
        />
      </div>
    </div>
    <create-dialog :visible.sync="createDiaVis" />
  </div>
</template>

<script>
import page from '@/static/mixin/page';
import debounce from '@u/debounce';
import CreateDialog from './create.vue';

export default {
  name: '',
  components: { CreateDialog },
  filters: {},
  mixins: [page],
  props: {},
  data() {
    return {
      // form 表单
      loading: false,
      tableData: [],
      form: { keyword: '' },
      createDiaVis: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.queryData();
  },
  mounted() {},
  methods: {
    createForm(form) {
      this.loading = true;
      this.$post(this.urls.study.distribution_study, form)
        .then(() => {
          this.loading = false;
          this.$message({
            message: '创建研究成功',
            type: 'success',
          });
          this.$refs.createStudy.onResetFields();
          this.experimentShow = false;
          this.queryData();
        })
        .catch((res) => {
          // console.log(res);
          this.$message({
            message: res.note,
            type: 'error',
          });
          this.loading = false;
        });
    },
    // 查询方法
    onSearchClick: debounce(function() {
      this.queryData();
    }, 400),
    // 查询中心的首页的列表
    queryData() {
      this.loading = true;
      const parame = {
        keyword: this.form.keyword,
        ...this.pagination,
      };
      this.$post(this.urls.account.query, parame)
        .then((result) => {
          this.loading = false;
          this.tableData = result.data;
          this.total = result.total;
        })
        .catch(() => {});
    },
    handleCreateAccount() {
      console.log('sdfasfasdfsda');
      this.createDiaVis = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/el-dialog/index.scss';
.experiment-container {
  margin: 0 20px;
  padding-top: 20px;
  background-color: #fff;
  .title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    color: #909399;
    font-weight: 500;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
  }
  .title_right {
    display: flex;
  }
  .environment_type {
    margin-left: 68px;
  }
  .expand-title {
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 10px;
  }
  .test-form {
    border-width: 1px;
    border-color: #e4e7ed;
    border-style: none solid solid solid;
    padding: 20px;
  }
  .block {
    margin-top: 20px;
  }
  .icon {
    text-align: right;
  }
  .experiment_detail {
    padding: 28px 28px 0 28px;
    margin-top: 28px;
    border: 1px #e4e7ed solid;
    .experiment_name {
      padding-bottom: 28px;
      font-size: 20px;
      font-weight: 600;
    }
    .drtail_list {
      display: flex;
      width: 560px;
      flex-wrap: wrap;
      > div {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .list_name {
          margin-right: 38px;
        }
      }
    }
  }
}
</style>
