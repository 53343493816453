<template>
  <el-dialog
    title="提示"
    :visible.sync="visible"
    width="30%"
    :before-close="handleClose"
    center
    :close-on-click-modal="false"
  >
    <div class="di-form">
      <el-form>
        <el-form-item label="数据模板">
          <el-button type="text" @click="downloadTemplate"> 下载模板 </el-button>
        </el-form-item>
        <el-form-item>
          <ul>
            <li class="di-desc__line">如需通过导入更新数据，请先导出需要更新的数据并完成修改</li>
            <li class="di-desc__line">支持 2MB 以内的xls、xlsx格式文件</li>
            <li class="di-desc__line">文件中数据不能超过50000行、200列</li>
          </ul>
        </el-form-item>
        <el-form-item label="批量导入">
          <el-upload
            ref="upload"
            width="100%"
            class="di-form-upload"
            drag
            :action="'/api/v1/' + urls.cdash_template.upload"
            :file-list="fileList"
            :auto-upload="false"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MsCdash',
  components: {},
  filters: {},
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    cdashItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    handleSubmit() {
      this.$refs.upload.submit();

      //   console.log(this.fileList);
      //   this.$exlPost(this.urls.cdash_template.suggestions, {})
      //     .then((response) => {})
      //     .catch(() => {});
    },
    downloadTemplate() {
      this.$downloadFile('/api/v1/' + this.urls.cdash_template.download_template, {}, 'cdash数据导入模板.xlsx');
    },
  },
};
</script>
<style lang="scss" scoped>
.di-form {
  // display: flex;
}
.di-form__tip {
  line-height: auto;
}
// .di-form-upload {
//   margin: auto;
// }
.el-form-item {
  margin-bottom: 0px;
}
.di-desc__line {
  line-height: normal;
}
</style>
