<template>
  <el-checkbox-group
    v-if="data.setting.isRemote && model"
    v-model="model[data.fieldId]"
    class="ad-checkbox-group"
  >
    <el-checkbox
      v-for="item in remoteData"
      :key="item[data.setting.remoteDataProps.value]"
      :label="item[data.setting.remoteDataProps.value]"
      class="ad-checkbox"
    >
      <span
        class="ad-label-props"
        v-text="item[data.setting.remoteDataProps.label]"
      />
    </el-checkbox>
  </el-checkbox-group>
  <el-checkbox-group
    v-else-if="data.setting.isRemote && !model"
    v-model="data.value"
    class="ad-checkbox-group"
  >
    <el-checkbox
      v-for="item in remoteData"
      :key="item[data.setting.remoteDataProps.value]"
      :label="item[data.setting.remoteDataProps.value]"
      class="ad-checkbox"
    >
      <span
        class="ad-label-props"
        v-text="item[data.setting.remoteDataProps.label]"
      />
    </el-checkbox>
  </el-checkbox-group>
  <el-checkbox-group
    v-else-if="!data.setting.isRemote && model"
    v-model="model[data.fieldId]"
    class="ad-checkbox-group"
  >
    <el-checkbox
      v-for="item in data.selections"
      :key="item.value"
      :label="item.value"
      class="ad-checkbox"
    >
      <span
        class="ad-label-props"
        v-text="item.label"
      />
    </el-checkbox>
  </el-checkbox-group>
  <el-checkbox-group
    v-else
    v-model="data.value"
    class="ad-checkbox-group"
  >
    <el-checkbox
      v-for="item in data.selections"
      :key="item.value"
      :label="item.value"
      class="ad-checkbox"
    >
      <span
        class="ad-label-props"
        v-text="item.label"
      />
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
import RemoteMixin from '../../utils/remote-mixin';
export default {
  name: 'TpCheckbox',
  mixins: [RemoteMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.ad-checkbox-group {
  display: flex;
  flex-direction: column;
  .ad-checkbox {
    .ad-label-props {
      display: block;
      width: 600px;
      white-space: normal;
    }
  }
}
</style>
