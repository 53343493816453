<template>
  <div class="experiment-container">
    <div class="title">
      <div>试验列表</div>
      <div class="title_right">
        <el-button v-if="!isProdEnv && isAdministrator" type="text" @click="handleCfgSubmitClick">
          系统管理
        </el-button>
        <el-button v-if="!isProdEnv && (isAdministrator || isDM)" type="text" @click="cdash">
          CDASH
        </el-button>
        <el-button v-if="!isProdEnv && isAdministrator" type="text">
          <a href="http://43.139.50.153/"> 影像系统</a>
        </el-button>
        <el-button v-if="!isProdEnv && isAdministrator" type="text" @click="caseReport">
          病例列表
        </el-button>
        <el-button v-if="!isProdEnv && isAdministrator" type="text" @click="handleUser">
          个人中心
        </el-button>
        <el-button v-if="isAdministrator" type="text" @click="handleAccount">
          用户管理
        </el-button>
        <el-button type="text" @click="logout">
          退出登录
        </el-button>
        <div v-if="!isProdEnv" class="environment_type">
          <el-select v-model="environmentType" placeholder="请选择" @change="onEnvironment()">
            <el-option v-for="item in environmentList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
    </div>
    <div class="test-form">
      <el-row type="flex" justify="space-between">
        <el-col :span="14">
          <el-form ref="headForm" :inline="true" :model="formInline">
            <el-form-item prop="name" label="研究名称">
              <el-input v-model="formInline.name" />
            </el-form-item>
            <el-form-item prop="protocolNo" label="方案编号">
              <el-input v-model="formInline.protocolNo" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="onSearchClick">
                搜索
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" class="icon">
          <el-row type="flex" justify="end">
            <el-button v-if="isAdministrator" type="" icon="el-icon-plus" @click="onAddClick">
              新增研究
            </el-button>
          </el-row>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="protocolNo" label="方案编号" />
        <el-table-column prop="name" label="研究名称" show-overflow-tooltip />
        <el-table-column prop="sponsor" label="申办方" show-overflow-tooltip />
        <el-table-column label="研究开始时间" show-overflow-tooltip>
          <template v-slot="scope">
            <span>{{ scope.row.plannedStartDate | parseTime('{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="研究结束时间" show-overflow-tooltip>
          <template v-slot="scope">
            <span>{{ scope.row.plannedEndDate | parseTime('{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="盲态" show-overflow-tooltip width="80">
          <template v-slot="scope">
            {{ scope.row.blindStatus | blindStatus }}
          </template>
        </el-table-column>
        <el-table-column v-if="false" label="状态" show-overflow-tooltip width="80">
          <template v-slot="scope">
            <div v-if="scope.row.status === 'published'">
              已启动
            </div>
            <el-tooltip v-else effect="dark" content="请先编辑研究配置" placement="bottom">
              <div>构建中</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-tooltip
              :disabled="!(scope.row.status === 'suspend')"
              content="项目已暂停，请联系管理员"
              placement="right"
              effect="light"
            >
              <el-button
                size="mini"
                type="success"
                :disabled="!isAdministrator && scope.row.status === 'suspend'"
                @click="handleHome(scope.row)"
              >
                进入研究
              </el-button>
            </el-tooltip>
            <!-- <el-button size="mini" type="primary" @click="recommendedPatients(scope.row.studyId)"> 推荐患者 </el-button> -->
            <!-- <el-button
              v-if="$store.getters.account.username === 'sys_administrator'"
              size="mini"
              type=""
              @click="handleDeleteClick(scope.row)"
            >
              删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <el-dialog
      title="创建研究"
      center
      width="560px"
      :close-on-click-modal="false"
      destroy-on-close
      :visible.sync="experimentShow"
      @close="close()"
    >
      <create-study ref="createStudy" @createForm="createForm" @crateCancel="crateCancel" />
    </el-dialog>
  </div>
</template>

<script>
import blindStatus from '@s/blind-status';
import debounce from '@u/debounce';
import parametFilter from '@u/paramet-filter';
import createStudy from './create';
import adCache from '@u/stroageEncapsulation';

export default {
  name: '',
  components: { createStudy },
  filters: {
    // 字段映射
    blindStatus(value) {
      return blindStatus.filter((item) => item.type === value || item.text === value)[0].text;
    },
  },
  props: {},
  data() {
    return {
      // form 表单
      loading: false,
      formInline: {
        name: '',
        protocolNo: '',
      },
      tableData: [],
      formLabelWidth: '120px',
      // 分页
      currentPage: 0,
      total: 0,
      tableLoading: false,
      experimentShow: false,
      httpType: 'info',
      editrForm: {
        ip: '127.0.0.1',
        platform: 'PC电脑',
        port: '9092',
      },
      serverList: [
        {
          name: 'PC电脑',
          id: '1',
        },
        {
          name: '腾讯云',
          id: '2',
        },
        {
          name: '阿里云',
          id: '3',
        },
      ],
      environmentList: [
        {
          value: 'uat',
          label: 'UAT测试环境',
        },
        {
          value: 'prod',
          label: 'PROD正式环境',
        },
      ],
      environmentType: '',
    };
  },
  computed: {
    isAdministrator() {
      let value = false;
      value = this.$store.getters?.account?.username === 'sys_administrator';
      return value;
    },
    isDM() {
      // 权衡
      let value = false;
      value = this.$store.getters?.account?.username === 'MS1681183775952';
      return value;
    },
    isProdEnv() {
      return process.env.NODE_ENV === 'production';
    },
  },
  watch: {},
  created() {
    this.getInfo();
    const temp = [];
    temp[0] = '11';
    this.environmentType = localStorage.getItem('environmentType') || 'prod';
    this.queryJoinedStudyFun();
  },
  mounted() {
    // 禁用浏览器返回键
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', this.disableBrowserBack);
  },
  destroyed() {
    // 清除popstate事件 否则会影响到其他页面
    window.removeEventListener('popstate', this.disableBrowserBack, false);
  },
  methods: {
    // 禁用浏览器返回
    disableBrowserBack() {
      history.pushState(null, null, document.URL);
    },
    getInfo() {
      this.$post(this.urls.account.get_info, {
        auid: this.$store.getters.account.auid,
      })
        .then((res) => {
          adCache.setlocalFun('account', res);
          adCache.setlocalFun('username', res.username || '');
        })
        .catch(() => {});
    },
    createForm(form) {
      this.loading = true;
      this.$post(this.urls.study.distribution_study, parametFilter(form))
        .then(() => {
          this.loading = false;
          this.$message({
            message: '创建研究成功',
            type: 'success',
          });
          this.$refs.createStudy.onResetFields();
          this.experimentShow = false;
          this.queryJoinedStudyFun();
        })
        .catch((res) => {
          // console.log(res);
          this.$message({
            message: res.note,
            type: 'error',
          });
          this.loading = false;
        });
    },
    cdash() {
      this.$router.push('/cdash');
    },
    caseReport() {
      this.$router.push('/caseReport/list');
    },
    handleUser() {
      this.$router.push('/account/user');
    },
    handleAccount() {
      this.$router.push('/account/list');
    },
    crateCancel() {
      this.experimentShow = false;
    },
    handleCurrentChange(val) {
      this.queryJoinedStudyFun(val);
    },
    onAddClick() {
      this.experimentShow = true;
    },
    getHttp() {
      this.loading = true;
      this.$post(this.urls.study.check_alive, this.editrForm)
        .then(() => {
          this.httpType = 'success';
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.codes.addExperiment.forEach((e) => {
            if (e.code === res.code) {
              this.httpType = e.type;
            }
          });
          this.loading = false;
        });
    },
    // 查询方法
    onSearchClick: debounce(function() {
      this.queryJoinedStudyFun();
    }, 400),
    // 刷新的事件方法
    onRefreshClick: debounce(function() {
      this.resetForm('headForm');
      this.queryJoinedStudyFun();
    }, 300),
    // 查询中心的首页的列表
    queryJoinedStudyFun(page = 1) {
      this.tableLoading = true;
      let basis = {
        page,
        pageSize: 10,
        environment: this.environmentType,
        auid: this.$store.getters.account.auid,
      };
      const parame = { ...basis, ...this.formInline };
      this.$post(this.urls.study.query_joined, parametFilter(parame))
        .then((result) => {
          this.tableLoading = false;
          this.tableData = result.data;
          this.total = result.total;
        })
        .catch(() => {});
    },
    // 退出登录
    async logout() {
      // this.$webSocket.close();
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    handleHome(row) {
      const { name, studyId, standalone } = row;
      this.$confirm(`确定要进入研究${name}的随机对照临床研究吗？`, '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showClose: false,
      })
        .then(() => {
          if (standalone) {
            window.location.href = `/${studyId}/jurisdiction?studyId=${studyId}`;
          } else {
            window.location.href = `/common/jurisdiction?studyId=${studyId}`;
          }
          // localStorage.setItem('studyId', studyId);
          // this.$post(this.urls.role.get_authority, { studyId }, standalone ? studyId : 'common')
          //   .then((result) => {
          //     this.$store.dispatch('user/role', result.role);
          //     this.$store.dispatch('user/p', result.p);
          //     if (standalone) {
          //       window.location.href = `/${studyId}/jurisdiction?studyId=${studyId}`;
          //     } else {
          //       window.location.href = `/common/jurisdiction`;
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        })
        .catch(() => {});
    },
    recommendedPatients(id) {
      this.$router.push({
        name: 'patientList',
        query: { studyId: id },
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleDeleteClick(e) {
      this.$confirm(`是否要删除${e.name}临床研究？`, '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showClose: false,
      })
        .then(() => {
          this.$post(this.urls.study.delete, { studyId: e.studyId })
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    },
    onCancel() {
      this.experimentShow = false;
    },
    onEnvironment() {
      const h = this.$createElement;
      let text = 'UAT测试环境';
      if (this.environmentType === 'prod') {
        text = 'PROD正式环境';
      }
      this.$msgbox({
        title: '提示',
        message: h('p', null, [h('span', null, '确认切换到 '), h('span', { style: 'color: red' }, text)]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '切换成功',
          });
          this.currentPage = 1;
          localStorage.setItem('environmentType', this.environmentType);
          this.queryJoinedStudyFun();
        })
        .catch(() => {
          this.environmentType = localStorage.getItem('environmentType') || 'prod';
        });
    },
    close() {
      this.experimentShow = false;
    },
    submitClick() {
      this.$post(this.urls.study.create, this.editrForm)
        .then((res) => {
          window.location.href = `/experiment/add?studyId=${res.studyId}`;
        })
        .catch(() => {});
    },
    // 配置页
    handleCfgSubmitClick() {
      this.$router.push({ name: 'templateSys' });
    },
    isProd() {
      // return process.env.VUE_APP_BASE_API === 'development1';
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/el-dialog/index.scss';
.experiment-container {
  margin: 0 20px;
  padding-top: 20px;
  background-color: #fff;
  .title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    color: #909399;
    font-weight: 500;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
  }
  .title_right {
    display: flex;
  }
  .environment_type {
    margin-left: 68px;
  }
  .expand-title {
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 10px;
  }
  .test-form {
    border-width: 1px;
    border-color: #e4e7ed;
    border-style: none solid solid solid;
    padding: 20px;
  }
  .block {
    margin-top: 20px;
  }
  .icon {
    text-align: right;
  }
  .experiment_detail {
    padding: 28px 28px 0 28px;
    margin-top: 28px;
    border: 1px #e4e7ed solid;
    .experiment_name {
      padding-bottom: 28px;
      font-size: 20px;
      font-weight: 600;
    }
    .drtail_list {
      display: flex;
      width: 560px;
      flex-wrap: wrap;
      > div {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .list_name {
          margin-right: 38px;
        }
      }
    }
  }
}
</style>
