<template>
  <div>
    <el-time-picker
      v-model="time"
      value-format="timestamp"
      :format="data.configuration.dateFormat"
      :picker-options="{
        selectableRange: `${dateFilter(
          data.configuration.minTime
        )}-${dateFilter(data.configuration.maxTime)}`,
        format: data.configuration.timeFormat
      }"
      :placeholder="`选择${data.cnName}`"
    />
  </div>
</template>

<script>
export default {
  name: "MsInput",
  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      time: ""
    };
  },
  methods: {
    dateFilter(date) {
      let d = new Date(date);
      let h = d.getHours();
      var m = d.getMinutes();
      var s = d.getSeconds();
      return `${h}:${m}:${s}`;
    }
  }
};
</script>
