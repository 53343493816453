var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "item-box",
        { attrs: { classSuffix: "input" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.label,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "label", $$v)
                  },
                  expression: "data.label"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.placeholder,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "placeholder", $$v)
                  },
                  expression: "data.placeholder"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "长度限制" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入整数" },
                nativeOn: {
                  keyup: function($event) {
                    return _vm.onKeyNativeClick($event)
                  }
                },
                model: {
                  value: _vm.data.maxlength,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "maxlength", $$v)
                  },
                  expression: "data.maxlength"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "必填" } },
            [
              _c("el-switch", {
                attrs: { "active-text": "是", "inactive-text": "否" },
                model: {
                  value: _vm.required,
                  callback: function($$v) {
                    _vm.required = $$v
                  },
                  expression: "required"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("select-icon", {
        ref: "select_icon",
        on: { "select-icon": _vm.selectIconItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }