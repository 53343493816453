var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        _vm._l(_vm.data.configuration.allowableValues, function(item, index) {
          return _c(
            "el-checkbox",
            { key: index, attrs: { label: item.value } },
            [_c("span", { domProps: { textContent: _vm._s(item.cnValue) } })]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }