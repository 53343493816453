var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    attrs: {
      layout: "total,prev, pager, next, jumper,sizes",
      "current-page": _vm.pagination.page,
      "page-size": _vm.pagination.pageSize,
      "page-sizes": _vm.pageSizes,
      total: _vm.total,
      background: ""
    },
    on: {
      "update:currentPage": function($event) {
        return _vm.$set(_vm.pagination, "page", $event)
      },
      "update:current-page": function($event) {
        return _vm.$set(_vm.pagination, "page", $event)
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }