<template>
  <div class="create-study">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px">
      <el-form-item label="研究名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="方案编号" prop="protocolNo">
        <el-input v-model="form.protocolNo" />
      </el-form-item>
      <el-form-item label="申办方" prop="sponsor">
        <el-input v-model="form.sponsor" />
      </el-form-item>
      <el-form-item label="主要研究者" prop="principalInvestigator">
        <el-input v-model="form.principalInvestigator" />
      </el-form-item>
      <el-form-item label="研究起止日期" prop="datePicker" class="study-date">
        <el-date-picker
          v-model="form.datePicker"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="盲态" prop="blindStatus">
        <el-radio-group v-model="form.blindStatus">
          <el-radio v-for="item in blindStatus" :key="item.label" :label="item.label">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- <el-divider content-position="center">
        <el-tooltip content="保存后，分组的数量和比例不可修改，请确认后再添加">
          <span>试验分组 <i class="el-icon-question" /> </span>
        </el-tooltip>
      </el-divider>

      <el-table :data="form.treatmentArmList" border style="margin: 0px 0px 20px 30px">
        <el-table-column prop="name" label="分组名称" min-width="100">
          <template #default="scope">
            <el-form-item
              label-width="0"
              :prop="`treatmentArmList[${scope.$index}].name`"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model="scope.row.name" style="width: 100%" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="proportion" min-width="100" label="分组比例">
          <template #default="scope">
            <el-form-item
              label-width="0"
              :prop="`treatmentArmList[${scope.$index}].proportion`"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model.number="scope.row.proportion" type="number" style="width: 100%" min="1" max="10" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template #default="scope">
            <el-button type="text" @click="handleArmAdd(scope.row, scope.$index)"> 新增 </el-button>
            <el-button
              :disabled="scope.$index === 0 && form.treatmentArmList.length === 1"
              type="text"
              @click="handleDelete(scope.row, scope.$index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
       -->
      <div class="more-set" @click="seeMore">
        高级设置
        <i class="el-icon-arrow-down" />
      </div>
      <el-collapse-transition>
        <div v-show="more" class="more-content">
          <el-alert
            class="more-content__tip"
            title="如需独立部署服务器，需填写下方配置"
            type="success"
            :closable="false"
            center
          />
          <el-form-item label="IP" prop="ip">
            <el-input v-model="form.ip" />
          </el-form-item>
          <el-form-item label="端口" prop="port">
            <el-input v-model="form.port" />
          </el-form-item>
        </div>
      </el-collapse-transition>
      <div style="text-align: center">
        <el-button type="primary" @click="onSubmit('form')"> 立即创建 </el-button>
        <el-button @click="onCancel('form')"> 取消 </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    editForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        name: '',
        protocolNo: '',
        sponsor: '',
        principalInvestigator: '',
        plannedStartDate: '',
        plannedEndDate: '',
        blindStatus: '',
        ip: '',
        port: '',
        datePicker: '',
        treatmentArmList: [{ name: '', proportion: null }],
      },
      rules: {
        name: [{ required: true, message: '请输入研究名称', trigger: 'blur' }],
        protocolNo: [{ required: true, message: '请输入方案编号', trigger: 'blur' }],
        sponsor: [{ required: true, message: '请输入申办方', trigger: 'blur' }],
        principalInvestigator: [{ required: true, message: '请输入主要研究者', trigger: 'blur' }],
        plannedStartDate: [{ required: true, message: '请选择研究开始日期', trigger: 'blur' }],
        plannedEndDate: [{ required: true, message: '请选择研究结束日期', trigger: 'blur' }],
        blindStatus: [{ required: true, message: '请选择盲态', trigger: ['blur', 'change'] }],
        datePicker: [{ required: true, message: '请选择盲态', trigger: ['blur', 'change'] }],
      },
      more: false,
      blindStatus: [
        { name: '开放', label: 'open' },
        { name: '单盲', label: 'single' },
        { name: '双盲', label: 'double' },
      ],
    };
  },
  computed: {
    isDatePicker() {
      const { plannedStartDate, plannedEndDate } = this.form;
      return (plannedStartDate ?? '') !== '' && (plannedEndDate ?? '') !== '';
    },
  },
  watch: {
    isDatePicker(value) {
      if (value) {
        const { plannedStartDate, plannedEndDate } = this.form;
        this.form.datePicker = [plannedStartDate, plannedEndDate];
      }
    },
    'form.datePicker'(value) {
      if ((value ?? '') !== '') {
        const [plannedStartDate, plannedEndDate] = value;
        this.form.plannedStartDate = plannedStartDate;
        this.form.plannedEndDate = plannedEndDate;
      }
    },
  },
  mounted() {
    // this.form = this.editForm;
  },
  methods: {
    onCancel(formName) {
      this.$refs[formName].resetFields();
      this.$emit('crateCancel');
    },
    onSubmit: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = JSON.parse(JSON.stringify(this.form));
          delete form.datePicker;
          this.$emit('createForm', form);
        } else {
          return false;
        }
      });
    }, 400),
    seeMore() {
      this.more = !this.more;
    },
    onResetFields() {
      this.$refs.form.resetFields();
    },
    handleDelete(row, index) {
      this.form.treatmentArmList.splice(index, 1);
    },
    handleArmAdd(row, index) {
      this.form.treatmentArmList.splice(index + 1, 0, { name: '', proportion: null });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-study {
  padding-right: 30px;
  .el-input {
    width: 350px;
  }
  .study-date .el-input {
    width: auto;
  }
  .more-set {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;
  }
  .line {
    text-align: center;
  }
}
.more-content__tip {
  margin: 0 0 22px 0;
}
</style>
