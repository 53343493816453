<template>
  <div class="case_report_list">
    <div class="header">
      <div class="conetent centre">
        <div class="title">
          <img src="@/assets/img/logo.png" />
        </div>
        <el-button type="text" style="font-size: 16px;" class="back" @click="$router.push('/')">
          返回
        </el-button>
      </div>
    </div>
    <div class="case_report_table">
      <div class="case_report_table_header">
        <el-row style="width: 100%; margin-top: 24px;">
          <el-form ref="queryForm" :model="queryForm" label-width="80px">
            <el-col :span="10">
              <div style="width: 100%;">
                <el-input
                  v-model="keyword"
                  :placeholder="`请输入${searchItemText()}`"
                  clearable
                  class="input-with-select"
                  @clear="clearKeyword"
                >
                  <el-select
                    slot="prepend"
                    v-model="queryConditionSelect"
                    clearable
                    placeholder="请选择关键字"
                    @clear="clearQueryCondition"
                  >
                    <el-option-group v-for="group in reportFormItems" :key="group.itemCode" :label="group.itemLabel">
                      <div v-for="item in group.repotItems" :key="item.itemCode">
                        <el-option
                          v-if="item.formType !== 'datePicker' && getOptionShow(group, item)"
                          :label="item.itemLabel"
                          :value="item.itemCode"
                          :disabled="item.formType === 'datePicker'"
                          @click.native="changeSelect(group, item)"
                        />
                      </div>
                    </el-option-group>
                  </el-select>
                  <el-button slot="append" type="primary" icon="el-icon-search" @click="searchCaseList" />
                </el-input>
              </div>
            </el-col>
            <!-- <el-col :span="2">
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="searchCaseList">
                  查询
                </el-button>
              </el-form-item>
            </el-col> -->
            <el-col :offset="10" :span="4">
              <el-form-item>
                <router-link to="/caseReport" tag="span" style="margin-right: 12px;">
                  <el-button type="success" size="small">
                    新建病历
                  </el-button>
                </router-link>
                <router-link to="/caseReport/edit" tag="span">
                  <el-button type="primary" size="small">
                    病历编辑
                  </el-button>
                </router-link>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <div v-loading="tableLoading" class="case_report_table_content">
        <el-table :data="tableData" style="width: 100%" size="small">
          <el-table-column label="病历编号" width="120" prop="randomNumber" />
          <el-table-column label="姓名" width="120" prop="information.name" />
          <el-table-column label="癌种类型" width="120" prop="information.cancerTypeGroupId">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag type="warning" size="small">
                  {{ scope.row.information.cancerTypeGroupId }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="病种" width="280" prop="information.cancerSubTypeId">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="small">
                  {{ scope.row.information.cancerSubTypeId }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="queryCondition.filedName && tableColumnProp.indexOf(queryCondition.filedName) == -1"
            :label="queryCondition.text"
            width="140"
            :prop="queryCondition.filedName"
          />
          <el-table-column label="年龄" width="80" prop="information.age" />
          <el-table-column label="手机号" width="140" prop="information.phone" />
          <el-table-column label="归属省份" width="140" prop="information.province" />
          <el-table-column label="归属城市" width="140" prop="information.city" />
          <el-table-column label="备注" width="260" prop="information.remark" />
          <el-table-column label="录入时间" width="150" prop="information.createTime">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <span>{{ scope.row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="负责人" width="100" prop="dataEntryClerkName" />
          <el-table-column width="200" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)">
                更新
              </el-button>
              <el-button type="text" @click="handleDetail(scope.row)">
                详情
              </el-button>
              <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  disabled
                  >删除</el-button
                > -->
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-drawer title="" size="65%" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <div v-if="drawer" class="case_detail">
        <div class="case_detail_header">
          <router-link tag="h3" :to="{ name: 'caseReport', query: { sid: caseDetail.sid } }">
            <span>病历编号:{{ caseDetail.randomNumber }}</span> <i class="el-icon-edit" />
          </router-link>
          <div class="case_detail_content">
            <div>负责人：{{ caseDetail.dataEntryClerkName }}</div>
            <div>创建时间：{{ caseDetail.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</div>
          </div>
        </div>
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClickActiveName">
          <el-tab-pane label="病历资料" name="caseDetail">
            <div class="case_detail_content">
              <div v-for="item in caseDetailItems" :key="item.itemCode" class="case_report_form">
                <el-descriptions :title="item.itemLabel" border :size="'small'" :column="3" direction="vertical">
                  <el-descriptions-item v-for="items in item.repotItems" :key="items.itemCode" :label="items.itemLabel">
                    <div v-if="items.formType === 'datePicker' && items.itemValue">
                      {{ items.itemValue | parseTime('{y}-{m}-{d} {h}:{i}') }}
                    </div>
                    <div v-else-if="items.itemValue">
                      {{ items.itemValue }}
                    </div>
                    <div v-else>
                      --
                    </div>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="病历图片" name="caseImg">
            <div class="case_report_img">
              <CaseReportImg :ocrShow="false" :caseReport="caseDetail" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import cancerType from '@/utils/cancerType.json';
import CaseReportImg from './components/caseReportImg.vue';

export default {
  components: { CaseReportImg },
  data() {
    return {
      queryForm: {},
      tableData: [],
      currentPage: 0,
      total: 0,
      keyword: '',
      departmentOptions: cancerType.data,
      tableColumnProp: [
        'information.name',
        'information.cancerTypeGroupId',
        'information.cancerSubTypeId',
        'information.age',
        'information.phone',
        'information.province',
        'information.city',
        'information.createTime',
      ],
      tableLoading: true,
      queryConditionSelect: '',
      queryConditionList: [
        {
          operatorType: 'or',
          filedName: 'randomNumber',
          operator: 'is',
          filedValue: '',
          text: '病历编号',
        },
        {
          operatorType: 'or',
          filedName: 'information.phone',
          operator: 'is',
          filedValue: '',
          text: '姓名',
        },
        {
          operatorType: 'or',
          filedName: 'information.name',
          operator: 'is',
          filedValue: '',
          text: '手机号',
        },
        {
          operatorType: 'or',
          filedName: 'information.cancerTypeGroupId',
          operator: 'is',
          filedValue: '',
          text: '癌种类型',
        },
      ],
      queryCondition: {
        operatorType: 'or',
        filedName: '',
        formType: '',
        operator: 'is',
        filedValue: '',
        parentText: '',
        text: '',
      },
      reportFormItems: [],
      caseDetail: { randomNumber: '' },
      caseDetailItems: [],
      activeName: 'caseDetail',
      queryData: {
        page: 0,
        keyword: '',
        operatorType: '',
        filedName: '',
        formType: '',
        operator: 'is',
        filedValue: '',
        parentText: '',
        text: '',
        queryConditionSelect: '',
      },
      drawer: false,
      direction: 'rtl',
    };
  },
  created() {
    let queryObj = this.$route.query;
    for (const key in queryObj) {
      if (Object.hasOwnProperty.call(queryObj, key)) {
        this.queryData[key] = queryObj[key];
      }
    }
    for (const key in this.queryCondition) {
      this.queryCondition[key] = queryObj[key];
    }
    this.keyword = queryObj.keyword || '';
    this.queryConditionSelect = queryObj.queryConditionSelect || '';
    let page = queryObj.page || 0;
    this.currentPage = parseInt(page);
    this.getCaseList(parseInt(page));
    this.getSick();
  },
  methods: {
    getSick() {
      this.$post(this.urls.sick.get, {})
        .then((res) => {
          this.reportFormItems = res.data;
        })
        .catch(() => {});
    },
    searchCaseList() {
      this.tableLoading = true;
      let queryData = this.queryData;
      queryData.queryConditionSelect = this.queryConditionSelect;
      this.queryData = queryData;
      this.currentPage = 0;
      this.routerQueryData();
      setTimeout(() => {
        this.getCaseList();
      }, 500);
    },
    searchItemText() {
      let t = '';
      if (this.queryConditionSelect === '') {
        t = '病历编号/姓名/手机号/癌种类型';
      } else {
        t = this.queryCondition.text;
      }

      return t;
    },
    handleClickActiveName(tab, event) {
      console.log(tab, event);
    },
    getCaseList(page = 1) {
      this.tableLoading = true;
      let obj = {
        page,
        pageSize: 10,
      };
      if (this.keyword !== '') {
        // 病历编号/姓名/手机号/癌种类型/省份
        if (this.queryConditionSelect !== '') {
          let queryCondition = this.queryCondition;
          queryCondition.filedValue = this.keyword;
          obj['queryCondition'] = [];
          obj['queryCondition'].push(queryCondition);
        } else {
          let arr = this.queryConditionList.map((item) => {
            item.filedValue = this.keyword;
            return item;
          });
          obj['queryCondition'] = arr;
        }
      }

      this.$post(this.urls.mdb_data.query_data, obj)
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data;
          this.total = res.total;
        })
        .catch(() => {});
    },
    clearKeyword() {
      this.currentPage = 0;
      this.routerQueryData();
      setTimeout(() => {
        this.getCaseList();
      }, 500);
    },
    clearQueryCondition() {
      this.queryCondition = {
        operatorType: 'or',
        filedName: '',
        formType: '',
        operator: 'is',
        filedValue: '',
        parentText: '',
        text: '',
      };
      this.$forceUpdate();
    },
    changeSelect(group, item) {
      this.queryCondition = {
        operatorType: 'or',
        filedName: `${group.itemCode}.${item.itemCode}`,
        formType: item.formType,
        operator: 'is',
        filedValue: '',
        parentText: group.itemLabel,
        text: item.itemLabel,
      };
      this.$forceUpdate();
    },
    changeQueryCondition() {
      // this.routerQueryData(val, 'queryConditionSelect');
    },
    handleCurrentChange(val) {
      this.routerQueryData(val, 'page');
      this.getCaseList(val);
    },
    handleEdit(row) {
      this.$router.push({
        name: 'caseReport',
        query: { sid: row.sid },
      });
    },
    getOptionShow(group, item) {
      let n1 = group.itemCode;
      let n2 = item.itemCode;
      let show = true;
      this.queryConditionList.map((items) => {
        if (items.filedName === `${n1}.${n2}`) {
          show = false;
        }
      });
      return show;
    },
    handleDetail(row) {
      this.caseDetail = row;

      let reportFormItems = this.reportFormItems;
      let arr = [];
      reportFormItems.map((item, index) => {
        if (row[item.itemCode]) {
          let { itemLabel, itemCode } = item;
          arr[index] = { itemLabel, itemCode, repotItems: [] };
          item.repotItems.map((items) => {
            if (items.itemCode in row[item.itemCode]) {
              items.itemValue = row[item.itemCode][items.itemCode];
              arr[index].repotItems.push(items);
            }
          });
        }
      });
      this.caseDetailItems = arr;
      this.drawer = true;
    },
    handleClose(done) {
      this.activeName = 'caseDetail';
      done();
    },
    cancerType(row) {
      let id = row.information.cancerTypeGroupId;
      const CancerType = this.departmentOptions.filter((item) => item.cancerTypeGroupId === id);
      if (CancerType.length > 0) {
        return CancerType[0].cnName;
      } else {
        return '暂无数据';
      }
    },
    cancerSubType(row) {
      let { cancerTypeGroupId, cancerSubTypeId } = row.information;
      const CancerType = this.departmentOptions.filter((item) => item.cancerTypeGroupId === cancerTypeGroupId);
      if (CancerType.length > 0) {
        const SubType = CancerType[0].cancerSubTypes.filter((item) => item.cancerSubTypeId === cancerSubTypeId);
        if (SubType.length > 0) {
          return SubType[0].cnName;
        } else {
          return '暂无数据';
        }
      } else {
        return '暂无数据';
      }
    },

    routerQueryData(val, key) {
      let queryData = this.queryData;
      let queryCondition = this.queryCondition;
      queryData.keyword = this.keyword;
      queryData.page = this.currentPage;
      for (const key in queryCondition) {
        if (Object.hasOwnProperty.call(queryCondition, key)) {
          queryData[key] = queryCondition[key];
        }
      }
      if (key) {
        queryData[key] = val;
      }
      this.$router.replace({
        query: { ...queryData },
      });
    },
  },
};
</script>

<style lang="scss">
.case_report_list {
  .el-select .el-input {
    width: 200px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .el-drawer__header {
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.case_report_list {
  background: #f6f7f9;
  height: 100%;
  ::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    //滚动条的宽度
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar-thumb {
    //滚动条的设置
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .header {
    background: #fff;
    .centre {
      width: 980px;
      margin: 0 auto;
    }
    .conetent {
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;
      .title {
        color: #303843;
        img {
          height: 40px;
        }
      }
      .back {
        color: #409eff;
      }
    }
  }
  .case_report_table {
    margin: 24px;
    background: #fff;
    border-radius: 4px;
    padding: 24px;
    .case_report_table_header {
    }
  }
  .block {
    padding: 24px 0;
  }

  .case_detail {
    padding: 0 24px;
    padding-bottom: 20px;
    .case_detail_header {
      > h3 {
        cursor: pointer;
      }
      .el-icon-edit {
        color: #1e87ff;
      }
      .case_detail_content {
        display: flex;
        margin-bottom: 12px;
        max-height: 750px;
        overflow-y: auto;
        > div {
          margin-right: 24px;
          font-size: 16px;
        }
      }
    }
    .case_report_form {
      margin-top: 24px;
    }
  }
  .case_report_img {
    padding-bottom: 40px;
  }
}
</style>
