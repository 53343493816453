var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "item-box",
    { attrs: { classSuffix: "switch" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "标签" } },
        [
          _c("el-input", {
            model: {
              value: _vm.data.label,
              callback: function($$v) {
                _vm.$set(_vm.data, "label", $$v)
              },
              expression: "data.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择默认值" },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.options, function(item, i) {
              return _c("el-option", {
                key: i,
                attrs: { label: item, value: item }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "打开图标" } },
        [
          _c(
            "el-input",
            {
              model: {
                value: _vm.data.setting.activeIconClass,
                callback: function($$v) {
                  _vm.$set(_vm.data.setting, "activeIconClass", $$v)
                },
                expression: "data.setting.activeIconClass"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function($event) {
                      return _vm.openSelectIcon("activeIconClass")
                    }
                  },
                  slot: "append"
                },
                [_vm._v(" 选择图标 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关闭图标" } },
        [
          _c(
            "el-input",
            {
              model: {
                value: _vm.data.setting.inactiveIconClass,
                callback: function($$v) {
                  _vm.$set(_vm.data.setting, "inactiveIconClass", $$v)
                },
                expression: "data.setting.inactiveIconClass"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function($event) {
                      return _vm.openSelectIcon("inactiveIconClass")
                    }
                  },
                  slot: "append"
                },
                [_vm._v(" 选择图标 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "打开文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.data.setting.activeText,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "activeText", $$v)
              },
              expression: "data.setting.activeText"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关闭文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.data.setting.inactiveText,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "inactiveText", $$v)
              },
              expression: "data.setting.inactiveText"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "值类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "Boolean" } }, [
                _vm._v(" 布尔型 ")
              ]),
              _c("el-radio-button", { attrs: { label: "String" } }, [
                _vm._v(" 字符型 ")
              ]),
              _c("el-radio-button", { attrs: { label: "Number" } }, [
                _vm._v(" 数字型 ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "打开值" } },
        [
          _c("el-input", {
            model: {
              value: _vm.activeValue,
              callback: function($$v) {
                _vm.activeValue = $$v
              },
              expression: "activeValue"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关闭值" } },
        [
          _c("el-input", {
            model: {
              value: _vm.inactiveValue,
              callback: function($$v) {
                _vm.inactiveValue = $$v
              },
              expression: "inactiveValue"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "打开背景色" } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.data.setting.activeColor,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "activeColor", $$v)
              },
              expression: "data.setting.activeColor"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关闭背景色" } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.data.setting.inactiveColor,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "inactiveColor", $$v)
              },
              expression: "data.setting.inactiveColor"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            attrs: { "active-text": "是", "inactive-text": "否" },
            model: {
              value: _vm.data.setting.disabled,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "disabled", $$v)
              },
              expression: "data.setting.disabled"
            }
          })
        ],
        1
      ),
      _c("select-icon", {
        ref: "select_icon",
        on: { "select-icon": _vm.selectIconItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }