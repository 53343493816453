<template>
  <div class="login-container">
    <div class="login-form">
      <div class="form">
        <div class="form_title">
          <h2>微信账号绑定</h2>
        </div>
        <!-- 绑定 -->
        <div class="form_type">
          <div class="form_type_title">
            验证方式
          </div>
          <div :class="[{'way-actived':isPhone},'form_type_item']" @click="changeType('phone')">
            手机验证
          </div>
          <div class="way-tip">
            |
          </div>
          <div :class="[{'way-actived':!isPhone},'form_type_item']" @click="changeType('email')">
            邮箱验证
          </div>
        </div>
        <el-form
          v-if="isPhone"
          ref="verifyForm"
          :model="verifyForm"

          auto-complete="on"
          label-position="left"
        >
          <el-form-item
            class="username-form-item"
            prop="email"
          >
            <span class="svg-container">
              <svg-icon icon-class="shoujihao" />
            </span>
            <el-input
              ref="email"
              v-model="verifyForm.phone"
              placeholder="手机号"
              name="phone"
              type="text"
              autocomplete="off"
              maxlength="11"
            />
          </el-form-item>
          <div class="verify-box">
            <el-form-item
              class="password-form-item"
              prop="password"
            >
              <span class="svg-container">
                <svg-icon icon-class="yanzhengma" />
              </span>
              <el-input
                ref="password"
                :key="passwordType"
                v-model="verifyForm.code"
                type="text"
                placeholder="请输入验证码"
                name="password"
                tabindex="2"
                autocomplete="on"
              />
            </el-form-item>
            <el-button
              class="show-pwd code-btn"
              :disabled="phoneDisabled"
              @click="handleVerifyCode"
            >
              {{ phoneBtnTxt }}
            </el-button>
          </div>
          <el-button
            :loading="loading"
            type="primary"
            class="el-btn"
            :disabled="verifyForm.code == '' "
            @click.native.prevent="submitForm"
          >
            确定
          </el-button>
        </el-form>
        <el-form
          v-else
          ref="verifyForm"
          :model="verifyForm"

          auto-complete="on"
          label-position="left"
        >
          <el-form-item
            class="username-form-item"
            prop="email"
          >
            <span class="svg-container">
              <svg-icon icon-class="youxiang" />
            </span>
            <el-input
              ref="email"
              v-model="verifyForm.email"
              placeholder="请输入邮箱"
              name="email"
              type="text"
              autocomplete="off"
            />
          </el-form-item>
          <div class="verify-box">
            <el-form-item
              class="password-form-item"
              prop="password"
            >
              <span class="svg-container">
                <svg-icon icon-class="yanzhengma" />
              </span>
              <el-input
                ref="password"

                v-model="verifyForm.code"
                type="text"
                placeholder="请输入验证码"
                name="code"
                tabindex="2"
                autocomplete="on"
              />
            </el-form-item>
            <el-button
              class="show-pwd code-btn"
              :disabled="emailDisabled"
              @click="handleVerifyCode"
            >
              {{ emailBtnTxt }}
            </el-button>
          </div>

          <el-button
            :loading="loading"
            type="primary"
            class="el-btn"
            :disabled="verifyForm.code == '' "
            @click.native.prevent="submitForm"
          >
            确定
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';

export default {
  props: {
    isForget: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    // 密码验证
    return {
      verifyForm: {
        phone: '',
        email: '',
        code: '',
      },
      phoneBtnTxt: '获取验证码',
      emailBtnTxt: '获取验证码',
      passwordType: 'password',
      loading: false,
      redirect: undefined,
      imgUrl: '',
      imgShow: true, isPhone: true,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    changeType(type) {
      this.isPhone = type === 'phone';
    },
    // 验证手机号、邮箱
    validateRules() {
      if (this.isPhone) {
        if (!this.verifyForm.phone) {
          this.$message.error('手机号码不能为空！');
        } else if (!/^1[3|4|5|6|7|8][0-9]{9}$/.test(this.verifyForm.phone)) {
          this.$message.error('手机号码格式不正确！');
        } else {
          return true;
        }
      } else {
        if (!this.verifyForm.email) {
          this.$message.error('邮箱不能为空！');
        } else if (
          !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            this.verifyForm.email,
          )
        ) {
          this.$message.error('邮箱格式不正确！');
        } else {
          return true;
        }
      }
    },
    // handleShowPwd() {
    //   if (this.passwordType === 'password') {
    //     this.passwordType = '';
    //   } else {
    //     this.passwordType = 'password';
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.password.focus();
    //   });
    // },
    // 获取验证码按钮
    handleVerifyCode: debounce(function () {
      if (this.validateRules()) {
        let type = 'bindWeChat';
        let url = this.urls.account.send_phone_code;
        if (!this.isPhone) {
          url = this.urls.account.send_email_code;
        }
        let phoneObj = {
          phone: this.verifyForm.phone,
          type,
        };
        let emailObj = {
          email: this.verifyForm.email,
          type,
        };

        this.$post(url, this.isPhone ? phoneObj : emailObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.sendCodeBtn();
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.login.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_PHONE_NOT_EXIST.text);
                break;
              case this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.text);
                break;
            }
          });
      }
    }, 400),
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.verifyForm.code) {
        this.verifyForm.code = '';
      }
      if (this.isPhone) {
        let time = 59;
        let timer = setInterval(() => {
          if (time === 0) {
            clearInterval(timer);
            this.phoneDisabled = false;
            this.phoneBtnTxt = '获取验证码';
          } else {
            this.phoneBtnTxt = time + '秒后重试';
            this.phoneDisabled = true;
            time--;
          }
        }, 1000);
      } else {
        let emailTime = 59;
        let emailTimer = setInterval(() => {
          if (emailTime === 0) {
            clearInterval(emailTimer);
            this.emailDisabled = false;
            this.emailBtnTxt = '获取验证码';
          } else {
            this.emailBtnTxt = emailTime + '秒后重试';
            this.emailDisabled = true;
            emailTime--;
          }
        }, 1000);
      }
    },
    submitForm: debounce(function () {
      let code = this.verifyForm.code;
      if (this.verifyForm.code) {
        let url = this.urls.account.auth_by_phone;
        if (!this.isPhone) {
          url = this.urls.account.auth_by_email;
        }
        let phoneObj = {
          phone: this.verifyForm.phone,
          code,
        };
        let emailObj = {
          email: this.verifyForm.email,
          code,
        };
        this.$post(url, this.isPhone ? phoneObj : emailObj)
          .then(res => {
            this.bindWxAccount(res.token);
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.code:
                this.$message.error(
                  this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.text,
                );
                break;
              case this.codes.authByPhone.VERIFICATION_CODE_ERROR.code:
                this.$message.error(
                  this.codes.authByPhone.VERIFICATION_CODE_ERROR.text,
                );
                break;
              case this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(
                  this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.text,
                );
                break;
            }
            this.$message.error('验证码有误');
          });
      } else {
        this.$message.error('验证码有误');
      }
    }, 400),
    bindWxAccount(token) {
      const { openId } = this.$route.query;
      this.loading = true;
      let param = new FormData();
      let { email, phone } = this.verifyForm;
      param.append('openId', openId);
      if (this.isPhone) {
        param.append('phone', phone);
      } else {
        param.append('email', email);
      }
      param.append('token', token);

      this.$post(this.urls.wx.web_bind_wx_account, param)
        .then((result) => {
          this.$store
            .dispatch('user/wx_login', result).then(() => {
              this.$router.push({ path: this.redirect || '/experiment' });
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        })
        .catch((err) => {
          let text = '参数有误';
          this.codes.bindWxAccount.forEach((e) => {
            if (e.code === err.code) {
              text = e.text;
            }
          });
          this.loading = false;
          this.$message.error(text);
        });
    },

    onGetCaptcha: debounce(function () {
      this.imgShow = false;
      setTimeout(() => {
        this.imgShow = true;
      }, 500);
    }, 500),
    // 激活用户方法
    accountActivate() {
      this.$post(this.urls.account.apply_activate, {
        email: this.verifyForm.email,
      })
        .then(() => {
          this.$message('请前往你的邮箱激活');
        })
        .catch(() => {
          this.$message.error('发送失败');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/el-input/index.scss'; //导入外部scss文件
$bg: #fff; //背景颜色
$light_gray: #333; // 字体颜色
$cursor: #333; // 光标

/* reset element-ui css */
.login-container {
  @include checkC3Cursor($cursor);
  .el-input {
    display: inline-block;
    width: 70%;
    font-size: 18px;
    @include inputFix($light_gray, $cursor, $bg);
  }
  .el-form {
    margin-top: 20px;
    .username-form-item {
      margin-top: 0;
    }
    .title-form-item {
      margin-top: 20px;
    }
    .el-form-item {
      margin-bottom: 30px;
      color: #fff;
      border-radius: 4px;
      border: 1px solid #b9bed1;
      .el-form-item__content {
        height: 50px;
        line-height: 50px;
      }
      .el-form-item__error {
        font-size: 16px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #889aa4;
$black_gray: black;

.svg-container {
  display: inline-block;
  padding-bottom: 6px;
  padding-left: 24px;
  font-size: 18px;
  color: #c7cbda;
  vertical-align: middle;
  .svg-icon {
    width: 1.2em;
    height: 1.2em;
  }
}
.form {
  width: 400px;
  height: 100%;

  margin: 0 auto;
  padding-bottom: 80px;

}
.form_title{
  padding-top: 100px;
  text-align: center;
}
.check {
  margin-bottom: 30px;
  text-align: right;
  color: #444;
  font-size: 16px;
  font-weight: 400;
  span {
    cursor: pointer;
    color: #63b7ff;
  }
}
 .verify-box {
      display: flex;
      justify-content: space-between;
      .password-form-item {
        width: 60%;
      }
    }
.show-pwd {
   height: 52px;
 cursor: pointer;
      font-size: 18px;
      color: $dark_gray;
      user-select: none;
}
.el-btn {
  width: 100%;
  padding: 14px 40px;
  font-size: 18px;
  border-radius: 4px;
}
.verify-box {
  display: flex;
  justify-content: space-between;
  .password-form-item {
    width: 60%;
  }
}
.code-img-btn {
  height: 50px;
  width: 120px;
  border-radius: 4px;
  overflow: hidden;
  padding: 0 4px;
  border: 1px solid #b9bed1;
  cursor: pointer;
}
.form_type{
   display: flex;
  align-items: center;
  margin : 40px 0 30px 0;
  .form_type_title{
  font-size: 22px;
  font-weight: 500;
  margin: 0 10px 0 0;
}

 .way-tip {
    color: #404040;
    margin: 0 5px;
}

.form_type_item{
      cursor: pointer;
      color: #8f8f8f;
      font-size: 14px;
}
 .way-actived {
    color: #63b7ff;
}
}

</style>
