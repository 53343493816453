var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case_report" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "conetent centre" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(" 病例详情 ")]),
          _c(
            "el-button",
            {
              staticClass: "back",
              staticStyle: { "font-size": "16px" },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v(" 返回 ")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "case_report_content" }, [
      _c(
        "div",
        { staticClass: "case_report_from" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.sideShow,
                  expression: "!sideShow"
                }
              ],
              staticClass: "arrow-right",
              on: {
                click: function($event) {
                  _vm.sideShow = true
                }
              }
            },
            [_c("i", { staticClass: "el-icon-d-arrow-right" })]
          ),
          _c("EkDrawerTransition", { attrs: { name: "el-zoom-in-left" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sideShow,
                    expression: "sideShow"
                  }
                ],
                staticClass: "from_item_list"
              },
              [
                _c("div", { staticClass: "from_item_list_title" }, [
                  _vm._v(" 目录 "),
                  _c(
                    "div",
                    {
                      staticClass: "arrow-left",
                      on: {
                        click: function($event) {
                          _vm.sideShow = false
                        }
                      }
                    },
                    [_c("i", { staticClass: "el-icon-d-arrow-left" })]
                  )
                ]),
                _c("div", { staticClass: "item_list_content" }, [
                  _c(
                    "div",
                    { staticClass: "item_list_content_list" },
                    _vm._l(_vm.formItems, function(item, index) {
                      return _c(
                        "div",
                        { key: item.itemCode },
                        [
                          _c(
                            "div",
                            {
                              class:
                                _vm.activeNames.indexOf(index) >= 0
                                  ? "list_label items"
                                  : "list_label",
                              on: {
                                click: function($event) {
                                  return _vm.onShows(index)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "list_label_left" }, [
                                _c("i", { staticClass: "el-icon-arrow-right" }),
                                _vm._v(" " + _vm._s(item.itemLabel))
                              ]),
                              _vm.getPlusItme(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "add_item",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.addItem(index)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c("el-collapse-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeNames.indexOf(index) >= 0,
                                    expression:
                                      "activeNames.indexOf(index) >= 0"
                                  }
                                ]
                              },
                              _vm._l(item.repotItems, function(items) {
                                return _c(
                                  "div",
                                  {
                                    key: items.itemCode,
                                    staticClass: "items_label"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "add_item_label" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(items.itemLabel) + " "
                                        )
                                      ]
                                    ),
                                    _vm.getPlusShow(
                                      item.itemCode,
                                      items.itemCode
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "add_item",
                                            on: {
                                              click: function($event) {
                                                return _vm.addItems(item, items)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-plus"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ]),
          _c(
            "div",
            {
              ref: "contentOutline",
              staticClass: "from_item_content",
              on: { mousewheel: _vm.scrollChange }
            },
            [
              _c(
                "div",
                { staticClass: "from_item_content_left" },
                [
                  _vm.caseReport.randomNumber
                    ? _c("div", { staticClass: "form_item_titles" }, [
                        _c("div", { staticClass: "item_label" }, [
                          _vm._v(
                            "病历编号 " + _vm._s(_vm.caseReport.randomNumber)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.CaseReportFormShow
                    ? _c("CaseReportForm", {
                        ref: "reportForm",
                        attrs: {
                          caseReport: _vm.caseReport,
                          cancerTypeGroups: _vm.cancerTypeGroups,
                          caseReportArr: _vm.caseReportArr
                        },
                        on: { deleteCaseReportItem: _vm.deleteCaseReportItems }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "submit" },
                    [
                      _vm.caseReportArr.length > 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit()
                                }
                              }
                            },
                            [
                              _vm.caseReport.randomNumber
                                ? _c("span", [_vm._v("更新病历")])
                                : _c("span", [_vm._v("保存病历")])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("CaseReportOutline", {
            attrs: {
              caseReportArr: _vm.caseReportArr,
              sideIndex: _vm.sideIndex
            },
            on: {
              onSideClick: _vm.onSideClick,
              onSideItemsClick: _vm.onSideItemsClick
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "case_report_img" },
        [
          _vm.CaseReportFormShow
            ? _c("CaseReportImg", { attrs: { caseReport: _vm.caseReport } })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }