import inputComponents from './inputComponents'; // 输入类型的组件
import selectComponents from './selectComponents'; // 选择类型的组件
// import buttonComponents from './buttonComponents'; // 按钮类型的组件
// import layoutComponents from './layoutComponents'; // 视图类型的组件

export default [
  {
    label: '输入型组件',
    list: inputComponents,
  },
  {
    label: '选择型组件',
    list: selectComponents,
  },
  // {
  //   label: '按钮型组件',
  //   list: buttonComponents,
  // },
  // {
  //   label: '布局型组件',
  //   list: layoutComponents,
  // },
];
