import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param role
 * @param route
 */
function hasPermission(route, p) {
  if (route.meta && route.meta.p) {
    return p.some((role) => route.meta.p.includes(role));
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param route asyncRoutes
 * @param p
 */
export function filterAsyncRoutes(routes, p) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(tmp, p)) {
      if (tmp.children) {
        // children子集的时候
        tmp.children = filterAsyncRoutes(tmp.children, p);
      }
      res.push(tmp);
    }
  });
  return res;
}
const getDefaultState = () => {
  return {
    routes: [],
    addRoutes: [],
  };
};
const state = getDefaultState;

const mutations = {
  SET_ROUTES: (state, value) => {
    let routers = value.accessedRoutes;
    state.addRoutes = routers;
    state.routes = constantRoutes.concat(routers);
    // if (routers.length !== 0) {
    // } else {
    //   Object.assign(state, getDefaultState());
    // }
  },
};

const actions = {
  generateRoutes({ commit }, p) {
    return new Promise((resolve) => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, p);
      commit('SET_ROUTES', { accessedRoutes });
      resolve(accessedRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
