<template>
  <div class="case_report">
    <div class="header">
      <div class="conetent centre">
        <div class="title">
          病例详情
        </div>
        <el-button type="text" style="font-size: 16px;" class="back" @click="$router.back()">
          返回
        </el-button>
      </div>
    </div>
    <div class="case_report_content">
      <div class="case_report_from">
        <div v-show="!sideShow" class="arrow-right" @click="sideShow = true">
          <i class="el-icon-d-arrow-right" />
        </div>
        <EkDrawerTransition name="el-zoom-in-left">
          <div v-show="sideShow" class="from_item_list">
            <div class="from_item_list_title">
              目录
              <div class="arrow-left" @click="sideShow = false">
                <i class="el-icon-d-arrow-left" />
              </div>
            </div>
            <div class="item_list_content">
              <div class="item_list_content_list">
                <div v-for="(item, index) in formItems" :key="item.itemCode">
                  <div
                    :class="activeNames.indexOf(index) >= 0 ? 'list_label items' : 'list_label'"
                    @click="onShows(index)"
                  >
                    <div class="list_label_left"><i class="el-icon-arrow-right" /> {{ item.itemLabel }}</div>
                    <div v-if="getPlusItme(item)" class="add_item" @click.stop="addItem(index)">
                      <i class="el-icon-plus" />
                    </div>
                  </div>
                  <el-collapse-transition>
                    <div v-show="activeNames.indexOf(index) >= 0">
                      <div v-for="items in item.repotItems" :key="items.itemCode" class="items_label">
                        <div class="add_item_label">
                          {{ items.itemLabel }}
                        </div>
                        <div
                          v-if="getPlusShow(item.itemCode, items.itemCode)"
                          class="add_item"
                          @click="addItems(item, items)"
                        >
                          <i class="el-icon-plus" />
                        </div>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </div>
            </div>
          </div>
        </EkDrawerTransition>
        <div ref="contentOutline" class="from_item_content" @mousewheel="scrollChange">
          <div class="from_item_content_left">
            <div v-if="caseReport.randomNumber" class="form_item_titles">
              <div class="item_label">病历编号 {{ caseReport.randomNumber }}</div>
            </div>
            <CaseReportForm
              v-if="CaseReportFormShow"
              ref="reportForm"
              :caseReport="caseReport"
              :cancerTypeGroups="cancerTypeGroups"
              :caseReportArr="caseReportArr"
              @deleteCaseReportItem="deleteCaseReportItems"
            />
            <div class="submit">
              <el-button v-if="caseReportArr.length > 0" type="primary" @click="onSubmit()">
                <span v-if="caseReport.randomNumber">更新病历</span>
                <span v-else>保存病历</span>
              </el-button>
            </div>
          </div>
        </div>
        <CaseReportOutline
          :caseReportArr="caseReportArr"
          :sideIndex="sideIndex"
          @onSideClick="onSideClick"
          @onSideItemsClick="onSideItemsClick"
        />
      </div>
      <div class="case_report_img">
        <CaseReportImg v-if="CaseReportFormShow" :caseReport="caseReport" />
      </div>
    </div>
  </div>
</template>

<script>
import reportFormItems from '@/utils/reportFormItems.js';
import CaseReportForm from '@/components/caseReportForm';
import cancerType from '@/utils/cancerType.json';
import CaseReportImg from './components/caseReportImg.vue';
import CaseReportOutline from './components/caseReportOutline.vue';
import EkDrawerTransition from '@/components/MSTransition';

export default {
  components: { CaseReportForm, CaseReportImg, CaseReportOutline, EkDrawerTransition },
  data() {
    return {
      activeNames: [],
      formItems: reportFormItems,
      cancerTypeGroups: [],
      caseReportArr: [],
      reportFormCode: [],
      alreadyCode: [],
      sideShow: true,
      caseReport: {
        imgList: [],
      },
      CaseReportFormShow: false,
      sideIndex: 0,
      dialogImageUrl: '',
      maxFileSize: 2,
      ocrContentData: '',
    };
  },
  created() {
    this.randomNumber();
    if (this.$route.query.sid) {
      this.getCaseReport();
    } else {
      this.addItem(0);
      this.CaseReportFormShow = true;
    }
  },
  methods: {
    getCaseReport() {
      this.$post(this.urls.mdb_data.getone, {
        sid: this.$route.query.sid,
      })
        .then((res) => {
          this.caseReport = res;
          this.CaseReportFormShow = true;
          this.formItems.map((item) => {
            // eslint-disable-next-line no-prototype-builtins
            if (res.hasOwnProperty(item.itemCode)) {
              this.reportFormCode.push(item.itemCode);
              this.caseReportArr.push({
                itemLabel: item.itemLabel,
                itemCode: item.itemCode,
                repotItems: [],
              });
              item.repotItems.map((items) => {
                // eslint-disable-next-line no-prototype-builtins
                if (res[item.itemCode].hasOwnProperty(items.itemCode)) {
                  this.caseReportArr.map((caseReportItem, index) => {
                    if (caseReportItem.itemCode === item.itemCode) {
                      this.caseReportArr[index].repotItems.push(items);
                    }
                  });
                }
              });
            }
          });
        })
        .catch(() => {});
    },
    onShows(index) {
      let i = this.activeNames.indexOf(index);
      if (i >= 0) {
        this.activeNames.splice(i);
      } else {
        this.activeNames.push(index);
      }
    },
    // 病例添加大项中的所有小项
    addItem(index) {
      let obj = JSON.parse(JSON.stringify(this.caseReport));
      let item = this.formItems[index];
      let caseReportIndex = this.reportFormCode.indexOf(item.itemCode);
      console.log(caseReportIndex);
      // 判断大纲中是否已经存在这个大项了
      if (caseReportIndex >= 0) {
        let { repotItems } = this.caseReportArr[caseReportIndex];
        let itemsCodeArr = repotItems.map((caseReportItems) => {
          return caseReportItems.itemCode;
        });
        item.repotItems.map((items) => {
          if (!itemsCodeArr.includes(items.itemCode)) {
            this.caseReportArr[caseReportIndex].repotItems.push(items);
            obj[`${item.itemCode}`][`${items.itemCode}`] = '';
          }
        });
        this.caseReport = obj;
      } else {
        obj[`${item.itemCode}`] = {};
        item.repotItems.map((items) => {
          obj[`${item.itemCode}`][`${items.itemCode}`] = '';
        });
        this.reportFormCode.push(item.itemCode);
        this.caseReportArr.push(item);
        this.caseReport = obj;
      }
    },
    // 病例添加大项中的某个小项
    addItems(a, b) {
      let obj = JSON.parse(JSON.stringify(this.caseReport));
      if (!this.reportFormCode.includes(a.itemCode)) {
        obj[a.itemCode] = {};
        obj[a.itemCode][b.itemCode] = '';
        this.caseReport = obj;
        this.reportFormCode.push(a.itemCode);
        this.caseReportArr.push({
          itemLabel: a.itemLabel,
          itemCode: a.itemCode,
          repotItems: [],
        });
        this.caseReportArr.map((item) => {
          if (a.itemCode === item.itemCode) {
            item.repotItems.push(b);
          }
        });
      } else {
        obj[a.itemCode][b.itemCode] = '';
        this.caseReport = obj;
        this.$set(this.caseReport);
        this.caseReportArr.map((item) => {
          if (a.itemCode === item.itemCode) {
            item.repotItems.push(b);
          }
        });
      }
    },

    getPlusShow(a, b) {
      let show = true;
      this.caseReportArr.map((item) => {
        if (item.itemCode === a) {
          item.repotItems.map((items) => {
            if (items.itemCode === b) {
              show = false;
            }
          });
        }
      });
      return show;
    },
    onSubmit() {
      let pass = this.$refs.reportForm.submitForm();
      if (pass) {
        const loading = this.$loading({
          lock: true,
          text: '创建新病例中...',
        });
        let { sid } = this.caseReport;
        if (sid) {
          this.caseReport['updateTime'] = new Date().getTime();
        } else {
          this.caseReport['createTime'] = new Date().getTime();
          let n1 = 1;
          let n2 = 1;
          let { cancerSubTypeId, cancerTypeGroupId } = this.caseReport.information;
          cancerType.data.map((item, index) => {
            if (item.cnName === cancerTypeGroupId) {
              n1 = index + 1;
              item.cancerSubTypes.map((itmes, indexs) => {
                if (itmes.cnName === cancerSubTypeId) {
                  n2 = indexs + 1;
                }
              });
            }
          });
          this.caseReport['randomNumber'] = this.randomNumber(n1, n2);
          this.caseReport['dataEntryClerkName'] = this.$store.getters.account.nickname;
        }

        this.$post(this.urls.mdb_data.save_data, this.caseReport)
          .then((res) => {
            this.caseReport = res;
            let { sid } = res;
            this.$router.replace({
              query: { sid: sid },
            });
            loading.close();

            this.$message.success('病例更新成功');
          })
          .catch(() => {
            loading.close();
            this.$message.error('更新失败');
          });
      }
    },
    onSideClick(index) {
      this.sideIndex = index;
      const targetOffsetTop = document.querySelectorAll(`.from_item_content  .el-form .form_item`);
      const top = targetOffsetTop[index].offsetTop;
      this.$refs.contentOutline.scrollTop = top;
    },
    onSideItemsClick(item) {
      let { index, id } = item;
      const targetOffsetTop = document.querySelectorAll(`.from_item_content  .el-form .form_item`);
      const itemsTargetOffsetTop = document.getElementById(`${id}`);
      const top = targetOffsetTop[index].offsetTop;
      const itemstop = itemsTargetOffsetTop.offsetTop;
      this.$refs.contentOutline.scrollTop = top + itemstop + 30;
    },
    scrollChange() {
      const navContents = document.querySelectorAll(`.from_item_content .el-form .form_item`);
      const scrollTop = this.$refs.contentOutline.scrollTop;
      let offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n += 1) {
        if (scrollTop >= offsetTopArr[n] - 128) {
          navIndex = n;
        }
      }
      this.sideIndex = navIndex;
    },
    getPlusItme(item) {
      let { itemCode, repotItems } = item;
      if (this.reportFormCode.includes(itemCode)) {
        let show = true;
        this.caseReportArr.map((caseItem) => {
          if (caseItem.itemCode === itemCode) {
            if (caseItem.repotItems.length === repotItems.length) {
              show = false;
            }
          }
        });
        return show;
      } else {
        return true;
      }
    },
    // 删除已添加项
    deleteCaseReportItems(arr) {
      let i1 = this.reportFormCode.indexOf(arr[0]);
      let repotItems = this.caseReportArr[i1].repotItems;
      let i2 = 0;
      repotItems.forEach((item, indexs) => {
        if (item.itemCode === arr[1]) {
          i2 = indexs;
        }
      });
      this.caseReportArr[i1].repotItems.splice(i2, 1);
      delete this.caseReport[arr[0]][arr[1]];
      if (this.caseReportArr[i1].repotItems.length < 1) {
        this.caseReportArr.splice([i1], 1);
        this.reportFormCode.splice([i1], 1);
        delete this.caseReport[arr[0]];
      }
    },
    randomNumber(a, b) {
      var alphabet = String.fromCharCode(64 + parseInt(a));
      let number = `${alphabet}${b}-${parseInt(Math.random() * 100000 + 100000)}`;
      return number;
    },
  },
};
</script>
<style lang="scss">
.case_report {
  background: #f6f7f9;
  height: 100%;
  .submit {
    margin-left: 20px;
    padding-bottom: 26px;
  }
  ::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    //滚动条的宽度
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar-thumb {
    //滚动条的设置
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .header {
    background: #fff;
    .centre {
      width: 980px;
      margin: 0 auto;
    }
    .conetent {
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      .title {
        color: #303843;
        line-height: 40px;
      }
      .back {
        color: #409eff;
      }
    }
  }
  .case_report_content {
    display: flex;
    .case_report_from {
      margin: 24px 0 0 24px;
      border-radius: 4px;
      display: flex;
      height: calc(100vh - 100px);
      background: #fff;
      position: relative;
      .arrow-right {
        position: absolute;
        left: -24px;
        top: 10px;
        text-align: center;
        line-height: 30px;
        width: 40px;
        height: 30px;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        opacity: 1;
        border-radius: 0px 15px 15px 0px;
        color: #676d74;
        cursor: pointer;
      }

      .from_item_list {
        width: 260px;
        background: #fff;

        border-right: 1px solid #dce5ec;

        .from_item_list_title {
          font-size: 16px;

          font-weight: bold;
          line-height: 24px;
          color: #323b46;
          margin: 20px 0 0 20px;
          position: relative;
          .arrow-left {
            position: absolute;
            right: -15px;
            top: -10px;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #ffffff;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            color: #676d74;
            cursor: pointer;
          }
        }
        .list_label {
          padding: 8px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .add_item {
            width: 24px;
            height: 24px;
            margin-left: 4px;
            background: #eaecee;
            opacity: 0;
            border-radius: 4px;
            font-size: 8px;
            color: #707070;
            line-height: 24px;
            text-align: center;
            position: relative;
            z-index: 10;
          }
          .list_label_left {
            line-height: 24px;
            i {
              transition: all 0.4s;
            }
          }
        }
        .list_label:hover {
          background: #f9fafb;
          border-radius: 4px;

          .add_item {
            opacity: 1;
          }
        }
        .items {
          .list_label_left {
            i {
              transform: rotate(90deg);
            }
          }
        }
      }
      .item_list_content {
        padding: 0 12px;
        height: calc(100% - 32px);
        overflow-x: hidden;
        overflow-y: hidden;
        width: 246px;
        .item_list_content_list {
          overflow-y: auto;
          height: calc(100% - 30px);
          width: 234px;
        }
        .el-collapse {
          border: 0;
        }
        .el-collapse-item__header {
          border: 0;
        }
        .el-collapse-item__wrap {
          border: 0;
        }

        .items_label {
          padding: 0 10px 0 24px;
          font-size: 14px;

          font-weight: 400;
          color: #323b46;
          height: 34px;
          line-height: 34px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .add_item_label {
            flex: 1;
          }
          .add_item {
            width: 24px;
            height: 24px;
            margin-left: 4px;
            background: #eaecee;
            opacity: 0;
            border-radius: 4px;
            font-size: 8px;
            color: #707070;
            line-height: 24px;
            text-align: center;
          }
        }
        .items_label:hover {
          background: #f9fafb;
          border-radius: 4px;
          .add_item {
            opacity: 1;
          }
        }
      }
      .from_item_content {
        width: 53vw;
        height: 100%;
        overflow: auto;
        .from_item_content_left {
          // height: calc(100% - 22px);
          // overflow: auto;
          margin-right: 260px;
          position: relative;
          .form_item_titles {
            padding: 20px 24px 0 20px;

            .item_label {
              font-size: 16px;
              font-weight: 600;
              color: #333;
              margin-right: 12px;
              padding-bottom: 12px;
              line-height: 24px;
            }
            .random_number {
              font-size: 16px;
              color: #606266;
              line-height: 40px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .case_report_img {
    margin: 24px 0 0 0;
    padding: 0 24px;
    padding-right: 4px;
    margin-left: 17px;
    flex: 1;
    background: #ffffff;
    opacity: 1;
    // width: 420px;
    border-radius: 4px 4px 0px 0px;
    height: calc(100vh - 100px);
    overflow: auto;
  }
}
</style>
