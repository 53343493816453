var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case_report_edit" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "conetent centre" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              staticClass: "back",
              staticStyle: { "font-size": "16px" },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v(" 返回 ")]
          )
        ],
        1
      )
    ]),
    _vm.reportFormItems.length > 0
      ? _c(
          "div",
          { staticClass: "case_report_table" },
          [
            _c("CaseReportForm", {
              attrs: {
                reportFormItems: _vm.reportFormItems,
                configId: _vm.configId
              },
              on: { saveForm: _vm.saveForm }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }