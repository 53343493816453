var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zh-search-box" },
    [
      _c(
        "el-input",
        {
          staticClass: "input-with-select",
          attrs: { placeholder: _vm.placeholder, clearable: "" },
          on: { input: _vm.handleInput, clear: _vm.handleClearInput },
          model: {
            value: _vm.inputVal,
            callback: function($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal"
          }
        },
        [
          _vm.isSelect
            ? _c(
                "el-select",
                {
                  attrs: {
                    slot: "prepend",
                    placeholder: "请选择",
                    clearable: ""
                  },
                  on: { clear: _vm.handleClearSelect },
                  slot: "prepend",
                  model: {
                    value: _vm.search.order,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "order", $$v)
                    },
                    expression: "search.order"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            : _vm._e(),
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: { click: _vm.handleSearchClick },
            slot: "append"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }