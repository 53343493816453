<template>
  <div class="account-changpwd-container">
    <el-form
      ref="accountForm"
      :model="accountForm"
      :rules="rules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          修改密码
        </h3>
      </div>
      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="pass"
          ref="pass"
          v-model="accountForm.password"
          :type="pass"
          placeholder="新密码"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span
          class="show-pwd"
          @click="handleShowPwd('pass')"
        >
          <svg-icon :icon-class="pass === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>
      <el-form-item prop="pass2">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="pass2"
          ref="pass2"
          v-model="accountForm.pass2"
          :type="pass2"
          placeholder="确认密码"
          name="pass2"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span
          class="show-pwd"
          @click="handleShowPwd('pass2')"
        >
          <svg-icon :icon-class="pass2 === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>
      <el-button
        :loading="loading"
        type="success"
        style="width:100%;margin-bottom:30px;"
        @click.native.prevent="handleLogin"
      >
        确认
      </el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Changpwd',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码长度至少六位数'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.accountForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      accountForm: {
        password: '',
        pass2: '',
      },
      rules: {
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        pass2: [{ required: true, trigger: 'blur', validator: validatePass2 }],
      },
      loading: false,
      pass: 'password',
      pass2: 'password',
      expireAt: '',
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.expireAt = route.query && route.query.expireAt;
      },
      immediate: true,
    },
  },
  created() {
    const timestamp = new Date().getTime();
    if (this.expireAt <= timestamp) {
      this.$alert('链接已失效', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.$router.push({ name: 'login' });
        },
      });
    }
  },
  methods: {
    handleShowPwd(type) {
      let ref = '';
      if (type === 'pass') {
        if (this.pass === 'password') {
          this.pass = '';
        } else {
          this.pass = 'password';
        }
        ref = this.$refs.pass.focus();
      } else {
        if (this.pass2 === 'password') {
          this.pass2 = '';
        } else {
          this.pass2 = 'password';
        }
        ref = this.$refs.pass2.focus();
      }
      this.$nextTick(() => {
        ref;
      });
    },
    handleLogin() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$post(this.urls.account.reset_password, {
            code: this.$route.query.code,
            password: this.accountForm.password,
          })
            .then(() => {
              this.$alert('密码重置成功', '提示', {
                confirmButtonText: '确定',
                showClose: false,
                callback: () => {
                  this.$router.push({ name: 'login' });
                },
              });
            })
            .catch((err) => {
              switch (err.code) {
                case this.codes.account.CODE_EXPIRED.code:
                  this.$message.error('操作超时');
                  this.$router.push({ name: 'login' });
                  break;
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/el-input/index.scss'; //导入外部scss文件
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
/* reset element-ui css */
.account-changpwd-container {
  @include checkC3Cursor($cursor);
  .el-input {
    display: inline-block;
    width: 85%;
    @include inputFix($light_gray, $cursor, $bg);
  }

  .el-form-item {
    color: #454545;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .el-form-item__content {
      height: 52px;
      line-height: 52px;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.account-changpwd-container {
  height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    .svg-container {
      display: inline-block;
      margin-left: 15px;
      vertical-align: middle;
      color: $dark_gray;
    }
    .title-container {
      position: relative;
      .title {
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        color: $light_gray;
      }
    }
    .show-pwd {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 16px;
      color: $dark_gray;
      user-select: none;
      cursor: pointer;
    }
  }
}
</style>
