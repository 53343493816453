<template>
  <div class="login-container">
    <div class="card">
      <div class="title-container">
        <img src="@/assets/img/logo.png" />
      </div>
      <!-- 登录表单 -->
      <template v-if="!isForget">
        <login-form :isForget="isForget" @forgetPwd="forgetPwd" />
      </template>
      <!-- 验证码表单 -->
      <template v-else>
        <login-verify
          :isForget="isForget"
          :isUpdate="isUpdate"
          :loading="loading"
          @authClick="authClick(arguments)"
          @backLogin="backLogin"
        />
        <!-- 修改密码 -->
        <login-update :isUpdate="isUpdate" :loadingReset="loadingReset" @updateBtn="updateBtn" />
      </template>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
import login from './components/login.vue';
import verify from './components/verify.vue';
import update from './components/update.vue';
export default {
  name: 'Login',
  components: {
    'login-form': login,
    'login-update': update,
    'login-verify': verify,
  },
  data() {
    return {
      isUpdate: false,
      isPhone: true,
      isForget: false,
      token: '',
      verifyForm: {
        phone: '',
        email: '',
        code: '',
      },
      redirect: undefined,
      checked: false,
      loading: false,
      loadingReset: false,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    // 忘记密码
    forgetPwd() {
      this.isForget = true;
    },
    // 返回登录
    backLogin() {
      this.isForget = false;
    },
    // 重置密码按钮
    authClick(data) {
      this.verifyForm = data[0];
      this.isPhone = data[1];
      this.handleAuth(data);
    },
    // 修改密码按钮
    updateBtn(data) {
      this.handleUpdate(data);
    },

    // 重置密码
    handleUpdate: debounce(function(data) {
      let phoneObj = {
        phone: this.verifyForm.phone,
        token: this.token,
        ...data,
      };
      let emailObj = {
        email: this.verifyForm.email,
        token: this.token,
        ...data,
      };
      this.loadingReset = true;
      this.$post(this.urls.account.reset_pwd, this.isPhone ? phoneObj : emailObj)
        .then(() => {
          this.loadingReset = false;
          this.$message.success('密码重置成功');
          this.isForget = false;
          this.isUpdate = false;
        })
        .catch((err) => {
          this.loadingReset = false;
          switch (err.code) {
            case this.codes.login.ACCOUNT_EMAIL_ERROR.code:
              this.$message.error(this.codes.login.ACCOUNT_EMAIL_ERROR.text);
              break;
            case this.codes.login.ACCOUNT_PHONE_ERROR.code:
              this.$message.error(this.codes.login.ACCOUNT_PHONE_ERROR.text);
              break;
            case this.codes.login.ACCOUNT_FORGET_PASSWORD_EXPIRE.code:
              this.$message.error(this.codes.login.ACCOUNT_FORGET_PASSWORD_EXPIRE.text);
              break;
            case this.codes.login.ACCOUNT_FORGET_PASSWORD_ERROR.code:
              this.$message.error(this.codes.login.ACCOUNT_FORGET_PASSWORD_ERROR.text);
              break;
          }
        });
    }, 400),
    // 校验验证码
    handleAuth: debounce(function(verifyData) {
      let obj = { code: verifyData[0].code };
      let phoneObj = {
        phone: verifyData[0].phone,
        ...obj,
      };
      let emailObj = {
        email: verifyData[0].email,
        ...obj,
      };
      this.loading = true;
      this.$post(this.urls.account.validate_code, verifyData[1] ? phoneObj : emailObj)
        .then((res) => {
          this.loading = false;
          this.isUpdate = true;
          this.token = res;
        })
        .catch((err) => {
          this.loading = false;
          switch (err.code) {
            case this.codes.login.VERIFICATION_CODE_EXPIRED.code:
              this.$message.error(this.codes.login.VERIFICATION_CODE_EXPIRED.text);
              break;
            case this.codes.login.VERIFICATION_CODE_ERROR.code:
              this.$message.error(this.codes.login.VERIFICATION_CODE_ERROR.text);
              break;
          }
        });
    }, 400),
  },
};
</script>

<style lang="scss">
@import '@/styles/el-input/index.scss'; //导入外部scss文件
$bg: #fff; //背景颜色
$light_gray: #333; // 字体颜色
$cursor: #333; // 光标

/* reset element-ui css */
.login-container {
  @include checkC3Cursor($cursor);
  .el-input {
    display: inline-block;
    width: 70%;
    font-size: 18px;
    @include inputFix($light_gray, $cursor, $bg);
  }
  .el-form {
    .username-form-item {
      margin-top: 60px;
    }
    .title-form-item {
      margin-top: 20px;
    }
    .el-form-item {
      margin-bottom: 30px;
      color: #fff;
      border-radius: 4px;
      border: 1px solid #b9bed1;
      .el-form-item__content {
        height: 50px;
        line-height: 50px;
      }
      .el-form-item__error {
        font-size: 16px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #889aa4;
$black_gray: black;

.login-container {
  position: relative;
  height: 100%;
  width: 100%;
  background: url('../../assets/img/login-bg.png');
  background-size: cover;
  overflow: hidden;
  .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 10px;
    box-shadow: 0px 10px 14px 2px rgba(27, 45, 62, 0.1);
    background-color: #fff;
    overflow: hidden;
    .form {
      width: 400px;
      height: 100%;
      margin: 0 auto;
      padding-bottom: 80px;
    }
  }
  .tips {
    margin-bottom: 10px;
    font-size: 14px;
    color: #fff;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    display: inline-block;
    padding-bottom: 6px;
    padding-left: 24px;
    font-size: 18px;
    color: #c7cbda;
    vertical-align: middle;
  }

  .title-container {
    position: relative;
    margin-top: 40px;
    text-align: center;
    img {
      margin: 0 auto;
      width: 180px;
    }
  }
  // .title {
  //   margin-top: 20px;
  //   font-weight: 400;
  //   font-size: 24px;
  //   color: #333333;
  // }
  .forget-title {
    display: flex;
    margin: 10px auto 0;
    width: 400px;
    align-items: center;
    color: #444;
    .login-title {
      font-size: 22px;
      font-weight: 500;
      margin: 0 10px 0 0;
    }
    .way-type {
      color: #8f8f8f;
      font-size: 14px;
    }
    .way-actived {
      color: #63b7ff;
    }
    .way-tip {
      color: #404040;
      margin: 0 5px;
    }
  }
  .check {
    margin-bottom: 30px;
    text-align: right;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    span {
      cursor: pointer;
      color: #63b7ff;
    }
  }
  .show-pwd {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 18px;
    color: $dark_gray;
    user-select: none;
  }
  .code-btn {
    right: 0px;
    height: 50px;
    color: #63b7ff;
  }
  .el-btn {
    width: 100%;
    padding: 14px 40px;
    font-size: 18px;
    border-radius: 4px;
  }
}
</style>
