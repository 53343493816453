var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sys-container" },
    [
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          { staticClass: "header", on: { click: _vm.handleAddFileClick } },
          [
            _c("i", {
              staticClass: "el-icon-circle-plus-outline",
              staticStyle: { "font-size": "20px" }
            }),
            _c("span", [_vm._v("添加CRF模板")])
          ]
        ),
        _c(
          "ul",
          _vm._l(_vm.sidebarList, function(item, index) {
            return _c(
              "li",
              {
                key: item.caseReportFormTemplateId,
                class: {
                  "is-item__content_active":
                    _vm.caseReportFormTemplateId ===
                    item.caseReportFormTemplateId
                },
                on: {
                  click: function($event) {
                    return _vm.handleItemClick(item, index)
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(item.formName) + " "),
                _c("i", {
                  staticClass: "ad-collapse-item_outline el-icon-more-outline",
                  class: {
                    "is-menu-active":
                      _vm.caseReportFormTemplateId ===
                      item.caseReportFormTemplateId
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleOutlineClick(
                        item.caseReportFormTemplateId
                      )
                    }
                  }
                }),
                _c("ad-popover", {
                  staticClass: "ad-popover",
                  attrs: {
                    menuActive:
                      _vm.childActive === item.caseReportFormTemplateId
                  },
                  on: {
                    handleRenameClick: function($event) {
                      return _vm.handleRenameClick(item)
                    },
                    handleDelClick: _vm.handleDelClick,
                    handleTagClick: _vm.handleTagClick
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ]),
      _vm.makeFormShow
        ? _c(
            "div",
            { staticClass: "make-form-ly" },
            [
              _c("MakeFormLayout", {
                attrs: { echoData: _vm.echoData, crfStatus: _vm.crfStatus },
                on: {
                  acquireJson: _vm.acquireJson,
                  handleLockClick: _vm.handleLockClick
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showTag
        ? [
            _c(
              "div",
              { staticClass: "ad-check-box" },
              [
                _vm._l(_vm.tags, function(tagGroup) {
                  return _c(
                    "div",
                    { key: tagGroup.tagGroupId, staticClass: "check-item" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" " + _vm._s(tagGroup.tagGroupName) + "： ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "group" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkAll,
                                callback: function($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll"
                              }
                            },
                            _vm._l(tagGroup.applicationTags, function(
                              applicationTag
                            ) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: applicationTag.applicationTagId,
                                  attrs: {
                                    label: applicationTag.applicationTagId
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        applicationTag.applicationTagName
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "check-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleCheckAllClick }
                  },
                  [_vm._v(" 保存 ")]
                )
              ],
              2
            )
          ]
        : _vm._e(),
      _c("ad-dialog", {
        attrs: {
          dialog: _vm.addDialog,
          formName: _vm.formName,
          message: _vm.message,
          title: _vm.title,
          url: _vm.url,
          parame: _vm.parame,
          list: _vm.sidebarList
        },
        on: {
          close: _vm.handleAddCloseClick,
          submit: _vm.HandleAddSubmitClick,
          onCancelClick: _vm.onCancelClick
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }