// import { showInfoMsg, showErrorMsg } from '@/utils/popInfo'
import store from '@/store';
import { MessageBox } from 'element-ui';

// 标识是否连接成功
let webSocketState = false;

// 记录重试的次数
let sendRetryCount = 0;

// 重新连接尝试的次数
let connectRetryCount = 0;
let socket = null;
let url = null;
function initWebSocket(wsUri) {
  const isWS = `${document.location.protocol === 'http:' ? 'ws' : 'wss'}://`;
  url = `${isWS}${wsUri}`; // ws:// wss://
  if (!window.WebSocket) {
    return console.log('您的浏览器不支持WebSocket');
  }
  socket = new WebSocket(wsUri); // 这里面的this都指向vue
  socket.onopen = webSocketOnOpen;
  socket.onerror = webSocketOnError;
  socket.onmessage = webSocketOnMessage;
  socket.onclose = websocketOnClose;
}

function webSocketOnOpen() {
  webSocketState = true;
  connectRetryCount = 0;
}
// 连接错误
function webSocketOnError(error) {
  console.log('连接异常', error);
}
// 订阅消息
function webSocketOnMessage(result) {
  const { data } = result;
  const { code } = JSON.parse(data);
  switch (code) {
    case '20004':
    case '10009':
      close();
      localStorage.clear();
      MessageBox.alert(code === '20004' ? '登录过期，请重新登录' : '用户在同类型终端登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        showClose: false,
        type: 'warning',
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        });
      });
      break;
    default:
      break;
  }
}
// 关闭websiocket
function websocketOnClose() {
  webSocketState = false;
  connectRetryCount++;
  setTimeout(() => {
    initWebSocket(url);
  }, 500 * connectRetryCount);
}
// 发送消息
function webSocketSend(agentData) {
  if (webSocketState) {
    sendRetryCount = 0;
    socket.send(agentData);
  } else {
    sendRetryCount++;
    setTimeout(() => {
      socket.send(agentData);
    }, sendRetryCount * 500);
  }
}
function close() {
  console.log('执行了');
  socket.close(); // 关闭 websocket
  socket.onclose = () => {};
}
export default {
  initWebSocket,
  webSocketSend,
  close,
};
