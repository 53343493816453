<template>
  <tp-form-box
    class="ad-sf-tp-form"
    :formData="formData"
  >
    <which-component
      v-for="li in formData.formDataList"
      :key="li.fieldId"
      :data="li"
      type="ShowForm"
      :prop="li.fieldId"
      :model="formData.model"
    />
  </tp-form-box>
</template>

<script>
import WhichComponent from '../componentList/WhichComponent';
import TpFormBox from '../componentList/common/FormBox';
export default {
  name: 'TpForm',
  components: { WhichComponent, TpFormBox },
  provide() {
    return {
      formData: this.formData,
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
      /**
       * formDataList: [],
       * formStyle: { }
       */
    },
  },
};
</script>
