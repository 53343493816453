var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-radio-group",
        {
          staticClass: "ad-radio-group",
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.remoteData, function(r) {
          return _c(
            "el-radio",
            {
              key: r[_vm.data.setting.remoteDataProps.value],
              staticClass: "ad-radio",
              attrs: { label: r[_vm.data.setting.remoteDataProps.value] }
            },
            [
              _vm._v(
                " " + _vm._s(r[_vm.data.setting.remoteDataProps.label]) + " "
              )
            ]
          )
        }),
        1
      )
    : _vm.data.setting.isRemote && !_vm.model
    ? _c(
        "el-radio-group",
        {
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.remoteData, function(r) {
          return _c(
            "el-radio",
            {
              key: r[_vm.data.setting.remoteDataProps.value],
              staticClass: "ad-radio",
              attrs: { label: r[_vm.data.setting.remoteDataProps.value] }
            },
            [
              _vm._v(
                " " + _vm._s(r[_vm.data.setting.remoteDataProps.label]) + " "
              )
            ]
          )
        }),
        1
      )
    : !_vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-radio-group",
        {
          staticClass: "ad-radio-group",
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.data.selections, function(r) {
          return _c(
            "el-radio",
            {
              key: r.value,
              staticClass: "ad-radio",
              attrs: { label: r.value }
            },
            [_vm._v(" " + _vm._s(r.label) + " ")]
          )
        }),
        1
      )
    : _c(
        "el-radio-group",
        {
          staticClass: "ad-radio-group",
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.data.selections, function(r) {
          return _c(
            "el-radio",
            {
              key: r.value,
              staticClass: "ad-radio",
              attrs: { label: r.value }
            },
            [_vm._v(" " + _vm._s(r.label) + " ")]
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }