<template>
  <div class="case_report_edit">
    <div class="header">
      <div class="conetent centre">
        <div class="title">
          <img src="@/assets/img/logo.png" />
        </div>
        <el-button type="text" style="font-size: 16px;" class="back" @click="$router.back()">
          返回
        </el-button>
      </div>
    </div>
    <div v-if="reportFormItems.length > 0" class="case_report_table">
      <CaseReportForm :reportFormItems="reportFormItems" :configId="configId" @saveForm="saveForm" />
    </div>
  </div>
</template>

<script>
import CaseReportForm from '@/components/caseReportFormEdit';

export default {
  components: { CaseReportForm },
  data() {
    return {
      reportFormItems: [],
      configId: '',
    };
  },
  created() {
    this.getSick();
  },
  methods: {
    getSick() {
      this.$post(this.urls.sick.get, {})
        .then((res) => {
          this.reportFormItems = res.data;
          this.configId = res.config_id;
        })
        .catch(() => {});
    },
    saveForm(form) {
      this.$post(this.urls.sick.save_data, { config_id: this.configId, data: form })
        .then((res) => {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.reportFormItems = res.data;
          console.log(res);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.case_report_edit {
  background: #f6f7f9;
  height: 100%;
  ::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    //滚动条的宽度
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar-thumb {
    //滚动条的设置
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .header {
    background: #fff;
    .centre {
      width: 980px;
      margin: 0 auto;
    }
    .conetent {
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;
      .title {
        color: #303843;
        img {
          height: 40px;
        }
      }
      .back {
        color: #409eff;
      }
    }
  }
  .case_report_table {
    margin: 24px;
    background: #fff;
    border-radius: 4px;
    padding: 24px;
  }
}
</style>
