var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "repot_form" },
    [
      _vm.caseReportArr.length > 0
        ? _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.caseReport } },
            _vm._l(_vm.caseReportArr, function(item, index) {
              return _c("div", { key: index, staticClass: "form_item" }, [
                _c(
                  "div",
                  {
                    staticClass: "form_item_title",
                    attrs: { id: item.itemCode }
                  },
                  [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v(" " + _vm._s(item.itemLabel) + " ")
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form_item_list" },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          "flex-wrap": "wrap",
                          "flex-direction": "row",
                          width: "100%"
                        },
                        attrs: { gutter: 20, type: "flex" }
                      },
                      _vm._l(item.repotItems, function(items) {
                        return _c(
                          "el-col",
                          { key: items.itemCode, attrs: { span: 12 } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form_items",
                                attrs: { id: index + "-" + items.itemCode }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: items.itemLabel,
                                      prop: item.itemCode
                                        ? item.itemCode + "." + items.itemCode
                                        : "" + items.itemCode,
                                      rules: _vm.setRules(items)
                                    }
                                  },
                                  [
                                    !items.fixed
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "items_delete",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteItem(
                                                  item.itemCode,
                                                  items.itemCode
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete"
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.defaultCancerType.includes(
                                      items.itemCode
                                    )
                                      ? _c("AdWhichComponents", {
                                          attrs: {
                                            data: items,
                                            model: _vm.caseReport,
                                            itemItemsCode: item.itemCode
                                          }
                                        })
                                      : _c("CustomItem", {
                                          attrs: {
                                            data: items,
                                            model: _vm.caseReport,
                                            cancerSubTypes: _vm.cancerSubTypes,
                                            provinces: _vm.provinces,
                                            citys: _vm.citys,
                                            itemItemsCode: item.itemCode,
                                            cancerTypeGroups:
                                              _vm.cancerTypeGroups,
                                            cancerDisabled: _vm.cancerDisabled
                                          }
                                        })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            0
          )
        : _c("div", [
            _c("div", { staticClass: "empty" }, [
              _c("img", {
                attrs: { src: require("@/assets/wsj.png"), alt: "1233" }
              }),
              _c("p", [_vm._v("暂无数据")]),
              _c("p", [_vm._v("可从左侧添加数据")])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }