<template>
  <div>
    <el-checkbox-group v-model="model[(`${data.name}`)] ">
      <el-checkbox
        v-for="(item ,index) in data.configuration.allowableValues "
        :key="index"
        :label="item.value"
      >
        <span v-text="item.cnValue" />
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'AdCheckbox',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.model[`${this.data.name}`] === null) {
      this.model[`${this.data.name}`] = [];
    }
  },
};
</script>
