var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.formType === "input"
        ? _c("AdInput", {
            attrs: {
              itemItemsCode: _vm.itemItemsCode,
              data: _vm.data,
              model: _vm.model
            }
          })
        : _vm.data.formType === "numInput"
        ? _c("AdNumInput", {
            attrs: {
              data: _vm.data,
              model: _vm.model,
              itemItemsCode: _vm.itemItemsCode
            }
          })
        : _vm.data.formType === "textarea"
        ? _c("AdTextarea", {
            attrs: {
              data: _vm.data,
              model: _vm.model,
              itemItemsCode: _vm.itemItemsCode
            }
          })
        : _vm.data.formType === "radio"
        ? _c("AdRadio", { attrs: { data: _vm.data, model: _vm.model } })
        : _vm.data.formType === "checkbox"
        ? _c("AdCheckbox", { attrs: { data: _vm.data, model: _vm.model } })
        : _vm.data.formType === "select"
        ? _c("AdSelect", {
            attrs: {
              data: _vm.data,
              model: _vm.model,
              itemItemsCode: _vm.itemItemsCode
            }
          })
        : _vm.data.formType === "timePicker"
        ? _c("AdTimePicker", { attrs: { data: _vm.data, model: _vm.model } })
        : _vm.data.formType === "datePicker"
        ? _c("AdDatePicker", {
            attrs: {
              data: _vm.data,
              model: _vm.model,
              itemItemsCode: _vm.itemItemsCode
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }