<template>
  <div class="standard">
    <div class="standard_table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
        />
        <el-table-column
          prop="address"
          label="入组标准"
          header-align="center"
        />
        <el-table-column
          fixed="right"
          header-align="center"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onUpdatedStard(scope.row , scope.$index )"
            >
              编程
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteStard(scope.row , scope.$index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="add_satndard">
      <el-button
        size="mini"
        type="success"
        @click="addSatbdard()"
      >
        新增标准项
      </el-button>
    </div>

    <el-dialog
      title="新增标准项"
      center
      width="620px"
      destroy-on-close
      :visible.sync="addStandard"
      @close="close()"
    >
      <div
        class="content"
        style="width :500px"
      >
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitClick()"
        >
          确 定
        </el-button>
        <el-button @click="onCancel">
          取消
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改标准项"
      center
      width="620px"
      destroy-on-close
      :visible.sync="updatedStard"
      @close="close()"
    >
      <div
        class="content"
        style="width :500px"
      >
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitClick()"
        >
          确 定
        </el-button>
        <el-button @click="onCancel">
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    studyId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addStandard: false,
      updatedStard: false,
      textarea: '',
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address:
            '上海市普陀区金沙江路 1518 弄上海市普陀区金沙江路 1518 弄上海市普陀区金沙江路 1518 弄上海市普陀区金沙江路 1518 弄上海市普陀区金沙江路 1518 弄上海市普陀区金沙江路 1518 弄',
          zip: 200333,
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333,
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333,
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333,
        },
      ],
    };
  },
  methods: {
    addSatbdard() {
      this.addStandard = true;
    },
    onCancel() {
      this.addStandard = false;
      this.textarea = '';
    },
    submitClick() {
      if (this.textarea !== '') {
        this.tableData.push({
          address: this.textarea,
        });
        this.$message.success('添加成功');
      } else {
        this.$message.error('添加失败，编辑为空');
      }
      this.addStandard = false;
      this.textarea = '';
    },
    close() {
      this.addStandard = false;
      this.textarea = '';
    },
    onUpdatedStard(row, index) {
      console.log(row, index);
    },
    deleteStard(row, index) {
      console.log(row);
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/el-dialog/index.scss';

.standard {
  .standard_table {
    margin-bottom: 12px;
  }
  margin-bottom: 28px;
}
</style>
