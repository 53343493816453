var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "提示",
        visible: _vm.visible,
        width: "30%",
        "before-close": _vm.handleClose,
        center: "",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "di-form" },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据模板" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.downloadTemplate }
                    },
                    [_vm._v(" 下载模板 ")]
                  )
                ],
                1
              ),
              _c("el-form-item", [
                _c("ul", [
                  _c("li", { staticClass: "di-desc__line" }, [
                    _vm._v(
                      "如需通过导入更新数据，请先导出需要更新的数据并完成修改"
                    )
                  ]),
                  _c("li", { staticClass: "di-desc__line" }, [
                    _vm._v("支持 2MB 以内的xls、xlsx格式文件")
                  ]),
                  _c("li", { staticClass: "di-desc__line" }, [
                    _vm._v("文件中数据不能超过50000行、200列")
                  ])
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "批量导入" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "di-form-upload",
                      attrs: {
                        width: "100%",
                        drag: "",
                        action: "/api/v1/" + _vm.urls.cdash_template.upload,
                        "file-list": _vm.fileList,
                        "auto-upload": false
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }