var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "MakeForm"
    ? _c(
        "el-row",
        {
          staticClass: "ad-mf-layout--row",
          attrs: { gutter: _vm.data.setting.gutter }
        },
        [
          _c("div", { staticClass: "ad-mf-layout--row--tip" }, [
            _vm._v(
              " col: " +
                _vm._s(_vm.data.setting.col) +
                ", gutter: " +
                _vm._s(_vm.data.setting.gutter) +
                " "
            )
          ]),
          _vm._l(_vm.data.setting.col, function(i) {
            return _c(
              "el-col",
              {
                key: i,
                staticClass: "ad-mf-layout--col__make",
                attrs: { span: 24 / _vm.data.setting.col }
              },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "ad-mf-layout--content--draggable",
                    attrs: { group: "TpFormGroup", animation: "300" },
                    model: {
                      value: _vm.arr[i - 1],
                      callback: function($$v) {
                        _vm.$set(_vm.arr, i - 1, $$v)
                      },
                      expression: "arr[i-1]"
                    }
                  },
                  [
                    _vm.data.setting.colList[i - 1]
                      ? _c("which-component", {
                          attrs: {
                            type: _vm.type,
                            data: _vm.data.setting.colList[i - 1]
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.clickComponent(
                                _vm.data.setting.colList[i - 1]
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _c(
        "el-row",
        {
          staticClass: "ad-mf-layout--row",
          attrs: { gutter: _vm.data.setting.gutter }
        },
        _vm._l(_vm.data.setting.col, function(i) {
          return _c(
            "el-col",
            {
              key: i,
              staticClass: "ad-mf-layout--col",
              attrs: { span: 24 / _vm.data.setting.col }
            },
            [
              _vm.data.setting.colList[i - 1]
                ? _c("which-component", {
                    attrs: {
                      type: _vm.type,
                      data: _vm.data.setting.colList[i - 1],
                      model: _vm.model,
                      prop: _vm.data.setting.colList[i - 1].fieldId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }