<template>
  <div class="case_report_img_content">
    <h3>
      患者文件
    </h3>
    <div class="case_report_img_item">
      <div v-loading="fullscreenLoading" class="img_item_list">
        <div class="img_item_label">
          上传病历单
        </div>
        <div v-if="previewType === 'listImg'" class="upload_img">
          <div class="upload_img_list">
            <div v-for="(item, index) in caseReport.imgList" :key="item.fileId" class="upload_img_list_item">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.url"
                fit="cover"
                @click="handlePictureCardPreview(item, index)"
              />
            </div>

            <el-upload
              :action="`/api/v1/${urls.cloud_file.single_upload}`"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :data="uploadData"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </div>
        </div>
        <div v-else class="ocr_img">
          <div class="preview_img">
            <div class="preview_img_header">
              <div class="preview_img_title" @click="handleEdit">
                <span v-if="activateImage.name">{{ activateImage.name || '' }}</span> <i class="el-icon-edit" />
              </div>
              <div class="close" @click="closePreviewImg()">
                <i class="el-icon-close" />
              </div>
            </div>
            <ImgAuot ref="ImgAuot" :imgUrl="dialogImageUrl" />
            <div class="preview_img_side">
              <!-- <el-button type="primary" icon="el-icon-edit" circle /> -->
              <div class="side_item" @click="onDialogVisible()">
                <i class="iconfont icon-quanping" />
              </div>
              <div class="side_item" @click="handleRemove()">
                <i class="iconfont icon-shanchu" />
              </div>
              <el-button v-if="ocrShow" type="primary" class="el-btn" size="small" round @click="ocrPreviewImg()">
                <i class="iconfont icon-ocr " style="margin-right:4px" />自动识别
              </el-button>
            </div>
          </div>
          <div class="preview_img_list">
            <div v-if="previewType === 'ocrImg'" class="preview_scroll_bar">
              <Carousel
                ref="Carousel"
                :imgList="caseReport.imgList"
                :activateIndex="activateIndex"
                :activateImage="activateImage"
                @onPreviewImg="onPreviewImg"
              />
            </div>
            <div v-if="ocrContentText != ''" class="ocr_content">
              <div class="img_item_label">
                识别结果
              </div>
              <div class="ocr_content_text">
                <div v-for="(item, index) in ocrContentText" :key="index">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible" append-to-body>
        <el-image width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-dialog title="图片编辑" :visible.sync="dialogEditImg" append-to-body width="30%" center style="z-index: 2040">
        <el-form :model="activateImage">
          <el-form-item label="名称" :label-width="formLabelWidth" placeholder="请输入名字">
            <el-input v-model="activateImage.name" autocomplete="off" />
          </el-form-item>
          <!-- <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="activateImage.remark" type="textarea" :rows="2" placeholder="请输入备注" />
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEditImg = false">
            取 消
          </el-button>
          <el-button type="primary" @click="handleEditImg()">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import ImgAuot from '@/components/imgAuot';
import Carousel from '@/components/carousel'; // 路径地址

export default {
  components: { ImgAuot, Carousel },
  props: {
    caseReport: {
      type: Object,
      default: () => {},
    },
    ocrShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false,
      dialogEditImg: false,
      dialogImageUrl: '',
      ocrContentText: '',
      formLabelWidth: '120px',
      activateImage: {
        fileId: '',
      },
      activateIndex: 0,
      uploadData: {
        type: '',
        name: '',
        remark: '',
        studyId: '',
      },
      previewType: 'listImg',
    };
  },

  methods: {
    onDialogVisible() {
      this.$refs.ImgAuot.viewBigPicture();
    },
    handleRemove() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let i = this.activateIndex;
          this.caseReport.imgList.splice(i, 1);
          this.activateIndex = i === 0 ? 0 : i - 1;
          this.activateImage = this.caseReport.imgList[this.activateIndex];
          this.dialogImageUrl = this.caseReport.imgList[this.activateIndex].url;
          this.$refs.Carousel.getActivateIndex();
          if (this.caseReport.sid) {
            this.uploadImageList();
          }
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    handleEdit() {
      this.dialogEditImg = true;
    },
    handleEditImg() {
      let { imgList } = this.caseReport;
      let arr = imgList.map((item) => {
        if (item.fileId === this.activateImage.fileId) {
          return this.activateImage;
        } else {
          return item;
        }
      });
      this.caseReport.imgList = arr;
      if (this.caseReport.sid) {
        this.uploadImageList();
      }
      this.dialogEditImg = false;
    },
    // 患者病例图片
    getOcrImg() {
      this.$post(this.urls.ocr.network, {
        imageFileUrl: this.dialogImageUrl,
      })
        .then((res) => {
          this.ocrContentData = res;
          this.fullscreenLoading = false;
          this.dialogImageUrl = res.visualizedResultUrl;

          this.ocrContentText = res.resultList;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    handleAvatarSuccess(res) {
      let { fileId, fileUrl, name, size } = res.data;
      let obj = {
        name,
        fileId,
        url: fileUrl,
        remark: '',
        size,
      };
      this.caseReport.imgList.push(obj);
      if (this.caseReport.sid) {
        this.uploadImageList();
      }
    },
    uploadImageList() {
      this.$post(this.urls.mdb_data.save_image_data, {
        sid: this.caseReport.sid,
        imgList: this.caseReport.imgList,
      })
        .then(() => {})
        .catch(() => {});
    },

    beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whitelist = this.supportTypes;
      this.uploadData.name = file.name;
      const white =
        whitelist.length === 0 ? ['jpg', 'png'].indexOf(fileSuffix) === -1 : whitelist.indexOf(fileSuffix) === -1;
      const isLtNM = file.size / 1024 / 1024 < this.maxFileSize;
      if (white) {
        this.$message.error(`只能上传'jpg'、'png'文件`);
        return false;
      }
      if (!isLtNM) {
        this.$message.error(`上传文件大小不能超过${this.maxFileSize}MB!`);
        return false;
      }
    },
    closePreviewImg() {
      this.dialogImageUrl = '';
      this.ocrContentText = '';
      this.previewType = 'listImg';
    },
    handlePictureCardPreview(file, index) {
      this.dialogImageUrl = file.url;
      this.previewType = 'ocrImg';
      this.activateImage = file;
      this.activateIndex = index;
    },
    onPreviewImg(items) {
      let { item, index } = items;
      this.dialogImageUrl = item.url;
      this.activateImage = item;
      this.activateIndex = index;
    },
    ocrPreviewImg() {
      this.fullscreenLoading = true;
      this.getOcrImg();
    },
  },
};
</script>

<style lang="scss">
.case_report_img_content {
  > h3 {
    font-size: 16px;

    font-weight: bold;
    color: #323b46;
    line-height: 24px;
    margin-top: 20px;
  }
  .case_report_img_item {
    // padding-top: 12px;
  }
  .img_item_list {
    // height: 740px;
    overflow-y: auto;
    padding-right: 20px;
    .img_item_label {
      padding-left: 4px;
      border-left: 3px solid #409eff;
      font-size: 14px;

      font-weight: 400;
      line-height: 20px;
      color: #323b46;
    }
    .upload_img {
      margin: 24px 0;
      .upload_img_list {
        display: flex;
        flex-flow: wrap;
        .upload_img_list_item {
          margin: 0 8px 8px 0;
          display: inline-block;
          overflow: hidden;
          background-color: #fff;
          border: 1px solid #c0ccda;
          border-radius: 6px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 110px;
          height: 110px;
          cursor: pointer;
        }
      }
      .upload-demo {
        margin-left: 40px;
        .el-upload-dragger {
          width: 275px;
        }
      }

      .el-upload-list__item {
        width: 110px;
        height: 110px;
      }
      .el-upload--picture-card {
        width: 110px;
        height: 110px;
        line-height: 110px;
      }
    }
    .ocr_img {
      margin: 14px 0;
      .preview_img {
        height: 296px;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #409eff;
        overflow: hidden;
        position: relative;
        .preview_img_header {
          position: absolute;
          top: 0;
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 40px;
          background: rgba(0, 0, 0, 0.4);
          z-index: 20;
          padding: 0 20px;
          .preview_img_title {
            flex: 1;
            margin-right: 8px;
            color: #f1f1f1;
            cursor: pointer;
          }
          .close {
            padding: 6px;
            color: #fff;
            font-size: 18px;
            position: relative;
            right: -10px;
          }
        }
        .preview_img_side {
          position: absolute;
          bottom: 16px;
          right: 20px;
          .side_item {
            width: 32px;
            height: 32px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            color: #fff;
            font-size: 20px;
            text-align: center;
            line-height: 32px;
            margin: 6px 0 6px 66px;
            cursor: pointer;
          }
          .el-button {
            margin-top: 34px;
          }
        }
      }
      .preview_img_list {
        width: 100%;
        margin-top: 16px;
        overflow: hidden;
        .preview_scroll_bar {
          display: flex;
          width: 100%;
          overflow: hidden;
          margin-bottom: 10px;
          .scroll_bar_image {
            margin: 0 4px;
            border: 3px solid #fff;
          }
          .scroll_bar_image:hover {
            border: 3px solid #409eff;
          }
          .activate_image {
            border: 3px solid #409eff;
          }
        }
      }
      .ocr_content {
        margin-top: 10px;
        .ocr_content_text {
          margin-top: 20px;
          padding: 16px;
          background: #f9fafb;
          font-size: 14px;

          font-weight: 400;
          line-height: 20px;
          color: #323b46;
          word-wrap: break-word;
          height: 290px;
          overflow-y: auto;
        }
      }
    }
  }
}
</style>
