var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case_report_list" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "conetent centre" },
          [
            _vm._m(0),
            _c(
              "el-button",
              {
                staticClass: "back",
                staticStyle: { "font-size": "16px" },
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/")
                  }
                }
              },
              [_vm._v(" 返回 ")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "case_report_table" }, [
        _c(
          "div",
          { staticClass: "case_report_table_header" },
          [
            _c(
              "el-row",
              { staticStyle: { width: "100%", "margin-top": "24px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "queryForm",
                    attrs: { model: _vm.queryForm, "label-width": "80px" }
                  },
                  [
                    _c("el-col", { attrs: { span: 10 } }, [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                placeholder: "请输入" + _vm.searchItemText(),
                                clearable: ""
                              },
                              on: { clear: _vm.clearKeyword },
                              model: {
                                value: _vm.keyword,
                                callback: function($$v) {
                                  _vm.keyword = $$v
                                },
                                expression: "keyword"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    slot: "prepend",
                                    clearable: "",
                                    placeholder: "请选择关键字"
                                  },
                                  on: { clear: _vm.clearQueryCondition },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.queryConditionSelect,
                                    callback: function($$v) {
                                      _vm.queryConditionSelect = $$v
                                    },
                                    expression: "queryConditionSelect"
                                  }
                                },
                                _vm._l(_vm.reportFormItems, function(group) {
                                  return _c(
                                    "el-option-group",
                                    {
                                      key: group.itemCode,
                                      attrs: { label: group.itemLabel }
                                    },
                                    _vm._l(group.repotItems, function(item) {
                                      return _c(
                                        "div",
                                        { key: item.itemCode },
                                        [
                                          item.formType !== "datePicker" &&
                                          _vm.getOptionShow(group, item)
                                            ? _c("el-option", {
                                                attrs: {
                                                  label: item.itemLabel,
                                                  value: item.itemCode,
                                                  disabled:
                                                    item.formType ===
                                                    "datePicker"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.changeSelect(
                                                      group,
                                                      item
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                }),
                                1
                              ),
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-search"
                                },
                                on: { click: _vm.searchCaseList },
                                slot: "append"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "el-col",
                      { attrs: { offset: 10, span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticStyle: { "margin-right": "12px" },
                                attrs: { to: "/caseReport", tag: "span" }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "success", size: "small" } },
                                  [_vm._v(" 新建病历 ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/caseReport/edit", tag: "span" }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary", size: "small" } },
                                  [_vm._v(" 病历编辑 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            staticClass: "case_report_table_content"
          },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, size: "small" }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "病历编号",
                    width: "120",
                    prop: "randomNumber"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "姓名",
                    width: "120",
                    prop: "information.name"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "癌种类型",
                    width: "120",
                    prop: "information.cancerTypeGroupId"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "name-wrapper",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c(
                                "el-tag",
                                { attrs: { type: "warning", size: "small" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.information.cancerTypeGroupId
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "病种",
                    width: "280",
                    prop: "information.cancerSubTypeId"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "name-wrapper",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("el-tag", { attrs: { size: "small" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.information.cancerSubTypeId
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm.queryCondition.filedName &&
                _vm.tableColumnProp.indexOf(_vm.queryCondition.filedName) == -1
                  ? _c("el-table-column", {
                      attrs: {
                        label: _vm.queryCondition.text,
                        width: "140",
                        prop: _vm.queryCondition.filedName
                      }
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: { label: "年龄", width: "80", prop: "information.age" }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "手机号",
                    width: "140",
                    prop: "information.phone"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "归属省份",
                    width: "140",
                    prop: "information.province"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "归属城市",
                    width: "140",
                    prop: "information.city"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    width: "260",
                    prop: "information.remark"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "录入时间",
                    width: "150",
                    prop: "information.createTime"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "name-wrapper",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parseTime")(
                                      scope.row.createTime,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "负责人",
                    width: "100",
                    prop: "dataEntryClerkName"
                  }
                }),
                _c("el-table-column", {
                  attrs: { width: "200", label: "操作", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleEdit(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 更新 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleDetail(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 详情 ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPage,
                    "page-size": 10,
                    layout: "prev, pager, next, jumper",
                    total: _vm.total
                  },
                  on: {
                    "update:currentPage": function($event) {
                      _vm.currentPage = $event
                    },
                    "update:current-page": function($event) {
                      _vm.currentPage = $event
                    },
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            size: "65%",
            visible: _vm.drawer,
            direction: _vm.direction,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _vm.drawer
            ? _c(
                "div",
                { staticClass: "case_detail" },
                [
                  _c(
                    "div",
                    { staticClass: "case_detail_header" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            tag: "h3",
                            to: {
                              name: "caseReport",
                              query: { sid: _vm.caseDetail.sid }
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "病历编号:" + _vm._s(_vm.caseDetail.randomNumber)
                            )
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-edit" })
                        ]
                      ),
                      _c("div", { staticClass: "case_detail_content" }, [
                        _c("div", [
                          _vm._v(
                            "负责人：" +
                              _vm._s(_vm.caseDetail.dataEntryClerkName)
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "创建时间：" +
                              _vm._s(
                                _vm._f("parseTime")(
                                  _vm.caseDetail.createTime,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      on: { "tab-click": _vm.handleClickActiveName },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "病历资料", name: "caseDetail" } },
                        [
                          _c(
                            "div",
                            { staticClass: "case_detail_content" },
                            _vm._l(_vm.caseDetailItems, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.itemCode,
                                  staticClass: "case_report_form"
                                },
                                [
                                  _c(
                                    "el-descriptions",
                                    {
                                      attrs: {
                                        title: item.itemLabel,
                                        border: "",
                                        size: "small",
                                        column: 3,
                                        direction: "vertical"
                                      }
                                    },
                                    _vm._l(item.repotItems, function(items) {
                                      return _c(
                                        "el-descriptions-item",
                                        {
                                          key: items.itemCode,
                                          attrs: { label: items.itemLabel }
                                        },
                                        [
                                          items.formType === "datePicker" &&
                                          items.itemValue
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("parseTime")(
                                                        items.itemValue,
                                                        "{y}-{m}-{d} {h}:{i}"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : items.itemValue
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(items.itemValue) +
                                                    " "
                                                )
                                              ])
                                            : _c("div", [_vm._v(" -- ")])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "病历图片", name: "caseImg" } },
                        [
                          _c(
                            "div",
                            { staticClass: "case_report_img" },
                            [
                              _c("CaseReportImg", {
                                attrs: {
                                  ocrShow: false,
                                  caseReport: _vm.caseDetail
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }