var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.data.deleted
    ? _c(
        "item-box",
        {
          style: {
            padding: _vm.data.name === "Layout" ? "0!important" : undefined
          },
          attrs: {
            data: _vm.data,
            isMakeForm: _vm.type === "MakeForm",
            prop: _vm.prop,
            status: _vm.status,
            showMask: _vm.data.name !== "Layout"
          }
        },
        [
          _vm.data.name === "Input"
            ? _c("tp-input", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Textarea"
            ? _c("tp-textarea", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Phone"
            ? _c("tp-phone", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Password"
            ? _c("tp-password", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Radio"
            ? _c("tp-radio", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Checkbox"
            ? _c("tp-checkbox", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Select"
            ? _c("tp-select", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "TimePicker"
            ? _c("tp-time-picker", {
                attrs: { data: _vm.data, model: _vm.model }
              })
            : _vm.data.name === "TimePickerMultiple"
            ? _c("tp-time-picker-multiple", {
                attrs: { data: _vm.data, model: _vm.model }
              })
            : _vm.data.name === "DatePicker"
            ? _c("tp-date-picker", {
                attrs: { data: _vm.data, model: _vm.model }
              })
            : _vm.data.name === "DatePickerMultiple"
            ? _c("tp-date-picker-multiple", {
                attrs: { data: _vm.data, model: _vm.model }
              })
            : _vm.data.name === "Rate"
            ? _c("tp-rate", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "Switch"
            ? _c("tp-switch", { attrs: { data: _vm.data, model: _vm.model } })
            : _vm.data.name === "InputNumber"
            ? _c("tp-input-number", {
                attrs: { data: _vm.data, model: _vm.model }
              })
            : _vm.data.name === "Link"
            ? _c("tp-link", { attrs: { data: _vm.data } })
            : _vm.data.name === "Divider"
            ? _c("tp-divider", { attrs: { data: _vm.data } })
            : _vm.data.name === "Layout"
            ? _c("tp-layout", {
                attrs: { data: _vm.data, type: _vm.type, model: _vm.model }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }