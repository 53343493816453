<template>
  <div class="img_auot">
    <div span="8" class="left" @mousewheel.prevent="rollImg">
      <div ref="imgDiv" class="img" @mousedown="move">
        <el-image style="width: 100%; height: auto" :src="imgUrl" alt="" />
      </div>
    </div>
    <el-image
      ref="previewImg"
      disabled
      style="width: 100%; height: auto;   display: none;"
      :src="imgUrl"
      alt=""
      :preview-src-list="srcList"
    />
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      srcList: [this.imgUrl],
    };
  },
  watch: {
    imgUrl: {
      // 深度监听，可监听到对象、数组的变化
      handler(newV) {
        // do something, 可使用this
        this.srcList.push(newV);
      },
      deep: true,
    },
  },

  methods: {
    viewBigPicture() {
      this.$refs.previewImg.showViewer = true;
    },
    // 拖动图片
    move(e) {
      e.preventDefault();
      // 获取元素
      var left = document.querySelector('.left');
      var img = document.querySelector('.img');
      var x = e.pageX - img.offsetLeft;
      var y = e.pageY - img.offsetTop;
      // 添加鼠标移动事件
      left.addEventListener('mousemove', move);
      function move(e) {
        img.style.left = e.pageX - x + 'px';
        img.style.top = e.pageY - y + 'px';
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move);
      });
      // 鼠标离开父级元素，把事件移除
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move);
      });
    },
    // 缩放图片
    rollImg() {
      /* 获取当前页面的缩放比 若未设置zoom缩放比，则为默认100%，即1，原图大小 */
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
      /* event.wheelDelta 获取滚轮滚动值并将滚动值叠加给缩放比zoom wheelDelta统一为±120，其中正数表示为向上滚动，负数表示向下滚动 */
      zoom += event.wheelDelta / 12;
      /* 最小范围 和 最大范围 的图片缩放尺度 */
      if (zoom >= 80 && zoom < 500) {
        this.$refs.imgDiv.style.zoom = zoom + '%';
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.img_auot {
  .left {
    position: relative;
    width: 100%;
    height: 300px;
    padding: 10px;
    left: 0;
    right: 0;
    float: left;
    overflow: hidden;
  }
  .img {
    position: absolute;
    margin: 0 auto;
    width: 260px;
    left: 50%;
    transform: translateX(-130px);
    max-width: 1000px;
    top: 20px;
    height: auto;
    cursor: move;
  }
}
</style>
