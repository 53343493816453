export default {
  setlocalFun(key, value) {
    return localStorage.setItem(`ad${key}`, JSON.stringify(value));
  },
  getlocalFun(key) {
    return JSON.parse(localStorage.getItem(`ad${key}`));
  },
  removeLocalFun(key) {
    return localStorage.removeItem(`ad${key}`);
  },
  setSessionFun(key, value) {
    return sessionStorage.setItem(`ad${key}`, value);
  },
  getSessionFun(key) {
    return sessionStorage.getItem(`ad${key}`);
  },
  removeSessionFun(key) {
    return sessionStorage.removeItem(`ad${key}`);
  },
};
