var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patient_list" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "conetent centre" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              staticClass: "back",
              staticStyle: { "font-size": "16px" },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [_vm._v(" 返回 ")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "patient_list_content" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "患者列表", name: "patient" } },
              [_vm.activeName == "patient" ? _c("RecommendPatient") : _vm._e()],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "规则设置", name: "patientRule" } },
              [_vm.activeName == "patientRule" ? _c("PatientRule") : _vm._e()],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }