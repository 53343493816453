var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-select",
        {
          attrs: {
            multiple: _vm.data.multiple,
            placeholder: _vm.data.placeholder
          },
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.remoteData, function(item) {
          return _c("el-option", {
            key: item[_vm.data.setting.remoteDataProps.value],
            attrs: {
              label: item[_vm.data.setting.remoteDataProps.label],
              value: item[_vm.data.setting.remoteDataProps.value]
            }
          })
        }),
        1
      )
    : _vm.data.setting.isRemote && !_vm.model
    ? _c(
        "el-select",
        {
          attrs: {
            multiple: _vm.data.multiple,
            placeholder: _vm.data.placeholder
          },
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.remoteData, function(item) {
          return _c("el-option", {
            key: item[_vm.data.setting.remoteDataProps.value],
            attrs: {
              label: item[_vm.data.setting.remoteDataProps.label],
              value: item[_vm.data.setting.remoteDataProps.value]
            }
          })
        }),
        1
      )
    : !_vm.data.setting.isRemote && _vm.model
    ? _c(
        "el-select",
        {
          attrs: {
            multiple: _vm.data.multiple,
            placeholder: _vm.data.placeholder
          },
          model: {
            value: _vm.model[_vm.data.fieldId],
            callback: function($$v) {
              _vm.$set(_vm.model, _vm.data.fieldId, $$v)
            },
            expression: "model[data.fieldId]"
          }
        },
        _vm._l(_vm.data.selections, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      )
    : _c(
        "el-select",
        {
          attrs: {
            multiple: _vm.data.multiple,
            placeholder: _vm.data.placeholder
          },
          model: {
            value: _vm.data.value,
            callback: function($$v) {
              _vm.$set(_vm.data, "value", $$v)
            },
            expression: "data.value"
          }
        },
        _vm._l(_vm.data.selections, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }