<template>
  <el-drawer size="40%" title="" :visible.sync="visible" direction="rtl" :before-close="handleClose" :show-close="true">
    <div style="padding: 0 30px">
      <el-descriptions title="详情" :column="1" direction="horizontal" border :label-style="labelStyle">
        <el-descriptions-item label="观察类别">
          {{ cdashItem.observationClass }}
        </el-descriptions-item>
        <el-descriptions-item label="域">
          {{ cdashItem.domain }}
        </el-descriptions-item>
        <el-descriptions-item label="数据收集情形">
          {{ cdashItem.dataCollectionScenario }}
        </el-descriptions-item>
        <el-descriptions-item label="实施选项">
          {{ cdashItem.implementationOptions }}
        </el-descriptions-item>
        <el-descriptions-item label="变量">
          {{ cdashItem.cdashigVariable }}
        </el-descriptions-item>
        <el-descriptions-item label="标签">
          {{ cdashItem.cdashigVariableLabel }}
        </el-descriptions-item>
        <el-descriptions-item label="定义">
          {{ cdashItem.draftCdashigDefinition }}
        </el-descriptions-item>
        <el-descriptions-item label="示例">
          {{ cdashItem.caseReportFormCompletionInstructions }}
        </el-descriptions-item>
        <el-descriptions-item label="数据类型">
          {{ cdashItem.dataType }}
        </el-descriptions-item>
        <el-descriptions-item label="核心类型">
          {{ cdashItem.cdashigCore }}
        </el-descriptions-item>
        <el-descriptions-item label="提示">
          {{ cdashItem.prompt }}
        </el-descriptions-item>
        <el-descriptions-item label="实施说明">
          {{ cdashItem.implementationNotes }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'MsCdash',
  components: {},
  filters: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cdashItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelStyle: {
        'text-align': 'right',
        width: '110px',
        visible: false,
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss"></style>
