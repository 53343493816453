var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        title: "创建用户",
        width: "560px",
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: { closed: _vm.handleClosed, open: _vm.handleOpen }
    },
    [
      _vm.selfVis
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "auto",
                model: _vm.form,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "nickname" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11" },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "邮箱" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmitClick }
            },
            [_vm._v(" 提交 ")]
          ),
          _c("el-button", { on: { click: _vm.handleClosed } }, [
            _vm._v(" 取消 ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }