<template>
  <div class="from_item_outline">
    <div class="content_right_title">
      大纲
    </div>
    <div class="content_right_bottom">
      <div class="content_outline">
        <div v-for="(item, index) in caseReportArr" :key="item.itmeCode">
          <div
            :class="activeName.indexOf(item.itemCode) >= 0 ? 'list_label items' : 'list_label'"
            @click="onSideClick(index)"
          >
            <div :class="sideIndex === index ? 'side side-activate' : 'side'" />
            <div style="flex:1;">
              <i class="el-icon-caret-right" @click.stop="onShow(item.itemCode)" /> {{ item.itemLabel }}
            </div>
          </div>
          <el-collapse-transition>
            <div v-show="activeName.indexOf(item.itemCode) >= 0">
              <div v-for="items in item.repotItems" :key="items.itemCode" class="items_label">
                <div
                  :class="
                    activeItemsName == `${index}-${items.itemCode}` && sideIndex === ''
                      ? `items_label_text items_label-activate`
                      : 'items_label_text'
                  "
                  @click="onSideItemsClick(index, items.itemCode)"
                >
                  {{ items.itemLabel }}
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseReportArr: {
      type: Array,
      default: () => [],
    },
    sideIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeName: [],
      activeItemsName: '',
    };
  },
  methods: {
    onSideClick(index) {
      this.activeItemsName = '';
      this.sideIndex = index;
      this.$emit('onSideClick', index);
    },
    onSideItemsClick(index, code) {
      let id = index + '-' + code;
      this.sideIndex = '';
      this.activeItemsName = id;
      console.log(this.activeItemsName);
      this.$emit('onSideItemsClick', { index, id });
    },
    onShow(index) {
      let i = this.activeName.indexOf(index);
      if (i >= 0) {
        this.activeName.splice(i);
      } else {
        this.activeName.push(index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.from_item_outline {
  position: absolute;
  padding-top: 20px;
  top: 0;
  right: 0;
  height: 100%;
  width: 244px;
  z-index: 100;
  background: #fff;
  .content_right_title {
    padding: 0 24px 10px 24px;
    font-size: 16px;

    font-weight: bold;
    line-height: 24px;
    color: #323b46;
  }
  ::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性

    background-color: #f8f8f8;
  }

  ::-webkit-scrollbar {
    //滚动条的宽度

    width: 4px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    //滚动条的设置

    background-color: #dddddd;

    background-clip: padding-box;

    min-height: 28px;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .content_right_bottom {
    height: calc(100% - 50px);
    overflow-y: auto;
    border-left: 2px solid #eeeeee;
  }
  .content_outline {
    .list_label {
      padding: 8px 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        transition: all 0.4s;
        padding: 0 4px;
      }
    }
    .items {
      position: relative;
      i {
        transform: rotate(90deg);
      }
    }
    .side {
      width: 2px;
      background: #1e87ff;
      z-index: 1;
      position: relative;
      left: -4px;
      height: 24px;
      opacity: 0;
    }
    .side-activate {
      opacity: 1;
    }
    .items_label {
      padding: 4px 10px 4px 18px;
      font-size: 14px;

      font-weight: 400;
      color: #323b46;
      line-height: 24px;
      cursor: pointer;
      .items_label_text {
        padding-left: 16px;
        border-left: 2px solid #fff;
      }
      .items_label-activate {
        border-left: 2px solid #1e87ff;
      }
    }
  }
}
</style>
