<template>
  <div class="repot_form">
    <el-form v-if="caseReportArr.length > 0" ref="ruleForm" :model="caseReport">
      <div v-for="(item, index) in caseReportArr" :key="index" class="form_item">
        <div :id="item.itemCode" class="form_item_title">
          <div class="item_label">
            {{ item.itemLabel }}
          </div>
          <!-- <div class="item_delete">
            <i class="el-icon-delete" />
          </div> -->
        </div>
        <div class="form_item_list">
          <el-row :gutter="20" type="flex" style="flex-wrap: wrap; flex-direction: row; width: 100%">
            <el-col v-for="items in item.repotItems" :key="items.itemCode" :span="12">
              <div :id="`${index}-${items.itemCode}`" class="form_items">
                <el-form-item
                  :label="items.itemLabel"
                  :prop="item.itemCode ? `${item.itemCode}.${items.itemCode}` : `${items.itemCode}`"
                  :rules="setRules(items)"
                >
                  <div v-if="!items.fixed" class="items_delete" @click="deleteItem(item.itemCode, items.itemCode)">
                    <i class="el-icon-delete" />
                  </div>
                  <AdWhichComponents
                    v-if="!defaultCancerType.includes(items.itemCode)"
                    :data="items"
                    :model="caseReport"
                    :itemItemsCode="item.itemCode"
                  />
                  <CustomItem
                    v-else
                    :data="items"
                    :model="caseReport"
                    :cancerSubTypes="cancerSubTypes"
                    :provinces="provinces"
                    :citys="citys"
                    :itemItemsCode="item.itemCode"
                    :cancerTypeGroups="cancerTypeGroups"
                    :cancerDisabled="cancerDisabled"
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div v-else>
      <div class="empty">
        <img src="@/assets/wsj.png" alt="1233" />
        <p>暂无数据</p>
        <p>可从左侧添加数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import AdWhichComponents from './components/addWhichComponents';
import CustomItem from './components/customItem';
import cityJson from '@/utils/city.json';
import cancerType from '@/utils/cancerType.json';

// import constant from '@/utils/constant';
import reportFormItems from '@/utils/reportFormItems.js';

export default {
  components: {
    AdWhichComponents,
    CustomItem,
  },
  props: {
    caseReport: {
      type: Object,
      default: () => {},
    },

    cancerDisabled: {
      type: Boolean,
      default: false,
    },
    caseReportArr: {
      type: Array,
      default: () => [],
    },
  },
  provide() {
    return {
      Parent: this,
    };
  },
  data() {
    return {
      defaultCancerType: ['cancerTypeGroupId', 'cancerSubTypeId', 'province', 'city'],
      formItems: reportFormItems,
      provinces: cityJson,
      cancerSubTypes: [],
      citys: [],
      ruleForm: {},
      cancerTypeGroups: [],
    };
  },
  mounted() {
    this.ruleForm = this.caseReport;
    this.cancerTypeGroups = cancerType.data;
    let cancerTypeGroupId = this.caseReport.information.cancerTypeGroupId;
    if (cancerTypeGroupId !== '' && cancerTypeGroupId !== null) {
      const cancerTypeGroup = this.cancerTypeGroups.find((item) => item.cnName === cancerTypeGroupId);
      this.cancerSubTypes = cancerTypeGroup.cancerSubTypes || [];
    }
    if (this.caseReport.information.province) {
      let provinceName = this.caseReport.information.province;
      this.citys = [];
      const province = this.provinces.find((item) => item.name === provinceName);
      if (province.children.length === 1) {
        // 北京，天津没有分区，只有一个
        this.citys.push({
          code: province.children[0].code,
          name: province.name,
        });
      } else {
        province.children.forEach((element) => {
          this.citys.push({
            code: element.code,
            name: element.name,
          });
        });
      }
    }
  },
  methods: {
    submitForm() {
      let pass = false;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          pass = true;

          return true;
        } else {
          this.$message.error('请完善必填项！');
          return false;
        }
      });
      return pass;
    },
    getWidth(items) {
      if (items.span > 1 && !items.spread) {
        return 'width :-webkit-calc(50% - 10px)';
      } else if (items.span > 1 && items.spread) {
        return 'width : 100%';
      }
    },
    cancerTypeGroupSelChange(cancerTypeGroupId) {
      this.caseReport.information.cancerSubTypeId = null;

      const cancerTypeGroup = this.cancerTypeGroups.find((item) => item.cnName === cancerTypeGroupId);
      // const cancerTypeGroup = this.cancerTypeGroups[index];
      this.cancerSubTypes = cancerTypeGroup.cancerSubTypes || [];
    },
    // 省份选择器
    provinceSelChange(provinceName) {
      this.caseReport.information.city = null;
      this.citys = [];
      const province = this.provinces.find((item) => item.name === provinceName);
      if (province.children.length === 1) {
        // 北京，天津没有分区，只有一个
        this.citys.push({
          code: province.children[0].code,
          name: province.name,
        });
      } else {
        province.children.forEach((element) => {
          this.citys.push({
            code: element.code,
            name: element.name,
          });
        });
      }
    },
    setRules(items) {
      let { required, rules } = items.configuration;
      let rulesLsit = [
        {
          required: required,
          message: `请选择${items.itemLabel}`,
          trigger: 'change',
        },
      ];
      if (required && rules && rules.length > 0) {
        rules.forEach((item) => {
          rulesLsit.push(item);
        });
      }
      return rulesLsit;
    },
    deleteItem(code1, code2) {
      console.log(code1, code2);
      this.$emit('deleteCaseReportItem', [code1, code2]);
    },
  },
};
</script>
<style lang="scss" scoped>
.empty {
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #323b46;
  text-align: center;
  margin-top: 360px;
  img {
    width: 136px;
    height: 136px;
  }
}
.repot_form {
  .el-select {
    width: 100%;
  }
  margin-top: 12px;
  .form_item {
    padding: 20px 24px 16px 20px;
    .form_item_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      .item_label {
        flex: 1;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-right: 12px;
        line-height: 24px;
      }
    }
    .form_item_list {
      display: flex;
      flex-wrap: wrap;
      .form_items {
        position: relative;
        width: 100%;
        .el-form-item__label {
          padding-right: 40px;
          text-align: left;
        }
        .items_delete {
          width: 24px;
          height: 24px;
          background: #eaecee;
          border-radius: 4px;
          position: absolute;
          font-size: 14px;
          top: 8px;
          right: 10px;
          text-align: center;
          line-height: 24px;
          translate: 0.2s;
          opacity: 0;
          cursor: pointer;
        }
      }
      .form_items:hover {
        .items_delete {
          opacity: 1;
        }
      }
    }
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
