<template>
  <div class="update_user">
    <div class="upload_user_header">
      <el-steps :space="200" :active="active" align-center>
        <el-step title="安全认证" />
        <el-step v-if="type == 'phone'" title="验证新手机号" />
        <el-step v-else-if=" type == 'email'" title="验证新邮箱" />
        <el-step v-else-if=" type == 'password'" title="更新新密码" />
        <el-step v-else :title="account.webOpenId ? '微信解绑' : '微信绑定' " />
      </el-steps>
    </div>
    <div v-if="activeType == 'verify'">
      <div v-if="verifyType == 'email' ">
        <VerifyEmail :account="account" :verifyType="verifyTypeCode" @submitForm="submitForm" @onVerifyFu="onVerifyFu" />
      </div>
      <div v-else-if="verifyType == 'password' ">
        <VerifyPassword :account="account" :verifyType="verifyTypeCode" @submitForm="submitForm" @onVerifyFu="onVerifyFu" />
      </div>
      <div v-else>
        <Verify :account="account" :verifyType="verifyTypeCode" @submitForm="submitForm" @onVerifyFu="onVerifyFu" />
      </div>
    </div>
    <div v-else>
      <div v-if="type == 'weixin' " class="upload-item">
        <el-button v-if="account.webOpenId" type="primary" @click="onUnbind">
          解绑微信
        </el-button>
        <el-button v-else type="primary" @click="onUpdateWX">
          绑定微信
        </el-button>
      </div>
      <div v-else>
        <Upload v-if="type == 'phone' " :account="account" :token="token" />
        <UploadEmage v-else-if="type == 'email'" :account="account" :token="token" />
        <UploadPassword v-else-if="type == 'password'" :account="account" :token="token" />
      </div>
    </div>
  </div>
</template>

<script>
import Verify from './verify.vue';
import Upload from './upload.vue';
import VerifyEmail from './verifyEmail.vue';
import UploadEmage from './uploadEmail.vue';
import UploadPassword from './uploadPassword.vue';
import VerifyPassword from './verifyPassword.vue';
export default {
  components: {
    Verify,
    Upload,
    VerifyEmail,
    UploadEmage,
    UploadPassword,
    VerifyPassword,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'phone',
    },
  },
  data() {
    return {
      token: '',
      activeType: 'verify',
      verifyType: '',
      verifyTypeCode: '',
      active: 1,
    };
  },
  created() {
    let type = JSON.parse(JSON.stringify(this.type));
    let { phone, email } = this.account;
    if (type === 'phone' && phone) {
      this.verifyType = type;
    } else if (type === 'phone' && email) {
      this.verifyType = 'email';
    } else if (type === 'email' && email) {
      this.verifyType = type;
    } else if (type === 'email' && phone) {
      this.verifyType = 'phone';
    } else if (type === 'password') {
      this.verifyType = type;
    }

    if (this.type === 'phone') {
      this.verifyTypeCode = 'authUpdatePhone';
    } else if (this.type === 'email') {
      this.verifyTypeCode = 'authUpdateEmail';
    } else if (this.type === 'password') {
      this.verifyTypeCode = 'authUpdatePassword';
    }
  },
  methods: {
    submitForm(token) {
      this.token = token;
      this.activeType = 'upload';
      this.active = 2;
    },
    onUnbind() {
      this.$post(this.urls.wx.unbind_account, {
        auid: this.account.auid,
        token: this.token,
      })
        .then(() => {
          this.$message.success('解绑成功');
          setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        })
        .catch(() => {

        });
    },
    onVerifyFu(type) {
      this.verifyType = type;
    },
    onUpdateWX() {
      window.location.href = 'https://cloud.musuikeji.com/api/v1/wx/bind_qr_authorize?token=123456';
    },
  },
};
</script>
<style lang="scss">
.update_user {
  .upload_user_header {
    .el-step__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
.update_user {
    height: 308px;
  .upload_user_header {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .upload-item{
    display: flex;
    margin-top:180px;
    justify-content: center;
  }
}
</style>
