var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "item-box",
    { attrs: { classSuffix: "input-number" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "标签" } },
        [
          _c("el-input", {
            model: {
              value: _vm.data.label,
              callback: function($$v) {
                _vm.$set(_vm.data, "label", $$v)
              },
              expression: "data.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input-number", {
            attrs: { controls: false },
            model: {
              value: _vm.data.value,
              callback: function($$v) {
                _vm.$set(_vm.data, "value", $$v)
              },
              expression: "data.value"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大值" } },
        [
          _c("el-input-number", {
            attrs: { controls: false },
            model: {
              value: _vm.data.setting.max,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "max", $$v)
              },
              expression: "data.setting.max"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最小值" } },
        [
          _c("el-input-number", {
            attrs: { controls: false },
            model: {
              value: _vm.data.setting.min,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "min", $$v)
              },
              expression: "data.setting.min"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "步长" } },
        [
          _c("el-input-number", {
            model: {
              value: _vm.data.setting.step,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "step", $$v)
              },
              expression: "data.setting.step"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "尺寸" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.data.setting.size,
                callback: function($$v) {
                  _vm.$set(_vm.data.setting, "size", $$v)
                },
                expression: "data.setting.size"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [
                _vm._v(" 偏大 ")
              ]),
              _c("el-radio-button", { attrs: { label: "medium" } }, [
                _vm._v(" 中等 ")
              ]),
              _c("el-radio-button", { attrs: { label: "small" } }, [
                _vm._v(" 偏小 ")
              ]),
              _c("el-radio-button", { attrs: { label: "mini" } }, [
                _vm._v(" 迷你 ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "按钮位置" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.data.setting.controlsPosition,
                callback: function($$v) {
                  _vm.$set(_vm.data.setting, "controlsPosition", $$v)
                },
                expression: "data.setting.controlsPosition"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [
                _vm._v(" 默认 ")
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v(" 靠右 ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            attrs: { "active-text": "是", "inactive-text": "否" },
            model: {
              value: _vm.data.setting.disabled,
              callback: function($$v) {
                _vm.$set(_vm.data.setting, "disabled", $$v)
              },
              expression: "data.setting.disabled"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }