import TpInput from './input';
import TpTextarea from './textarea';
import TpPhone from './phone';
import TpPassword from './password';

import TpRadio from './radio';
import TpCheckbox from './checkbox';
import TpSelect from './select';
import TpTimePicker from './time-picker';
import TpTimePickerMultiple from './time-picker-multiple';
import TpDatePicker from './date-picker';
import TpDatePickerMultiple from './date-picker-multiple';

import TpRate from './rate';
import TpSwitch from './switch';
import TpInputNumber from './input-number';

import TpLink from './link';
import TpDivider from './divider';
import TpLayout from './layout';

export default {
  TpInput,
  TpTextarea,
  TpPhone,
  TpPassword,

  TpRadio,
  TpCheckbox,
  TpSelect,
  TpTimePicker,
  TpTimePickerMultiple,
  TpDatePicker,
  TpDatePickerMultiple,

  TpRate,
  TpSwitch,
  TpInputNumber,

  TpLink,
  TpDivider,
  TpLayout,
};
