var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-link",
    {
      attrs: {
        icon: _vm.data.setting.icon,
        type: _vm.data.setting.type,
        disabled: _vm.data.setting.disabled,
        underline: _vm.data.setting.underline,
        href: _vm.data.setting.href,
        target: _vm.data.setting.target
      }
    },
    [_vm._v(" " + _vm._s(_vm.data.setting.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }