var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ad-mf-layout--items" }, [
    _c(
      "div",
      { staticClass: "ad-mf-layout--items--panel" },
      [
        _c(
          "draggable",
          {
            staticClass: "ad-mf-layout--items--panel--list",
            attrs: {
              group: { name: "TpFormGroup", pull: "clone", put: false },
              clone: _vm.clone,
              sort: false,
              animation: "300"
            },
            model: {
              value: _vm.list,
              callback: function($$v) {
                _vm.list = $$v
              },
              expression: "list"
            }
          },
          _vm._l(_vm.list, function(item) {
            return _c(
              "div",
              {
                key: item.name,
                staticClass: "ad-mf-layout--items--panel--list--li"
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }