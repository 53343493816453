<template>
  <div class="upload_password">
    <div class="upload_password_form">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="form-item">
          <el-input
            v-model="ruleForm.oldPassword"
            style="width: 200px;"
            type="password"
            placeholder="请输入旧密码"
          />
          <span
            class="activate_tooltip"
            style="color: #409EFF; margin-left: 12px;"
          >
            其它方式
            <div class="item_tooltip">
              <div v-if="account.email" class="item_tooltip_header" @click="onVerifyFu('email')">
                <img src="@/assets/img/verifyEmail.png" alt="">
                <span>验证邮箱</span>
              </div>
              <div v-if="account.phone" class="item_tooltip_header" @click="onVerifyFu('phone')">
                <img src="@/assets/img/verifyPhone.png" alt="">
                <span>验证手机号</span>
              </div>
              <div class="item_tooltip_text">
                以上方式不可用，可联系管理员 <span> 400-8788-638</span>
              </div>
            </div>
          </span>
        </div>
        <div class="form_bottom">
          <el-button
            type="primary"
            :disabled="ruleForm.oldPassword == ''"
            @click="submitForm('ruleForm')"
          >
            下一步
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      rules: {
        oldPassword: [{ required: true, trigger: 'blur', message: '请输入旧密码' }],
      },
      ruleForm: {
        oldPassword: '',
      },

    };
  },
  methods: {
    onVerifyFu(type) {
      this.$emit('onVerifyFu', type);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(this.urls.account.auth_by_password
            , {
              password: this.ruleForm.oldPassword,
            })
            .then((res) => {
              this.ruleForm.token = res.token;
              this.$message.success('校验成功');
              this.$emit('submitForm', res.token);
            })
            .catch((err) => {
              this.loadingReset = false;
              if (err.code === '20004') {
                this.$message.error('操作超时');
              } else {
                this.$message.error('设置失败，参数错误');
              }
            });
        } else {
          this.$message.error('参数错误');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss">
    @import '@/styles/tooltip.scss';
</style>
<style lang="scss" scoped>
.upload_password_form{
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .upload_password_form-item{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .form_bottom {
    margin-top: 66px;
    display: flex;
    justify-content: center;
  }
  .form-item {
    margin-top: 32px;
    padding: 0 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 40px;
  }
}

</style>
