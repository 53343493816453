<template>
  <div class="patient_list">
    <div class="header">
      <div class="conetent centre">
        <div class="title">
          <img src="@/assets/img/logo.png" />
        </div>
        <el-button type="text" style="font-size: 16px;" class="back" @click="$router.push('/')">
          返回
        </el-button>
      </div>
    </div>
    <div class="patient_list_content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="患者列表" name="patient">
          <RecommendPatient v-if="activeName == 'patient'" />
        </el-tab-pane>
        <el-tab-pane label="规则设置" name="patientRule">
          <PatientRule v-if="activeName == 'patientRule'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import RecommendPatient from './components/recommendPatient.vue';
import PatientRule from './components/patientRule.vue';

export default {
  components: {
    RecommendPatient,
    PatientRule,
  },
  data() {
    return {
      activeName: 'patient',
    };
  },
  methods: {
    handleClick() {},
  },
};
</script>
<style lang="scss" scoped>
.patient_list {
  background: #f6f7f9;
  height: 100%;
  .header {
    background: #fff;
    .centre {
      width: 980px;
      margin: 0 auto;
    }
    .conetent {
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;
      .title {
        color: #303843;
        img {
          height: 40px;
        }
      }
      .back {
        color: #409eff;
      }
    }
  }
  .patient_list_content {
    background: #ffffff;
    border-radius: 4px;
    margin: 24px 24px 24px;
    padding: 20px 24px;
    .list_header {
      padding-bottom: 20px;
      border-bottom: 1px solid #d7dce1;
      font-size: 16px;

      font-weight: bold;
      line-height: 24px;
      color: #323b46;
    }
  }
}
</style>
