var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "recommend_patient",
      attrs: { "element-loading-text": "加载中..." }
    },
    [
      _c(
        "div",
        { staticClass: "recommend_patient_header" },
        _vm._l(_vm.patientPeopleNumber, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "patient_people_number",
              on: {
                click: function($event) {
                  return _vm.onMatchRate(item)
                }
              }
            },
            [
              _c("div", { staticClass: "people_number" }, [
                _vm._v(" " + _vm._s(_vm.peopleNumber[index]) + " ")
              ]),
              _c("div", { staticClass: "people_text" }, [
                _c("span", {
                  class:
                    _vm.matchRate.indexOf(item.matchRate) >= 0
                      ? "" + item.color
                      : item.color + " is-disabled"
                }),
                _vm._v(_vm._s(item.text) + " ")
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "recommend_patient_list" },
        [
          _vm.recommendPatientArr.length > 0
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                _vm._l(_vm.recommendPatientArr, function(item, index) {
                  return _c("el-col", { key: index, attrs: { span: 8 } }, [
                    _vm.getRandomNumber(parseInt(item._studyMatchRate * 100))
                      ? _c("div", { staticClass: "recommend_patient_item" }, [
                          _c("div", { staticClass: "patient_number" }, [
                            _vm._v(
                              " 患者编号：" + _vm._s(item.randomNumber) + " "
                            ),
                            _c(
                              "div",
                              { staticClass: "disease" },
                              [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "info", size: "small" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.information.cancerTypeGroupId
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "disease" },
                              [
                                _c(
                                  "el-tag",
                                  { attrs: { type: "info", size: "small" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.information.cancerSubTypeId
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "patient_item_top" }, [
                            _c("div", { staticClass: "patient_detail" }, [
                              _c(
                                "div",
                                { staticClass: "patient_detail_item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "detail_item_label" },
                                    [_vm._v("姓名")]
                                  ),
                                  _vm._v(
                                    "：" + _vm._s(item.information.name) + " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "patient_detail_item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "detail_item_label" },
                                    [_vm._v("年龄")]
                                  ),
                                  _vm._v(
                                    "：" + _vm._s(item.information.age) + " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "patient_detail_item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "detail_item_label" },
                                    [_vm._v("联系方式")]
                                  ),
                                  _vm._v(
                                    "：" + _vm._s(item.information.phone) + " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "patient_detail_item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "detail_item_label" },
                                    [_vm._v("地区")]
                                  ),
                                  _vm._v(
                                    "：" +
                                      _vm._s(item.information.province) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "patient_detail_item" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "detail_item_label" },
                                    [_vm._v("负责人")]
                                  ),
                                  _vm._v(
                                    "：" + _vm._s(item.dataEntryClerkName) + " "
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "matched-degree",
                                style:
                                  "border: 1px solid " +
                                  _vm.getColor(
                                    parseInt(item._studyMatchRate * 100)
                                  )
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class:
                                      "matched-degree_progress " +
                                      _vm.getColorName(
                                        parseInt(item._studyMatchRate * 100)
                                      ) +
                                      " "
                                  },
                                  [
                                    _c("el-progress", {
                                      attrs: {
                                        type: "circle",
                                        width: 100,
                                        "stroke-width": 6,
                                        percentage: parseInt(
                                          item._studyMatchRate * 100
                                        ),
                                        color: _vm.colors
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "matched-degree_text" },
                                  [_vm._v(" 项目匹配度 ")]
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "patient_item_bottom" }, [
                            _c(
                              "div",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    return _vm.acceptPatient()
                                  }
                                }
                              },
                              [_vm._v(" 同意推荐 ")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                }),
                1
              )
            : _c("div", { staticClass: "emptys" }, [
                _c("img", {
                  attrs: { src: require("@/assets/wsj.png"), alt: "暂无数据" }
                }),
                _c("p", [_vm._v("暂无数据")])
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }