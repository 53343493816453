var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("el-date-picker", {
        attrs: {
          type: _vm.data.setting.type,
          "prefix-icon": _vm.data.setting.prefixIcon,
          format: _vm.data.setting.format,
          placeholder: _vm.data.placeholder
        },
        model: {
          value: _vm.model[_vm.data.fieldId],
          callback: function($$v) {
            _vm.$set(_vm.model, _vm.data.fieldId, $$v)
          },
          expression: "model[data.fieldId]"
        }
      })
    : _c("el-date-picker", {
        attrs: {
          type: _vm.data.setting.type,
          "prefix-icon": _vm.data.setting.prefixIcon,
          format: _vm.data.setting.format,
          placeholder: _vm.data.placeholder
        },
        model: {
          value: _vm.data.value,
          callback: function($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }