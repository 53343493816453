<template>
  <item-box
    v-if="!data.deleted"
    :data="data"
    :isMakeForm="type==='MakeForm'"
    :prop="prop"
    :status="status"
    :showMask="data.name !== 'Layout'"
    :style="{padding:data.name === 'Layout' ? '0!important' : undefined}"
  >
    <tp-input
      v-if="data.name === 'Input'"
      :data="data"
      :model="model"
    />
    <tp-textarea
      v-else-if="data.name === 'Textarea'"
      :data="data"
      :model="model"
    />
    <tp-phone
      v-else-if="data.name === 'Phone'"
      :data="data"
      :model="model"
    />
    <tp-password
      v-else-if="data.name === 'Password'"
      :data="data"
      :model="model"
    />

    <tp-radio
      v-else-if="data.name === 'Radio'"
      :data="data"
      :model="model"
    />
    <tp-checkbox
      v-else-if="data.name === 'Checkbox'"
      :data="data"
      :model="model"
    />
    <tp-select
      v-else-if="data.name === 'Select'"
      :data="data"
      :model="model"
    />
    <tp-time-picker
      v-else-if="data.name === 'TimePicker'"
      :data="data"
      :model="model"
    />
    <tp-time-picker-multiple
      v-else-if="data.name === 'TimePickerMultiple'"
      :data="data"
      :model="model"
    />
    <tp-date-picker
      v-else-if="data.name === 'DatePicker'"
      :data="data"
      :model="model"
    />
    <tp-date-picker-multiple
      v-else-if="data.name === 'DatePickerMultiple'"
      :data="data"
      :model="model"
    />

    <tp-rate
      v-else-if="data.name === 'Rate'"
      :data="data"
      :model="model"
    />
    <tp-switch
      v-else-if="data.name === 'Switch'"
      :data="data"
      :model="model"
    />
    <tp-input-number
      v-else-if="data.name === 'InputNumber'"
      :data="data"
      :model="model"
    />

    <tp-link
      v-else-if="data.name === 'Link'"
      :data="data"
    />
    <tp-divider
      v-else-if="data.name === 'Divider'"
      :data="data"
    />
    <tp-layout
      v-else-if="data.name === 'Layout'"
      :data="data"
      :type="type"
      :model="model"
    />
  </item-box>
</template>

<script>
import AllComponents from './components.js';
import ItemBox from './common/ItemBox';
export default {
  name: 'WhichComponents',
  components: { ...AllComponents, ItemBox },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'MakeForm',
    },
    prop: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: 'pending',
    },
  },
};
</script>
