var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "item-box",
        { attrs: { classSuffix: "tphone", model: _vm.data } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.label,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "label", $$v)
                  },
                  expression: "data.label"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "默认值", prop: "value", rules: _vm.rule } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.value,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "value", $$v)
                  },
                  expression: "data.value"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.placeholder,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "placeholder", $$v)
                  },
                  expression: "data.placeholder"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "必填" } },
            [
              _c("el-switch", {
                attrs: { "active-text": "是", "inactive-text": "否" },
                model: {
                  value: _vm.required,
                  callback: function($$v) {
                    _vm.required = $$v
                  },
                  expression: "required"
                }
              })
            ],
            1
          ),
          _vm.required
            ? _c(
                "el-form-item",
                { attrs: { label: "必填提示" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "必填提示文本" },
                    model: {
                      value: _vm.data.rules.required.message,
                      callback: function($$v) {
                        _vm.$set(_vm.data.rules.required, "message", $$v)
                      },
                      expression: "data.rules.required.message"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("regx-add", {
            model: {
              value: _vm.data.rules,
              callback: function($$v) {
                _vm.$set(_vm.data, "rules", $$v)
              },
              expression: "data.rules"
            }
          })
        ],
        1
      ),
      _c("select-icon", {
        ref: "select_icon",
        on: { "select-icon": _vm.selectIconItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }