<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    // let adaccount = localStorage.getItem('adaccount');
    // if (adaccount) this.$webSocket.initWebSocket('cloud-dev.musuikeji.com/api/v1/data/ws');
  },
};
</script>
