<template>
  <div
    v-if="isForget&&!isUpdate"
    class="login-verify"
  >
    <div class="login-verify-title">
      <h1 class="title">
        忘记密码
      </h1>
      <div
        :class="[{'way-actived':isPhone},'way-type']"
        @click="changeType('phone')"
      >
        手机号找回
      </div>
      <div class="way-tip">
        |
      </div>
      <div
        :class="[{'way-actived':!isPhone},'way-type']"
        @click="changeType('email')"
      >
        邮箱找回
      </div>
    </div>
    <div class="form">
      <!-- 验证码表单 -->
      <el-form
        v-if="!isUpdate&&isPhone"
        ref="verifyForm"
        :model="verifyForm"
        :rules="verifyRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <el-form-item
          class="title-form-item"
          prop="phone"
        >
          <span class="svg-container">
            <svg-icon icon-class="shoujihao" />
          </span>
          <el-input
            ref="phone"
            v-model="verifyForm.phone"
            placeholder="请输入手机号"
            name="phone"
            type="text"
            autocomplete="off"
            maxlength="11"
          />
        </el-form-item>
        <div class="verify-box">
          <el-form-item
            class="password-form-item"
            prop="code"
          >
            <span class="svg-container">
              <svg-icon icon-class="yanzhengma" />
            </span>
            <el-input
              ref="code"
              v-model="verifyForm.code"
              type="text"
              placeholder="请输入验证码"
              name="code"
              autocomplete="off"
              @keyup.enter.native="handleAuth"
            />
          </el-form-item>
          <el-button
            class="show-pwd code-btn"
            :disabled="phoneDisabled"
            @click="handleVerifyCode"
          >
            {{ phoneBtnTxt }}
          </el-button>
        </div>
        <div class="check">
          <span @click="backLogin">返回登录</span>
        </div>
        <el-button
          :loading="loading"
          type="primary"
          class="el-btn"
          @click.native.prevent="handleAuth"
        >
          重置密码
        </el-button>
      </el-form>
      <el-form
        v-if="!isUpdate&&!isPhone"
        ref="verifyForm"
        :model="verifyForm"
        :rules="verifyRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <el-form-item
          class="title-form-item"
          prop="email"
        >
          <span class="svg-container">
            <svg-icon icon-class="youxiang" />
          </span>
          <el-input
            v-model="verifyForm.email"
            placeholder="请输入邮箱"
            name="email"
            type="email"
            autocomplete="off"
          />
        </el-form-item>
        <div class="verify-box">
          <el-form-item
            class="password-form-item"
            prop="code"
          >
            <span class="svg-container">
              <svg-icon icon-class="yanzhengma" />
            </span>
            <el-input
              ref="code"
              v-model="verifyForm.code"
              type="text"
              placeholder="请输入验证码"
              name="code"
              tabindex="2"
              autocomplete="off"
              @keyup.enter.native="handleAuth"
            />
          </el-form-item>
          <el-button
            class="show-pwd code-btn"
            :disabled="emailDisabled"
            @click="handleVerifyCode"
          >
            {{ emailBtnTxt }}
          </el-button>
        </div>
        <div class="check">
          <span @click="backLogin">返回登录</span>
        </div>
        <el-button
          :loading="loading"
          type="primary"
          class="el-btn"
          @click.native.prevent="handleAuth"
        >
          重置密码
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    isForget: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 手机号验证
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error('请输入手机号码'));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error('手机号码格式不正确'));
          }
        }
      }, 100);
    };
    return {
      // isUpdate: false,
      // isForget: false,
      isPhone: true,
      token: '',
      phoneDisabled: false,
      emailDisabled: false,
      phoneBtnTxt: '获取验证码',
      emailBtnTxt: '获取验证码',
      verifyForm: {
        phone: '',
        email: '',
        code: '',
      },
      verifyRules: {
        phone: [{ validator: checkPhone, trigger: ['blur'] }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }],
        code: [{ required: true, message: '请输入验证码', trigger: ['blur'] }],
      },
      // loading: false,
    };
  },
  created() {},
  methods: {
    backLogin() {
      this.$emit('backLogin');
    },
    // 校验验证码
    handleAuth() {
      this.$refs.verifyForm.validate((valid) => {
        if (valid) {
          this.$emit('authClick', this.verifyForm, this.isPhone);
        } else {
          return false;
        }
      });
    },
    // 忘记密码类型改变
    changeType(type) {
      this.isPhone = type === 'phone';
    },
    // 验证手机号、邮箱
    validateRules() {
      if (this.isPhone) {
        if (!this.verifyForm.phone) {
          this.$message.error('手机号码不能为空！');
        } else if (!/^1[3|4|5|6|7|8][0-9]{9}$/.test(this.verifyForm.phone)) {
          this.$message.error('手机号码格式不正确！');
        } else {
          return true;
        }
      } else {
        if (!this.verifyForm.email) {
          this.$message.error('邮箱不能为空！');
        } else if (
          !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            this.verifyForm.email,
          )
        ) {
          this.$message.error('邮箱格式不正确！');
        } else {
          return true;
        }
      }
    },
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.verifyForm.code) {
        this.verifyForm.code = '';
      }
      if (this.isPhone) {
        let time = 59;
        let timer = setInterval(() => {
          if (time === 0) {
            clearInterval(timer);
            this.phoneDisabled = false;
            this.phoneBtnTxt = '获取验证码';
          } else {
            this.phoneBtnTxt = time + '秒后重试';
            this.phoneDisabled = true;
            time--;
          }
        }, 1000);
      } else {
        let emailTime = 59;
        let emailTimer = setInterval(() => {
          if (emailTime === 0) {
            clearInterval(emailTimer);
            this.emailDisabled = false;
            this.emailBtnTxt = '获取验证码';
          } else {
            this.emailBtnTxt = emailTime + '秒后重试';
            this.emailDisabled = true;
            emailTime--;
          }
        }, 1000);
      }
    },
    // 获取验证码按钮
    handleVerifyCode: debounce(function () {
      if (this.validateRules()) {
        let phoneObj = {
          phone: this.verifyForm.phone,
        };
        let emailObj = {
          email: this.verifyForm.email,
        };
        this.$post(this.urls.account.send_code, this.isPhone ? phoneObj : emailObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.sendCodeBtn();
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.login.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_PHONE_NOT_EXIST.text);
                break;
              case this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.text);
                break;
            }
          });
      }
    }, 400),
  },
};
</script>

<style lang="scss" scoped>
$dark_gray: #889aa4;
.svg-container {
  display: inline-block;
  padding-bottom: 6px;
  padding-left: 24px;
  font-size: 18px;
  color: #c7cbda;
  vertical-align: middle;
  .svg-icon {
    width: 1.2em;
    height: 1.2em;
  }
}
.login-verify {
  .login-verify-title {
    display: flex;
    margin: 10px auto 0;
    width: 400px;
    height: 30px;
    align-items: center;
    color: #444;
    .title {
      font-size: 22px;
      font-weight: 500;
      margin: 0 10px 0 0;
    }

    .way-type {
      cursor: pointer;
      color: #8f8f8f;
      font-size: 14px;
    }
    .way-actived {
      color: #63b7ff;
    }
    .way-tip {
      color: #404040;
      margin: 0 5px;
    }
  }
  .form {
    width: 400px;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
    .verify-box {
      display: flex;
      justify-content: space-between;
      .password-form-item {
        width: 60%;
      }
    }
    .check {
      margin-bottom: 30px;
      text-align: right;
      color: #444;
      font-size: 16px;
      font-weight: 400;
      span {
        cursor: pointer;
        color: #63b7ff;
      }
    }
    .show-pwd {
      cursor: pointer;
      font-size: 18px;
      color: $dark_gray;
      user-select: none;
    }
    .code-btn {
      height: 50px;
      color: #63b7ff;
      border: 1px solid #b9bed1;
    }
    .el-btn {
      width: 100%;
      padding: 14px 40px;
      font-size: 18px;
      border-radius: 4px;
    }
  }
}
</style>
