var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("el-input-number", {
        attrs: {
          min: _vm.data.setting.min,
          max: _vm.data.setting.max,
          step: _vm.data.setting.step,
          "step-strictly": _vm.data.setting.stepStrictly,
          size: _vm.data.setting.size,
          disabled: _vm.data.setting.disabled,
          "controls-position": _vm.data.setting.controlsPosition
        },
        model: {
          value: _vm.model[_vm.data.fieldId],
          callback: function($$v) {
            _vm.$set(_vm.model, _vm.data.fieldId, $$v)
          },
          expression: "model[data.fieldId]"
        }
      })
    : _c("el-input-number", {
        attrs: {
          min: _vm.data.setting.min,
          max: _vm.data.setting.max,
          step: _vm.data.setting.step,
          "step-strictly": _vm.data.setting.stepStrictly,
          size: _vm.data.setting.size,
          disabled: _vm.data.setting.disabled,
          "controls-position": _vm.data.setting.controlsPosition
        },
        model: {
          value: _vm.data.value,
          callback: function($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }