var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("el-switch", {
        attrs: {
          width: _vm.data.setting.width,
          "active-icon-class": _vm.data.setting.activeIconClass,
          "inactive-icon-class": _vm.data.setting.inactiveIconClass,
          "active-text": _vm.data.setting.activeText,
          "inactive-text": _vm.data.setting.inactiveText,
          "active-value": _vm.data.setting.activeValue,
          "inactive-value": _vm.data.setting.inactiveValue,
          "active-color": _vm.data.setting.activeColor,
          "inactive-color": _vm.data.setting.inactiveColor,
          disabled: _vm.data.setting.disabled
        },
        model: {
          value: _vm.model[_vm.data.fieldId],
          callback: function($$v) {
            _vm.$set(_vm.model, _vm.data.fieldId, $$v)
          },
          expression: "model[data.fieldId]"
        }
      })
    : _c("el-switch", {
        attrs: {
          width: _vm.data.setting.width,
          "active-icon-class": _vm.data.setting.activeIconClass,
          "inactive-icon-class": _vm.data.setting.inactiveIconClass,
          "active-text": _vm.data.setting.activeText,
          "inactive-text": _vm.data.setting.inactiveText,
          "active-value": _vm.data.setting.activeValue,
          "inactive-value": _vm.data.setting.inactiveValue,
          "active-color": _vm.data.setting.activeColor,
          "inactive-color": _vm.data.setting.inactiveColor,
          disabled: _vm.data.setting.disabled
        },
        model: {
          value: _vm.data.value,
          callback: function($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }