<template>
  <div class="ad-mf-layout--content">
    <tp-form-box
      class="ad-mf-layout--content--form"
      :formData="formData"
      :status="status"
    >
      <draggable
        v-model="formData.formDataList"
        class="ad-mf-layout--content--draggable"
        group="TpFormGroup"
        animation="300"
      >
        <which-component
          v-for="li in formData.formDataList"
          :key="li.fieldId"
          :data="li"
          :status="status"
          @click.native="clickComponent(li)"
        />
      </draggable>
    </tp-form-box>
    <!-- <el-dialog
      title="查看表单"
      :visible.sync="showFormVisible"
      width="600px"
    >
      <tp-form
        v-if="showFormVisible"
        :formData="form"
      />
    </el-dialog> -->
    <!-- <el-dialog
      title="查看JSON"
      :visible.sync="showJSONVisible"
      width="600px"
    >
      <json-viewer
        v-if="showJSONVisible"
        :value="json"
        :expand-depth="2"
      />
    </el-dialog> -->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import TpFormBox from '../componentList/common/FormBox';
import WhichComponent from '../componentList/WhichComponent.vue';
export default {
  name: 'Content',
  components: { draggable, TpFormBox, WhichComponent },
  inject: ['formData'],
  props: {
    // pending, publish
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      json: '{}',
      form: {},
    };
  },
  watch: {
    'formData.formDataList': {
      handler(v) {
        this.$emit('change', v);
      },
      deep: true,
    },
  },
  created() {
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  methods: {
    clickComponent(item) {
      this.formData.formDataList.forEach((f) => {
        if (f.name === 'Layout') {
          f.setting.colList.forEach((c) => {
            if (c) {
              c && (c.makeStyle.active = false);
            }
          });
        }
        if (f === item) f.makeStyle.active = true;
        else f.makeStyle.active = false;
      });
      this.$emit('select-change', item);
    },
    //
    // saveClick() {
    //   this.json = this.$parent.$getFormDataByVueSet();
    //   const formDataList = this.json.formDataList;
    //   this.$emit('acquireJson', this.modifyArrFun(formDataList));
    // },
    // // 锁定发布
    // lockClick() {
    //   this.json = this.$parent.$getFormDataByVueSet();
    //   this.$emit('handleLockClick', this.modifyArrFun(this.json.formDataList));
    // },
    // 对象过滤裁切
    // filterObj(selections, value) {
    //   let pick = [];
    //   selections.forEach((element) => {
    //     if (value !== '') {
    //       let obj = {};
    //       if (element.value === value) {
    //         obj = { ...element, defaultValue: true };
    //       } else {
    //         obj = { ...element, defaultValue: false };
    //       }
    //       pick.push(obj);
    //     } else {
    //       pick.push({ ...element, defaultValue: false });
    //     }
    //   });
    //   return pick;
    // },
    // filterArr(selections, value) {
    //   let pick = [];
    //   selections.forEach((item) => {
    //     if (value.length !== 0) {
    //       let obj = {};
    //       value.forEach((element) => {
    //         if (element === item.value && selections.length === value.length) {
    //           obj = { ...item, defaultValue: true };
    //           pick.push(obj);
    //         } else if (element === item.value) {
    //           obj = { ...item, defaultValue: true };
    //         } else {
    //           obj = { ...item, defaultValue: false };
    //         }
    //       });
    //       if (selections.length !== value.length) {
    //         pick.push(obj);
    //       }
    //     } else {
    //       pick.push({ ...item, defaultValue: false });
    //     }
    //   });
    //   return pick;
    // },
    // modifyArrFun(formDataList) {
    //   let paramArr = [];
    //   if (formDataList.length !== 0) {
    //     formDataList.forEach((element, index) => {
    //       const obj = {
    //         name: '',
    //         label: '',
    //         placeholder: '',
    //         position: 0,
    //         required: false,
    //       };
    //       for (const key in obj) {
    //         obj[key] = element[key];
    //       }
    //       // 针对选择样式的处理
    //       if (
    //         element.name === 'Checkbox' ||
    //         element.name === 'Radio' ||
    //         element.name === 'Select'
    //       ) {
    //         obj.selections = element.selections;
    //         // if (element.name === 'Checkbox') {
    //         //   obj.selections = this.filterArr(element.selections, element.value);
    //         // } else {
    //         //   obj.selections = this.filterObj(element.selections, element.value);
    //         // }
    //       }
    //       // 针对输入框的处理
    //       if (element.name === 'Input' || element.name === 'Textarea') {
    //         obj.length = element.maxlength;
    //       }
    //       // 针对是否勾选
    //       if (element.rules.required) {
    //         obj.required = element.rules.required.required;
    //       } else {
    //         obj.required = false;
    //       }
    //       if (element.itemId) obj.itemId = element.itemId;
    //       // 默认值
    //       obj.defaultValue = element.value;
    //       obj.deleted = element.deleted;
    //       obj.position = index + 1;
    //       paramArr.push(obj);
    //     });
    //   }
    //   return [...paramArr].filter((item) => !(item.itemId === undefined && item.deleted));
    // },
  },
};
</script>
