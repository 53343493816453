<template>
  <div class="patient_rule">
    <div class="patient_rule_list">
      <div class="rule_list">
        <div v-for="(item, index) in ruleItems" :key="index" class="rule_items">
          <div class="rule_from_item">
            <el-select v-model="item.code" @change="changeRuleItem(index)">
              <el-option
                v-for="items in formItems"
                :key="items.cancerSubTypeId"
                :label="items.itemLabel"
                :value="items.itemCode"
              />
            </el-select>
          </div>
          <div v-if="item.code" class="rule_from_item">
            <el-select v-model="item.rule.code" @change="changeRuleCode(item.code, index)">
              <el-option
                v-for="repotItems in formItems[`${gitRepotItemsIndex(item.code)}`].repotItems"
                :key="repotItems.cancerSubTypeId"
                :label="repotItems.itemLabel"
                :value="repotItems.itemCode"
              />
            </el-select>
          </div>
          <div v-if="item.rule.code" class="rule_from_item">
            <el-select v-model="item.rule.operator">
              <el-option
                v-for="itmes in getOperatorList(item)"
                :key="itmes.value"
                :label="itmes.label"
                :value="itmes.value"
              />
            </el-select>
          </div>
          <div v-if="item.rule.operator" class="rule_from_item">
            <OperatorItem :ruleItem="item" />
          </div>
          <div class="rule_from_item">
            <div class="delete_rule" @click="deleteMatchingRule(index)">
              <i class="el-icon-delete-solid" />
            </div>
          </div>
        </div>
      </div>
      <div class="add_rule">
        <span class="list-add-item" @click="addMatchingRule"><i class="el-icon-circle-plus" />添加条件</span>
      </div>
    </div>
    <div class="submit">
      <el-button type="primary" @click="saveCondition()">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import reportFormItems from '@/utils/reportFormItems.js';
import operatorList from '@/utils/operator.json';
import OperatorItem from './operatorItem.vue';
import cancerType from '@/utils/cancerType.json';
import cityJson from '@/utils/city.json';

export default {
  components: {
    OperatorItem,
  },
  provide() {
    return {
      Parent: this,
    };
  },

  data() {
    return {
      cancerType: cancerType.data,
      ruleOperatorList: [],
      ruleItems: [],
      formItems: reportFormItems,
      reportFormItems,
      operatorList,
      cityJson,
      smcId: '',
    };
  },
  created() {
    this.queryCondition();
  },
  methods: {
    queryCondition() {
      this.$post(this.urls.sick_mr.query_condition, {
        studyId: this.$route.query.studyId,
      })
        .then((res) => {
          this.ruleItems = res.ruleItems;
          this.smcId = res.smcId;
        })
        .catch(() => {});
    },
    gitRepotItemsIndex(code) {
      let i = 0;
      this.formItems.map((item, index) => {
        if (item.itemCode === code) {
          i = index;
        }
      });
      return i;
    },
    addMatchingRule() {
      this.ruleItems.push({
        code: '',
        rule: {
          code: '',
          operator: '',
          comparedValue: '',
          endRange: '',
          startRange: '',
          formType: '',
          supplement: '',
        },
      });
    },
    deleteMatchingRule(index) {
      this.ruleItems.splice(index, 1);
    },
    changeRuleCode(code, index) {
      let i = this.gitRepotItemsIndex(code);
      let c = this.ruleItems[index].rule.code;
      this.formItems[i].repotItems.map((item) => {
        let { itemCode, formType } = item;
        if (itemCode === c) {
          this.ruleItems[index].rule.formType = formType;
          this.ruleItems[index].rule.operator = '';
        }
      });
    },
    changeRuleItem(index) {
      this.ruleItems[index].rule = {
        code: '',
        operator: '',
        comparedValue: '',
        endRange: '',
        startRange: '',
        formType: '',
        supplement: '',
      };
    },
    getOperatorList(item) {
      let arr = [];
      let operatorRule = [];
      if (['input', 'textarea'].includes(item.rule.formType) > 0) {
        operatorRule = ['eq', 'ne', 'hv', 'nv', 'ic', 'ni'];
      } else if (['select', 'radio', 'checkbox'].includes(item.rule.formType) > 0) {
        operatorRule = ['hv', 'nv', 'ic', 'ni'];
      } else if (['datePicker', 'timePicker', 'numInput'].includes(item.rule.formType) > 0) {
        operatorRule = ['eq', 'ne', 'gt', 'ge', 'lt', 'le', 'in', 'hv', 'nv'];
      }
      if (operatorRule.length > 0) {
        this.operatorList.map((item) => {
          if (operatorRule.includes(item.value)) {
            arr.push(item);
          }
        });
      } else {
        arr = this.operatorList;
      }
      return arr;
    },
    saveCondition() {
      if (this.ruleItems.length > 0) {
        this.$confirm(` 规则改变会影响推荐患者的人数，是否继续？`, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          showClose: false,
        })
          .then(() => {
            this.$post(this.urls.sick_mr.save_condition, {
              studyId: this.$route.query.studyId,
              ruleItems: this.ruleItems,
              smcId: this.smcId || '',
            })
              .then(() => {
                this.$message.success('保存成功');
              })
              .catch(() => {
                this.$message.error('保存失败');
              });
          })
          .catch(() => {});
      } else {
        this.$message.error('条件不能为空');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient_rule {
  padding-bottom: 32px;
  .patient_rule_list {
    .rule_items {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .rule_from_item {
        margin-right: 24px;
        .delete_rule {
          padding: 0 10px;
          font-size: 20px;
          color: #e3e8ee;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
  .add_rule {
    margin-top: 24px;
    .list-add-item {
      padding: 0;
      font-size: 16px;
      color: rgb(227, 232, 238);
      cursor: pointer;

      i {
        margin-right: 5px;
        font-size: 20px;
        vertical-align: -2px;
      }

      &:hover {
        color: #333;
      }
    }
  }
  .submit {
    margin-top: 42px;
  }
}
</style>
