var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    {
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.data.configuration.allowableValues || [], function(r) {
      return _c("el-radio", { key: r.value, attrs: { label: r.value } }, [
        _vm._v(" " + _vm._s(r.cnValue) + " ")
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }