<template>
  <div>
    <div v-if="data.itemCode === 'cancerTypeGroupId'">
      <el-select
        v-model="model[itemItemsCode][`${data.itemCode}`]"
        :disabled="cancerDisabled"
        @change="cancerTypeGroupSelChange"
      >
        <el-option
          v-for="item in cancerTypeGroups"
          :key="item.cancerSubTypeId"
          :label="item.cnName"
          :value="item.cnName"
        />
      </el-select>
    </div>
    <div v-else-if="data.itemCode === 'cancerSubTypeId'">
      <el-select v-model="model[itemItemsCode][`${data.itemCode}`]" :disabled="cancerDisabled">
        <el-option
          v-for="item in cancerSubTypes"
          :key="item.cancerSubTypeId"
          :label="item.cnName"
          :value="item.cnName"
        />
      </el-select>
    </div>
    <div v-else-if="data.itemCode === 'province'">
      <el-select v-model="model[itemItemsCode][`${data.itemCode}`]" @change="provinceSelChange">
        <el-option v-for="item in provinces" :key="item.code" :label="item.name" :value="item.name" />
      </el-select>
    </div>
    <div v-else-if="data.itemCode === 'city'">
      <el-select v-model="model[itemItemsCode][`${data.itemCode}`]">
        <el-option v-for="item in citys" :key="item.code" :label="item.name" :value="item.name" />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      required: true,
    },
    itemItemsCode: {
      type: String,
      default: '',
    },
    cancerSubTypes: {
      type: Array,
      default: () => [],
    },
    cancerTypeGroups: {
      type: Array,
      default: () => [],
    },
    provinces: {
      type: Array,
      default: () => [],
    },
    citys: {
      type: Array,
      default: () => [],
    },
    cancerDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  inject: ['Parent'],

  methods: {
    cancerTypeGroupSelChange(cancerTypeGroupId) {
      this.Parent.cancerTypeGroupSelChange(cancerTypeGroupId);
    },
    provinceSelChange(provinceName) {
      this.Parent.provinceSelChange(provinceName);
    },
  },
};
</script>

<style></style>
