var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "from_item_outline" }, [
    _c("div", { staticClass: "content_right_title" }, [_vm._v(" 大纲 ")]),
    _c("div", { staticClass: "content_right_bottom" }, [
      _c(
        "div",
        { staticClass: "content_outline" },
        _vm._l(_vm.caseReportArr, function(item, index) {
          return _c(
            "div",
            { key: item.itmeCode },
            [
              _c(
                "div",
                {
                  class:
                    _vm.activeName.indexOf(item.itemCode) >= 0
                      ? "list_label items"
                      : "list_label",
                  on: {
                    click: function($event) {
                      return _vm.onSideClick(index)
                    }
                  }
                },
                [
                  _c("div", {
                    class:
                      _vm.sideIndex === index ? "side side-activate" : "side"
                  }),
                  _c("div", { staticStyle: { flex: "1" } }, [
                    _c("i", {
                      staticClass: "el-icon-caret-right",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onShow(item.itemCode)
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(item.itemLabel) + " ")
                  ])
                ]
              ),
              _c("el-collapse-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName.indexOf(item.itemCode) >= 0,
                        expression: "activeName.indexOf(item.itemCode) >= 0"
                      }
                    ]
                  },
                  _vm._l(item.repotItems, function(items) {
                    return _c(
                      "div",
                      { key: items.itemCode, staticClass: "items_label" },
                      [
                        _c(
                          "div",
                          {
                            class:
                              _vm.activeItemsName ==
                                index + "-" + items.itemCode &&
                              _vm.sideIndex === ""
                                ? "items_label_text items_label-activate"
                                : "items_label_text",
                            on: {
                              click: function($event) {
                                return _vm.onSideItemsClick(
                                  index,
                                  items.itemCode
                                )
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(items.itemLabel) + " ")]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }