<template>
  <div>
    <el-input
      v-if="itemItemsCode"
      v-model="model[itemItemsCode][`${data.itemCode}`]"
      :placeholder="`请输入${data.itemLabel}`"
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 4 }"
      :rows="2"
    />
    <el-input
      v-else
      v-model="model[`${data.itemCode}`]"
      :placeholder="`请输入${data.itemLabel}`"
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 4 }"
      :rows="2"
    />
  </div>
</template>

<script>
export default {
  name: "AdTextarea",
  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      default: () => {}
    },
    itemItemsCode: {
      type: String,
      default: ""
    }
  }
};
</script>
