<template>
  <div class="experiment-add-container">
    <adCard
      title="name"
      class="experiment-add-wrap"
    >
      <template #container>
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="基础信息"
            name="info"
          >
            <Ad-info :studyId="studyId" />
          </el-tab-pane>
          <el-tab-pane
            label="入组标准"
            name="succeed"
          >
            <Ad-standard :studyId="studyId" />
          </el-tab-pane>
          <el-tab-pane
            label="排除标准"
            name="defeated"
          >
            排除标准
          </el-tab-pane>
          <el-tab-pane
            label="系统配置"
            name="configuration"
          >
            系统配置
          </el-tab-pane>
          <el-tab-pane
            label="随机方案"
            name="scheme"
          >
            随机方案
          </el-tab-pane>
        </el-tabs>
      </template>
    </adCard>
  </div>
</template>

<script>
import adCard from '@c/Card';
import info from './components/info';
import standard from './components/standard';
export default {
  name: '',
  components: {
    adCard,
    'Ad-info': info,
    'Ad-standard': standard,
  },
  filters: {},
  props: {},
  data() {
    return {
      activeName: 'info',
      studyId: '',
    };
  },
  computed: {},
  watch: {},
  created() {
    this.studyId = this.$route.query.studyId;
    this.gerExperiment(this.studyId);
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    gerExperiment(id) {
      console.log(id, 1);
      this.$post(this.urls.study.get, {
        studyId: id,
      })
        .then((result) => {
          this.tableLoading = false;
          this.tableData = result.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang='scss' scoped>
.experiment-add-container {
  padding-top: 20px;
  .experiment-add-wrap {
    width: 1200px;
    margin: 0 auto;
    .wrap_steps {
      width: 860px;
      margin: 0 auto;
    }
    .wrap—content {
      width: 1080px;
      margin: 20px auto;
    }
  }
}
</style>
