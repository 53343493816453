import axios from 'axios';
// import router from '@/router';
import message from '@u/reset-message';
import store from '@/store';
import { MessageBox } from 'element-ui';
// 记录请求的状态码
let messageBox = '';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
});
// 请求前
service.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken();
    // }
    config.baseURL = config.isUse ? ` ${config.isUse}${process.env.VUE_APP_BASE_API}` : process.env.VUE_APP_BASE_API;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const { code, note } = response.data;
      if (code === '10000') {
        return Promise.resolve(response);
      } else if (['20004', '10010'].includes(code)) {
        // 当一个页面调用多个接口的时候 这个地方就会出现多次messageBox
        if (messageBox) MessageBox.close();
        messageBox = MessageBox.alert('登录过期，请重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          showClose: false,
          type: 'warning',
        }).then(() => {
          messageBox = null;
          store.dispatch('user/resetToken').then(() => {
            window.location.reload();
          });
        });
      } else if (['10500', '10002'].includes(code)) {
        message.error(note);
      }
      return Promise.reject(response);
    }
    return Promise.reject(response);
  },
  (error) => {
    // 在本地调试的时候新增判断只能本地使用
    if (error.response.status) {
      if (process.env.NODE_ENV === 'development') {
        message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000,
        });
      }
      return Promise.reject(error.response);
    }
  },
);
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function post(url, params, isUse = '') {
  return new Promise((resolve, reject) => {
    service
      .post(url, params, { isUse })
      .then((res) => {
        if (res.data.code === '10000') {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        if (err.data.code === '20004') {
          return;
        } else {
          reject(err.data);
        }
      });
  });
}
export function exlPost(url, params) {
  return new Promise((resolve) => {
    service
      .get(url, params, {
        emulateJSON: false,
        responseType: 'blob', // 响应数据格式blob
      })
      .then(() => {})
      .catch((err) => {
        // 有点反人类的写法
        resolve(err);
      });
  });
}

export function downloadFile(url, params, fileName) {
  axios({
    method: 'GET',
    url: url,
    responseType: 'blob', // 一定要设置响应类型，否则不能正确处理响应的数据
    data: params,
  }).then(function(res) {
    let blob = new Blob([res.data], {});
    let downloadElement = document.createElement('a');
    let href = window.URL.createObjectURL(blob); // 创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName; // 下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); // 点击下载
    document.body.removeChild(downloadElement); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放blob对象
  });
}
