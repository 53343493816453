var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "item-box",
        { attrs: { classSuffix: "divider" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "内容" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.data.setting.content,
                  callback: function($$v) {
                    _vm.$set(_vm.data.setting, "content", $$v)
                  },
                  expression: "data.setting.content"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标" } },
            [
              _c(
                "el-input",
                {
                  model: {
                    value: _vm.data.setting.icon,
                    callback: function($$v) {
                      _vm.$set(_vm.data.setting, "icon", $$v)
                    },
                    expression: "data.setting.icon"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append" },
                      on: {
                        click: function($event) {
                          return _vm.openSelectIcon("icon")
                        }
                      },
                      slot: "append"
                    },
                    [_vm._v(" 选择图标 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文案位置" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.data.setting.contentPosition,
                    callback: function($$v) {
                      _vm.$set(_vm.data.setting, "contentPosition", $$v)
                    },
                    expression: "data.setting.contentPosition"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "left" } }, [
                    _vm._v(" 居左 ")
                  ]),
                  _c("el-radio-button", { attrs: { label: "center" } }, [
                    _vm._v(" 居中 ")
                  ]),
                  _c("el-radio-button", { attrs: { label: "right" } }, [
                    _vm._v(" 居右 ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "方向" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.data.setting.direction,
                    callback: function($$v) {
                      _vm.$set(_vm.data.setting, "direction", $$v)
                    },
                    expression: "data.setting.direction"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "horizontal" } }, [
                    _vm._v(" 水平 ")
                  ]),
                  _c("el-radio-button", { attrs: { label: "vertical" } }, [
                    _vm._v(" 垂直 ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("select-icon", {
        ref: "select_icon",
        on: { "select-icon": _vm.selectIconItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }