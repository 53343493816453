var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-crop" }, [
    _vm.show
      ? _c(
          "div",
          [
            _c("my-upload", {
              attrs: {
                modelValue: _vm.show,
                langExt: { hint: _vm.langText },
                width: _vm.width,
                height: _vm.height,
                "img-format": "jpg/png",
                maxSize: _vm.size,
                params: _vm.uploadData,
                field: "file",
                langType: "zh",
                noRotate: false,
                url: "/api/v1/" + _vm.urls.cloud_file.single_upload
              },
              on: {
                "update:modelValue": function($event) {
                  _vm.show = $event
                },
                "update:model-value": function($event) {
                  _vm.show = $event
                },
                "crop-success": _vm.cropSuccess,
                "crop-upload-success": _vm.cropUploadSuccess,
                "crop-upload-fail": _vm.cropUploadFail
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }