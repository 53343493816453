<template>
  <!-- {{ data[`${data.itemId}_${data.itemName}`] }} -->
  <div>
    <el-input
      v-if="itemItemsCode"
      v-model.trim="model[itemItemsCode][`${data.itemCode}`]"
      :placeholder="`请输入${data.itemLabel}`"
      oninput="value=value.replace(/[^0-9.]/g,'')"
    />
  </div>
</template>

<script>
export default {
  name: 'AdInput',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
    itemItemsCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
};
</script>
