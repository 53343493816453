import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
Vue.use(Vuex);
const file = require.context('./modules', false, /.js$/);
let modules = {};
file.keys().forEach((key) => {
  // 还可以使用 const moduleName = key.replace(/^\.\/(.*)\.\w+$/, '$1')
  const fileName = key.slice(2, -3); // 文件名
  modules[fileName] = file(key).default;
});
let store = new Vuex.Store({
  modules,
  getters,
});
export default store;
