var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload_user" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "status-icon": "",
              "label-width": "100px"
            }
          },
          [
            _c("div", { staticClass: "form-item" }, [
              _c("span", [
                _vm._v(
                  " 使用手机 " + _vm._s(_vm.geTel(_vm.account.phone)) + " 验证 "
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "activate_tooltip",
                  staticStyle: { color: "#409EFF", "margin-left": "12px" }
                },
                [
                  _vm._v(" 手机号不可用？ "),
                  _c("div", { staticClass: "item_tooltip" }, [
                    _vm.verifyType == "authUpdatePassword"
                      ? _c(
                          "div",
                          {
                            staticClass: "item_tooltip_header",
                            on: {
                              click: function($event) {
                                return _vm.onVerifyFu("password")
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/verifyPassword.png"),
                                alt: ""
                              }
                            }),
                            _c("span", [_vm._v("验证密码")])
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "item_tooltip_header",
                        on: {
                          click: function($event) {
                            return _vm.onVerifyFu("email")
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/verifyEmail.png"),
                            alt: ""
                          }
                        }),
                        _c("span", [_vm._v("验证邮箱")])
                      ]
                    ),
                    _c("div", { staticClass: "item_tooltip_text" }, [
                      _vm._v("以上方式不可用，可联系管理员 "),
                      _c("span", [_vm._v(" 400-8788-638")])
                    ])
                  ])
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "290px" },
                    attrs: { placeholder: "请输入验证码" },
                    model: {
                      value: _vm.ruleForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "code", $$v)
                      },
                      expression: "ruleForm.code"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "code-btn",
                        attrs: { slot: "append", disabled: _vm.phoneDisabled },
                        on: { click: _vm.handleVerifyCode },
                        slot: "append"
                      },
                      [_vm._v(" " + _vm._s(_vm.phoneBtnTxt) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form_bottom" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.ruleForm.code == ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v(" 下一步 ")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }