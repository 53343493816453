var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "item-box",
    { attrs: { classSuffix: "checkbox" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "标签" } },
        [
          _c("el-input", {
            model: {
              value: _vm.data.label,
              callback: function($$v) {
                _vm.$set(_vm.data, "label", $$v)
              },
              expression: "data.label"
            }
          })
        ],
        1
      ),
      !_vm.data.setting.isRemote
        ? _c(
            "el-form-item",
            { attrs: { label: "选项" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.data.value,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "value", $$v)
                    },
                    expression: "data.value"
                  }
                },
                _vm._l(_vm.data.selections, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.value,
                      staticClass: "ad-mf-setting-checkbox--checkbox"
                    },
                    [
                      item.value !== _vm.editCheckbox
                        ? [
                            _c(
                              "el-checkbox",
                              { attrs: { label: item.value } },
                              [
                                _c("span", {
                                  domProps: { textContent: _vm._s(item.label) }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ad-mf-setting-checkbox--checkbox--edit"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.editCheckboxSelect(item)
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ad-mf-setting-checkbox--checkbox--delete"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.delCheckbox(item)
                                    }
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.data.value.includes(item.value),
                                    expression:
                                      "data.value.includes(item.value)"
                                  }
                                ],
                                staticClass:
                                  "ad-mf-setting-checkbox--checkbox--default-text"
                              },
                              [_vm._v("默认选择")]
                            )
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "ad-mf-setting-checkbox--edit" },
                              [
                                _c(
                                  "el-form",
                                  {
                                    staticClass:
                                      "ad-mf-setting-checkbox--edit--form",
                                    attrs: {
                                      inline: true,
                                      model: _vm.editForm,
                                      rules: _vm.rules
                                    }
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "label" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入修改的名称",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.editForm.label,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editForm,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression: "editForm.label"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-check",
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editSaveClick(item)
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "info",
                                    icon: "el-icon-close",
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.editCheckbox = ""
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                }),
                0
              ),
              _vm.inputing
                ? _c(
                    "div",
                    { staticClass: "ad-mf-setting-checkbox--add" },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "ad-mf-setting-checkbox--add--form",
                          attrs: {
                            inline: true,
                            model: _vm.addForm,
                            rules: _vm.rules
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "label" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入标签"
                                },
                                model: {
                                  value: _vm.addForm.label,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm, "label", $$v)
                                  },
                                  expression: "addForm.label"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-check",
                          size: "mini"
                        },
                        on: { click: _vm.add }
                      }),
                      _c("el-button", {
                        attrs: {
                          type: "info",
                          icon: "el-icon-close",
                          size: "mini"
                        },
                        on: {
                          click: function($event) {
                            _vm.inputing = !_vm.inputing
                          }
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-plus",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          _vm.inputing = !_vm.inputing
                          _vm.addForm.label = ""
                          _vm.addForm.value = ""
                        }
                      }
                    },
                    [_vm._v(" 添加一项 ")]
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            attrs: { "active-text": "是", "inactive-text": "否" },
            model: {
              value: _vm.required,
              callback: function($$v) {
                _vm.required = $$v
              },
              expression: "required"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }