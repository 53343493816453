<template>
  <div class="upload_user">
    <div>
      <el-form ref="ruleForm" :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
        <div class="form-item">
          <span> 使用手机 {{ geTel(account.phone) }} 验证 </span>
          <span class="activate_tooltip" style="color: #409EFF; margin-left: 12px;">
            手机号不可用？
            <div class="item_tooltip">
              <div
                v-if="verifyType == 'authUpdatePassword'"
                class="item_tooltip_header"
                @click="onVerifyFu('password')"
              >
                <img src="@/assets/img/verifyPassword.png" alt="" />
                <span>验证密码</span>
              </div>
              <div class="item_tooltip_header" @click="onVerifyFu('email')">
                <img src="@/assets/img/verifyEmail.png" alt="" />
                <span>验证邮箱</span>
              </div>
              <div class="item_tooltip_text">以上方式不可用，可联系管理员 <span> 400-8788-638</span></div>
            </div>
          </span>
        </div>
        <div class="form-item">
          <el-input v-model="ruleForm.code" style="width: 290px;" placeholder="请输入验证码">
            <el-button slot="append" class="code-btn" :disabled="phoneDisabled" @click="handleVerifyCode">
              {{ phoneBtnTxt }}
            </el-button>
          </el-input>
        </div>

        <div class="form_bottom">
          <el-button type="primary" :disabled="ruleForm.code == ''" @click="submitForm('ruleForm')">
            下一步
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    verifyType: {
      type: String,
      default: 'authUpdatePhone',
    },
  },
  data() {
    return {
      ruleForm: {
        phone: '',
        code: '',
        token: '',
      },

      active: 1,
      phoneDisabled: false,
      activateInterval: false,
      phoneBtnTxt: '获取验证码',
      phoneType: 'authUpdatePhone',
    };
  },
  created() {
    this.phoneType = this.verifyType;
  },
  methods: {
    submitForm() {
      if (this.ruleForm.code) {
        let { phone, code } = this.ruleForm;
        let phoneObj = {
          phone,
          code,
        };
        this.$post(this.urls.account.auth_by_phone, phoneObj)
          .then((res) => {
            this.ruleForm.token = res.token;
            this.$message.success('校验成功');
            this.phoneType = 'verifyNewPhone';
            this.activateInterval = true;
            this.$emit('submitForm', res.token);
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.code:
                this.$message.error(this.codes.authByPhone.VERIFICATION_CODE_EXPIRED.text);
                break;
              case this.codes.authByPhone.VERIFICATION_CODE_ERROR.code:
                this.$message.error(this.codes.authByPhone.VERIFICATION_CODE_ERROR.text);
                break;
              case this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.code:
                this.$message.error(this.codes.authByPhone.ACCOUNT_PHONE_NOT_EXIST.text);
                break;
            }
            this.$message.error('验证码有误');
          });
      } else {
        this.$message.error('验证码有误');
      }
    },
    // 验证手机号、邮箱
    validateRules() {
      if (!this.ruleForm.phone) {
        this.$message.error('手机号码不能为空！');
      } else if (!/^1[3|4|5|6|7|8][0-9]{9}$/.test(this.ruleForm.phone)) {
        this.$message.error('手机号码格式不正确！');
      } else {
        return true;
      }
      return false;
    },
    // 获取验证码按钮
    handleVerifyCode: debounce(function() {
      this.ruleForm.phone = this.account.phone;

      if (this.validateRules()) {
        let type = this.phoneType;
        let phoneObj = {
          phone: this.ruleForm.phone,
          type,
        };
        this.$post(this.urls.account.send_phone_code, phoneObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.activateInterval = false; //
            this.sendCodeBtn();
          })
          .catch(() => {
            this.$message.error('参数有误');
          });
      }
    }, 400),
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.ruleForm.code) {
        this.ruleForm.code = '';
      }
      let time = 59;
      let timer = setInterval(() => {
        if (time === 0 || this.activateInterval) {
          clearInterval(timer);
          this.phoneDisabled = false;
          this.phoneBtnTxt = '获取验证码';
        } else {
          this.phoneBtnTxt = time + '秒后重试';
          this.phoneDisabled = true;
          time--;
        }
      }, 1000);
    },
    geTel(tel) {
      return tel.substring(0, 3) + '****' + tel.substr(tel.length - 4);
    },
    onVerifyFu(type) {
      this.$emit('onVerifyFu', type);
    },
  },
};
</script>
<style lang="scss">
.phone {
  .phone_header {
    .el-step__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
@import '@/styles/tooltip.scss';
</style>
<style lang="scss" scoped>
.upload_user {
  .upload_user_header {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .form-item {
    margin-top: 32px;
    padding: 0 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 40px;

    .code-btn {
      background: #409eff;
      border-radius: 0;
      font-size: 14px;

      color: #ffffff;
    }
  }
  .form_bottom {
    margin-top: 66px;
    display: flex;
    justify-content: center;
  }
}
</style>
