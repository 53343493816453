var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad-mf-setting-textarea" },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-position": "right",
            "label-width": "85px",
            size: "small",
            model: _vm.model
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }