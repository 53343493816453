<template>
  <item-box classSuffix="radio">
    <el-form-item label="标签">
      <el-input
        v-model="data.label"
        clearable
      />
    </el-form-item>
    <!-- <el-form-item label="远程数据">
      <el-switch
        v-model="data.setting.isRemote"
        active-text="是"
        inactive-text="否"
      />
    </el-form-item> -->
    <el-form-item
      v-if="!data.setting.isRemote"
      label="选项"
    >
      <div
        v-for="item in data.selections"
        :key="item.value"
        class="ad-mf-setting-radio--radio"
      >
        <template v-if="item.value !== editRadio">
          <el-radio
            v-model="data.value"
            :label="item.value"
          >
            <span v-text="item.label" />
          </el-radio>
          <span class="ad-mf-setting-radio--radio--edit">
            <i
              class="el-icon-edit-outline"
              @click.stop="editRadioClick(item)"
            /></span>
          <span class="ad-mf-setting-radio--radio--delete">
            <i
              class="el-icon-delete"
              @click.stop="delRadio(item)"
            /></span>
          <span
            v-show="item.value === data.value"
            class="ad-mf-setting-radio--radio--default-text"
          >默认选择</span>
        </template>
        <template v-else>
          <div class="ad-mf-setting-radio--edit">
            <el-form
              :inline="true"
              :model="editForm"
              class="ad-mf-setting-radio--edit--form"
              :rules="rules"
            >
              <el-form-item prop="label">
                <el-input
                  v-model="editForm.label"
                  size="mini"
                  placeholder="请输入修改的名称"
                  clearable
                />
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              icon="el-icon-check"
              size="mini"
              @click="editSaveClick(item)"
            />
            <!-- @click="add" -->
            <el-button
              type="info"
              icon="el-icon-close"
              size="mini"
              @click="editRadio=''"
            />
          </div>
        </template>
      </div>
      <!-- 添加项的输入框 -->
      <div
        v-if="inputing"
        class="ad-mf-setting-radio--add"
      >
        <el-form
          :inline="true"
          :model="addForm"
          class="ad-mf-setting-radio--add--form"
          :rules="rules"
        >
          <el-form-item prop="label">
            <el-input
              v-model="addForm.label"
              size="mini"
              placeholder="请输入标签"
            />
          </el-form-item>
          <!-- <el-form-item prop="value">
            <el-input
              v-model="addForm.value"
              size="mini"
              placeholder="请输入值"
            />
          </el-form-item> -->
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-check"
          size="mini"
          @click="add"
        />
        <el-button
          type="info"
          icon="el-icon-close"
          size="mini"
          @click="inputing=!inputing"
        />
      </div>
      <el-button
        v-else
        type="text"
        icon="el-icon-plus"
        size="mini"
        @click="inputing=!inputing;addForm.label='';addForm.value=''"
      >
        添加一项
      </el-button>
    </el-form-item>
    <!-- <template v-else>
      <el-form-item label="请求地址">
        <el-input
          v-model="data.setting.remoteUrl"
          placeholder="请输入远程地址"
          @blur="getRemoteData"
        />
      </el-form-item>
      <el-form-item label="数据路径">
        <el-input
          v-model="data.setting.remoteDataPath"
          placeholder="请输入远程获取路径"
        >
          <template slot="prepend">
            data.
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="Label">
        <el-input
          v-model="data.setting.remoteDataProps.label"
          placeholder="远程数据中的label"
        />
      </el-form-item>
      <el-form-item label="Value">
        <el-input
          v-model="data.setting.remoteDataProps.value"
          placeholder="远程数据中的value"
        />
      </el-form-item>
      <el-form-item label="远程数据">
        <div v-if="!remoteData || remoteData.length === 0">
          暂无数据
        </div>
        <div
          v-for="item in remoteData"
          :key="item[data.setting.remoteDataProps.value]"
          class="ad-mf-setting-radio--radio"
        >
          <el-radio
            v-model="data.value"
            :label="item[data.setting.remoteDataProps.value]"
          >
            <span v-text="item[data.setting.remoteDataProps.label]" />
          </el-radio>
          <span
            v-show="item[data.setting.remoteDataProps.value] === data.value"
            class="ad-mf-setting-radio--radio--default-text"
          >默认选择</span>
        </div>
      </el-form-item>
    </template> -->
    <el-form-item label="必填">
      <el-switch
        v-model="required"
        active-text="是"
        inactive-text="否"
      />
    </el-form-item>
  </item-box>
</template>

<script>
import _ from 'lodash';
import ItemBox from '../common/ItemBox';
import Mixins from '../common/mixins';
// import RemoteMixin from '../../../utils/remote-mixin';
export default {
  name: 'RadioConfig',
  components: { ItemBox },
  mixins: [Mixins],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputing: false,
      addForm: {
        label: '',
        // value: '',
      },
      rules: {
        label: [{ required: true, message: '请输入标签', trigger: 'blur' }],
        // value: [{ required: true, message: '请输入值', trigger: 'blur' }],
      },
      editForm: {
        label: '',
      },
      editRadio: '',
    };
  },
  watch: {
    data(v) {
      this.required = v.rules.required ? v.rules.required.required : false;
    },
  },
  methods: {
    delRadio(item) {
      const i = this.data.selections.findIndex((s) => s === item);
      this.data.selections.splice(i, 1);
      if (item.value === this.data.value) {
        this.data.value = '';
      }
    },
    editRadioClick(item) {
      this.editRadio = item.value;
      this.editForm.label = item.label;
    },
    editSaveClick(item) {
      if (_.trim(this.editForm.label) !== '') {
        item.label = this.editForm.label;
        this.editRadio = '';
        this.editForm.label = '';
      }
    },
    add() {
      // && _.trim(this.addForm.value) !== ''
      if (_.trim(this.addForm.label) !== '') {
        const addFormValue =
          this.data.selections.length !== 0
            ? Number(this.data.selections[this.data.selections.length - 1].value) + 1
            : 0;
        this.addForm.value = addFormValue.toString();
        this.data.selections.push({ ...this.addForm });
        this.inputing = !this.inputing;
      }
    },
  },
};
</script>
