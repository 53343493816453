<template>
  <el-dialog
    center
    title="创建用户"
    width="560px"
    :visible="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="handleClosed"
    @open="handleOpen"
  >
    <el-form v-if="selfVis" ref="form" label-position="right" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="姓名" prop="nickname">
        <el-input v-model="form.nickname" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" />
      </el-form-item>
      <el-form-item prop="email" label="邮箱">
        <el-input v-model="form.email" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmitClick">
        提交
      </el-button>
      <el-button @click="handleClosed">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UserDialogCreate',
  components: {},
  filters: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 手机号验证
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!value) {
        return callback(new Error('电话号码不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'));
        } else if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error('电话号码格式不正确'));
        }
      }, 100);
    };
    return {
      checkPhone,
      form: {},
      selfVis: false,
    };
  },
  computed: {
    rules() {
      return {
        nickname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, validator: this.checkPhone, trigger: 'blur' }],
        email: [
          {
            required: true,
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur'],
          },
        ],
      };
    },
  },
  watch: {
    // formData: {
    //   handler(form) {
    //     if (form) this.form = _.cloneDeep(form);
    //     else this.form = this.formInit();
    //   },
    //   immediate: true,
    // },
  },
  created() {},
  mounted() {},
  methods: {
    handleSubmitClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$post(this.urls.account.create, this.form)
            .then((res) => {
              const { pwd, auid } = res;
              const text = pwd
                ? `用户创建成功，初始密码为：${pwd}`
                : `用户创建成功，初始密码将通过短信方式发送到用户手机上。`;
              this.$alert(text, '操作成功', {}).then(() => {
                this.$emit('success');
              });
            })
            .catch((err) => {
              switch (err.code) {
                case this.codes.account.ACCOUNT_JOINED.code:
                  this.$message.error(this.codes.account.ACCOUNT_JOINED.text);
                  break;
                case this.codes.account.ACCOUNT_PHONE_FORMAT_ERROR.code:
                  this.$message.error(this.codes.account.ACCOUNT_PHONE_FORMAT_ERROR.text);
                  break;
                case this.codes.account.ACCOUNT_PHONE_REPEAT.code:
                  this.$message.error(this.codes.account.ACCOUNT_PHONE_REPEAT.text);
                  break;
                case this.codes.account.ACCOUNT_EMAil_REPEAT.code:
                  this.$message.error(this.codes.account.ACCOUNT_EMAil_REPEAT.text);
                  break;
                default:
                  break;
              }
            });
        } else {
          return false;
        }
      });
    },
    handleOpen() {
      this.form = this.formInit();
      //   this.$refs.form.resetFields();
      this.selfVis = true;
    },
    handleClosed() {
      this.$emit('update:visible', false);
      this.form = this.formInit();
      //   this.form = this.formInit();
      //   this.$refs.form.resetFields();
    },
    formInit() {
      return {
        nickname: '吴永健',
        phone: '13790759822',
        email: 'wuyonjian@aidi-sz.com',
      };
    },
  },
};
</script>
