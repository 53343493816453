<template>
  <input-config
    v-if="data.name === 'Input'"
    :data="data"
  />
  <textarea-config
    v-else-if="data.name === 'Textarea'"
    :data="data"
  />
  <phone-config
    v-else-if="data.name === 'Phone'"
    :data="data"
  />
  <password-config
    v-else-if="data.name === 'Password'"
    :data="data"
  />

  <radio-config
    v-else-if="data.name === 'Radio'"
    :data="data"
  />
  <checkbox-config
    v-else-if="data.name === 'Checkbox'"
    :data="data"
  />
  <select-config
    v-else-if="data.name === 'Select'"
    :data="data"
  />
  <time-picker-config
    v-else-if="data.name === 'TimePicker'"
    :data="data"
  />
  <time-picker-multiple-config
    v-else-if="data.name === 'TimePickerMultiple'"
    :data="data"
  />
  <date-picker-config
    v-else-if="data.name === 'DatePicker'"
    :data="data"
  />
  <date-picker-multiple-config
    v-else-if="data.name === 'DatePickerMultiple'"
    :data="data"
  />

  <rate-config
    v-else-if="data.name === 'Rate'"
    :data="data"
  />
  <switch-config
    v-else-if="data.name === 'Switch'"
    :data="data"
  />
  <input-number-config
    v-else-if="data.name === 'InputNumber'"
    :data="data"
  />

  <link-config
    v-else-if="data.name === 'Link'"
    :data="data"
  />
  <divider-config
    v-else-if="data.name === 'Divider'"
    :data="data"
  />
  <layout-config
    v-else-if="data.name === 'Layout'"
    :data="data"
  />
</template>

<script>
import AllComponents from './components';
export default {
  name: 'WhichComponents',
  components: { ...AllComponents },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
