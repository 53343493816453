var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-component-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "研究名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 }
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方案编号", prop: "protocolNo" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.protocolNo,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "protocolNo", $$v)
                          },
                          expression: "ruleForm.protocolNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "试验OID", prop: "oid" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.oid,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "oid", $$v)
                          },
                          expression: "ruleForm.oid"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申办方", prop: "sponsor" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.sponsor,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "sponsor", $$v)
                          },
                          expression: "ruleForm.sponsor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "主要研究者",
                        prop: "principalInvestigator"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.principalInvestigator,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "principalInvestigator", $$v)
                          },
                          expression: "ruleForm.principalInvestigator"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "研究期间", required: "" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "date-picker",
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.ruleForm.planned,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "planned", $$v)
                          },
                          expression: "ruleForm.planned"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入组期间",
                        prop: "enrollment",
                        required: ""
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "date-picker",
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.ruleForm.enrollment,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "enrollment", $$v)
                          },
                          expression: "ruleForm.enrollment"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "计划入组", prop: "expectedEnrollment" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "expectedEnrollment",
                        attrs: {
                          autocomplete: "off",
                          type: "number",
                          min: 1,
                          onkeypress:
                            "return (/[\\d]/.test(String.fromCharCode(event.keyCode)))"
                        },
                        model: {
                          value: _vm.ruleForm.expectedEnrollment,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "expectedEnrollment", $$v)
                          },
                          expression: "ruleForm.expectedEnrollment"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盲态", prop: "blindStatus" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.blindStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "blindStatus", $$v)
                            },
                            expression: "ruleForm.blindStatus"
                          }
                        },
                        [
                          _c("el-radio", {
                            attrs: { label: "开放", value: "open" }
                          }),
                          _c("el-radio", {
                            attrs: { label: "单盲", value: "single" }
                          }),
                          _c("el-radio", {
                            attrs: { label: "双盲", value: "double" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "试验分期", prop: "stage" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.stage,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "stage", $$v)
                            },
                            expression: "ruleForm.stage"
                          }
                        },
                        [
                          _vm._l(_vm.nperList, function(item, index) {
                            return _c(
                              "el-radio",
                              { key: index, attrs: { label: item.value } },
                              [_vm._v(" " + _vm._s(item.text) + "期 ")]
                            )
                          }),
                          _c("el-radio", { attrs: { label: "其他" } })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方案版本号", prop: "protocolVersion" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.protocolVersion,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "protocolVersion", $$v)
                          },
                          expression: "ruleForm.protocolVersion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }