<template>
  <el-input
    v-if="model"
    v-model="model[data.fieldId]"
    show-password
    :placeholder="data.placeholder"
    :prefix-icon="data.setting.prefixIcon"
    :suffix-icon="data.setting.suffixIcon"
  />
  <el-input
    v-else
    v-model="data.value"
    show-password
    :placeholder="data.placeholder"
    :prefix-icon="data.setting.prefixIcon"
    :suffix-icon="data.setting.suffixIcon"
  />
</template>

<script>
export default {
  name: 'TpPassword',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
