var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload_password" }, [
    _c(
      "div",
      { staticClass: "upload_password_form" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "status-icon": "",
              rules: _vm.rules,
              "label-width": "100px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "密码", prop: "pass" } },
              [
                _c("el-input", {
                  attrs: { type: "password", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.pass,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "pass", $$v)
                    },
                    expression: "ruleForm.pass"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "确认密码", prop: "checkPass" } },
              [
                _c("el-input", {
                  attrs: { type: "password", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.checkPass,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "checkPass", $$v)
                    },
                    expression: "ruleForm.checkPass"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload_password_form-item" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.submitForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v(" 提交 ")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.resetForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v(" 重置 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }