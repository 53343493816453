<template>
  <div>
    <AdInput v-if="data.formType === 'input'" :itemItemsCode="itemItemsCode" :data="data" :model="model" />
    <AdNumInput v-else-if="data.formType === 'numInput'" :data="data" :model="model" :itemItemsCode="itemItemsCode" />
    <AdTextarea v-else-if="data.formType === 'textarea'" :data="data" :model="model" :itemItemsCode="itemItemsCode" />
    <AdRadio v-else-if="data.formType === 'radio'" :data="data" :model="model" />
    <AdCheckbox v-else-if="data.formType === 'checkbox'" :data="data" :model="model" />
    <AdSelect v-else-if="data.formType === 'select'" :data="data" :model="model" :itemItemsCode="itemItemsCode" />
    <AdTimePicker v-else-if="data.formType === 'timePicker'" :data="data" :model="model" />
    <AdDatePicker
      v-else-if="data.formType === 'datePicker'"
      :data="data"
      :model="model"
      :itemItemsCode="itemItemsCode"
    />
  </div>
</template>

<script>
import AllComponents from './components.js';
export default {
  name: 'WhichComponents',
  components: { ...AllComponents },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'MakeForm',
    },
    prop: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      required: true,
    },
    itemItemsCode: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.which-com-container {
  background-color: #fff;
}
</style>
