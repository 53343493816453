<template>
  <div>
    <el-select v-model="model[itemItemsCode][`${data.itemCode}`]">
      <el-option
        v-for="item in data.configuration.allowableValues"
        :key="item.label"
        :label="item.label"
        :value="item.label"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'AdSelect',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
    itemItemsCode: {
      type: String,
      default: '',
    },
  },
};
</script>
