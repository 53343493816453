<template>
  <div class="email">
    <div>
      <el-form ref="ruleForm" :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
        <div class="form-item">
          <el-input v-model="ruleForm.email" placeholder="请输入邮箱" style="width: 290px;" />
        </div>
        <div class="form-item">
          <el-input v-model="ruleForm.code" style="width: 290px;" placeholder="请输入验证码">
            <el-button slot="append" class="code-btn" :disabled="emailDisabled" @click="handleVerifyCode">
              {{ emailBtnTxt }}
            </el-button>
          </el-input>
        </div>

        <div class="form_bottom">
          <el-button type="primary" :disabled="ruleForm.code == ''" @click="updatePhone()">
            确定
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import debounce from '@u/debounce';
export default {
  props: {
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ruleForm: {
        email: '',
        code: '',
      },
      emailDisabled: false,
      emailBtnTxt: '获取验证码',
      active: 1,
      activateInterval: false,
      phoneType: 'authUpdateEmail',
    };
  },
  methods: {
    updatePhone() {
      if (this.ruleForm.code) {
        let { email, code } = this.ruleForm;
        let token = this.token;
        let phoneObj = {
          email,
          code,
          token,
        };
        this.$post(this.urls.account.update_email, phoneObj)
          .then(() => {
            this.$message.success('更新成功');
            this.ruleForm.email = '';
            this.ruleForm.code = '';
            setTimeout(() => {
              this.$router.go(0);
            }, 1000);
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.updatePhone.VERIFICATION_CODE_EXPIRED.code:
                this.$message.error(this.codes.login.VERIFICATION_CODE_EXPIRED.text);
                break;
              case this.codes.updatePhone.VERIFICATION_CODE_ERROR.code:
                this.$message.error(this.codes.updatePhone.VERIFICATION_CODE_ERROR.text);
                break;
              case this.codes.updatePhone.TOKEN_INVALID.code:
                this.$message.error(this.codes.updatePhone.TOKEN_INVALID.text);
                break;
            }
          });
      } else {
        this.$message.error('验证码有误');
      }
    },
    // 验证手机号、邮箱
    validateRules() {
      if (!this.ruleForm.email) {
        this.$message.error('邮箱不能为空！');
      } else if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.ruleForm.email)) {
        this.$message.error('邮箱格式不正确！');
      } else {
        return true;
      }
      return false;
    },

    // 获取验证码按钮
    handleVerifyCode: debounce(function() {
      if (this.validateRules()) {
        let emailObj = {
          email: this.ruleForm.email,
        };
        this.$post(this.urls.account.send_email_code, emailObj)
          .then(() => {
            this.$message.success('验证码发送成功');
            this.sendCodeBtn();
          })
          .catch((err) => {
            switch (err.code) {
              case this.codes.login.ACCOUNT_email_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_email_NOT_EXIST.text);
                break;
              case this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.code:
                this.$message.error(this.codes.login.ACCOUNT_EMAIL_NOT_EXIST.text);
                break;
            }
          });
      }
    }, 400),
    // 获取验证码倒计时
    sendCodeBtn() {
      if (this.ruleForm.code) {
        this.ruleForm.code = '';
      }
      let time = 59;
      let timer = setInterval(() => {
        if (time === 0 || this.activateInterval) {
          clearInterval(timer);
          this.emailDisabled = false;
          this.emailBtnTxt = '获取验证码';
        } else {
          this.emailBtnTxt = time + '秒后重试';
          this.emailDisabled = true;
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.email {
  .email_header {
    .el-step__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
.email {
  .email_header {
    width: 400px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .form-item {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .code-btn {
      background: #409eff;
      border-radius: 0;
      font-size: 14px;

      color: #ffffff;
    }
  }
  .form_bottom {
    margin-top: 66px;
    display: flex;
    justify-content: center;
  }
}
</style>
