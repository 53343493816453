<template>
  <div class="tags-add-container">
    <ad-tags
      v-for="tagGroup in tags"
      :key="tagGroup.tagGroupId"
      :value="tagGroup"
      @handleTagGroupDelClick="handleTagGroupDelClick"
      @handleDelete="handleDelete"
      @handleSubmitClick="handleTagsSubmitClick"
      @handleCloseClick="handleTagsCloseClick"
    />
    <el-button class="el-btn" type="primary" style="margin:20px" @click="handleLabelGroupClick">
      添加组
    </el-button>
    <div />
    <ad-dialog
      :dialog="dialog"
      label="名称"
      :message="message"
      :title="title"
      :url="url"
      :parame="parame"
      :formName="formName"
      :list="list"
      :type="type"
      @close="handleCloseClick"
      @submit="HandleSubmitClick"
      @onCancelClick="onCancelClick"
    />
  </div>
</template>

<script>
import tags from '../template/sys/components/label-item.vue';
import dialog from '../template/sys/components/dialog.vue';
export default {
  name: '',
  components: {
    'ad-tags': tags,
    'ad-dialog': dialog,
  },
  filters: {},
  props: {},
  data() {
    return {
      tags: [],
      dialog: false,
      formName: '',
      message: '',
      title: '',
      url: '',
      list: [],
      parame: {},
      type: 'label',
      item: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getListTags();
  },
  mounted() {},
  methods: {
    handleLabelGroupClick() {
      this.dialog = true;
      this.formName = null;
      this.message = '添加成功';
      this.title = '添加标签组';
      this.type = 'labelGroup';
      this.url = this.urls.application_tag.create_tag_group;
      this.list = this.tags;
      this.parame = {};
    },
    getListTags() {
      this.$post(this.urls.application_tag.list_tags, {})
        .then((result) => {
          this.tags = result;
        })
        .catch(() => {});
    },

    HandleSubmitClick() {
      this.getListTags();
      this.dialog = false;
    },
    handleCloseClick() {
      this.dialog = false;
    },
    // 删除子项
    handleDelete(item) {
      this.$post(this.urls.application_tag.delete_tag, {
        applicationTagId: item.applicationTagId,
      }).then(() => {
        this.getListTags();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      });
    },
    // 删除组
    handleTagGroupDelClick(item) {
      this.$post(this.urls.application_tag.delete_group, {
        tagGroupId: item.tagGroupId,
      }).then(() => {
        this.getListTags();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      });
    },
    handleTagsSubmitClick() {
      this.getListTags();
    },
    handleTagsCloseClick() {},
    onCancelClick() {
      this.dialog = false;
      this.title = '';
      this.formName = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.tags-add-container {
  .el-btn {
    margin: 20px;
  }
}
</style>
