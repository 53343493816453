<template>
  <item-box classSuffix="checkbox">
    <el-form-item label="标签">
      <el-input v-model="data.label" />
    </el-form-item>
    <!-- <el-form-item label="远程数据">
      <el-switch
        v-model="data.setting.isRemote"
        active-text="是"
        inactive-text="否"
      />
    </el-form-item> -->
    <el-form-item
      v-if="!data.setting.isRemote"
      label="选项"
    >
      <el-checkbox-group v-model="data.value">
        <div
          v-for="item in data.selections"
          :key="item.value"
          class="ad-mf-setting-checkbox--checkbox"
        >
          <template v-if="item.value !== editCheckbox">
            <el-checkbox :label="item.value">
              <span v-text="item.label" />
            </el-checkbox>
            <span class="ad-mf-setting-checkbox--checkbox--edit">
              <i
                class="el-icon-edit-outline"
                @click.stop="editCheckboxSelect(item)"
              /></span>
            <span class="ad-mf-setting-checkbox--checkbox--delete">
              <i
                class="el-icon-delete"
                @click.stop="delCheckbox(item)"
              /></span>
            <span
              v-show="data.value.includes(item.value)"
              class="ad-mf-setting-checkbox--checkbox--default-text"
            >默认选择</span>
          </template>
          <template v-else>
            <div class="ad-mf-setting-checkbox--edit">
              <el-form
                :inline="true"
                :model="editForm"
                class="ad-mf-setting-checkbox--edit--form"
                :rules="rules"
              >
                <el-form-item prop="label">
                  <el-input
                    v-model="editForm.label"
                    size="mini"
                    placeholder="请输入修改的名称"
                    clearable
                  />
                </el-form-item>
              </el-form>
              <el-button
                type="primary"
                icon="el-icon-check"
                size="mini"
                @click="editSaveClick(item)"
              />
              <!-- @click="add" -->
              <el-button
                type="info"
                icon="el-icon-close"
                size="mini"
                @click="editCheckbox=''"
              />
            </div>
          </template>
        </div>
      </el-checkbox-group>
      <div
        v-if="inputing"
        class="ad-mf-setting-checkbox--add"
      >
        <el-form
          :inline="true"
          :model="addForm"
          class="ad-mf-setting-checkbox--add--form"
          :rules="rules"
        >
          <el-form-item prop="label">
            <el-input
              v-model="addForm.label"
              size="mini"
              placeholder="请输入标签"
            />
          </el-form-item>
          <!-- <el-form-item prop="value">
            <el-input
              v-model="addForm.value"
              size="mini"
              placeholder="请输入值"
            />
          </el-form-item> -->
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-check"
          size="mini"
          @click="add"
        />
        <el-button
          type="info"
          icon="el-icon-close"
          size="mini"
          @click="inputing=!inputing"
        />
      </div>
      <el-button
        v-else
        type="text"
        icon="el-icon-plus"
        size="mini"
        @click="inputing=!inputing;addForm.label='';addForm.value=''"
      >
        添加一项
      </el-button>
    </el-form-item>
    <el-form-item label="必填">
      <el-switch
        v-model="required"
        active-text="是"
        inactive-text="否"
      />
    </el-form-item>
    <!-- <template v-else>
      <el-form-item label="请求地址">
        <el-input
          v-model="data.setting.remoteUrl"
          placeholder="请输入远程地址"
          @blur="getRemoteData"
        />
      </el-form-item>
      <el-form-item label="数据路径">
        <el-input
          v-model="data.setting.remoteDataPath"
          placeholder="请输入远程获取路径"
        >
          <template slot="prepend">
            data.
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="Label">
        <el-input
          v-model="data.setting.remoteDataProps.label"
          placeholder="远程数据中的label"
        />
      </el-form-item>
      <el-form-item label="Value">
        <el-input
          v-model="data.setting.remoteDataProps.value"
          placeholder="远程数据中的value"
        />
      </el-form-item>
      <el-form-item label="远程数据">
        <div v-if="!remoteData || remoteData.length === 0">
          暂无数据
        </div>
        <el-checkbox-group v-model="data.value">
          <div
            v-for="item in remoteData"
            :key="item[data.setting.remoteDataProps.value]"
            class="ad-mf-setting-checkbox--checkbox"
          >
            <el-checkbox :label="item[data.setting.remoteDataProps.value]">
              <span v-text="item[data.setting.remoteDataProps.label]" />
            </el-checkbox>
            <span class="ad-mf-setting-checkbox--checkbox--delete">
              <i
                class="el-icon-delete"
                @click.stop="delCheckbox(item)"
              /></span>
            <span
              v-show="data.value.includes(item[data.setting.remoteDataProps.value])"
              class="ad-mf-setting-checkbox--checkbox--default-text"
            >默认选择</span>
          </div>
        </el-checkbox-group>
      </el-form-item>
    </template> -->
  </item-box>
</template>

<script>
import _ from 'lodash';
import ItemBox from '../common/ItemBox';
import Mixins from '../common/mixins';
export default {
  name: 'CheckboxConfig',
  components: { ItemBox },
  mixins: [Mixins],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputing: false,
      addForm: {
        label: '',
        // value: '',
      },
      rules: {
        label: [{ required: true, message: '请输入标签', trigger: 'blur' }],
        // value: [{ required: true, message: '请输入值', trigger: 'blur' }],
      },
      editCheckbox: '',
      editForm: {
        label: '',
      },
    };
  },
  watch: {
    data(v) {
      this.required = v.rules.required ? v.rules.required.required : false;
    },
  },
  methods: {
    delCheckbox(item) {
      const i = this.data.selections.findIndex((s) => s === item);
      this.data.selections.splice(i, 1);
      const j = this.data.value.indexOf(item.value);
      if (j > -1) {
        this.data.value.splice(j, 1);
      }
    },
    add() {
      if (_.trim(this.addForm.label) !== '') {
        const addFormValue =
          this.data.selections.length !== 0
            ? Number(this.data.selections[this.data.selections.length - 1].value) + 1
            : 0;
        this.addForm.value = addFormValue.toString();
        this.data.selections.push({ ...this.addForm });
        this.inputing = !this.inputing;
      }
    },
    editCheckboxSelect(item) {
      this.editCheckbox = item.value;
      this.editForm.label = item.label;
    },
    editSaveClick(item) {
      if (_.trim(this.editForm.label) !== '') {
        item.label = this.editForm.label;
        this.editCheckbox = '';
        this.editForm.label = '';
      }
    },
  },
};
</script>
