var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "login-form" }, [
      _c(
        "div",
        { staticClass: "form" },
        [
          _vm._m(0),
          _c("div", { staticClass: "form_type" }, [
            _c("div", { staticClass: "form_type_title" }, [
              _vm._v(" 验证方式 ")
            ]),
            _c(
              "div",
              {
                class: [{ "way-actived": _vm.isPhone }, "form_type_item"],
                on: {
                  click: function($event) {
                    return _vm.changeType("phone")
                  }
                }
              },
              [_vm._v(" 手机验证 ")]
            ),
            _c("div", { staticClass: "way-tip" }, [_vm._v(" | ")]),
            _c(
              "div",
              {
                class: [{ "way-actived": !_vm.isPhone }, "form_type_item"],
                on: {
                  click: function($event) {
                    return _vm.changeType("email")
                  }
                }
              },
              [_vm._v(" 邮箱验证 ")]
            )
          ]),
          _vm.isPhone
            ? _c(
                "el-form",
                {
                  ref: "verifyForm",
                  attrs: {
                    model: _vm.verifyForm,
                    "auto-complete": "on",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "username-form-item",
                      attrs: { prop: "email" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "shoujihao" }
                          })
                        ],
                        1
                      ),
                      _c("el-input", {
                        ref: "email",
                        attrs: {
                          placeholder: "手机号",
                          name: "phone",
                          type: "text",
                          autocomplete: "off",
                          maxlength: "11"
                        },
                        model: {
                          value: _vm.verifyForm.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.verifyForm, "phone", $$v)
                          },
                          expression: "verifyForm.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "verify-box" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "password-form-item",
                          attrs: { prop: "password" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "yanzhengma" }
                              })
                            ],
                            1
                          ),
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: "text",
                              placeholder: "请输入验证码",
                              name: "password",
                              tabindex: "2",
                              autocomplete: "on"
                            },
                            model: {
                              value: _vm.verifyForm.code,
                              callback: function($$v) {
                                _vm.$set(_vm.verifyForm, "code", $$v)
                              },
                              expression: "verifyForm.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "show-pwd code-btn",
                          attrs: { disabled: _vm.phoneDisabled },
                          on: { click: _vm.handleVerifyCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.phoneBtnTxt) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "el-btn",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        disabled: _vm.verifyForm.code == ""
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm($event)
                        }
                      }
                    },
                    [_vm._v(" 确定 ")]
                  )
                ],
                1
              )
            : _c(
                "el-form",
                {
                  ref: "verifyForm",
                  attrs: {
                    model: _vm.verifyForm,
                    "auto-complete": "on",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "username-form-item",
                      attrs: { prop: "email" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "youxiang" }
                          })
                        ],
                        1
                      ),
                      _c("el-input", {
                        ref: "email",
                        attrs: {
                          placeholder: "请输入邮箱",
                          name: "email",
                          type: "text",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.verifyForm.email,
                          callback: function($$v) {
                            _vm.$set(_vm.verifyForm, "email", $$v)
                          },
                          expression: "verifyForm.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "verify-box" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "password-form-item",
                          attrs: { prop: "password" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "yanzhengma" }
                              })
                            ],
                            1
                          ),
                          _c("el-input", {
                            ref: "password",
                            attrs: {
                              type: "text",
                              placeholder: "请输入验证码",
                              name: "code",
                              tabindex: "2",
                              autocomplete: "on"
                            },
                            model: {
                              value: _vm.verifyForm.code,
                              callback: function($$v) {
                                _vm.$set(_vm.verifyForm, "code", $$v)
                              },
                              expression: "verifyForm.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "show-pwd code-btn",
                          attrs: { disabled: _vm.emailDisabled },
                          on: { click: _vm.handleVerifyCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.emailBtnTxt) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "el-btn",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        disabled: _vm.verifyForm.code == ""
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm($event)
                        }
                      }
                    },
                    [_vm._v(" 确定 ")]
                  )
                ],
                1
              )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_title" }, [
      _c("h2", [_vm._v("微信账号绑定")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }