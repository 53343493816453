<template>
  <div
    v-loading="loading"
    class="ad-mf-layout"
  >
    <div
      v-if="crfStatus==='pending'"
      class="ad-mf-layout--left"
    >
      <Items :componentsList="componentsList" />
    </div>
    <div class="ad-mf-layout--center">
      <div class="ad-mf-layout--center--content">
        <Content
          :status="status"
          @change="contentChange"
          @select-change="contentSelectChange"
        />
        <!-- @acquireJson="acquireJson"
          @handleLockClick="handleLockClick" -->
      </div>
      <div
        v-if="status==='pending'"
        class="ad-mf-layout--center--btns"
      >
        <el-button
          icon="el-icon-bell"
          type="primary"
          @click="saveClick"
        >
          保存
        </el-button>
        <el-button
          icon="el-icon-lock"
          type="primary"
          @click="lockClick"
        >
          锁定并发布
        </el-button>
      </div>
    </div>
    <template v-if="crfStatus==='pending'">
      <div class="ad-mf-layout--right">
        <Setting
          v-if="form.formDataList.length && settingData"
          :data="settingData"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Items from './Items';
import Content from './Content';
import Setting from './Setting';
import componentsList from '../configForm';

const exceptComponents = ['Link', 'Divider'];

export default {
  name: 'MakeFormLayout',
  components: { Items, Content, Setting },
  provide() {
    return {
      formData: this.form,
      contentSelectChange: this.contentSelectChange,
    };
  },
  props: {
    echoData: {
      type: Array,
      default: () => [],
    },
    // pending, publish
    crfStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentsList: componentsList,
      form: {
        formDataList: [
          // {
          //   label: '单行文本',
          //   length: null,
          //   name: 'Input',
          //   placeholder: '请输入',
          //   position: 1,
          //   required: false,
          //   selections: [],
          // },
          // {
          //   fieldId: 'Input_1611912139811',
          //   label: '单行文本',
          //   makeStyle: {
          //     active: false,
          //   },
          //   maxlength: '',
          //   name: 'Input',
          //   placeholder: '请输入1',
          //   rules: {},
          //   style: {},
          //   value: '',
          // },
          // {
          //   fieldId: 'Input_9de704fd191f44a996acfbe106f5660e',
          //   label: '单行文本',
          //   makeStyle: {
          //     active: false,
          //   },
          //   maxlength: null,
          //   name: 'Input',
          //   placeholder: '请输入',
          //   rules: {
          //     required: {
          //       message: '不能为空',
          //       required: true,
          //       trigger: 'blur',
          //     },
          //   },
          //   style: {},
          // },
        ],
        formStyle: {
          hideLabel: false,
          labelWidth: '85px',
          labelPosition: 'top',
          showRequiredAsterisk: true,
          showVerificationMessage: true,
          size: 'medium',
          labelSuffix: ':',
        },
        model: {
          Input_1611912139811: '',
        },
      },
      settingData: null,
      // filterJson: [],
      status: '',
      loading: false,
    };
  },
  watch: {
    echoData(e) {
      this.settingData = null;
      this.form.formDataList = e;
      this.loading = false;
    },
    crfStatus(e) {
      this.status = e;
    },
  },
  created() {},
  methods: {
    $getFormDataJSON() {
      this.form.formDataList.forEach((item) => {
        if (item.name === 'Layout') {
          item.setting.colList.forEach((col) => {
            if (col && exceptComponents.indexOf(col.name) === -1) {
              this.form.model[col.fieldId] = col.value;
            }
          });
        } else if (exceptComponents.indexOf(item.name) === -1) {
          this.form.model[item.fieldId] = item.value;
        }
      });
      return JSON.stringify(this.form);
    },
    $getFormDataByVueSet() {
      this.form.formDataList.forEach((item) => {
        if (item.name === 'Layout') {
          item.setting.colList.forEach((col) => {
            if (col && exceptComponents.indexOf(col.name) === -1) {
              this.$set(this.form.model, col.fieldId, col.value);
            }
          });
        } else if (exceptComponents.indexOf(item.name) === -1) {
          this.$set(this.form.model, item.fieldId, item.value);
        }
      });
      return this.form;
    },
    saveClick() {
      this.loading = true;
      this.$emit('acquireJson', this.filterJsonFun());
    },
    lockClick() {
      this.loading = true;
      this.$emit('handleLockClick', this.filterJsonFun());
    },
    contentSelectChange(item) {
      this.settingData = item;
    },
    contentChange(list) {
      this.form.formDataList = list;
    },
    modifyArrFun(formDataList) {
      let paramArr = [];
      if (formDataList.length !== 0) {
        formDataList.forEach((element, index) => {
          const obj = {
            placeholder: '',
            position: 0,
            required: false,
          };
          for (const key in obj) {
            obj[key] = element[key];
          }
          // 针对选择样式的处理
          obj.itemName = element.name;
          obj.itemLabel = element.label;
          if (
            element.name === 'Checkbox' ||
            element.name === 'Radio' ||
            element.name === 'Select'
          ) {
            let arr = [];
            element.selections.forEach((item) => {
              arr.push({
                itemSelectionLabel: item.label,
                itemSelectionValue: item.value,
              });
            });
            obj.selections = arr;
            // if (element.name === 'Checkbox') {
            //   obj.selections = this.filterArr(element.selections, element.value);
            // } else {
            //   obj.selections = this.filterObj(element.selections, element.value);
            // }
          }
          // 针对输入框的处理
          if (element.name === 'Input' || element.name === 'Textarea') {
            obj.length = element.maxlength;
          }
          // 针对是否勾选
          if (element.rules.required) {
            obj.required = element.rules.required.required;
          } else {
            obj.required = false;
          }
          if (element.itemId) obj.itemTemplateId = element.itemId;
          // 默认值
          obj.defaultValue = element.value;
          obj.deleted = element.deleted;
          obj.position = index + 1;
          paramArr.push(obj);
        });
      }
      return [...paramArr].filter((item) => !(item.itemTemplateId === undefined && item.deleted));
    },
    filterJsonFun() {
      // const filterJson = this.modifyArrFun(this.$getFormDataByVueSet().formDataList);
      // this.filterJson = filterJson;
      return this.modifyArrFun(this.$getFormDataByVueSet().formDataList);
    },
  },
};
</script>
