<template>
  <el-radio-group v-model="model[(`${data.name}`)]">
    <el-radio
      v-for="r in data.configuration.allowableValues|| data.selections"
      :key="r.value"
      :label="r.value"
    >
      {{ r.cnValue }}
    </el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'AdRadio',
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
