var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMakeForm
    ? _c(
        "div",
        {
          class: [
            "ad-mf-item",
            { "ad-mf-item__active": _vm.data.makeStyle.active }
          ]
        },
        [
          _vm.showMask
            ? _c("div", { staticClass: "ad-mf-item--mask" })
            : _vm._e(),
          _vm.status === "pending"
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.makeStyle.active,
                        expression: "data.makeStyle.active"
                      }
                    ],
                    staticClass: "ad-mf-item--op"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "ad-mf-item--op--copy",
                        on: { click: _vm.copy }
                      },
                      [_c("i", { staticClass: "el-icon-document-copy" })]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ad-mf-item--op--delete",
                        on: { click: _vm.del }
                      },
                      [_c("i", { staticClass: "el-icon-delete" })]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              class: {
                required:
                  _vm.required && _vm.formData.formStyle.showRequiredAsterisk
              },
              attrs: {
                label:
                  _vm.formData.formStyle.hideLabel || _vm.data.style.hideLabel
                    ? ""
                    : _vm.data.label,
                "label-width":
                  _vm.formData.formStyle.hideLabel || _vm.data.style.hideLabel
                    ? "0"
                    : ""
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        2
      )
    : _c(
        "el-form-item",
        {
          class: {
            required:
              _vm.required && _vm.formData.formStyle.showRequiredAsterisk
          },
          attrs: {
            label:
              _vm.formData.formStyle.hideLabel || _vm.data.style.hideLabel
                ? ""
                : _vm.data.label,
            "label-width":
              _vm.formData.formStyle.hideLabel || _vm.data.style.hideLabel
                ? "0"
                : "",
            prop: _vm.prop
          }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }