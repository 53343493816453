import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5bb59e50&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\development_idea_workspace\\中山一院\\aidi_web_recruiting_sso\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bb59e50')) {
      api.createRecord('5bb59e50', component.options)
    } else {
      api.reload('5bb59e50', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5bb59e50&", function () {
      api.rerender('5bb59e50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dynamicform/components/componentList/input-number/index.vue"
export default component.exports