import dtCache from '@u/stroageEncapsulation';

const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  account: (state) => {
    if (
      state.user.account.auid === '' &&
      state.user.account.avatarUrl === '' &&
      state.user.account.name === '' &&
      state.user.account.registerTime === ''
    ) {
      const account = dtCache.getlocalFun('account');
      if (account === null) {
        return null;
      }
      return account;
    } else {
      return state.user.account;
    }
  },
  username: () => {
    const username = dtCache.getlocalFun('username');
    if (username === null) {
      return null;
    }
    return username;
  },

  // 新增权限列表的状态
  p: (state) => {
    if (state.user.p == null || state.user.p.length === 0) {
      const p = dtCache.getlocalFun('p');
      if (p === null) {
        return state.user.p;
      }
      return p;
    } else {
      return state.user.p;
    }
  },
  // 角色
  role: (state) => {
    if (state.user.role.name === '' && state.user.role.roleId === '') {
      const role = dtCache.getlocalFun('role');
      if (role === null) {
        return null;
      }
      return role;
    } else {
      return state.user.role;
    }
  },
  stepsStatus: (state) => state.subject.stepsStatus,
  permission_routes: (state) => state.permission.routes,
  custom_data: (state) => {
    if (state.user.custom_data.length === 0) {
      const custom_data = dtCache.getlocalFun('custom_data');
      if (custom_data === null) {
        return state.user.custom_data;
      }
      return custom_data;
    } else {
      return state.user.custom_data;
    }
  },
};
export default getters;
