var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-add-container" },
    [
      _vm._l(_vm.tags, function(tagGroup) {
        return _c("ad-tags", {
          key: tagGroup.tagGroupId,
          attrs: { value: tagGroup },
          on: {
            handleTagGroupDelClick: _vm.handleTagGroupDelClick,
            handleDelete: _vm.handleDelete,
            handleSubmitClick: _vm.handleTagsSubmitClick,
            handleCloseClick: _vm.handleTagsCloseClick
          }
        })
      }),
      _c(
        "el-button",
        {
          staticClass: "el-btn",
          staticStyle: { margin: "20px" },
          attrs: { type: "primary" },
          on: { click: _vm.handleLabelGroupClick }
        },
        [_vm._v(" 添加组 ")]
      ),
      _c("div"),
      _c("ad-dialog", {
        attrs: {
          dialog: _vm.dialog,
          label: "名称",
          message: _vm.message,
          title: _vm.title,
          url: _vm.url,
          parame: _vm.parame,
          formName: _vm.formName,
          list: _vm.list,
          type: _vm.type
        },
        on: {
          close: _vm.handleCloseClick,
          submit: _vm.HandleSubmitClick,
          onCancelClick: _vm.onCancelClick
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }