var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemItemsCode
        ? _c("el-input", {
            attrs: {
              placeholder: "请输入" + _vm.data.itemLabel,
              oninput: "value=value.replace(/[^0-9.]/g,'')"
            },
            model: {
              value: _vm.model[_vm.itemItemsCode]["" + _vm.data.itemCode],
              callback: function($$v) {
                _vm.$set(
                  _vm.model[_vm.itemItemsCode],
                  "" + _vm.data.itemCode,
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model[itemItemsCode][`${data.itemCode}`]"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }