var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-time-picker", {
        attrs: {
          "value-format": "timestamp",
          format: _vm.data.configuration.dateFormat,
          "picker-options": {
            selectableRange:
              _vm.dateFilter(_vm.data.configuration.minTime) +
              "-" +
              _vm.dateFilter(_vm.data.configuration.maxTime),
            format: _vm.data.configuration.timeFormat
          },
          placeholder: "选择" + _vm.data.cnName
        },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }